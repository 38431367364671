import {combineReducers} from 'redux';
import adminData from "./adminData";
import user from "./user";
import filters from "./filters";
import spinner from "./spinner";
import dashboardData from "./dashboardData";
import zoneData from "./zoneData";
import debugtoolData from "./debugtoolData";
import notifications from "./notifications";
import language from "./language";
import {reducer as toastr} from "react-redux-toastr";

const molengo = combineReducers({
    adminData,
    filters,
    dashboardData,
    zoneData,
    debugtoolData,
	user,
    spinner,
    notifications, //turned off adding case in reducer
    language,
    toastr
});

export default molengo;
