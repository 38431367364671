import {connect} from 'react-redux';
import EventsFormComponent from '../components/EventsFormComponent';
import {chooseEvent, setEditMode, setVisibilityAddForm, switchEvent} from "../actions/filters";
import {addEvent, deleteEvent, editEvent} from "../actions/apiRequests";


const mapStateToProps = ({ adminData, filters, dashboardData, language }) => {
    return {
        shops: adminData.shops,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        chosenEvent: dashboardData.chosenEvent,
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addEvent: (data)=> dispatch(addEvent(data)),
        editEvent: (id , data)=> dispatch(editEvent(id , data)),
        deleteEvent: (id)=> dispatch(deleteEvent(id)),
        switchEvent: (state) => dispatch(switchEvent(state)),
        chooseEvent: (event) => dispatch(chooseEvent(event)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        }
    };
};
const EventsFormContainer = connect(mapStateToProps,mapDispatchToProps)(EventsFormComponent);


export default EventsFormContainer;
