import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import UsersAdminComponent from '../../components/admin/UsersAdminComponent';
import {addUser, editUser, deleteUser, getUsers, complexRequest} from "../../actions/apiRequests";
import {setEditMode, setVisibilityAddForm} from "../../actions/filters";


const mapStateToProps = ({ adminData, filters, user, language }) => {
    return {
        users: adminData.users,
        malls: adminData.malls,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        role: filters.role,
        user,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addUser: (data)=> dispatch(addUser(data)),
        editUser: (id , data)=> dispatch(editUser(id , data)),
        deleteUser: (id)=> dispatch(deleteUser(id)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getUsers: (options="") => dispatch(getUsers(options)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType))
    };
};
const UsersAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(UsersAdminComponent));

export default UsersAdminContainer;
