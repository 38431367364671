import React, { Fragment, Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import classNames from 'classnames'

import LeftSidebarContainer from '../containers/LeftSidebarContainer'
import HeaderContainer from '../containers/HeaderContainer'
import DashboardContainer from '../pages/dashboard/DashboardContainer'
import EventsContainer from '../containers/EventsContainer'
import CompareComponent from './CompareComponent'
import ItemsComponent from './ItemsComponent'
import ReportsComponent from '../pages/reports/ReportsComponent'
import AdminComponent from './admin/AdminComponent'
import ErrorBoundary from "./ErrorBoundary";
import ProfileContainer from "../containers/ProfileContainer";
import DebugToolContainer from "../pages/debugtool/DebugToolContainer";

class BaseComponent extends Component {
    componentDidMount() {
        console.log('!!! componentDidMount BaseComponent')
        const {getCurrentSession, getMall_by_allMalls, getMall, user: {roles, malls_id}} = this.props;
        getCurrentSession();

        if (roles === "admin" || roles === "debugger") {  // this code done when, user relogin without reloading page
            getMall_by_allMalls({nvr: true, floor: true})
        } else if ((roles === "manager" || roles === "marketer") && malls_id) {
            getMall(malls_id, {nvr: true, floor: true})
        }
    }

    componentDidUpdate(prevProps){
        console.log('!!! componentDidUpdate BaseComponent')
        const {user: {roles, malls_id}, getMall_by_allMalls, getMall} = this.props;
        console.log('!!BaseComponent roles: ', roles)
        if (roles && roles !== prevProps.user.roles ) {  // get mall when data about user came
            if (roles === "admin" || roles === "debugger") {
                getMall_by_allMalls({nvr: true, floor: true})
            } else if ((roles === "manager" || roles === "marketer") && malls_id) {
                getMall(malls_id, {nvr: true, floor: true})
            }
        }
    }

    render() {
        const { role, newUser, isOpenSidebarMenu, language: { direction, data } } = this.props;
        return (
            <div className={classNames('main-wrapper', direction)}>
                <LeftSidebarContainer/>

                <div className={`right-panel ${!isOpenSidebarMenu ? "hide" : ""}`}>
                    <HeaderContainer/>
                    <ErrorBoundary language={data}>
                        <Switch>
                            { role === "debugger"
                            ?
                                <Fragment>
                                    <Route path="/admin/debugtool" component={DebugToolContainer}/>
                                    <Route path="/profile" component={ProfileContainer}/>

                                    <Redirect to={newUser ? "/profile" : "/admin/debugtool"}/>
                                </Fragment>
                            : (
                                <Fragment>
                                    <Route path="/dashboard" component={DashboardContainer}/>
                                    <Route path="/events" component={EventsContainer}/>
                                    <Route path="/compare" component={CompareComponent}/>
                                    <Route path="/items" component={ItemsComponent}/>
                                    <Route path="/reports" component={ReportsComponent}/>
                                    <Route path="/profile" component={ProfileContainer}/>
                                    {(role === "manager" || role === "admin") && <Route path="/admin" component={AdminComponent}/>}

                                    <Redirect to={newUser ? "/profile" : "/dashboard"}/>
                                </Fragment>
                            )}
                        </Switch>
                    </ErrorBoundary>
                </div>
            </div>
        )
    }
}

export default BaseComponent;
