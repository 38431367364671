import {connect} from 'react-redux';
import StatsSlider from './StatsSlider';
import {
    setChosenStatsBlock,
    setFilterTooltips,
} from '../../actions/filters';

const mapStateToProps = ({ dashboardData, language }) => {
    return {
        mallDailyStats: dashboardData.mallDailyStats,
        chosenStatsBlock: dashboardData.chosenStatsBlock,
        filterTooltips: dashboardData.filterTooltips,
        language,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setChosenStatsBlock:(state) => dispatch(setChosenStatsBlock(state)),
        setFilterTooltips:(state) => dispatch(setFilterTooltips(state)),
    }
}

const StatsSliderContainer = connect(mapStateToProps, mapDispatchToProps)(StatsSlider);

export default StatsSliderContainer
