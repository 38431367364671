import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";

import ChangeLanguageButton from "./ChangeLanguageButton";

const Login = ({logIn, history, language}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, serRemFlag] = useState(true);

    const {
        welcomeToTitle,
        loginViaMessage,
        loginTitle,
        usernameTitle,
        usernamePlaceholder,
        passwordTitle,
        passwordPlaceholder,
        rememberMeTitle,
        forgotPasswordTitle,
        copyrightMessage,
    } = language.unauthorizedSection;

    return (
        <>
            <KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => logIn({email, password}, remember)}/>
            <div className="main_block">
                <div className="welcome_wrap">
                    <h4>{welcomeToTitle}</h4>
                    <h5>{process.env.REACT_APP_PROJECT_TITLE}</h5>
                </div>

                <Form className="log_in_block">
                    <ChangeLanguageButton />

                    <h3>{loginTitle}</h3>
                    <p className="description">{loginViaMessage}</p>

                    <Form.Group>
                        <Form.Label>{usernameTitle}</Form.Label>

                        <Form.Control
                            type="email"
                            placeholder={usernamePlaceholder}
                            onKeyPress={(e) => e.key === "Enter" && logIn({email, password})}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>{passwordTitle}</Form.Label>

                        <Form.Control
                            type="password"
                            placeholder={passwordPlaceholder}
                            onKeyPress={(e) => e.key === "Enter" && logIn({email, password})}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <div className="option_wrap">
                        <Form.Group controlId="formBasicChecbox">
                            <Form.Check
                                type="checkbox"
                                custom
                                checked={remember}
                                label={rememberMeTitle}
                                onChange={e => serRemFlag(e.target.checked)}
                            />
                        </Form.Group>

                        <Button
                            className="forgot_pass-button"
                            onClick={() => history.push("/forgot")}
                        >
                            {forgotPasswordTitle}
                        </Button>
                    </div>

                    <Button
                        variant="outline-dark"
                        className="submit login"
                        onClick={() => logIn({email, password}, remember)}
                    >
                        {loginTitle}
                    </Button>

                    <p className="copyright">2018-{new Date().getFullYear()} {process.env.REACT_APP_PROJECT_TITLE} {copyrightMessage}</p>
                </Form>
            </div>
        </>
    )

};

export default withRouter(Login);
