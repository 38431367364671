import React, { useRef } from 'react';
import { Bar } from "react-chartjs-2";
import { isEmpty } from "lodash";

import FullScreenToggle from "./FullScreenToggle";

const Graph = ({ data, height, options, fullScreenMode = false, onGraphClick, showDetailVisitorsData, setVisibilityVisitorsDetailData }) =>{
    const wrapper = useRef();
    return <div className="bar-wrapper" ref={wrapper} style={{ height: `${height}px` }}>
        {fullScreenMode && <FullScreenToggle element={wrapper.current}/>}

        {showDetailVisitorsData && <button className="return" onClick={() => setVisibilityVisitorsDetailData(false)}/>}

        <Bar
            data={data}
            height={height}
            options={options}
            getElementAtEvent={elem => {
                let index = null
                let datasetIndex = null
                if (!isEmpty(elem) && !isEmpty(elem[0])) {
                    index = elem[0]._index
                    datasetIndex = elem[0]._datasetIndex
                }
                return onGraphClick(index, datasetIndex)
            }}
        />
    </div>
};

export default Graph;
