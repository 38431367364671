import {connect} from 'react-redux';
import Dashboard from './Dashboard';
import {
    setTimerange,
    setDaterange,
    setChosentopfloor,
    setCompareWith,
    removeVisitorsComparisonData,
    setCurrentDateRangeFlag,
    setVisibilityVisitorsDetailData,
    setVisibilityHeatmap,
    switchSwapStore,
} from '../../actions/filters';
import { complexRequest } from "../../actions/apiRequests";


const mapStateToProps = ({ dashboardData, filters, adminData, user, language }) => {
    return {
        // calendar: dashboardData.calendar,
        chosenStatsBlock: dashboardData.chosenStatsBlock,
        mallDailyStats: dashboardData.mallDailyStats,
        zonesDailyStats: dashboardData.zonesDailyStats,
        showSwapStore: filters.showSwapStore,

        heatmap:dashboardData.heatmap,
        visitors:dashboardData.visitors,
        mallshops:dashboardData.mallshops,
        topzones:dashboardData.topzones,
        zones:dashboardData.zones,
        chosenMall:dashboardData.chosenMall,
        showShop:filters.showShop,
        daterange:dashboardData.daterange,
        timerange:dashboardData.timerange,
        chosenTopFloor: dashboardData.chosenTopFloor,
        // showTopShopPopover: filters.showTopShopPopover,
        // showShopComparison: filters.showShopComparison,
        showZoneComparison: filters.showZoneComparison,
        isOpenSidebarMenu: filters.isOpenSidebarMenu,
        showStoresSelectList: filters.showStoresSelectList,
        malls: adminData.malls,
        isDateRangeConsistTodayDate: filters.isDateRangeConsistTodayDate,
        showDetailVisitorsData: filters.showDetailVisitorsData,
        showHeatmap: filters.showHeatmap,
        showHeatmapClustered: filters.showHeatmapClustered,
        user,
        language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
        setTimerange: (state) => dispatch(setTimerange(state)),
        setDaterange: (state) => dispatch(setDaterange(state)),
        setChosentopfloor: (state) => dispatch(setChosentopfloor(state)),
        setCompareWith:(state) => dispatch(setCompareWith(state)),
        setCurrentDateRangeFlag: state => dispatch(setCurrentDateRangeFlag(state)),
        removeVisitorsComparisonData:() => dispatch(removeVisitorsComparisonData()),
        setVisibilityVisitorsDetailData:state => dispatch(setVisibilityVisitorsDetailData(state)),
        setVisibilityHeatmap: state => dispatch(setVisibilityHeatmap(state)),
        switchSwapStore: state => dispatch(switchSwapStore(state)),
    }
};
const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);


export default DashboardContainer;
