import { isString, isArray } from "lodash";

export default function catchParser (e, name, { somethingBadHappenWithMessage, errorTitle }){
    if(e.response && e.response.data){
        const {result, response: {errors}} = e.response.data;
        return {
            result: result || errorTitle,
            description: isString(errors) ? errors : isArray(errors) ? errors.join(" ") : `${somethingBadHappenWithMessage} ${name}`
        }
    }else {
        return {
            result: errorTitle,
            description: `${somethingBadHappenWithMessage} ${name}`
        }
    }
}