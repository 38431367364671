export const statBlocks = [
    { id: 1, name: 'Conversion', unit: '%', tooltip: 'Amount of transactions divided by the foot traffic', apiKey: 'conversion' },
    { id: 2, name: 'Engagement', unit: '%', tooltip: 'The Average engagement ratio of all zones', apiKey: 'engagement' },
    { id: 3, name: 'Visitors', unit: '', tooltip: 'Amount of unique visitors at the store', apiKey: 'visitors' },
    { id: 4, name: 'Transactions', unit: '', tooltip: 'Amount of transactions at the store', apiKey: 'transactions' },
    { id: 5, name: 'Revenue', unit: '₪', tooltip: 'Sum of revenues at the store', apiKey: 'revenues' },
    { id: 6, name: 'Revenue Per Visitor', unit: '₪', tooltip: 'Sum of revenues divided by the visitors amount', apiKey: 'revenues_per_visitor' },
    { id: 7, name: 'Dwell Time', unit: 'm', tooltip: 'The average dwell time of visitors', apiKey: 'dwell_time' },
    { id: 8, name: 'Men', unit: '%', tooltip: 'The proportion of male visitors over the age of 18', apiKey: 'men' },
    { id: 9, name: 'Women', unit: '%', tooltip: 'The proportion of female visitors over the age of 18', apiKey: 'women' },
    { id: 10, name: 'Boys', unit: '%', tooltip: 'The proportion of male visitors under the age of 18', apiKey: 'boys' },
    { id: 11, name: 'Girls', unit: '%', tooltip: 'The proportion of female visitors under the age of 18', apiKey: 'girls' },
]