import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import {toastr} from "react-redux-toastr";
import {withRouter, NavLink} from "react-router-dom";
import KeyboardEventHandler from 'react-keyboard-event-handler';

import ChangeLanguageButton from "./ChangeLanguageButton";

const ForgotPassword = ({ history, forgotPassword, language}) => {
    const [email, setEmail] = useState("");
    const { notifications, unauthorizedSection } = language;
    const {
        attentionTitle,
        enterEmailMessage,
        enterCorrectEmailMessage,
    } = notifications;
    const {
        resetPasswordTitle,
        resetTitle,
        emailAddressTitle,
        emailPlaceholder,
    } = unauthorizedSection;

    const forgot = () =>{
        const reg = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

        if (email.trim() === "") {
            toastr.info(attentionTitle, enterEmailMessage);
        } else {
            if (reg.test(email)) {
                forgotPassword({email});
                history.push("/");
            } else {
                toastr.info(attentionTitle, enterCorrectEmailMessage);
            }
        }
    };


    return (
        <>
            <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => history.push("/login")}/>
            <KeyboardEventHandler handleEventType="keyup" handleKeys={['enter']} onKeyEvent={() => forgot()}/>

            <div className="main_block">
                <Form className="log_in_block">
                    <ChangeLanguageButton />

                    <NavLink className="close_btn" to="/login"/>

                    <h3>{resetPasswordTitle}</h3>

                    <Form.Group>
                        <Form.Label>{emailAddressTitle}</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={emailPlaceholder}
                            value={email}
                            onKeyPress={(e) => e.key === "Enter" && forgot()}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="outline-success" onClick={() => forgot()}>{resetTitle}</Button>
                </Form>
            </div>
        </>
    )
};

export default withRouter(ForgotPassword)
