import React, { Component } from 'react'
import { Card, Button, Overlay, OverlayTrigger, Popover, Row, Col } from "react-bootstrap"
import moment from 'moment'
import _ from 'lodash'
import Select from 'react-select'
// import { DateRange } from 'react-date-range'
import KeyboardEventHandler from "react-keyboard-event-handler"
// import momentDurationFormatSetup from 'moment-duration-format'
import { toastr } from "react-redux-toastr"

// import languages from '../../lang';

import GraphWrapper from './GraphWrapper'
import DashboardCalendarCompareContainer from './DashboardCalendarCompareContainer'
import { getDataGroupNew } from '../../helpers/dashboardUtils'
import '../../styles/ShopComparisonComponent.sass'

const GRAPHS = [
    { title: 'Visitors & conversion rate', dataFieldName: 'visitors', dailyData: false },
    { title: 'Revenue per zone', dataFieldName: 'revenue_per_zone', dailyData: false },
    { title: 'Revenue per visitor', dataFieldName: 'revenue_per_visitor', dailyData: true },
    { title: 'Conversion rate', dataFieldName: 'conversion_rate_by_day', dailyData: true },
    { title: 'Engagment', dataFieldName: 'engagement', dailyData: false },
    { title: '% Visited that zone', dataFieldName: 'percent_visited_zone', dailyData: false },
]

class ZoneComparisonComponent extends Component{
    constructor(props) {
        super(props);

        this.state = {
            chosenComparisonsZones: [],
        };

        this.modal = React.createRef()
    }

    componentDidMount() {
        // console.log('!!!!!!!!!!!!!!!! componentDidMount ZoneComparisonComponent props: ', this.props)
        document.getElementById("shop-comparison").addEventListener('mousedown', this.handleClickOutside);
        const {
            zonesCheckedList,
            zones,
        } = this.props
        const chosenComparisonsZones = ( zones.length ? zones.filter(({id}) => zonesCheckedList.includes(id)).map(({id, name}) => ({ value: id, label: name })) : [] )
        this.setState({ chosenComparisonsZones }, () => this.getCompareData())
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedComparisonDates !== this.props.selectedComparisonDates) { 
            // console.log('!!!!!!!!!!!!!!!! componentDidUpdate ZoneComparisonComponent props: ', prevProps.selectedComparisonDates, this.props.selectedComparisonDates)
            this.getCompareData()
        }
    }

    componentWillUnmount() {
        const { switchZoneComparison } = this.props;
        document.getElementById("shop-comparison").removeEventListener('mousedown', this.handleClickOutside)
        switchZoneComparison(false)
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.props.switchZoneComparison(false)
        }
    };

    getCompareData = () => {
        console.log('getCompareData ZoneComparisonComponent')

        const {
            selectedComparisonDates,
            getZoneComparisonWithData,
            // setShowDetailCompareData,
        } = this.props
        const {
            chosenComparisonsZones,
        } = this.state
        const selectedZonesId = [...(_.map(chosenComparisonsZones, 'value'))]

        // const diffDays = selectedComparisonDates.length && moment(selectedComparisonDates[0].endDate).diff(selectedComparisonDates[0].startDate, 'days')
        // console.log('getCompareData diffDays:', diffDays)

        const optionsArr = selectedComparisonDates.map((item) => {
            const start_ts = moment.utc(item.startDate).format('YYYYMMDDHHmmss')
            const end_ts = moment.utc(item.endDate).format('YYYYMMDDHHmmss')
            const group_range = getDataGroupNew(item.startDate, item.endDate)
            // const group_range = diffDays === 0 ? getDataGroup(item.startDate, item.endDate) : 86400
            return { start_ts, end_ts, group_range, zone_id: selectedZonesId.join(',') } 
        })
        getZoneComparisonWithData(optionsArr)
    }


    handleZonesCompareWithSelect = (argTypesZones) => {
        this.setState({ chosenComparisonsZones: argTypesZones || [] }, () => this.getCompareData())
    }


    setProps = (states) => {
		this.setState( states )
	}


    render() {
        // console.log('PROPS ZoneComparisonComponent: ', this.props)
        // console.log('STATE ZoneComparisonComponent: ', this.state)
        const {
            zones,
            switchZoneComparison,
            language,
        } = this.props
        const {
            chosenComparisonsZones,
        } = this.state
        const { data: languageData } = language
        const {
            genericLabels,
        } = languageData
        const {
            closeTitle,
        } = genericLabels

        const chosenComparisonsZonesIds = chosenComparisonsZones.map(({value}) => value)
        const availableZones = ( zones.length ? zones.filter(({id}) => !chosenComparisonsZonesIds.includes(id)).map(({id, name}) => ({ value: id, label: name })) : [] )


        return (
            <div className="comparison shop-comparison" id="shop-comparison">
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => switchZoneComparison(false)}/>

                <Card className="timeline-wrapper scrollbar" id="style-3" ref={this.modal}>
                    <Card.Header>
                        <div>
                            <div style={{ position: 'relative', fontSize: '24px', lineHeight: '36px', marginBottom: '20px' }}>
                                Compare Zones
                                <button className="close_btn" type="button" onClick={() => switchZoneComparison(false)} />
                            </div>
                            <Row>
                                <Col className="selectWrapper">
                                    <div className="compare-with-title">Comparing Zones:</div>
                                    <div className="select-wrapper comparingZones">
                                        <Select
                                            isMulti = {true}
                                            isSearchable = {true}
                                            // isDisabled  = {disabledCustomTimeZonesCompareWith}
                                            name = "comparisons"
                                            // placeholder={selectDateRangeTitle}
                                            // placeholder = {selectShopTitle}
                                            options = {availableZones}
                                            // options     = {available_shops}
                                            // styles      = {customSelectStyles}
                                            className = 'react-select-container'
                                            classNamePrefix = "react-select"
                                            value = {chosenComparisonsZones}
                                            onChange = {this.handleZonesCompareWithSelect}

                                            // onChange={this.handleDataRangeShowSelect}
                                            // onChange={this.handleComparisonsAndSelect}
                                        />
                                    </div>
                                </Col>
                                <Col className="selectWrapper">
                                    <div className="compare-with-title">In Dates:</div>
                                    <DashboardCalendarCompareContainer
                                        getCompareData={this.getCompareData}
                                        // calendar={calendar}
                                        // setProps={this.setProps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {
                                GRAPHS.slice(0,3).map(({title, dataFieldName, dailyData}) => {
                                    return (
                                        <GraphWrapper
                                            key={title}
                                            title={title}
                                            dataFieldName={dataFieldName}
                                            dailyData={dailyData}
                                            chosenComparisonsZones={chosenComparisonsZones}
                                        />
                                    )
                                })
                            }
                        </Row>
                        <Row>
                            {
                                GRAPHS.slice(3,6).map(({title, dataFieldName, dailyData}) => {
                                    return (
                                        <GraphWrapper
                                            key={title}
                                            title={title}
                                            dataFieldName={dataFieldName}
                                            dailyData={dailyData}
                                            chosenComparisonsZones={chosenComparisonsZones}
                                        />
                                    )
                                })
                            }
                        </Row>
                        <div className="text-right" style={{ margin: '30px 0' }}>
                            <Button
                                variant="outline-primary"
                                className="button"
                                onClick={() => switchZoneComparison(false)}
                                style={{ paddingLeft: '50px', paddingRight: '50px'}}
                            >
                                {closeTitle}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default ZoneComparisonComponent
