import React, { Component } from 'react';
import { Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isEmpty, flatten, has, map } from "lodash";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Circle from "../Circle"

import { /*addCameraPolygons,*/ editCameraPolygons } from "../../actions/apiRequests";

import "../../styles/CamersAdminHeatmapComponent.sass"

class CameraMappingDemarcation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // drawing: false,
            // circles: [],
            scalemap: 1,
            scalesnapshot: 1,
            // showPolygonRemoveTooltip: false,
            selectedPolygonIndex: null,
            showCameraPolygonTooltip: false,
            selectedCamerasPolygonName: '',
            // showCircleRemoveTooltip: false,
            // selectedCircle: {},

            showCircleRemoveTooltip: false,
            drawingNew: false,
            drawingEdit: false,
            circles: [],
            // polygons: [],
            circlesEdit: [],
            circleNew: {},
        };
    }

    startDrawing = () => {
        const { drawingNew } = this.state
        this.setState({
            drawingNew: !drawingNew,
            drawingEdit: false,
            circles: [],
            circlesEdit: [],
        })
    }

    handleMouseClick = (e) => {
        // console.log('handleMouseClick: ', e)
        e.preventDefault()
        const { drawingNew, scalesnapshot } = this.state

        if (drawingNew) {
            const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
            const drawWrapperRect = drawWrapper.getBoundingClientRect()

            // console.log('1!', e, e.clientX, e.clientY, e.pageX, e.pageY, e.screenX, e.screenY)
            // console.log('2!', drawWrapperRect, drawWrapperRect.x, drawWrapperRect.y)
            // console.log('3!', e.clientX - drawWrapperRect.x, e.clientY - drawWrapperRect.y)
            // console.log('4!', drawWrapperRect.offsetLeft, drawWrapperRect.offsetTop, e.offsetLeft, e.offsetTop)


            const { circles } = this.state
            circles.push({ x: (e.clientX - drawWrapperRect.x)/scalesnapshot, y: (e.clientY - drawWrapperRect.y)/scalesnapshot })
            this.setState({ circles })
        }
    }

    handleCircleClick = (e, circleCoords) => {
        e.preventDefault();
        e.stopPropagation();

        const { polygons, setProps } = this.props;
        const { circles } = this.state

        if (circles.length > 2) {
            const arr = []
            circles.forEach((item) => arr.push(item.x, item.y))

            polygons.push({ index: polygons.length, screen_coords: arr })
            this.setState({ circles: [], drawingNew: false });
            setProps({ polygons })
        }
    }

    handleApplyToMap = () => {
        const { polygons, selectedFloor,  editCameraPolygons, setProps, location: {state}, /*mappingSnapshotPolygons, addCameraPolygons,*/ } = this.props
        const { camera_id} = state
        const { drawingEdit, circlesEdit } = this.state

        if (drawingEdit) {
            const arr = []
            circlesEdit.forEach((item) => arr.push(item.x, item.y))
            // polygons.push(arr)
            polygons.push({ index: polygons.length, screen_coords: arr })
            setProps({ polygons })
        }



        // if (isEmpty(mappingSnapshotPolygons)) {
        //     addCameraPolygons(camera_id, {camera_id, floor_number: selectedFloor.number, polygons: JSON.stringify(polygons)});
        // } else if (!isEqual(mappingSnapshotPolygons, polygons)) {
            editCameraPolygons(camera_id, {camera_id, floor_number: selectedFloor.number, polygons: JSON.stringify(map(polygons, 'screen_coords'))});
        // }
    }

    handleCamerasPolygonMouseOver = (e, camerasName) => {
        const tooltipSpan = document.getElementById('camera-polygon-tooltip');
        const x = e.clientX
        const y = e.clientY

        tooltipSpan.style.top = (y + 5) + 'px';
        tooltipSpan.style.left = (x + 5) + 'px';

        this.setState({ showCameraPolygonTooltip: true,  selectedCamerasPolygonName: camerasName })
    }

    handleCamerasPolygonMouseOut = (e) => {
        this.setState({ showCameraPolygonTooltip: false })
    }






    handleFormClick = (e) => {
        e.preventDefault()
        const { polygons, setProps } = this.props
        const { drawingEdit, circlesEdit } = this.state

        if (drawingEdit) {
            if (circlesEdit.length > 2) {
                const arr = []
                circlesEdit.forEach((item) => arr.push(item.x, item.y))
                // polygons.push(arr)
                polygons.push({ index: polygons.length, screen_coords: arr })
                // this.setState({ polygons, circlesEdit: [], drawingEdit: false });
                this.setState({ circlesEdit: [], drawingEdit: false });
                setProps({ polygons })
            }
        }
    }

    // handlePolygonMouseOver = (e, poly) => {
    //     const tooltipSpan = document.getElementById('remove-polygon-tooltip');
    //     const x = e.clientX
    //     const y = e.clientY

    //     tooltipSpan.style.top = (y + 5) + 'px';
    //     tooltipSpan.style.left = (x + 5) + 'px';

    //     this.setState({ showPolygonRemoveTooltip: true, selectedPolygonIndex: poly })
    // }

    // handlePolygonMouseOut = (e) => {
    //     this.setState({ showPolygonRemoveTooltip: false })
    // }

    // handleClickRemovePolygon = () => {
    //     const { polygons, setProps } = this.props
    //     const { selectedPolygonIndex } = this.state

    //     let arr = []
    //     if (has(selectedPolygonIndex, 'polygon_id')) {
    //         arr = polygons.filter((item, index) => item.polygon_id !== selectedPolygonIndex.polygon_id)
    //     } else if (has(selectedPolygonIndex, 'index')) {
    //         arr = polygons.filter((item, index) => item.index !== selectedPolygonIndex.index)
    //     }

    //     setProps({ polygons: arr, showPolygonRemoveTooltip: false })
    // }


    handlePolygonClick = (e, index) => {
        e.stopPropagation();

        // const { polygons, drawingEdit, circlesEdit } = this.state
        const { polygons, setProps } = this.props
        const { drawingEdit, circlesEdit } = this.state

        console.log('handlePolygonClick polygons:', polygons, drawingEdit, circlesEdit)

        if (drawingEdit) {
            const arr = []
            circlesEdit.forEach((item) => arr.push(item.x, item.y))
            // polygons.push(arr)
            polygons.push({ index: polygons.length, screen_coords: arr })
        }

        

        const arrC = polygons[index].screen_coords.reduce((result, value, index, array) => {
            if (index % 2 === 0) {
                result.push({x: array.slice(index, index + 1)[0], y: array.slice(index+1, index + 2)[0] }); }
            return result;
        }, []);

        const arrP = polygons.filter((item, i) => i !== index)

        // this.setState({ drawingEdit: true, circlesEdit: arrC, polygons: arrP })
        this.setState({ drawingEdit: true, circlesEdit: arrC })
        setProps({ polygons: arrP })
    }

    handleClearPolygon = (e) => {
        e.stopPropagation();

        this.setState({ drawingEdit: false, circlesEdit: [] })
    }

    handleCircleContextMenu = (e, index) => {
        e.preventDefault()

        // const tooltipSpan = document.getElementById('remove-polygon-tooltip');
        // tooltipSpan.style.top = '0';
        // tooltipSpan.style.left = '0';

        const tooltipSpan1 = document.getElementById('remove-circle-tooltip');
        const x = e.clientX
        const y = e.clientY
        tooltipSpan1.style.top = (y-2) + 'px';
        tooltipSpan1.style.left = (x-2) + 'px';

        this.setState({ /*showPolygonRemoveTooltip: false,*/ showCircleRemoveTooltip: true, selectedPolygonIndex: index })
    }

    handleClickRemoveCircle = (e) => {
        e.stopPropagation();
        const { selectedPolygonIndex, circlesEdit } = this.state

        let arr = []
        arr = circlesEdit.filter((item, index) => index !== selectedPolygonIndex)
        // if (has(selectedPolygonIndex, 'polygon_id')) {
        //     arr = polygons.filter((item, index) => item.polygon_id !== selectedPolygonIndex.polygon_id)
        // } else if (has(selectedPolygonIndex, 'index')) {
        //     arr = polygons.filter((item, index) => item.index !== selectedPolygonIndex.index)
        // }

        const tooltipSpan = document.getElementById('remove-circle-tooltip');
        tooltipSpan.style.top = '0';
        tooltipSpan.style.left = '0';

        this.setState({ circlesEdit: arr, showCircleRemoveTooltip: false })
    }

    setProps = (states) => {
		this.setState( states )
	}


    render() {
        // console.log('PROPS CameraMappingDemarcation: ', this.props)
        // console.log('STATE CameraMappingDemarcation: ', this.state)
        const { polygons, chosenMall, chosenCamera, selectedFloor, mappingMapPolygons, camerasMapPolygons, setProps, language } = this.props
        const { circles, drawingNew, scalesnapshot, scalemap, /*showPolygonRemoveTooltip,*/ showCameraPolygonTooltip, selectedCamerasPolygonName, drawingEdit, circleNew, circlesEdit, showCircleRemoveTooltip } = this.state
        const { floors } = chosenMall
        const { snapshot, id } = chosenCamera
        // const circlePars = reduce(circles, (res, v, k) => { res[Math.floor(k/2)] ? res[Math.floor(k/2)].push(v) : res[Math.floor(k/2)] = [v]; return res;}, {} )

        const {
            genericLabels,
            adminSection: { camerasPage },
        } = language;
        const {
            snapshotTitle,
            selectFloorTitle,
            applyToMapTitle,
        } = camerasPage;
        const {
            floorTitle,
            removeCircleTitle,
            floorLabel,
            mapTitle,
            clearPolygonTitle,
            drawTitle,
            polygonTitle,
        } = genericLabels;

        // displays new red circle when mouse over the polygon line
        let circleNewScreen = null
        if (!isEmpty(circleNew)) {
            circleNewScreen = <circle cx={circleNew.x} cy={circleNew.y} r={5/scalesnapshot} stroke="#f00" />
        }

        const circlesEditScreen = []
        if (drawingEdit) {
            circlesEdit.forEach( ({x,y,id}, i) => {
                circlesEditScreen.push(
                    <Circle
                        key={'сe'+x+y+i}
                        index={i}
                        x={x}
                        y={y}
                        scalemap={scalesnapshot}
                        circlesEdit={circlesEdit}
                        handleCircleContextMenu={this.handleCircleContextMenu}
                        setProps={this.setProps}
                    />
                )

                if (i) {
                    const x1 = circlesEdit[i - 1].x < x ? circlesEdit[i - 1].x + 2 : circlesEdit[i - 1].x - 2
                    const x2 = x > circlesEdit[i - 1].x ? x - 2 : x + 2
                    const y1 = circlesEdit[i - 1].y < y ? circlesEdit[i - 1].y + 2 : circlesEdit[i - 1].y - 2
                    const y2 = y > circlesEdit[i - 1].y ? y - 2 : y + 2

                    circlesEditScreen.push(
                        <line
                            key={'le'+x+y+i} x1={x1} x2={x2} y1={y1} y2={y2}
                            onMouseOver={ (e) => {
                                const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
                                const drawWrapperRect = drawWrapper.getBoundingClientRect()

                                this.setState({ circleNew: {x: (e.clientX - drawWrapperRect.x)/scalesnapshot, y: (e.clientY - drawWrapperRect.y)/scalesnapshot} })
                            }}
                            onMouseOut={ (e) => {
                                this.setState({ circleNew: {} })
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const newData = circlesEdit.slice(0); // copy
                                newData.splice(i, 0, circleNew);

                                this.setState({ circlesEdit: newData })
                            }}
                        />
                    )
                }
            })

            const x1 = circlesEdit[0].x < circlesEdit[circlesEdit.length-1].x ? circlesEdit[0].x + 2 : circlesEdit[0].x - 2
            const x2 = circlesEdit[circlesEdit.length-1].x > circlesEdit[0].x ? circlesEdit[circlesEdit.length-1].x - 2 : circlesEdit[circlesEdit.length-1].x + 2
            const y1 = circlesEdit[0].y < circlesEdit[circlesEdit.length-1].y ? circlesEdit[0].y + 2 : circlesEdit[0].y - 2
            const y2 = circlesEdit[circlesEdit.length-1].y > circlesEdit[0].y ? circlesEdit[circlesEdit.length-1].y - 2 : circlesEdit[circlesEdit.length-1].y + 2
            circlesEditScreen.push(
                <line key={'le0'} x1={x1} x2={x2} y1={y1} y2={y2}
                    onMouseOver={ (e) => {
                        const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
                        const drawWrapperRect = drawWrapper.getBoundingClientRect()

                        this.setState({ circleNew: { x: (e.clientX - drawWrapperRect.x)/scalesnapshot, y: (e.clientY - drawWrapperRect.y)/scalesnapshot, id: 'newCircle'} })
                    }}
                    onMouseOut={ (e) => {
                        this.setState({ circleNew: {} })
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const newData = circlesEdit.slice(0); // copy
                        newData.splice(0, 0, circleNew);

                        this.setState({ circlesEdit: newData })
                    }}

                />
            )
        }

        const cirlsScreen = []
        circles.forEach( ({x,y}, i) => {
            cirlsScreen.push(
                <circle key={'с'+x+y+i} cx={x} cy={y} r={5/scalesnapshot}
                    onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                />
            )

            if (i) {
                const x1 = circles[i - 1].x < x ? circles[i - 1].x + 2 : circles[i - 1].x - 2
                const x2 = x > circles[i - 1].x ? x - 2 : x + 2
                const y1 = circles[i - 1].y < y ? circles[i - 1].y + 2 : circles[i - 1].y - 2
                const y2 = y > circles[i - 1].y ? y - 2 : y + 2

                cirlsScreen.push(
                    <line key={'l'+x+y+i} x1={x1} x2={x2} y1={y1} y2={y2} />
                )
            }
        })

        const polysScreen = []
        polygons.forEach( (poly, i) => {
            polysScreen.push(
                <polygon
                    key={i}
                    // points={poly}
                    points={poly.screen_coords}
                    onClick={(e) => this.handlePolygonClick(e, i)}
                />
            )
        })


        // displays the blue polygon of zone on the Map
        const polysMap = []
        mappingMapPolygons.forEach( (poly, i) => {
            polysMap.push(
                <polygon key={i} points={poly.map_coords} />
            )
        })

        // displays the all of green zones polygons on the Map
        const polysCameras = []
        camerasMapPolygons.filter(({ camera_id, floor_number }) => floor_number === selectedFloor.number && camera_id !== id).forEach( (camera, i) => {
            const poly = flatten(JSON.parse(camera.map_coords))
            polysCameras.push(
                <polygon
                    key={i}
                    points={poly}
                    onMouseOver={ (e) => this.handleCamerasPolygonMouseOver(e, camera.camera_title || camera.camera_name) }
                    onMouseOut={ this.handleCamerasPolygonMouseOut }
                />
            )
        })

        return (
            <div
                className="camera-mapping-demarcation"
                onClick={this.handleFormClick}
            >
                <Row>
                    <Col md={6}>
                        <div className="map-title">{snapshotTitle}</div>
                        <div className="map-block">
                            <TransformWrapper
                                // minScale={1} maxScale={5} scale={s} positionX={x} positionY={y}
                                defaultScale={1}
                                defaultPositionX={0}
                                defaultPositionY={0}
                                options={{
                                    limitToBounds: false,
                                    centerContent: true,
                                }}
                                pan={{
                                    disabled: drawingNew,
                                    velocity: false,
                                }}
                                scalePadding={{
                                    animationTime: 0,
                                }}
                                doubleClick={{
                                    disabled: true,
                                }}
                                // onZoomChange={ (a) => {console.log('onZoomChange: ', a);} }
                                onWheelStop={ (a) => {this.setState({ scalesnapshot: a.scale})} }
                            >
                                <TransformComponent>
                                    <span
                                        className={`draw-wrapper ${drawingNew && 'drawing'}`}
                                        style={{ position: 'relative', display: 'inline-table' }}
                                        onClick={this.handleMouseClick}
                                    >
                                        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                            <g fill="transparent" stroke="#475ED1" strokeWidth={4/scalesnapshot} fillOpacity="0.5" strokeOpacity="1" r={5/scalesnapshot}>
                                                {/* <polygon points="148.81755600305453 180.93267467892267 231.58469621306074 153.13773953377134 293.9688839832893 214.90426207855205 227.8787048603739 258.1408278598986 140.17024284678524 251.3465103799727 148.81755600305453 180.93267467892267" /> */}

                                                {cirlsScreen}
                                                {circleNewScreen}
                                                {circlesEditScreen}
                                                {polysScreen}

                                                {/* <polygon points={[57.19487931993273, 78.3611085679796, 35.66710154215495, 260.30555301242407, 165.52821265326605, 260.30555301242407, 81.50043487548828, 75.58333079020183]} />
                                                <polygon points={[402.462158203125, 129.3895263671875, 487.01055908203125, 199.39852905273438, 438.82080078125, 210.61509704589844, 516.8704833984375, 214.13949584960938]} /> */}

                                                {/* {
                                                    Object.keys(circlePars).map( (key) => {
                                                        console.log('key: ', key)
                                                        const item = circlePars[key]
                                                        if (item.length > 1) {
                                                            return (
                                                                <line key={key} x1={item[0].x} x2={item[1].x} y1={item[0].y} y2={item[1].y} />
                                                            )
                                                        }
                                                    })
                                                } */}

                                                {/*
                                                <circle cx="50" cy="50" r="5" />
                                                <circle cx="120" cy="160" r="5" />
                                                <line x1="52" x2="118" y1="52" y2="158" />
                                                */}
                                            </g>
                                        </svg>
                                        <img className="drop-image-snapshot" src={snapshot} alt="" />
                                    </span>
                                </TransformComponent>

                                <div
                                    id="remove-circle-tooltip"
                                    role="tooltip"
                                    className={`remove-marker-tooltip remove-point-icon tooltip ${showCircleRemoveTooltip  && 'show'}`}
                                    style={{ position: 'fixed' }}
                                    onMouseOver={ () => this.setState({ showCircleRemoveTooltip: true }) }
                                    // onMouseOut={ () => this.setState({ showPolygonRemoveTooltip: false })}
                                    // onMouseOut={ this.handlePolygonMouseOut }
                                    onMouseOut={ () => {
                                        const tooltipSpan = document.getElementById('remove-circle-tooltip');
                                        tooltipSpan.style.top = '0';
                                        tooltipSpan.style.left = '0';

                                        this.setState({ showCircleRemoveTooltip: false })
                                    }}
                                    onClick= {this.handleClickRemoveCircle}
                                >
                                    <div className="tooltip-inner">
                                        <div className="remove-marker">{removeCircleTitle}</div>
                                    </div>
                                </div>
                            </TransformWrapper>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="map-title">{mapTitle}</div>
                        <div className="map-block">
                            <TransformWrapper
                                // minScale={1} maxScale={5} scale={s} positionX={x} positionY={y}
                                defaultScale={1}
                                defaultPositionX={0}
                                defaultPositionY={0}
                                options={{
                                    limitToBounds: false,
                                    centerContent: true,
                                }}
                                pan={{
                                    // disabled: drawing,
                                    velocity: false,
                                }}
                                scalePadding={{
                                    animationTime: 0,
                                }}
                                doubleClick={{
                                    disabled: true,
                                }}
                                // onZoomChange={ (a) => {console.log('onZoomChange: ', a);} }
                                onWheelStop={ (a) => {this.setState({ scalemap: a.scale})} }
                            >
                                <TransformComponent>
                                        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                            <g fill="transparent" stroke="#19AB27" strokeWidth={2/scalemap} fillOpacity="0.5" strokeOpacity="1" strokeDasharray="6,3" r={3/scalemap}>
                                                {polysCameras}
                                            </g>
                                            <g fill="transparent" stroke="#475ED1" strokeWidth={4/scalemap} fillOpacity="0.5" strokeOpacity="1" r={5/scalemap}>
                                                {polysMap}
                                            </g>
                                        </svg>
                                        <img className="drop-image-map" src={selectedFloor.floor_image} alt="" />
                                </TransformComponent>

                                <div
                                    id="camera-polygon-tooltip"
                                    role="tooltip"
                                    className={`fade remove-marker-tooltip tooltip ${showCameraPolygonTooltip && 'show'}`}
                                    style={{ position: 'fixed' }}
                                    // onMouseOver={ () => this.setState({ showCameraPolygonTooltip: true })}
                                    // onMouseOut={ () => this.setState({ showCameraPolygonTooltip: false })}
                                >
                                    <div className="tooltip-inner">
                                        {selectedCamerasPolygonName}
                                    </div>
                                </div>
                            </TransformWrapper>
                        </div>
                    </Col>
                </Row>

                <Row style={{ position: 'relative' }}>
                    <div style={{ margin: '24px auto 0' }}>
                        <Button
                            variant="outline-primary"
                            className={`add-polygon ${(drawingEdit || polygons.length > 7) && 'btn-disabled'}`}
                            onClick={this.startDrawing}
                            disabled={(polygons.length > 7)}
                        >
                            {`${drawTitle+' '+polygonTitle}`} {polygons.length}/8
                        </Button>

                        <Button
                            variant="outline-danger"
                            className={`clear-polygon ${!drawingEdit && 'btn-disabled'}`}
                            onClick={this.handleClearPolygon}
                        >
                            {clearPolygonTitle}
                        </Button>
                        <Button
                            variant="outline-success"
                            className={`button apply-map ${!polygons.length && !mappingMapPolygons.length && 'btn-disabled'}`}
                            disabled={!polygons.length && !mappingMapPolygons.length}
                            onClick={this.handleApplyToMap}
                        >
                            {applyToMapTitle}
                        </Button>
                    </div>

                    <div className="floor-dropdown">
                        <DropdownButton
                            disabled={true}
                            title={selectedFloor ? `${floorTitle} ${selectedFloor.number}` : selectFloorTitle}
                            id="drop_for_mall_floors"
                            variant="outline-dark"
                        >
                            {!isEmpty(chosenMall) && !isEmpty(floors) && floors
                                .sort((a,b) => a.number - b.number)
                                .map((item) => {
                                    return <Dropdown.Item
                                        key={item.id}
                                        onClick={() => setProps({ selectedFloor: item })}
                                    >
                                        {floorLabel} {item.number}
                                    </Dropdown.Item>
                                }
                            )}
                        </DropdownButton>
                    </div>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = ({ adminData, language }) => {
    return {
        chosenMall: adminData.chosenMall,
        chosenCamera: adminData.chosenCamera,
        // mappingSnapshotPolygons: adminData.mappingSnapshotPolygons,
        mappingMapPolygons: adminData.mappingMapPolygons,
        camerasMapPolygons: adminData.camerasMapPolygons,
        language: language.data,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // addCameraPolygons: (id, data)=> dispatch(addCameraPolygons(id, data)),
        editCameraPolygons: (id, data)=> dispatch(editCameraPolygons(id, data)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CameraMappingDemarcation));
