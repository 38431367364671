import {connect} from 'react-redux'
import StoresSelectList from '../components/StoresSelectList'
import {addStoresToList,switchShopComparison, deleteStoresFromList, switchStoresSelectList} from '../actions/filters';

const mapStateToProps = ({dashboardData, language }) => {
    return {
        storesList: dashboardData.storesList,
        language: language.data,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addStoresToList: (state) => dispatch(addStoresToList(state)),
        switchShopComparison: (state) => dispatch(switchShopComparison(state)),
        deleteStoresFromList: (id) => dispatch(deleteStoresFromList(id)),
        switchStoresSelectList:(state) => dispatch(switchStoresSelectList(state)),
    }
};

const StoresSelectListContainer = connect(mapStateToProps, mapDispatchToProps)(StoresSelectList);

export default StoresSelectListContainer
