import React, {Component, Fragment} from 'react';
import {Form, Button, Row, Col} from 'react-bootstrap'
import {toastr} from "react-redux-toastr"
import KeyboardEventHandler from "react-keyboard-event-handler"
import _ from "lodash"
import DatePicker from "react-datepicker";
import moment from "moment";

import {apiCall} from '../actions/apiRequests'

import CamerasMapContainer from "../containers/admin/CamerasMapContainer"
import readImage from "../helpers/readImage"
import validate from "../helpers/validate"
import catchParser from "../helpers/catchParser";


class MallsFormComponent extends Component {
    constructor(props) {
        super(props);

        this.state ={
            mall: {
                name:"",
                description:"",
                open_time: new Date(moment().startOf("day").format()),
                close_time: new Date(moment().endOf("day").format()),
            },

            nvrmodules: [],
            floors: [],
            selectedFloor: {}
        };

        this.modal = React.createRef();
    }

    componentDidMount() {
        const {chosenMall, editMode, setForm, getMall, location: {state}} = this.props;

        if ( state && state.mall_id) {
            const mallParams = {nvr: true, floor: true};
            getMall(state.mall_id, mallParams, true);
            setForm(true, true);
        }

        if (!_.isEmpty(chosenMall) && editMode){
            const mall = _.cloneDeep(chosenMall);

            mall.open_time = new Date(moment(mall.open_time, "HHmmss").format());
            mall.close_time = new Date(moment(mall.close_time, "HHmmss").format());

            const nvrmodules = mall.nvrs || [];
            const floors = mall.floors || [];

            delete mall.nvrs
            delete mall.floors

            this.setState({ mall, nvrmodules, floors })
        }

        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.props.setForm(false, false);
        this.props.chooseMall({});
    }

    componentDidUpdate(prevProps) {
        const {chosenMall, editMode} = this.props;

        if (prevProps.chosenMall !== chosenMall && !_.isEmpty(chosenMall) && editMode){
            const mall = _.cloneDeep(chosenMall);

            mall.open_time = new Date(moment(mall.open_time, "HHmmss").format());
            mall.close_time = new Date(moment(mall.close_time, "HHmmss").format());

            const nvrmodules = mall.nvrs || [],
            floors = mall.floors || []

            delete mall.nvrs
            delete mall.floors

            this.setState({ mall, nvrmodules, floors })
        }
    }

    validateScedule = () => {
        const {open_time, close_time} = this.state.mall;
        const {errorTitle, scheduleValidationMessage} = this.props.language.data.notifications;

        if (moment(open_time, "HHmmss").isValid() && moment(close_time, "HHmmss").isValid() && moment(open_time, "HHmmss").isBefore(moment(close_time,"HHmmss"))){
            return true
        }else {
            toastr.error(errorTitle, scheduleValidationMessage);
            return false
        }
    };

    addData = () => {
        const {name, open_time, close_time, ...mall} = this.state.mall;
        const {setForm, addMall, history, language} = this.props;
        const data = {
            name,
            open_time: moment(open_time).format("HHmmss"),
            close_time: moment(close_time).format("HHmmss"),
            ...mall
        };
        const { warningTitle, filledInMessage } = language.data.notifications;

        if (
            validate({name}, { warningTitle, filledInMessage })
            && this.validateScedule()
        ) {
            addMall(
                data,
                ({id}) => history.push({
                    pathname: "/admin/malls/edit",
                    state: { mall_id: id }
                }),
            );

            setForm(false, false);
        }
    };

    editData = () => {
        const { mall, nvrmodules, floors } = this.state;
        const { id, name, open_time, close_time, ...request } = mall;
        const {
            setForm,
            editMall,
            history,
            chooseMall,
            editMode,
            chosenMall,
            editMallData,
            language,
        } = this.props;
        const {
            successTitle,
            NVRModulesUpdatedMessage,
            warningTitle,
            filledInMessage,
        } = language.data.notifications;

        if (
            validate({name}, { warningTitle, filledInMessage })
            &&
            this.validateScedule()
        ){
            let requestArray = [];

            //* NVRs storage
            // console.log('difference1 chosenMall.nvrs: ',  chosenMall.nvrs)
            // console.log('difference2 nvrmodules: ', nvrmodules)

            // const changedData = []
            // nvrmodules.forEach((item, i) => {
            //     if (item.id) {
            //         const existNVR = chosenMall.nvrs.find(({id}) => id === item.id)
            //         if (existNVR) {
            //             const changedObj = _.reduce(_.keys(item), (result, key) => {
            //                 if (!Object.prototype.hasOwnProperty.call(existNVR, key) || !_.isEqual(existNVR[key], item[key])) {
            //                     result[key] = item[key]
            //                 }
            //                 return result;
            //             }, {});
            //             console.log('changedObj: ', changedObj)
            //             if (!_.isEmpty(changedObj)) {
            //                 changedData.push({id: existNVR.id, ...changedObj})
            //             }
            //         }
            //     }
            // })
            // console.log('changedData: ', changedData)
            // const newData = []
            // nvrmodules.forEach((item) => {if (!item.id) {newData.push(item)} })
            // console.log('newData: ', newData)
            // const removedData = _.difference(_.map(chosenMall.nvrs, "id"), _.map(nvrmodules, "id"))
            // console.log('removedData: ', removedData)
            // return

            if (!_.isEqual(nvrmodules, chosenMall.nvrs)) {
                const changedData = []
                nvrmodules.forEach((item, i) => {
                    if (item.id) {
                        const existNVR = chosenMall.nvrs.find(({id}) => id === item.id)
                        if (existNVR) {
                            const changedObj = _.reduce(_.keys(item), (result, key) => {
                                if (!Object.prototype.hasOwnProperty.call(existNVR, key) || !_.isEqual(existNVR[key], item[key])) {
                                    result[key] = item[key]
                                }
                                return result;
                            }, {});
                            console.log('changedObj: ', changedObj)
                            if (!_.isEmpty(changedObj)) {
                                changedData.push({id: existNVR.id, ...changedObj})
                            }
                        }
                    }
                })
                // console.log('changedData: ', changedData)
                const newData = []
                nvrmodules.forEach((item) => {if (!item.id) {newData.push(item)} })
                // console.log('newData: ', newData)
                const removedData = _.difference(_.map(chosenMall.nvrs, "id"), _.map(nvrmodules, "id"))
                // console.log('removedData: ', removedData)

                //* removes the all of existing nvr modules
                // chosenMall.nvrs && chosenMall.nvrs.map(({id}) => requestArray.push(
                //     apiCall('delete', `nvr-module/${id}`)
                //         .catch((e) => {
                //             const error = catchParser(e, chosenMall.name);
                //             toastr.info(error.result, error.description);
                //         }))
                // );
                removedData.forEach((item) => requestArray.push(
                    apiCall('delete', `nvr-module/${item}`)
                        .catch((e) => {
                            const error = catchParser(e, chosenMall.name);
                            toastr.info(error.result, error.description);
                        }))
                );
                //* adds new nvr modules
                // nvrmodules.map((item) => requestArray.push(
                //     apiCall('post', 'nvr-modules', {mall_id: id, ...item})
                //         .catch((e) => {
                //             const error = catchParser(e, item.name);
                //             toastr.info(error.result, error.description);
                //         })))
                newData.forEach((item) => requestArray.push(
                    apiCall('post', 'nvr-module', {mall_id: id, ...item})
                        .catch((e) => {
                            const error = catchParser(e, item.name);
                            toastr.info(error.result, error.description);
                        }))
                )                
                //* updates nvr modules
                changedData.map((item) => requestArray.push(
                    apiCall('put', `nvr-module/${item.id}`, {mall_id: id, ...item})
                        .catch((e) => {
                            const error = catchParser(e, item.name);
                            toastr.info(error.result, error.description);
                        })))
            }
            //* Floors storage
            if (!_.isEqual(floors, chosenMall.floors)) {
                //* removes the deleted floors
                chosenMall.floors && _.differenceBy(chosenMall.floors, floors, 'id').map((item) =>
                    requestArray.push(
                        apiCall('delete', `mall-floor/${item.id}`)
                            .catch((e) => {
                                const error = catchParser(e, item.name);
                                toastr.info(error.result, error.description);
                            })
                    ));
                //* adds or updates existing floors
                floors.forEach((item, index) => {
                    item.number = index + 1
                });
                floors.forEach( (item, index) => {
                    if (item.id && !_.isEqual(item, chosenMall.floors[index])) {
                        if (typeof item.floor_image !== "object"){
                            delete item.floor_image
                        }

                        requestArray.push(
                            apiCall('put', `mall-floor/${item.id}`, {mall_id: id, ...item})
                                .catch((e) => {
                                    const error = catchParser(e, item.name);
                                    toastr.info(error.result, error.description);
                                })
                        )
                    }
                    else if (!item.id) {
                        requestArray.push(
                            apiCall('post', 'mall-floors', {mall_id: id, ...item})
                                .catch((e) => {
                                    const error = catchParser(e, item.name);
                                    toastr.info(error.result, error.description);
                                })
                        )
                    }
                })
            }

            if (requestArray.length) {
                const payload = Promise.all(requestArray)
                    .then(() => {
                        toastr.success(successTitle, `${NVRModulesUpdatedMessage} ${name}`);
                    }).catch((e) => {
                        const error = catchParser(e, name);
                        console.log('Promise: ', error)
                        toastr.info(error.result, error.description);
                    });

                editMallData({
                    type: "EDIT_MALL_DATA",
                    payload
                })
            }

            editMall(id, {
                name,
                open_time: moment(open_time).format("HHmmss"),
                close_time: moment(close_time).format("HHmmss"),
                ...request
            });

            if (editMode) {
                history.push("/admin/malls/dashboard");
                history.replace("/admin/malls/dashboard");
                chooseMall({});
            }else {
                this.setState({ mall: {
                    name:"",
                    description:"",
                }});
            }

            setForm(false, false);
        }
    }


    cancelData = () => {
        const { chosenMall } = this.props

        let mall = _.cloneDeep(chosenMall)

        mall.open_time = new Date(moment(mall.open_time, "HHmmss").format());
        mall.close_time = new Date(moment(mall.close_time, "HHmmss").format());

        const nvrmodules = mall.nvrs || []
        const floors = mall.floors || []

        delete mall.nvrs
        delete mall.floors

        this.setState({ mall, nvrmodules, floors })
    }

    deleteMall = (id) =>{
        const { history, deleteMall, editMode } = this.props;
        if (editMode) {
            history.push("/admin/malls/dashboard");
            history.replace("/admin/malls/dashboard");
        }
        deleteMall(id);
    };

    handleChangeForm(){
        const { openAddForm, setForm, history, chooseMall, editMode } = this.props;
        if (editMode) {
            history.push("/admin/malls/dashboard");
            history.replace("/admin/malls/dashboard");
            chooseMall({});
        }else {
            this.setState({ mall: {
                name:"",
                description:"",
                // mask:"",
                // folder:"",
                // map_image:"",
                // nvr_port: "",
                // nvr_ip:"",
                // nvr_user:"",
                // nvr_pass: ""
            }});
            // this.file.current.value = "";
        }
        setForm(!openAddForm, false);
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            !this.props.editMode && this.handleChangeForm();
        }
    }


    addNVR = () => {
        const nvrmodules = this.state.nvrmodules,
        item = {
            name:   'NVR ' + (nvrmodules.length + 1),
            ip:     '',
            port:   '',
            user:   '',
            password: ''
        }
        this.setState({
            nvrmodules: [...nvrmodules, item]
        })

        // let nvrmodules = this.state.nvrmodules
        // nvrmodules.push({ name: 'NVR ' + (nvrmodules.length + 1), ip:null, port: null, user: null, password: null })
        // this.setState({ nvrmodules })
    }

    removeNVR = (idx) => {
        let nvrmodules = this.state.nvrmodules
        nvrmodules.splice(idx, 1)
        this.setState({ nvrmodules })

        // let nvrmodules = this.state.nvrmodules
        // nvrmodules = nvrmodules.filter((item, idx) => {
        //     return index !== indexedDB
        // })

        // this.setState({ nvrmodules })
    }

    handleNVRChange = (e, idx) => {
        const { name, value } = e.target
        let nvrmodules = [...this.state.nvrmodules]

        nvrmodules[idx][name] = value

        this.setState({ nvrmodules })
    }

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form
            const index = Array.prototype.indexOf.call(form, e.target)
            form.elements[index + 1].focus()

            e.preventDefault()
        }
    }

    addFloors = () => {
        let floors = this.state.floors
        floors.push({ number: floors.length + 1, floor_image:null, map_image: null })
        this.setState({ floors })
    }

    updateFloorImage = (e, id) => {
        readImage("mallFile"+id, "mall_image"+id);

        let floors = this.state.floors

        let obj = floors[id]
        if (obj) {
            obj.floor_image = e.target.files[0]
        }

        this.setState({ floors })
    }

    handleChangeFloor = (e, id) => {
        const { floors } = this.state
        const { target } = e
        const { name, value } = target

        floors.map((item, index) => {
            if (index === id) {
                item[name] = value*1
            }

            return item
        })

        this.setState({ floors })
    }

    removeFloor = (idx) => {
        let floors = this.state.floors
        floors = floors.filter((item, index) => {
            return index !== idx
        })

        this.setState({ floors })
    }

    handleCamerasMap = (idx) => {
        const { attentionTitle, needFloorImageSelectionMessage } = this.props.language.data.notifications;

        if (!this.state.floors[idx].floor_image) {
            toastr.info(attentionTitle, needFloorImageSelectionMessage)
        }
        else {
            this.setState({ selectedFloor: this.state.floors[idx] }, () => this.props.switchCamerasMap(true))
        }
    }

    render() {
        const { mall, nvrmodules, floors, selectedFloor } = this.state,
        { id, name, description, open_time, close_time } = mall,
        { editMode, showCamerasMap, language } = this.props;

        const { data, code } = language;
        const {
            adminSection,
            nvrsLabels,
            floorsLabels,
            genericLabels,
            notifications: { deleteActionConfirmationMessage },
            columns,
            images: { noImageUrl },
        } = data;

        const {
            floorsMapsTitle,
            newFloorTitle,
            addCameraTitle,
            noFloorsTitle,
            lengthTitle,
            widthTitle,
        } = floorsLabels;

        const {
            deleteButtonTitle,
            saveTitle,
            cancelTitle,
            floorTitle,
        } = genericLabels;

        const {
            createMallTitle,
            editMallTitle,
            workScheduleTitle,
            createMallButtonTitle,
            openScheduleTitle,
            closeScheduleTitle,
        } = adminSection.mallsPage;

        const {
            NVRConnectionsTitle,
            newNVRTitle,
            noNVRTitle,
        } = nvrsLabels;
        const {
            nameLabel,
            descriptionLabel,
            floorImageLabel,
            floorLengthWidthLabel,
            camerasMapLabel,
            ipLabel,
            portLabel,
            userLabel,
            passwordLabel,
            numberLabel,
        } = columns;

        return (
            <Fragment>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <Form
                    className={`add_new_data ${showCamerasMap ? "mall_cameras_editing" : ""}`}
                    ref={this.modal}
                >
                    <h3 className={`mb-4 ${editMode ? "edit_title" : ""}`}>
                        {editMode ? editMallTitle : createMallTitle}
                    </h3>

                    <button
                        className="close_btn"
                        type="button"
                        onClick={() => this.handleChangeForm()}
                    />

                    <div className="mall_description">
                        <div className="main_info">
                            <Form.Group>
                                <Form.Label>{nameLabel}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={nameLabel}
                                    value={name}
                                    onKeyDown={this.handleEnter}
                                    onChange={(e) => this.setState({ mall: {...mall, name: e.target.value} })}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{descriptionLabel}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="4"
                                    placeholder={descriptionLabel}
                                    style={{maxHeight:"150px"}}
                                    value={description}
                                    onChange={(e) => this.setState({ mall: {...mall, description: e.target.value} })}/>
                            </Form.Group>
                        </div>


                        <div className="block-schedule">
                            <Form.Group>
                                <Form.Label>{workScheduleTitle}</Form.Label>

                                <DatePicker
                                    selected={open_time}
                                    onChange={date => this.setState({mall: {...mall, open_time: date}})}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption={openScheduleTitle}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={code}
                                />

                                <DatePicker
                                    selected={close_time}
                                    onChange={date => this.setState({mall: {...mall, close_time: date}})}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption={closeScheduleTitle}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={code}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    {editMode && <Fragment>
                        <h4 className="text-center">{NVRConnectionsTitle}</h4>

                        <div className="block-wrapper block-floors">
                            <div className="buttons_wrap">
                                <Button
                                    className="event_button new_ev"
                                    onClick={this.addNVR}
                                >
                                    {newNVRTitle}
                                </Button>
                            </div>

                            <div className="row-floor mt-3">
                                <label className="form-label col-md-2">
                                    {nameLabel}
                                </label>
                                <label className="form-label col-md-2">
                                    {ipLabel}
                                </label>
                                <label className="form-label col-md-2">
                                    {portLabel}
                                </label>
                                <label className="form-label col-md-2">
                                    {userLabel}
                                </label>
                                <label className="form-label col-md-2">
                                    {passwordLabel}
                                </label>
                                <label className="form-label col-md-2"></label>
                            </div>

                            { nvrmodules.length === 0 && <Col style={{color:'#666'}}>{noNVRTitle}</Col>}

                            { 
                                nvrmodules.map(({name, ip, port, user, password}, index) => {
                                    return <div key={index} className="row-floor">
                                        <Form.Group className="col-md-2">
                                            <Form.Control
                                                type="text"
                                                placeholder={nameLabel}
                                                name="name"
                                                value={name}
                                                onKeyDown={this.handleEnter}
                                                onChange={ (e) => this.handleNVRChange(e,index) }
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-2">
                                            <Form.Control
                                                type="text"
                                                placeholder={ipLabel}
                                                name="ip"
                                                value={ip || ''}
                                                onKeyDown={this.handleEnter}
                                                onChange={ (e) => this.handleNVRChange(e,index) }
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-2">
                                            <Form.Control
                                                type="text"
                                                placeholder={portLabel}
                                                name="port"
                                                value={port || ''}
                                                onKeyDown={this.handleEnter}
                                                onChange={ (e) => this.handleNVRChange(e,index) }
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-2">
                                            <Form.Control
                                                type="text"
                                                placeholder={userLabel}
                                                name="user"
                                                value={user || ''}
                                                onKeyDown={this.handleEnter}
                                                onChange={ (e) => this.handleNVRChange(e,index) }
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-2">
                                            <Form.Control
                                                type="text"
                                                placeholder={passwordLabel}
                                                name="password"
                                                value={password || ''}
                                                onChange={ (e) => this.handleNVRChange(e,index) }
                                            />
                                        </Form.Group>

                                        <Form.Group className="col-md-2">
                                            <Button
                                                className="event_button"
                                                onClick={() => toastr.confirm(
                                                    deleteActionConfirmationMessage,
                                                    {onOk: () => this.removeNVR(index)}
                                                    )}
                                            >
                                                {deleteButtonTitle}
                                            </Button>
                                        </Form.Group>
                                    </div>
                                })
                            }
                        </div>

                        <h4 className="text-center">{floorsMapsTitle}</h4>

                        <div className="block-wrapper block-floors">
                            <div className="buttons_wrap">
                                <Button
                                    className="event_button new_ev"
                                    onClick={this.addFloors}
                                >
                                    {newFloorTitle}
                                </Button>
                            </div>

                            <div className="row-floor mt-3">
                                <label className="form-label col-md-2">
                                    {numberLabel}
                                </label>
                                <label className="form-label col-md-3">
                                    {floorImageLabel}
                                </label>
                                <label className="form-label col-md-3">
                                    {floorLengthWidthLabel}
                                </label>
                                <label className="form-label col-md-2">
                                    {camerasMapLabel}
                                </label>
                                <label className="form-label col-md-2"></label>
                            </div>

                            { floors.length === 0 && <Col style={{ color:'#666' }}>{noFloorsTitle}</Col>}

                            {
                                floors.map(({ floor_image, map_image, length, width }, index) => {
                                    return (
                                        <div key={index} className="row-floor">
                                            <div className="col-md-2">{floorTitle} {index+1}</div>

                                            <div className="col-md-3">
                                                <div className="col-floor-image">
                                                    <img
                                                        src={floor_image ? floor_image : noImageUrl}
                                                        onErrorCapture={e => e.target.src = noImageUrl}
                                                        id={`mall_image${index}`}
                                                        alt="shop view"
                                                    />

                                                    <Form.Group>
                                                        <Form.Label
                                                            htmlFor={`mallFile${index}`}
                                                            className="labelForPhoto"
                                                        >
                                                            <img src="/images/plus_green.svg" alt="plus"/>
                                                        </Form.Label>

                                                        <Form.Control
                                                            type="file"
                                                            id={`mallFile${index}`}
                                                            name={`mallFile${index}`}
                                                            className="inputfile"
                                                            onChange={ (e) => this.updateFloorImage(e, index) }
                                                            placeholder="image"
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <Col md={3} style={{ display: 'flex' }}>
                                                <Form.Group>
                                                    <Form.Control
                                                        name="length"
                                                        type="number"
                                                        placeholder={lengthTitle}
                                                        min="1"
                                                        value={length || ''}
                                                        onKeyDown={this.handleEnter}
                                                        onChange={(e) => this.handleChangeFloor(e, index)}
                                                        style={{ width: 130, marginRight: 10 }}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control
                                                        name="width"
                                                        type="number"
                                                        placeholder={widthTitle}
                                                        min="1"
                                                        value={width || ''}
                                                        // value={width}
                                                        onKeyDown={this.handleEnter}
                                                        onChange={(e) => this.handleChangeFloor(e, index)}
                                                        style={{ width: 130 }}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <div className="col-md-2">
                                                <Button
                                                    className="event_button"
                                                    onClick={() => this.handleCamerasMap(index)}>
                                                    {addCameraTitle}
                                                </Button>
                                            </div>

                                            <div className="col-md-2">
                                                <Button
                                                    className="event_button"
                                                    onClick={() => toastr.confirm(
                                                        deleteActionConfirmationMessage,
                                                        {onOk: () => this.removeFloor(index)}
                                                    )}
                                                >
                                                    {deleteButtonTitle}
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Fragment>}

                    <Row className="wrap-buttons">
                    {editMode ?
                        <>
                            <Col>
                                <Button
                                    variant="outline-success"
                                    onClick={this.editData}>
                                    {saveTitle}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="outline-success"
                                    onClick={this.cancelData}>
                                    {cancelTitle}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    onClick={() => toastr.confirm(
                                        deleteActionConfirmationMessage,
                                        { onOk: () => this.deleteMall(id) },
                                    )}
                                    variant="outline-danger"
                                >
                                    {deleteButtonTitle}
                                </Button>
                            </Col>
                        </>
                        :
                        <Col>
                            <Button
                                variant="outline-success"
                                className="submit"
                                onClick={() => this.addData()}>
                                {createMallButtonTitle}
                            </Button>
                        </Col>
                    }
                    </Row>
                </Form>

                { showCamerasMap && <CamerasMapContainer floor={selectedFloor}/>}
            </Fragment>
        )
    }
}

export default MallsFormComponent
