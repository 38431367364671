import React, {Component} from 'react';
import { Form, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import { MDBDataTable} from 'mdbreact';
import KeyboardEventHandler from "react-keyboard-event-handler";
import { map, isEmpty, cloneDeep, flatten } from "lodash";
import Toggle from 'react-toggle'
import Select from 'react-select'
import { toastr } from "react-redux-toastr";

import Image from "../Image";

import { apiCall } from "../../actions/apiRequests";
import queryOptionsParser from "../../helpers/queryOptionsParser";
import validate from "../../helpers/validate";
import catchParser from "../../helpers/catchParser";
import tableSort from "../../helpers/tableSort";

import "react-toggle/style.css"


class CamersAdminComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            camera:{
                name: "",
                description: "",
                floor_number: "",
                title: "",
                nvr_name: "",
                attr: "none",
                id: "",
            },
            selectedFloor: "all",
            chosenMallName: null,
            selectedShops: [],
            openSnapshot: false,
            sorting:{
                key: "name",
                sortable: "cam_name",
                forward: true
            },
            cameras: cloneDeep(this.props.cameras),
        };
        this.modal = React.createRef();
        this.snapshot_modal = React.createRef();
    }

    componentDidMount(){
        const { chosenMall, complexRequest} = this.props;
        if (!isEmpty(chosenMall)) {
            complexRequest(
                [
                    {name: "cameras", method: "get", type: `cameras/?mall_id=${chosenMall.id}`},
                    {name: "allpolygons", method: "get", type: `cameras/polygons`},
                    // {name: "shops", method: "get", type: `shops/?mall_id=${chosenMall.id}`},
                ],
                "GET_CAMERAS_POLYGONS"
            )

            this.setState({chosenMallName: chosenMall.name})
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    componentWillReceiveProps(nextProps) {
        const { chosenMall, complexRequest, /*openAddForm,*/ location, cameras} = this.props;

        if((nextProps.chosenMall !== chosenMall || nextProps.location.key !== location.key) && !isEmpty(nextProps.chosenMall)) {
            complexRequest(
                [
                    {name: "cameras", method: "get", type: `cameras${queryOptionsParser({mall_id: nextProps.chosenMall.id})}`},
                    {name: "allpolygons", method: "get", type: `cameras/polygons`},
                    // {name: "shops", method: "get", type: `shops${queryOptionsParser({mall_id: nextProps.chosenMall.id})}`},
                ],
                "GET_CAMERAS_POLYGONS"
            );

            this.setState({chosenMallName: nextProps.chosenMall.name})
        }

        if (nextProps.cameras !== cameras /*&& !isEmpty(cameras)*/) {
            this.setState({ cameras: nextProps.cameras });
        }

        // if (openAddForm){
        //     document.addEventListener('mousedown', this.handleClickOutside);
        // } else {
        //     document.removeEventListener('mousedown', this.handleClickOutside);
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        const { openAddForm /*, chosenMall*/ } = this.props;

        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }


        // const { chosenMall, complexRequest, openAddForm, location, cameras} = this.props;

        // if((prevProps.chosenMall !== chosenMall || prevProps.location.key !== location.key) && !isEmpty(chosenMall)) {
        //     complexRequest(
        //         [
        //             {name: "cameras", method: "get", type: `cameras${queryOptionsParser({mall_id: chosenMall.id})}`},
        //             {name: "shops", method: "get", type: `shops${queryOptionsParser({mall_id: chosenMall.id})}`},
        //         ],
        //         "GET_CAMERAS_SHOPS"
        //     );

        //     this.setState({chosenMallName: chosenMall.name})
        // }

        // if (prevState.cameras !== cameras /*&& !isEmpty(cameras)*/) {
        //     this.setState({ cameras });
        // }

        // if (openAddForm){
        //     document.addEventListener('mousedown', this.handleClickOutside);
        // } else {
        //     document.removeEventListener('mousedown', this.handleClickOutside);
        // }
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    editData = () => {
        const {camera: { id, title, floor_number, ...request }, selectedShops} = this.state;
        const { setForm, editCamera, language } = this.props;
        const shops = map(selectedShops, 'value').join(',');
        const { warningTitle, filledInMessage } = language.notifications;

        if (validate({title, floor_number}, { warningTitle, filledInMessage })){
            // editCamera(id, pickBy({title, floor_number, shops, ...request}));
            editCamera(id, {title, floor_number, shops, ...request});
            setForm(false, false);

            this.setState({
                camera:{
                    name: "",
                    description: "",
                    floor_number: "",
                    title: "",
                    nvr_name: "",
                    attr: "none",
                    id: "",
                },
                selectedShops: []
            });
        }
    }

    switchEditMode = (e, camera) => {
        if ( e.target.type &&  e.target.type === 'button' ) {
            return
        }

        const { setForm } = this.props;
        setForm(true, true);
        this.setState({
            camera:{
                name: camera.name,
                description: camera.description || "",
                floor_number: camera.floor_number || "",
                title: camera.title || "",
                nvr_name: camera.nvr_name || "",
                attr: camera.attr,
                id: camera.id,
                snapshot: camera.snapshot,
                mall_id: camera.mall_id,
            },
            selectedShops: ( camera ? camera.shops.map(({id, name}) => ({ value: id, label: name })) : [] )
        });
    }

    handleChangeForm(){
        const { setForm, openAddForm } = this.props;
        setForm(!openAddForm, false);
        this.setState({
            camera:{
                name: "",
                description: "",
                floor_number: "",
                title: "",
                nvr_name: "",
                attr: "none",
                id: "",
            },
        });
    }

    handleClickOutside = (event) => {
        const {openSnapshot} = this.state;
        const {openAddForm} = this.props;

        if (openAddForm && openSnapshot) {
            if (this.snapshot_modal.current && !this.snapshot_modal.current.contains(event.target)) {
                this.setState({openSnapshot: false})
            }
        } else {
            if (this.modal.current && !this.modal.current.contains(event.target)) {
                this.handleChangeForm();
            }
        }
    };

    handleClickEsc = () => {
        if (this.props.openAddForm) {
            if (this.state.openSnapshot) {
                this.setState({openSnapshot: false})
            }else{
                this.handleChangeForm();
            }
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    handleSelectShops = shops => this.setState({ selectedShops: shops });

    getSnapshot = () => {
        const {camera} = this.state, {name} = camera;
        const { getCameraSnapshot, language } = this.props;
        const { successTitle, snapshotReceivedMessage } = language.notifications;

        if (name){
            const payload = apiCall("get", `snapshot_request/camera/${name}`)
                .then(({content}) => {
                    const {snapshot} = content;
                    this.setState({camera: {...camera, snapshot: snapshot}});
                    toastr.success(successTitle, snapshotReceivedMessage);
                    return content
                })
                .catch((e) => {
                    const error = catchParser(e, name);
                    toastr.info(error.result, error.description);
                    return null
                });

            getCameraSnapshot(payload)
        }
    };

    handleRequestSort = (key, sortable) => {
        // if (key === 'button') {
        //     return
        // }

        const { cameras, sorting } = this.state;
        const forward = !sorting.forward;

        console.log('sorting: ', sorting)

        document.querySelectorAll(".sorting").forEach(item => item.classList.remove("sorting_asc", "sorting_desc"));
        console.log('key: ', key, document.getElementsByClassName(key)[0].classList)
        document.getElementsByClassName(key)[0].classList.add(forward ? "sorting_desc" : "sorting_asc");

        this.setState({
            sorting: {key, sortable, forward},
            cameras: tableSort(cloneDeep(cameras), key, sortable, forward)
        });
    };

    handleSchemeClick = (camera) => {
        const { attentionTitle, cameraShouldHaveSnapshotMessage } = this.props.language.notifications;
        // if (!camera.floor_number) {
        //     toastr.info('Camera should have floor number');
        //     return false
        // }

        if (!camera.snapshot) {
            toastr.info(attentionTitle, cameraShouldHaveSnapshotMessage);
            return false
        }

        this.props.history.push({ pathname: "/admin/cameras/heatmap", state: { camera, camera_id: camera.id, mall_id: camera.mall_id } })
    }

    render() {
        const { camera, selectedFloor, chosenMallName, selectedShops, openSnapshot, cameras } = this.state;
        const { name, description, floor_number, nvr_name, title, attr, snapshot } = camera;
        const { shops, openAddForm, chosenMall, malls, role, complexRequest, language, camerasMapPolygons } = this.props;
        const {
            genericLabels,
            adminSection: { camerasPage },
            columns,
            images: { noImageUrl },
        } = language;
        const {
            allTitle,
            floorTitle,
            tableCountPaginationLabels,
            searchFormPlaceholder,
            allFloorsTitle,
        } = genericLabels;
        const {
            mainTitle,
            editCameraButtonTitle,
            addMappingButtonTitle,
            editMappingButtonTitle,
            chooseFloorTitle,
            observedShopsTitle,
            getSnapshotTitle,
            mappingTitle,
            editCameraMappingTitle,
            selectShopTitle,
        } = camerasPage;
        const {
            nameLabel,
            descriptionLabel,
            titleLabel,
            floorLabel,
            mappingLabel,
            attrLabel,
            NVRNameLabel,
            snapshotLabel,
        } = columns;

        const data = {
            columns: [
                {
                    label: nameLabel,
                    field: 'name',
                    minimal: ' name',
                    sort: 'cam_name',
                    width: 200,
                },
                {
                    label: descriptionLabel,
                    field: 'description',
                    minimal: ' description',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: titleLabel,
                    field: 'title',
                    minimal: ' title',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: floorLabel,
                    field: 'floor_number',
                    minimal: ' floor_number',
                    sort: 'numeric',
                    width: 200
                },
                {
                    label: mappingLabel,
                    field: 'button',
                    sort: 'button',
                    minimal: 'sm button'
                    // sort: 'disabled',
                },
                {
                    label: attrLabel,
                    field: 'attr',
                    minimal: 'sm attr',
                    sort: 'attr'
                },
            ],
            rows:   //(cameras && cameras.length)
                    (!isEmpty(cameras))
                    ?
                        cameras
                        // .filter((it) => selectedFloor === "all" || selectedFloor === +it.floor_number)
                        .map((item) => {
                            return {
                                name: item.name ? item.name : "",
                                description: item.description ? item.description.slice(0, 45) : "",
                                title: item.title ? item.title : "",
                                floor_number: item.floor_number ? item.floor_number : "",
                                button: (item.markers_count || item.polygons_count)
                                    ? <Button
                                        variant={(!item.markers_count || !item.polygons_count) ? 'outline-filling' : 'outline-success'}
                                        className="button"
                                        onClick={() => this.handleSchemeClick(item)}
                                    >
                                        {editMappingButtonTitle}
                                    </Button>
                                    : <Button
                                        variant="outline-primary"
                                        className="button"
                                        onClick={() => this.handleSchemeClick(item)}
                                    >
                                        {addMappingButtonTitle}
                                    </Button>,
                                attr: <span className={`attr ${item.attr === "entrance" ? "active" : ""}`}/>,
                                clickEvent: (e) => this.switchEditMode(e, item),
                            }
                        })
                    : [],
        }
        const available_shops = ( shops.length ? shops.map(({id,name}) => ({ value: id, label: name })) : [] )

        const polysCameras = []
        camerasMapPolygons.filter((item) => item.camera_id === camera.id).forEach( (camera, i) => {
            const poly = flatten(JSON.parse(camera.screen_coords))
            polysCameras.push(
                <polygon key={i} points={poly} />
            )
        })

        return (
            <div className={`content block_wrapper admin ${openAddForm || openSnapshot ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this.handleClickEsc}/>

                <div className="wrap_header">
                    <h2 className="page_title">{mainTitle}</h2>

                    {(role === "admin" && !isEmpty(malls)) &&
                    <Dropdown id="dropdown-basic-button" className="dropdown_btn reverse">
                        <Dropdown.Toggle id="dropdown-basic">
                            {chosenMallName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {malls.map(({id, name}) =>
                                <Dropdown.Item
                                    key={id}
                                    onClick={() => {
                                        this.setState({chosenMallName: name});
                                        complexRequest(
                                            [
                                                {name: "cameras", method: "get", type: `cameras/?mall_id=${id}`},
                                                {name: "allpolygons", method: "get", type: `cameras/polygons`},
                                                // {name: "shops", method: "get", type: `shops/?mall_id=${id}`},
                                            ],
                                            "GET_CAMERAS_POLYGONS"
                                        )
                                    }}>
                                    {name}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>}

                    <Dropdown id="dropdown-basic-button" className="dropdown_btn">
                        <Dropdown.Toggle id="dropdown-basic">
                            {selectedFloor === "all" ? allFloorsTitle : `${floorTitle} ${selectedFloor}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => this.setState({selectedFloor: "all"})}>{allTitle}</Dropdown.Item>
                            {!isEmpty(chosenMall) && !isEmpty(chosenMall.floors) && chosenMall.floors
                                .sort((a, b) => a.number - b.number)
                                .map(({id, number}) =>
                                    <Dropdown.Item key={id} onClick={() => this.setState({selectedFloor: number})}>
                                        {floorTitle} {number}
                                    </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className={`centralise ${openAddForm ? "open" : ""}`}>
                        <Form className="add_new_data" ref={this.modal}>
                            <h3 className="mb-4">{editCameraButtonTitle}</h3>

                            <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/>
                            <div className="cameras">
                                <Form.Group>
                                    <Form.Label>{nameLabel}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={nameLabel}
                                        disabled
                                        onKeyDown={this.handleEnter}
                                        value={name}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{NVRNameLabel}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={NVRNameLabel}
                                        disabled
                                        onKeyDown={this.handleEnter}
                                        value={nvr_name || ""}/>
                                </Form.Group>
                            </div>

                            <div className="cameras_option">
                                <Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>{titleLabel}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={titleLabel}
                                                min="0"
                                                value={title}
                                                onKeyDown={this.handleEnter}
                                                onChange={e => this.setState({camera: {...camera, title: e.target.value}})}/>
                                        </Form.Group>
                                    </Col>

                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>{floorTitle}</Form.Label>
                                            <DropdownButton
                                                title={floor_number ? floor_number : chooseFloorTitle}
                                                id="drop_for_mall_floors"
                                                variant="outline-dark">
                                                {!isEmpty(chosenMall.floors) && chosenMall.floors.map(({number, id}) => {
                                                    return <Dropdown.Item
                                                        key={id}
                                                        onClick={e => this.setState({camera: {...camera, floor_number: number}})}>
                                                        {number}
                                                    </Dropdown.Item>
                                                })}
                                            </DropdownButton>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <Form.Group className="mb-0">
                                            <Form.Label>{observedShopsTitle}</Form.Label>
                                            <Select
                                                isMulti         = {true}
                                                isSearchable    = {true}
                                                name        = "shops"
                                                placeholder = {selectShopTitle}
                                                options     = {available_shops}
                                                // styles      = {customSelectStyles}
                                                className   = 'react-select-container'
                                                classNamePrefix = "react-select"
                                                value       = {selectedShops}
                                                onChange    = {this.handleSelectShops}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={2}>
                                        <Form.Group className="attr_checkbox">
                                            <Form.Label>{attrLabel}</Form.Label>
                                            <Toggle
                                                className="mt-2"
                                                checked={attr === "entrance"}
                                                onChange={e => this.setState({camera: {...camera, attr: e.target.checked ? "entrance" : "none"}})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <Col md={6}>
                                    <Form.Group style={{ height: 'calc(100% - 48px)' }}>
                                        <Form.Label>{descriptionLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows="8"
                                            placeholder={descriptionLabel}
                                            value={description}
                                            onChange={e => this.setState({camera: {...camera, description: e.target.value}})}
                                            style={{ height: '100%' }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>{snapshotLabel}</Form.Label>
                                        <div className="text-center">
                                            <Image
                                                src={snapshot}
                                                // height='160px'
                                                // width="auto"
                                                alt="snapshot"
                                                // className="snapshot_image form-group"
                                                className="snapshot_image"
                                                onClick={() => snapshot && this.setState({openSnapshot: true})}
                                                failedSrc={noImageUrl}
                                            />

                                            <div>
                                                <Button
                                                    variant="outline-primary btn-sm btn-block"
                                                    onClick={this.getSnapshot}
                                                >
                                                    {getSnapshotTitle}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>{mappingTitle}</Form.Label>
                                        <div className="text-center">

                                            <div style={{ position: 'relative' }}>
                                                {/* We suppose/hardcoded the camera's snapshots have width=704 and height=480 */}
                                                <svg   preserveAspectRatio='xMinYMin slice' viewBox="0 0 704 480" width="auto"  height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ position: 'absolute', top: 0, left: 0 }}>
                                                    <g fill="transparent" stroke="#475ED1" strokeWidth="10" fillOpacity="0.5" strokeOpacity="1" r="5">
                                                        {/* <rect x='40%' y='40%' width='25%' height='25%' /> */}
                                                        {polysCameras}
                                                        {/* <polygon points="83.251953125%,98.33%,92.05877130681818%,42.29166666666667%,62.939453125%,27.70833333333333%,17.20081676136364%,63.125%,57.11558948863636%,98.95833333333333%"></polygon> */}
                                                        {/*<polygon points="581.09375,294,292.09375,119,217.09375,162,487.09375,412"></polygon>
                                                        <polygon points="493.9969141472956,410.26772638478263,646.6741934526502,478.28509252081733,696.6018345525055,312.5832111894136,610.4947433802913,246.0130230562732,581.5511833224042,292.3227191488926"></polygon>
                                                        <polygon points="370.09375,478,181.09375,227,49.09375,286,98.09375,477"></polygon> */}
                                                    </g>
                                                </svg>


                                                <Image
                                                    src={snapshot}
                                                    // height='160px'
                                                    // width="auto"
                                                    alt="mapping"
                                                    // className="snapshot_image form-group"
                                                    className="snapshot_image"
                                                    onClick={() => snapshot && this.setState({openSnapshot: true})}
                                                    failedSrc={noImageUrl}
                                                />
                                            </div>

                                            <div>
                                                <Button
                                                    variant="outline-primary btn-sm btn-block"
                                                    onClick={ () => this.handleSchemeClick(camera) }
                                                >
                                                    {editCameraMappingTitle}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button
                                style={{width: "100%"}}
                                variant="outline-success"
                                onClick={this.editData}>
                                {editCameraButtonTitle}
                            </Button>
                        </Form>
                    </div>
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    handleSort={this.handleRequestSort}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                    // paging={false}
                />

                {openSnapshot &&
                <div className="centralise open snapshot_modal-container">
                    <div className="snapshot_modal" ref={this.snapshot_modal}>
                        <button className="close_btn" type="button" onClick={() => this.setState({openSnapshot: false})}/>

                        <h2 className="page_title snapshot_modal_title">{snapshotLabel}</h2>

                        <img
                            src={snapshot}
                            alt=""
                            onErrorCapture={e => e.target.src = noImageUrl}
                        />
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default CamersAdminComponent
