import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';

import '../styles/LeftSidebar.sass'

const dashboardMenu = [
    {routeKey:'dashboard', image:'menu_dashboard.png', href:'/dashboard', active:true},
    // {routeKey:'events', image:'menu_events.png', href:'/events', active:true},
    // {routeKey:'compare', image:'menu_compare.png', href:'/compare'},
    // {routeKey:'items', image:'menu_items.png', href:'/items'},
    {routeKey:'reports', image:'menu_reports.png', href:'/reports', active:true},
    {routeKey:'admin', image:'admin.png', href:'/admin', active:true}
];

const adminMenu = [
    {routeKey:'dashboard', image:'menu_dashboard.png', href:'/dashboard'},
    {routeKey:'admin_users', image:'users.svg', href:'/admin/users'},
    {routeKey:'admin_malls_dashboard', image:'malls.svg', href:'/admin/malls/dashboard'},
    {routeKey:'admin_cameras', image:'cameras.svg', href:'/admin/cameras'},
    {routeKey:'admin_zones', image:'zones.svg', href:'/admin/zones'},
    {routeKey:'admin_debug_tool', image:'menu_events.png', href:'/admin/debugtool'},
    // {routeKey:'admin_shops', image:'shops.svg', href:'/admin/shops'},
    // {routeKey:'admin_shops_owners', image:'owners.svg', href:'/admin/shops_owners'},
    // {routeKey:'admin_events', image:'menu_events.png', href:'/admin/events'},

];


const LeftSidebar = ({role, location, switchVisibilitySidebar, isOpenSidebarMenu, routes}) => {
    const toggleSwitch = () =>{
        !isOpenSidebarMenu ? localStorage.removeItem("isHideSidebarMenu") : localStorage.setItem('isHideSidebarMenu', "true");
        switchVisibilitySidebar(!isOpenSidebarMenu)
    };

    return (
        <aside className={`left-panel ${!isOpenSidebarMenu ? "hide" : ""}`} id="style-5">
            <nav className="sidebar-nav text-center">
                <div className="navbar-header">
                    <div className="hamburger" onClick={toggleSwitch}>
                        <span/>
                    </div>
                </div>
                <div className="main-menu" id="left_sidebar-menu">
                    <ul id="sidebarnav">
                        {role === "debugger"
                        ? (
                            <li className='sidebar-item'>
                                <div className="item-wrapper">
                                    <NavLink exact
                                            to="/admin/debugtool"
                                            className={`${location.pathname.indexOf('/admin/debugtool'.split("/")[1]) !== -1 ? "active" : ""}`}
                                    >
                                        <img src="/images/menu_events.png" alt={routes['admin_debug_tool']}/>
                                        <div className="menu-title">{routes['admin_debug_tool']}</div>

                                        <div className="slider"/>
                                    </NavLink>
                                </div>
                            </li>
                        ) : (

                            (role === "admin" || role === "manager") && location.pathname.indexOf("admin") > -1 
                            ? (
                                <Fragment>
                                    {adminMenu.map((item) => {
                                        if (role !== "admin" && item.routeKey === "admin_malls_dashboard") {
                                            return null
                                        }

                                            return (
                                                <li className='sidebar-item' key={routes[item.routeKey]}>
                                                    <div className="item-wrapper">
                                                        <NavLink exact to={item.href} activeClassName='active'>
                                                            <img src={`/images/${item.image}`} alt={item.routeKey}/>
                                                            <div className="menu-title">{routes[item.routeKey]}</div>

                                                            <div className="slider"/>
                                                        </NavLink>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </Fragment>
                            ) : (
                                <Fragment>
                                    {dashboardMenu.map((item) => {
                                        if (item.routeKey === "admin" && role && role === "marketer"){
                                            return null
                                        }

                                        return (
                                            <li className='sidebar-item' key={item.routeKey}>
                                                <div className="item-wrapper">
                                                    {item.active ?
                                                    <NavLink exact
                                                            to={item.href}
                                                            className={`${location.pathname.indexOf(item.href.split("/")[1]) !== -1 ? "active" : ""}`}
                                                    >
                                                        <img src={`/images/${item.image}`} alt={routes[item.routeKey]}/>
                                                        <div className="menu-title">{routes[item.routeKey]}</div>

                                                        <div className="slider"/>
                                                    </NavLink>
                                                    :
                                                    <a href="/" onClick={e => e.preventDefault()} className="link-disabled">
                                                        <img src={`/images/${item.image}`} alt={routes[item.routeKey]}/>
                                                        <div className="menu-title">{routes[item.routeKey]}</div>
                                                    </a>
                                                    }
                                                </div>
                                            </li>
                                        )
                                    })}
                                </Fragment>
                            )
                        )}
                        </ul>
                    </div>
                </nav>
            </aside>
        )
    };

export default LeftSidebar
