import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ShopsAdminComponent from '../../components/admin/ShopsAdminComponent';
import {addShop, deleteShop, editShop, getShops, complexRequest} from '../../actions/apiRequests';
import {setEditMode, setVisibilityAddForm} from "../../actions/filters";


const mapStateToProps = state => {
    const {
        adminData,
        dashboardData,
        filters,
        user,
        language,
    } = state;

    return {
        shops: adminData.shops,
        malls: adminData.malls,
        shops_owners: adminData.shops_owners,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        role: filters.role,
        chosenMall: dashboardData.chosenMall,
        shopTypes: dashboardData.shopTypes,
        user,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addShop: (data)=> dispatch(addShop(data)),
        editShop: (id , data)=> dispatch(editShop(id , data)),
        deleteShop: (id)=> dispatch(deleteShop(id)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getShops: (options="") => dispatch(getShops(options)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType))
    };
};
const ShopsAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ShopsAdminComponent));

export default ShopsAdminContainer;