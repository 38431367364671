import React, {Component, Fragment} from 'react';
import {Button, Dropdown, DropdownButton, Form} from "react-bootstrap";
import { MDBDataTable } from 'mdbreact';
import {isEmpty, find} from "lodash";
import {toastr} from "react-redux-toastr";
import moment from "moment";
import EventsComparisonContainer from "../containers/EventsComparisonContainer";
import EventsFormContainer from "../containers/EventsFormContainer";
import EventContainer from "../containers/EventContainer";
import EventsSelectListContainer from "../containers/EventsSelectListContainer";
import parseDate from "../helpers/parseDate";


class EventsComponent extends Component{
    state={
        chosenMallName: "all"
    };

    componentDidMount(){
        const { role, user: {id, roles, malls_id}, complexRequest } = this.props;
        if (role !== null && id){
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`},
                    {name: "events", method: "get", type: `events${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`}
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }
    }

    componentDidUpdate(prevProps){
        const { user, complexRequest, location, malls } = this.props, {roles, malls_id} = user;
        const {chosenMallName} = this.state;

        if(prevProps.user !== user){
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`},
                    {name: "events", method: "get", type: `events${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`}
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }

        if(prevProps.location.key !== location.key && malls_id){
            complexRequest(
                [
                    {
                        name: "shops",
                        method: "get",
                        type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : chosenMallName === "all" ? "" : `/?mall_id=${find(malls, ['name', chosenMallName]).id}`}`
                    },
                    {
                        name: "events",
                        method: "get",
                        type: `events${roles === "manager" ? `/?mall_id=${malls_id}` : chosenMallName === "all" ? "" : `/?mall_id=${find(malls, ['name', chosenMallName]).id}`}`
                    }
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }
    }

    componentWillUnmount(){
        const { addEventToList, eventsList, showEventsSelectList, switchEventsSelectList, chooseEvent, chosenEvent, setForm } = this.props;
        eventsList.length > 0 && addEventToList(false);
        showEventsSelectList && switchEventsSelectList(false);
        !isEmpty(chosenEvent) && chooseEvent(null, null, false);
        setForm(false, false);
    }

    onEventAdd(event, e){
        const {
            eventsList,
            switchEventsSelectList,
            deleteEventFromList,
            chooseEvent,
            language,
        } = this.props;
        const {start_datetime, end_datetime, shop_id, id} = event;
        const { attentionTitle, eventExistsMessage } = language.notifications;

        if (e.target.checked){
            if (isEmpty(eventsList.filter(it => it.id === id))){
                const eventOptions = {
                    start_ts: moment(start_datetime).utc().format('YYYYMMDDHHmmss'),
                    end_ts: moment(end_datetime).utc().format('YYYYMMDDHHmmss'),
                    shop_id
                };
                switchEventsSelectList(true);
                chooseEvent(event, eventOptions, true);
            } else {
                toastr.info(attentionTitle, eventExistsMessage);
            }
        }else {
            deleteEventFromList(id)
        }
    }

    chooseEvent(event, e){
        const {start_datetime, end_datetime, shop_id, id} = event;
        const {
            chooseEvent,
            showEventsSelectList,
            switchEventsSelectList,
            switchEvent,
            language,
        } = this.props;
        const { attentionTitle, comparisonAndModalsExceptionMessage } = language.notifications;

        if (!(e.target.htmlFor === `comparison_checkbox${id}` || e.target.id === `comparison_checkbox${id}`)) {  //Checking target may be it's a checkbox if true, do nothing
            if (showEventsSelectList){
                toastr.info(attentionTitle, comparisonAndModalsExceptionMessage);
            }else {
                const eventOptions = {
                    start_ts: moment(start_datetime).utc().format('YYYYMMDDHHmmss'),
                    end_ts: moment(end_datetime).utc().format('YYYYMMDDHHmmss'),
                    shop_id
                };

                chooseEvent(event, eventOptions);
                switchEventsSelectList(false);
                switchEvent(true);
            }
        }
    }

    close(){
        const {addEventToList, switchEventsSelectList, showEventsSelectList, eventsList} = this.props;

        if (!isEmpty(eventsList)) {
            eventsList.forEach(id => {
                const item = document.getElementById(`comparison_checkbox${id}`);
                item && (item.checked = false)
            });
            if(showEventsSelectList){
                addEventToList(false);
                switchEventsSelectList(false);
            }
        }
    }

    render(){
        const {
            showEvent,
            showEventsSelectList,
            showEventsComparison,
            openAddForm,
            setForm,
            getEvents,
            malls,
            events,
            eventsList,
            user: {roles},
            language,
        } = this.props;
        const {chosenMallName } = this.state;

        const { eventsPage, genericLabels, columns } = language;
        const { mainTitle, createEventButtonTitle } = eventsPage;
        const {
            tableCountPaginationLabels,
            allMallButtonTitle,
            searchFormPlaceholder,
            allTitle,
        } = genericLabels;
        const {
            nameLabel,
            descriptionLabel,
            shopLabel,
            budgetLabel,
            dateLabel,
        } = columns;

        const data = {
            columns: [
                {
                    label: <div className="comparison-btn comparison-minus" onClick={() => this.close()}/>,
                    field: 'check',
                    minimal: "comparison none_sort",
                    sort: 'disabled'
                },
                {
                    label: nameLabel,
                    field: 'name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: descriptionLabel,
                    field: 'description',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: shopLabel,
                    field: 'shop',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: budgetLabel,
                    field: 'budget',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: dateLabel,
                    field: 'date',
                    sort: 'asc',
                    width: 200
                },
            ],
            rows: !isEmpty(events) ?
                events.map((item) => {
                    const {start_datetime, end_datetime, name, description, shop_name, budget, id} = item;
                    let date, time;

                    if (parseDate(start_datetime, "DD") === parseDate(end_datetime, "DD")) {
                        date = parseDate(start_datetime, "DD MMM YYYY");
                        time = `${parseDate(start_datetime, "dddd")} ${parseDate(start_datetime, "HH:mm")}-${parseDate(end_datetime, "HH:mm")}`;
                    } else {
                        date = `${parseDate(start_datetime, "DD")}-${parseDate(end_datetime, "DD")} ${parseDate(start_datetime, "MMM YYYY")}`;
                        time = `${parseDate(start_datetime, "dddd")} ${parseDate(start_datetime, "HH:mm")}-${parseDate(end_datetime, "dddd")} ${parseDate(end_datetime, "HH:mm")}`;
                    }

                    return {
                        check: <Form.Group controlId={`comparison_checkbox${id}`}>
                            <Form.Check
                                type="checkbox"
                                custom
                                label=""
                                onChange={e => this.onEventAdd(item, e)}
                                checked={!isEmpty(eventsList.filter((it) => it.id === id))}
                            />
                        </Form.Group>,
                        name: name ? name : "",
                        description: description ? description.slice(0, 45) : "",
                        shop: shop_name ? shop_name : "",
                        budget: budget ? budget : "",
                        date: <Fragment>
                            <p className="title">{date}</p>
                            <span className="description">{time}</span>
                        </Fragment>,
                        clickEvent: (e) => this.chooseEvent(item, e)
                    }
                }) : []
        };

        return (
            <div className={`content ${showEvent || showEventsComparison || openAddForm ? "event_modal disabled" : ""} ${showEventsSelectList ? "visible_events_list" : ""}`}>
                <div className="wrap_header">
                    <h2 className="page_title">
                        {mainTitle}
                    </h2>

                    <Button className="btn_new_item" onClick={() => setForm(!openAddForm, false)}>
                        {createEventButtonTitle}
                    </Button>

                    {roles === "admin" &&
                    <DropdownButton
                        className="dropdown_btn"
                        title={chosenMallName === "all" ? allMallButtonTitle : chosenMallName}
                        variant="dark">
                        <Dropdown.Item onClick={() => {
                            this.setState({chosenMallName: "all"});
                            getEvents()
                        }}>{allTitle}</Dropdown.Item>

                        {!isEmpty(malls) && malls.map(({name, id}) => {
                            return <Dropdown.Item
                                className="drop_menu-item"
                                key={id}
                                onClick={() => {
                                    this.setState({chosenMallName: name});
                                    getEvents(`/?mall_id=${id}`)
                                }}>
                                {name}
                            </Dropdown.Item>
                        })}
                    </DropdownButton>}
                </div>

                <div className="main">
                    <MDBDataTable
                        className="data_table"
                        striped
                        info
                        data={data}
                        infoLabel={tableCountPaginationLabels}
                        searchLabel={searchFormPlaceholder}
                    />
                </div>

                <EventsFormContainer/>

                {showEvent && <EventContainer/>}

                {showEventsSelectList && <EventsSelectListContainer/>}

                {showEventsComparison && <EventsComparisonContainer/>}
            </div>
        )
    }
}

export default EventsComponent
