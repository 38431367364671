import { isEmpty } from "lodash";

const defaultState = {
    malls:[],
    users:[],
    shops:[],
    events:[],
    shops_owners:[],
    cameras:[],
    zones:[],
    chosenMall: {},
    chosenCamera: {},
    chosenZone: {},
    mappingMarkers: [],
    mappingSnapshotPolygons: [],
    mappingMapPolygons: [],
    camerasMapPolygons: [],
    // mappingSnapshotPolygons: [[79.890625,121,51.890625,175,148.890625,255,223.890625,273.890625,114]]
    // mappingSnapshotPolygons: [[{x: 79.890625, y: 121},{x: 51.890625, y: 175},{x: 148.890625, y: 255},{x: 223.890625, y: 218},{x: 273.890625, y: 114},{x: 79.890625, y: 121}]]
};

const adminData = (state = defaultState, action) => {
    // console.log('reducers/adminData state, action: ', state, action)
    switch (action.type) {
        case 'GET_MALLS_FULFILLED': {
            return Object.assign({}, state, {
                malls: action.payload,
            });
        }
        case 'GET_DEBUGTOOL_AVAILABLE_DAYS_FULFILLED': {
            return Object.assign({}, state, {
                malls: action.payload.malls,
                camerasMapPolygons: action.payload.allcameraspolygons,
            });
        }
        case 'GET_MALL_BY_ALL_MALLS_FULFILLED':{
            return Object.assign({}, state, {
                malls: action.payload.malls,
            });
        }
        case 'GET_USERS_FULFILLED': {
            return Object.assign({}, state, {
                users: action.payload,
            });
        }
        case 'GET_SHOPS_FULFILLED': {
            return Object.assign({}, state, {
                shops: action.payload,
            });
        }
        case 'GET_SHOPS_OWNERS_FULFILLED': {
            return Object.assign({}, state, {
                shops_owners: action.payload
            });
        }
        case 'GET_EVENTS_FULFILLED': {
            return Object.assign({}, state, {
                events: action.payload
            });
        }
        case 'GET_CAMERAS_FULFILLED': {
            return Object.assign({}, state, {
                cameras: action.payload
            });
        }

        case 'GET_MALLS_AND_SHOPS_AND_SHOPS_OWNERS_FULFILLED': {
            if(action.payload){
                return Object.assign({}, state, {
                    malls: action.payload.malls,
                    shops: action.payload.shops,
                    shops_owners: action.payload.shops_owners,
                });
            }
            return state
        }
        case 'GET_SHOPS_AND_SHOPS_OWNERS_FULFILLED': {
            if(action.payload){
                const {shops, shops_owners} = action.payload;
                return Object.assign({}, state, {shops, shops_owners});
            }
            return state
        }
        case 'GET_MALLS_AND_USERS_FULFILLED': {
            if (action.payload){
                return Object.assign({}, state, {
                    malls: action.payload.malls,
                    users: action.payload.users
                });
            }
            return state
        }
        case 'GET_MALL_AND_CAMERA_FULFILLED': {
            if (action.payload){
                const screenPolygons = []
                const mapPolygons = []
                if (!isEmpty(action.payload.mapping)) {
                    action.payload.mapping.polygons.forEach((polygon, i) => {
                        const arrScreen = []
                        const arrMap = []
                        JSON.parse(polygon.screen_coords).forEach((item) => {
                            arrScreen.push(item[0], item[1])
                        })
                        // screenPolygons.push(arrScreen)
                        screenPolygons.push({ polygon_id: polygon.id, screen_coords: arrScreen })
        
                        JSON.parse(polygon.map_coords).forEach((item) => {
                            arrMap.push(item[0], item[1])
                        })
                        // mapPolygons.push(arrMap)
                        mapPolygons.push({ polygon_id: polygon.id, map_coords: arrMap })
                    })
                }

                return Object.assign({}, state, {
                    chosenMall: action.payload.mall,
                    chosenCamera: action.payload.camera,
                    mappingMarkers: action.payload.mapping.markers || [],
                    mappingSnapshotPolygons: screenPolygons,
                    mappingMapPolygons: mapPolygons,
                    camerasMapPolygons: action.payload.allpolygons,
                });
            }
            return state
        }
        case 'GET_SHOPS_AND_EVENTS_FULFILLED': {
            if(action.payload){
                return Object.assign({}, state, {
                    shops: action.payload.shops,
                    events: action.payload.events
                });
            }
            return state
        }
        case 'GET_CAMERAS_POLYGONS_FULFILLED': {
            if(action.payload){
                return Object.assign({}, state, {
                    cameras: action.payload.cameras,
                    camerasMapPolygons: action.payload.allpolygons,
                    // shops: action.payload.shops
                });
            }
            return state
        }
        case 'GET_CAMERA_SNAPSHOT_FULFILLED': {
            return Object.assign({}, state, {
                cameras: action.payload ? state.cameras.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...item, ...{snapshot: action.payload.snapshot}
                        })
                    }
                    return item;
                }) : state.cameras
            });
        }
        case 'GET_ZONES_FULFILLED': {
            if (action.payload && action.payload.hasOwnProperty('zones')) {
                return Object.assign({}, state, {
                    zones: action.payload.zones,
                });
            }
            return state
        }
        case 'GET_MALL_AND_POLYGONS_FULFILLED': {
            if (action.payload){
                return Object.assign({}, state, {
                    chosenMall: action.payload.mall,
                    camerasMapPolygons: action.payload.allpolygons,
                });
            }
            return state
        }



        case 'ADD_USER_FULFILLED': {
            return Object.assign({}, state, {
                users: action.payload ? [...state.users , action.payload] : state.users
            });
        }
        case 'ADD_MALL_FULFILLED': {
            return Object.assign({}, state, {
                malls: action.payload ? [...state.malls , action.payload] : state.malls
            });
        }
        case 'ADD_SHOP_FULFILLED': {
            return Object.assign({}, state, {
                shops: action.payload ? [...state.shops , action.payload] : state.shops
            });
        }
        case 'ADD_SHOP_OWNER_FULFILLED': {
            return Object.assign({}, state, {
                shops_owners: action.payload ? [...state.shops_owners , action.payload] : state.shops_owners
            });
        }
        case 'ADD_EVENT_FULFILLED': {
            return Object.assign({}, state, {
                events: action.payload ? [...state.events , action.payload] : state.events
            });
        }
        case 'ADD_ZONE_FULFILLED': {
            return Object.assign({}, state, {
                zones: action.payload ? [...state.zones , action.payload] : state.zones
            });
        }
        // case 'APPLY_POLYGONS_TO_MAP_FULFILLED': {
        //     if(action.payload){
        //         console.log('APPLY_POLYGONS_TO_MAP_FULFILLED: ', action.payload)
        //         // return Object.assign({}, state, {
        //         //     malls: action.payload.malls,
        //         //     shops: action.payload.shops,
        //         //     shops_owners: action.payload.shops_owners,
        //         // });

        //         // const polygons = []
        //         // action.payload.markers.polygons.forEach((polygon, i) => {
        //         //     console.log('polygon: ', JSON.parse(polygon.map_coords))
        //         //     const arr = []
        //         //     JSON.parse(polygon.screen_coords).forEach((item) => {
        //         //         arr.push(item[0], item[1])
        //         //     })
        //         //     polygons.push(arr)
        //         // })


        //         // return Object.assign({}, state, {
        //         //     chosenMall: action.payload.mall,
        //         //     chosenCamera: action.payload.camera,
        //         //     mappingMarkers: action.payload.markers.markers || [],
        //         //     mappingSnapshotPolygons: polygons,
        //         // });
        //     }
        //     return state
        // }


        case 'EDIT_ADMIN_DATA': {
            const cluster = JSON.parse(JSON.stringify(state[action.payload.type+"s"]));

            return {...state, [action.payload.type+"s"] : cluster.map((item) => {
                    if (item.id=== action.payload.data.id) {
                        return Object.assign({}, item, {
                            ...action.payload.data
                        })
                    }
                    return item;
                })}
        }
        case 'EDIT_USER_FULFILLED': {
            return Object.assign({}, state, {
                users: action.payload ? state.users.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.users
            });
        }
        case 'EDIT_MALL_FULFILLED': {
            return Object.assign({}, state, {
                malls: action.payload ? state.malls.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.malls
            });
        }
        case 'EDIT_SHOP_FULFILLED': {
            return Object.assign({}, state, {
                shops: action.payload ? state.shops.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.shops
            });
        }
        case 'EDIT_SHOP_OWNER_FULFILLED': {
            return Object.assign({}, state, {
                shops_owners: action.payload ? state.shops_owners.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.shops_owners
            });
        }
        case 'EDIT_EVENT_FULFILLED': {
            return Object.assign({}, state, {
                events: action.payload ? state.events.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.events
            });
        }
        case 'EDIT_CAMERA_FULFILLED': {
            return Object.assign({}, state, {
                cameras: action.payload ? state.cameras.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.events
            });
        }
        // case 'ADD_CAMERA_POLYGONS_FULFILLED':
        case 'EDIT_CAMERA_POLYGONS_FULFILLED': {
            if (action.payload){
                const screenPolygons = []
                const mapPolygons = []
                action.payload.forEach((polygon, i) => {
                    console.log('polygon: ', polygon)

                    const arrScreen = []
                    const arrMap = []
                    JSON.parse(polygon.screen_coords).forEach((item) => {
                        arrScreen.push(item[0], item[1])
                    })
                    // screenPolygons.push(arrScreen)
                    screenPolygons.push({ polygon_id: polygon.id, screen_coords: arrScreen })

                    JSON.parse(polygon.map_coords).forEach((item) => {
                        arrMap.push(item[0], item[1])
                    })
                    // mapPolygons.push(arrMap)
                    mapPolygons.push({ polygon_id: polygon.id, map_coords: arrMap })
                })

                return Object.assign({}, state, {                    
                    mappingSnapshotPolygons: screenPolygons,
                    mappingMapPolygons: mapPolygons,
                });
            }
            return state
        }
        case 'EDIT_ZONE_FULFILLED': {
            return Object.assign({}, state, {
                zones: action.payload ? state.zones.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.zones
            });
        }


        case 'DELETE_USER_FULFILLED': {
            return Object.assign({}, state, {
                users: action.payload ? state.users.filter((item) => action.payload !== item.id) : state.users
            })
        }
        case 'DELETE_MALL_FULFILLED': {
            return Object.assign({}, state, {
                malls: action.payload ? state.malls.filter((item) => action.payload !== item.id) : state.malls
            })
        }
        case 'DELETE_SHOP_FULFILLED': {
            return Object.assign({}, state, {
                shops: action.payload ? state.shops.filter((item) => action.payload !== item.id) : state.shops
            })
        }
        case 'DELETE_SHOP_OWNER_FULFILLED': {
            return Object.assign({}, state, {
                shops_owners: action.payload ? state.shops_owners.filter((item) => action.payload !== item.id) : state.shops_owners
            })
        }
        case 'DELETE_EVENT_FULFILLED': {
            return Object.assign({}, state, {
                events: action.payload ? state.events.filter((item) => action.payload !== item.id) : state.events
            })
        }
        case 'DELETE_ZONES_FULFILLED': {
            const removedZones = action.payload.split(',')
            return Object.assign({}, state, {
                zones: action.payload ? state.zones.filter((item) => removedZones.indexOf(item.id.toString()) === -1 ) : state.zones
            })
        }

        case 'LOGGED_OUT_FULFILLED': {
            return defaultState
        }
        case 'CHOOSE_MALL':
        case 'GET_ADMIN_MALL_FULFILLED': {
            return Object.assign({}, state, {
                chosenMall: action.payload
            })
        }
        case 'CHOOSE_ZONE_FULFILLED':
        case 'CHOOSE_ZONE': {
            return Object.assign({}, state, {
                chosenZone: action.payload
            });
        }
        default:
            return state;
    }
};

export default  adminData;