import React, {useState, useEffect} from 'react';

const FullScreenToggle = ({element = null}) => {
    const [fullScreenMode, setFullScreenMode] = useState(false);

    const fullscreenEnabled = document.fullscreenEnabled;

    const fullScreenBrowserType =
        document.mozFullScreenEnabled ? "mozfullscreenchange" :
        document.webkitFullscreenEnabled ? "webkitfullscreenchange" :
        document.msFullscreenEnabled ? "MSFullscreenChange" :
        'fullscreenchange';

    useEffect(() => {
        if (fullscreenEnabled) {
            document.addEventListener(fullScreenBrowserType, handleFullScreenChange, false);
            return () => {
                document.removeEventListener(fullScreenBrowserType, handleFullScreenChange, false);
            };
        }
    }, [fullscreenEnabled, fullScreenBrowserType]);

    const handleFullScreenChange = () => setFullScreenMode(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement);

    const fullScreenChange = () => {
        if (fullScreenMode) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }else {
            if (element) {
                if (element.requestFullScreen) {
                    element.requestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen();
                }
            }
        }
    };

    if (fullscreenEnabled){
        return <button className={`full-screen-launcher ${fullScreenMode ? "opn" : "cls"}`} onClick={fullScreenChange}/>
    }else {
        return null
    }
};

export default FullScreenToggle;
