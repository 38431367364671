import _ from 'lodash'
import moment from 'moment'

const dasetsParser = (label, data = [], color) =>{
    return {
        label,
        data,
        backgroundColor: color,
        hoverBackgroundColor: color,
        borderWidth: 0
    }
};

const formatedLabel = (argKeyValue) => { 
    let {start_ts, end_ts} = argKeyValue
    const startDate = moment.utc(start_ts, 'YYYYMMDDHHmmss').format()
    const endDate = moment.utc(end_ts, 'YYYYMMDDHHmmss').format()

    start_ts = moment.utc(startDate).local();
    end_ts = moment.utc(endDate).local();

    if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'day')) {
        return start_ts.format('DD/MM')
    } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'month')) {
        return `${start_ts.format('DD')} - ${end_ts.format('DD/MM')}`
    } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'year')) {
        return `${start_ts.format('DD/MM')} - ${end_ts.format('DD/MM')}`
    } else {
        return `${start_ts.format('DD/MM')} - ${end_ts.format('DD/MM')}`
    }
}

export default function visitorsZoneAndParser(selectedZonesList, daterangeKey, dataZone, data, colors, format, daterangesLabels, hourLabel, dataFieldName) {
    if (_.isEmpty(data)) {
        return {
            datasets: [],
            labels:[]
        }
    } else {
        const arr = [];
        data.forEach((item) => {
            const obj = arr.find(({zone_id}) => zone_id === item.zone_id)
            if (obj) {
                obj.stats.push({...item})
            } else {
                arr.push({zone_id: item.zone_id, stats: [{...item}]})
            }
        })

        const datasets = arr.map((item, index) => {
            return dasetsParser(
                selectedZonesList.find(({id}) => id === item.zone_id).name,
                item.stats.map((item1) => {
                    // let res = item1[dataFieldName].reduce((a, b) => a + b, 0)
                    let res = _.sum(item1[dataFieldName])
                    if (!Number.isInteger(res)) {
                        res = res.toFixed(2)
                    }
                    return res
                }),
                // item.stats.reverse().map((item1) => _.sum(item1[dataFieldName])),
                colors[index % colors.length]
            )
        });

        return {
            labels:  _.uniq(_.map([...data], 'key').map(item => formatedLabel(item))),
            // labels:  _.uniq(_.map([...data], 'key').reverse().map(item => formatedLabel(item))),
            datasets,
        };

    }
}
