import React, { useState, useEffect } from 'react'
import { Col } from "react-bootstrap"
import moment from 'moment'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'

import Graph from '../../components/Graph'
import Spinner from '../../components/Spinner'
import visitorsZoneParser from '../../helpers/visitorsZoneParser'
import visitorsZoneAndParser from '../../helpers/visitorsZoneAndParser'
import { getDataGroup, getDataGroupNew } from '../../helpers/dashboardUtils'
import queryOptionsParser from '../../helpers/queryOptionsParser'
import catchParser from '../../helpers/catchParser'
import { apiCall /*getZoneDetailCompareData*/ } from '../../actions/apiRequests'
// import { setShowDetailCompareData } from '../../actions/filters'

import '../../styles/ShopComparisonComponent.sass'


const BGS = ['#201158', '#56ca61', '#63a6b5', '#e575a3', '#fbc02d'];

function GraphWrapper (props) {
    const {
        title,
        dataFieldName,
        dailyData,
        compareData,
        chosenMall,
        // detailCompareData,
        selectedComparisonDates,
        chosenComparisonsZones,
        // showDetailCompareData,
        zones,
        language,
        // setShowDetailCompareData,
        // getZoneDetailCompareData,
    } = props
    const { data: languageData } = language;
    const {
        dashboardPage,
        genericLabels,
    } = languageData;
    const {
        daterangesLabels,
    } = dashboardPage;
    const {
        hourLabel,
    } = genericLabels;
    const { open_time, close_time } = chosenMall;

    const [ showDetailCompareData, setShowDetailCompareData] = useState(false)
    const [ detailCompareData, setDetailCompareData] = useState([])
    const [ showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        setDetailCompareData([])
        setShowDetailCompareData(false)
    }, [selectedComparisonDates, chosenComparisonsZones]);

    const diffDays = compareData && compareData.length && moment(compareData[0].key.end_ts,'YYYYMMDDHHmmss').diff(moment(compareData[0].key.start_ts,'YYYYMMDDHHmmss'), 'days') // compareData.key: {start_ts: "20210222220000", end_ts: "20210223215959"}
    // console.log('GraphWrapper diffDays!!!: ', diffDays)

    const datalabelsPluginCofig = {
        datalabels: {
            display: true, // visibility flag of datalabels
            color: '#201158',
            font:{
                size: 10,
                weight: 600,
                family: "Poppins"
            },
            anchor: 'end',
            align: 'top',
            // formatter: data => data > 0 ? formatNumber(data) : ""
            formatter: data => data > 0 ? data : ""
        }
    }    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {enabled: false},
        plugins: datalabelsPluginCofig,
        increaseLegendHeight: 15,
        hover: {
            // mode: (showDetailCompareData || (dailyData && diffDays === 0)) ? null : 'nearest', // we should not allow to click on bar for Daily Data graphs when "In Dates" containes just one day
            // mode: (showDetailCompareData || (dailyData && diffDays < 30)) ? null : 'nearest', // we should not allow to click on bar for Daily Data graphs when "In Dates" containes just one day
            mode: (showDetailCompareData || (dailyData && ((selectedComparisonDates.length === 1 && diffDays <= 30) || (selectedComparisonDates.length > 1 && diffDays === 0)))) ? null : 'nearest', // we should not allow to click on bar for Daily Data graphs when "In Dates" containes just one day
            onHover: (e, chartElement) => {
                return e.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
            }
        },
        legend: {
            position: 'top',
            labels: {
                fontSize: 10,
                boxWidth: 20,
                padding: 10
            }
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        fontSize: 12,
                        lineHeight: .5,
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                    }
                }
            ],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 6
                }
            }]
        }
    }

    const handleGraphClick = (elemIndex, datasetIndex) => {
        if (elemIndex !== null && !showDetailCompareData && !(dailyData && ((selectedComparisonDates.length === 1 && diffDays <= 30) || (selectedComparisonDates.length > 1 && diffDays === 0)))) {
            if (selectedComparisonDates.length > 1 && chosenComparisonsZones.length) {
                const dateRange = _.uniq(_.map([...compareData], 'key'))[elemIndex]
                // console.log('dateRange: ', dateRange)
                const selectedZonesId = [...(_.map(chosenComparisonsZones, 'value'))]
                const params = {
                    start_ts: dateRange.start_ts,
                    end_ts: dateRange.end_ts,
                    group_range: dailyData ? 86400 : getDataGroup(moment(dateRange.start_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'), moment(dateRange.end_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')),
                    zone_id: selectedZonesId.join(','),
                }
                setShowSpinner(true)
                apiCall('get', `stats/zone/comparison${queryOptionsParser(params)}`)
                    .then(res => {
                        const records = []
                        res.content.forEach((item) => {
                            const {stats:{timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors}, zone_id} = item
                            records.push({zone_id, timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors})
                        })

                        setDetailCompareData(records)
                        setShowDetailCompareData(true)
                        setShowSpinner(false)
                    })
                    .catch(e => {
                        const error = catchParser(e)
                        toastr.info(error.result, error.description)
                        setShowSpinner(false)
                    })
            } else {
                const dateRange = compareData[0].key
                let group_range = getDataGroup(moment(dateRange.start_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'), moment(dateRange.end_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'))
                if (!dailyData && diffDays !== 0) {
                    group_range =  getDataGroupNew(compareData[0].timestamp[0], compareData[0].timestamp[1])
                }
                // console.log('dateRange: ', dateRange)
                const selectedZonesId = [...(_.map(chosenComparisonsZones, 'value'))]

                const timestamp = compareData[0].timestamp[elemIndex] // 2021-02-22 22:00:00
                let start_ts = moment(timestamp).format('YYYYMMDDHHmmss')
                let end_ts = moment(timestamp).add({seconds: group_range-1}).format('YYYYMMDDHHmmss')
                // console.log('group_range: ', group_range)
                if (group_range === 86400) { // was clicked by the one day bar
                    moment.utc('20210303220000', 'YYYYMMDDHHmmss').local().format('YYYYMMDDHHmmss')
                    // console.log('open_time, close_time: ', open_time, close_time)
                    let openTime = '000000'
                    let closeTime = '235959'
                    // let openTime = moment("000000", "HHmmss").utc().format('HHmmss')
                    // let closeTime = moment("235959", "HHmmss").utc().format('HHmmss')
                    if (open_time) {
                        openTime = open_time
                        // openTime = moment(open_time, "HHmmss").utc().format('HHmmss')
                    }
                    if (close_time) {
                        closeTime = close_time
                        // closeTime = moment(close_time, "HHmmss").utc().format('HHmmss')
                    }
                    // console.log('openTime, closeTime: ', openTime, closeTime)
                    start_ts = moment(moment.utc(start_ts, 'YYYYMMDDHHmmss').local().format(`YYYYMMDD${openTime}`), 'YYYYMMDDHHmmss').utc().format('YYYYMMDDHHmmss')
                    end_ts = moment(moment.utc(end_ts, 'YYYYMMDDHHmmss').local().format(`YYYYMMDD${closeTime}`), 'YYYYMMDDHHmmss').utc().format('YYYYMMDDHHmmss')
                    // start_ts = moment(start_ts, 'YYYYMMDDHHmmss').format(`YYYYMMDD${openTime}`)
                    // end_ts = moment(end_ts, 'YYYYMMDDHHmmss').format(`YYYYMMDD${closeTime}`)
                }
                
                const params = {
                    start_ts,
                    end_ts,
                    group_range: dailyData ? 86400 : (diffDays === 0 ? group_range / 12 : Math.max(group_range / 12, 3600)),
                    zone_id: selectedZonesId.join(','),
                }
                // console.log('GRaphWrappers params: ', params)
                setShowSpinner(true)
                apiCall('get', `stats/zone/comparison${queryOptionsParser(params)}`)
                    .then(res => {
                        const records = []
                        res.content.forEach((item) => {
                            const {stats:{timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors}, zone_id} = item
                            records.push({zone_id, timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors})
                        })

                        setDetailCompareData(records)
                        setShowDetailCompareData(true)
                        setShowSpinner(false)
                    })
                    .catch(e => {
                        const error = catchParser(e)
                        toastr.info(error.result, error.description)
                        setShowSpinner(false)
                    })
            } 
        }

    };

    // Data for graph
    let format = ''
    if (diffDays === 0) {
        format = 'HH';
    } else if (diffDays < 30) {
        format = 'DD';
    } else if (diffDays >= 30 && diffDays < 365) {
        format = 'DD/MM';
    } else {
        format = 'DD/MM';
    }
    // }
    // let customUnit = 'day';
    // let customUnitStepSize = 1;
    // let format = ''
    // if (daterange.key === 'customTime') {
    //     const diff = moment(daterange.endDate).diff(moment(daterange.startDate), "days")
    //     if (diff < 2) {
    //         format = 'HH:mm';
    //         customUnit = 'hour';
    //         customUnitStepSize = 2;
    //     } else if (diff < 5) {
    //         format = 'DD.MM HH:mm';
    //         customUnit = 'hour';
    //         customUnitStepSize = 5;
    //     } else if (diff < 30) {
    //         format = 'DD.MM';
    //         customUnit = 'day';
    //         customUnitStepSize = 1;
    //     } else if (diff >= 30 && diff < 365) {
    //         format = 'DD';
    //         customUnit = 'day';
    //         customUnitStepSize = 5;
    //     } else {
    //         format = 'MM-DD';
    //         customUnit = 'month';
    //         customUnitStepSize = 1;
    //     }
    // }

    let mainData = {
        labels: [],
        datasets: [],
    }
    let detailData = {
        labels: [],
        datasets: [],
    }
    
    if (selectedComparisonDates.length > 1 && chosenComparisonsZones.length) {
        const selectedZonesId = [..._.map(compareData, "zone_id")] // list of zone's id which will be displayed in top legends
        const selectedZonesList = zones.filter(({id}) => selectedZonesId.includes(id)) // list of zones selected in dropdown list and top zone
        mainData = visitorsZoneAndParser(
            selectedZonesList,
            '',
            // daterange.key, 
            // (daterange.key === 'customTime' ? 'customTimeShow' : daterange.key),
            [],
            // _.cloneDeep([currentVisitors, ...visitorsComparisonData]),
            _.cloneDeep([...compareData]),
            BGS, format, daterangesLabels, hourLabel, dataFieldName, dailyData
        )

        detailData = visitorsZoneParser(
            selectedZonesList,
            '',
            // (daterange.key === 'customTime' ? 'customTimeShow' : daterange.key),
            _.cloneDeep([...detailCompareData]),
            BGS, format, daterangesLabels, hourLabel, dataFieldName
        )
    } else if (selectedComparisonDates.length === 1 && compareData) {
        const selectedZonesId = [..._.map(compareData, "zone_id")] // list of zone's id which will be displayed in top legends
        const selectedZonesList = zones.filter(({id}) => selectedZonesId.includes(id)) // list of zones selected in dropdown list and top zone
        mainData = visitorsZoneParser(
            selectedZonesList,
            '',
            // (daterange.key === 'customTime' ? 'customTimeShow' : daterange.key),
            _.cloneDeep([...compareData]),
            BGS, format, daterangesLabels, hourLabel, dataFieldName, dailyData
        )

        detailData = visitorsZoneParser(
            selectedZonesList,
            '',
            // (daterange.key === 'customTime' ? 'customTimeShow' : daterange.key),
            _.cloneDeep([...detailCompareData]),
            BGS, format, daterangesLabels, hourLabel, dataFieldName
        )
    }
    const data = showDetailCompareData ? detailData : mainData;
    // console.log('!!!data: ', data)


    // console.log('PROPS GraphWrapper: ', props)
    // console.log('STATE GraphWrapper title, dataFieldName, dailyData, showDetailCompareData: ', title, dataFieldName, dailyData, showDetailCompareData)
    return (
        <Col md={4} className="graphWrapper">
            <div className="title">{title}</div>
            <div className='bar-wrapper' /*ref={wrapper}*/ style={{height: `200px`, backgroundColor:"#F7F7F7"}} >
                {/* <FullScreenToggle element={wrapper.current}/> */}
                <Graph
                    data={data}
                    height={210}
                    options={options}
                    // fullScreenMode
                    onGraphClick={handleGraphClick}
                    showDetailVisitorsData={showDetailCompareData}
                    setVisibilityVisitorsDetailData={setShowDetailCompareData}
                />
            </div>

            <Spinner
                show={showSpinner}
                stopSpinner={() => setShowSpinner(false)}
                language={language.data}
            />
        </Col>
    )
}

const mapStateToProps = ({ dashboardData, zoneData, language }) => {
    return {
        compareData: zoneData.compareData,
        chosenMall: dashboardData.chosenMall,
        // detailCompareData: zoneData.detailCompareData,
        selectedComparisonDates: zoneData.selectedComparisonDates,
        zones: dashboardData.zones,
        // showDetailCompareData: zoneData.showDetailCompareData,
        language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setShowDetailCompareData: state => dispatch(setShowDetailCompareData(state)),
        // getZoneDetailCompareData: state => dispatch(getZoneDetailCompareData(state)),
    }
};

const GraphWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(GraphWrapper);

export default GraphWrapperContainer
