import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ProductMatchTabComponent from './ProductMatchTabComponent';

import { addZoneRelations, editZoneRelations, complexRequest } from "../../actions/apiRequests";
import { setEditMode, setVisibilityAddForm, chooseZone, setRemovedZone } from "../../actions/filters";


const mapStateToProps = ({ zoneData, adminData, language }) => {
    return {
        chosenZone: adminData.chosenZone,
        departments: zoneData.b2sDepartments,
        subdepartments: zoneData.b2sSubdepartments,
        deepdepartments: zoneData.b2sDeepdepartments,
        zoneRelations: zoneData.b2sZoneRelations,
        language: language.data,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addZoneRelations: (data, callback)=> dispatch(addZoneRelations(data, callback)),
        editZoneRelations: (id , data, callback)=> dispatch(editZoneRelations(id , data, callback)),
        // chooseZone: (data)=> dispatch(chooseZone(data)),
        // deleteZones: (zonesList)=> dispatch(deleteZones(zonesList)),
        // setForm: (form, editMode)=> {
        //     dispatch(setVisibilityAddForm(form));
        //     dispatch(setEditMode(editMode));
        // },
        // setRemovedZone: (listId) => dispatch(setRemovedZone(listId)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    };
};
const ProductMatchTabContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ProductMatchTabComponent));

export default ProductMatchTabContainer;