import {connect} from 'react-redux'
import EventsComparisonComponent from '../components/EventsComparisonComponent'
import {switchEventsComparison} from '../actions/filters'

const mapStateToProps = ({dashboardData, filters}) => {
    return {
        eventsList: dashboardData.eventsList,
        editMode: filters.editMode,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchEventsComparison: (state) => dispatch(switchEventsComparison(state)),
    }
};

const EventsComparisonContainer = connect(mapStateToProps, mapDispatchToProps)(EventsComparisonComponent);

export default EventsComparisonContainer