import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, InputGroup, FormControl, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import _ from "lodash"

const DepartmentNode = (props) => {
	const { item: { id, name }, removeCallback } = props

	return(
		<div className="departmentNode">
			{name}
			<Button
				className="close_btn"
				onClick={removeCallback}
			/>
		</div>
	)
}

const ProductMatchTabComponent = (props) => {
	const { chosenZone, zoneRelations, departments, subdepartments, deepdepartments, language, handleChangeForm, addZoneRelations, editZoneRelations, complexRequest } = props
	const { genericLabels } = language;
	const {
		cancelTitle,
		saveTitle,
	} = genericLabels;

	const [activeDepartments, setActiveDepartments] = useState({department: null, subdepartment: null})
	const [departmentsList, setDepartmentsList] = useState([])
	const [selectedDepartments, setSelectedDepartments] = useState([])
	const [selectedSubdepartments, setSelectedSubdepartments] = useState([])
	const [selectedDeepdepartments, setSelectedDeepdepartments] = useState([])

	useEffect(() => {
		setSelectedDepartments(zoneRelations.departments || [])
		setSelectedSubdepartments(zoneRelations.subdepartments || [])
		setSelectedDeepdepartments(zoneRelations.deepdepartments || [])
	}, [zoneRelations]);

	useEffect(() => {
		if (!_.isEqual(departments, departmentsList)) {
			setDepartmentsList(departments)
		}
	}, [departments]);

	const onSearch = (e) => {
		const value = e.target.value.toLowerCase();
		const filter = departments.filter(item => {console.log('item: ', item);return(
			item.name && item.name.toLowerCase().includes(value)			
		)})

		setDepartmentsList(filter)
	}

	const changeListSelectedDepartments = (type, item) => {
		let selectedList = []
		let setSelectedList = null
		switch (type) {
			case "department":{
				selectedList = selectedDepartments
				setSelectedList = setSelectedDepartments
				break
			}
			case "subdepartment": {
				selectedList = selectedSubdepartments
				setSelectedList = setSelectedSubdepartments
				break
			}
			case "deepdepartment": {
				selectedList = selectedDeepdepartments
				setSelectedList = setSelectedDeepdepartments
				break
			}
			default:
				break
		}

		const selectedDepartmentsIds = selectedList ? selectedList.map(({id}) => id) : []
		const isItemSelected = selectedDepartmentsIds.includes(item.id)

		let newItems = []
		if (isItemSelected) {
			newItems = selectedList.filter(({id}) => id !== item.id)
		} else {
			newItems = [...selectedList, item]
		}
		setSelectedList([...newItems])
	}
	
	const renderAvailableDepartments = () => {
		const selectedDepartmentsIds = selectedDepartments ? selectedDepartments.map(({id}) => id) : []

		return departmentsList.map((item) => {
			const isItemSelected = selectedDepartmentsIds.includes(item.id)
			return (
				<div
					key={item.id}
					className={`departmentItem ${activeDepartments.department && activeDepartments.department.id === item.id && 'active'} ${isItemSelected && 'selected'}`}
					onClick={() => setActiveDepartments({ department: item, subdepartment: null})}
				>
					{item.name}
					<div className="iconWrapper">
						<span className="separator" />
						<div
							className="icon"
							onClick={() => changeListSelectedDepartments('department', item)}
						>
							<i className={`fa ${isItemSelected ? 'fa-check-circle': 'fa-plus'}`} />
						</div>
					</div>
				</div>
			)
		})
	}

	const renderAvailableSubdepartments = () => {
		if (activeDepartments.department) {
			// we should not display subdepartments selected in other zones
			const selectedSubdepartmentsIds = selectedSubdepartments ? selectedSubdepartments.map(({id}) => id) : []
			const selectedStoredSubdepartmentsIds = zoneRelations.subdepartments ? zoneRelations.subdepartments.map(({id}) => id) : []

			return subdepartments
				.filter(({ parent_id, id, is_selected }) => parent_id === activeDepartments.department.id && (is_selected !== true || selectedStoredSubdepartmentsIds.includes(id)))
				.map((item) => {
					const isItemSelected = selectedSubdepartmentsIds.includes(item.id)

					return (
						<div
							key={item.id}
							className={`departmentItem ${activeDepartments.subdepartment && activeDepartments.subdepartment.id === item.id && 'active'} ${isItemSelected && 'selected'}`}
							onClick={() => setActiveDepartments({...activeDepartments, subdepartment: item})}
						>
							{item.name}
							<div className="iconWrapper">
								<span className="separator" />
								<div
									className="icon"
									onClick={() => changeListSelectedDepartments('subdepartment', item)}
								>
									<i className={`fa ${isItemSelected ? 'fa-check-circle': 'fa-plus'}`} />
								</div>
							</div>
						</div>
					)
				})
		}
	}

	const renderAvailableDeepdepartments = () => {
		if (activeDepartments.subdepartment) {
			// we should not display deepdepartments selected in other zones
			const selectedDeepdepartmentsIds = selectedDeepdepartments ? selectedDeepdepartments.map(({id}) => id) : []
			const selectedStoredDeepdepartmentsIds = zoneRelations.deepdepartments ? zoneRelations.deepdepartments.map(({id}) => id) : []

			return deepdepartments
				.filter(({ parent_id, id, is_selected }) => parent_id === activeDepartments.subdepartment.id && (is_selected !== true || selectedStoredDeepdepartmentsIds.includes(id)))
				.map((item) => {
					const isItemSelected = selectedDeepdepartmentsIds.includes(item.id)

					return (
						<div
							key={item.id}
							className={`departmentItem deepDepartmentItem ${isItemSelected && 'selected'}`}
						>
							{item.name}
							<div className="iconWrapper">
								<span className="separator" />
								<div
									className="icon"
									onClick={() => changeListSelectedDepartments('deepdepartment', item)}
								>
									<i className={`fa ${isItemSelected ? 'fa-check-circle': 'fa-plus'}`} />
								</div>
							</div>
						</div>
					)
				})
		}
	}

	const handleSaveClick = () => {
		console.log('handleSaveClick zoneRelations: ', zoneRelations)

		const params = {
			zone_id: chosenZone.id,
			department_id: selectedDepartments.length ? selectedDepartments.map(({id}) => id).join(',') : null,
			subdepartment_id: selectedSubdepartments.length ? selectedSubdepartments.map(({id}) => id).join(',') : null,
			deepdepartment_id: selectedDeepdepartments.length ? selectedDeepdepartments.map(({id}) => id).join(',') : null,
		}
		console.log('handleSaveClick params: ', params)
		if (_.isEmpty(zoneRelations)) {
			addZoneRelations(
				params,
				() => {
					complexRequest([
							{ name: "departments", method: "get", type: `byte2story/departments` },
							{ name: "subdepartments", method: "get", type: `byte2story/subdepartments` },
							{ name: "deepdepartments", method: "get", type: `byte2story/deepdepartments` },
						],
						"GET_ZONES"
					)
				}
			)
		} else {
			editZoneRelations(
				params.zone_id,
				params,
				() => {
					complexRequest([
							{ name: "departments", method: "get", type: `byte2story/departments` },
							{ name: "subdepartments", method: "get", type: `byte2story/subdepartments` },
							{ name: "deepdepartments", method: "get", type: `byte2story/deepdepartments` },
						],
						"GET_ZONES"
					)
				}
			)
		}
	}

	// console.log('PROPS ProductMatchTabComponent: ', props)
	// console.log('STATE ProductMatchTabComponent: ', activeDepartments, selectedDepartments, selectedSubdepartments, selectedDeepdepartments)
	return (
		<div className="byte2storyRelations">
			<div className="bodyWrapper">
				<InputGroup style={{ marginBottom: '20px' }}>
					<InputGroup.Prepend>
						<Button className="search-btn">
							<i className="fa fa-search search-icon"/>
						</Button>
					</InputGroup.Prepend>
					<FormControl placeholder="Search Department Name" className="search-input" onChange={onSearch}/>
				</InputGroup>

				<Row>
					<Col md={4}>
						<label className="form-label">
							Departments
						</label>
					</Col>
					<Col md={4}>
						<label className="form-label">
							Sub Departments
						</label>
					</Col>
					<Col md={4}>
						<label className="form-label">
							Deep Departments
						</label>
					</Col>
				</Row>

				<Row style={{ height: '100%', overflow: 'auto', marginBottom: '20px' }}>
					<Col md={4} style={{ height: '100%', overflow: 'auto' }}>
						<div className="departmentsList">
							{ renderAvailableDepartments() }
						</div>
					</Col>
					<Col md={4} style={{ height: '100%', overflow: 'auto' }}>
						<div className="departmentsList">
							{ renderAvailableSubdepartments() }
						</div>
					</Col>
					<Col md={4} style={{ height: '100%', overflow: 'auto' }}>
						<div className="departmentsList">
							{ renderAvailableDeepdepartments() }
						</div>
					</Col>
				</Row>

				{(selectedDepartments.length > 0 || selectedSubdepartments.length > 0 || selectedDeepdepartments.length > 0)
				&& (
					<div className="selectedDepartments">
						{selectedDepartments.length > 0 &&
							<div className="rowDepartments">
								<span className="title">Departments</span>
								{
									selectedDepartments.map((item) => {
										return (
											<DepartmentNode
												key={`dep${item.id}`}
												item={item}
												removeCallback={() => changeListSelectedDepartments('department', item)}
											/>
										)
									})
								}
							</div>
						}
						{selectedSubdepartments.length > 0 &&
							<div className="rowDepartments">
								<span className="title">Sub Departments</span>
								{
									selectedSubdepartments.map((item) => {
										return (
											<DepartmentNode
												key={`subdep${item.id}`}
												item={item}
												removeCallback={() => changeListSelectedDepartments('subdepartment', item)}
											/>
										)
									})
								}
							</div>
						}
						{selectedDeepdepartments.length > 0 &&
							<div className="rowDepartments">
								<span className="title">Deep Departments</span>
								{
									selectedDeepdepartments.map((item) => {
										return (
											<DepartmentNode
												key={`deepdep${item.id}`}
												item={item}
												removeCallback={() => changeListSelectedDepartments('deepdepartment', item)}
											/>
										)
									})
								}
							</div>
						}
					</div>
				)}
			</div>

			<Row
				className="footer"
				style={{ marginTop: "20px" }}
			>
				<Col className="left">
					<Button
						variant="outline-primary"
						className="button"
						onClick={handleChangeForm}
					>
						{cancelTitle}
					</Button>
				</Col>
				<Col className="right text-right">
					<Button
						variant="outline-success"
						className="button"
						// className={`button ${!polygons.length && 'btn-disabled'}`}
						// onClick={(e) => editMode ? this.editData(e) : this.addData(e)}
						onClick={handleSaveClick}
						// disabled={!polygons.length}
					>
						{saveTitle}
					</Button>
				</Col>
			</Row>
		</div>
	)
}

ProductMatchTabComponent.propTypes = {
	zoneRelations: PropTypes.objectOf(PropTypes.any).isRequired,
	departments: PropTypes.arrayOf(PropTypes.any).isRequired,
	subdepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
	deepdepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
	language: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductMatchTabComponent
