import React, {useState, useEffect} from 'react';

const NotificationBell = ({notificationsLength}) => {
    const [unreadNotification, setUnread] = useState(0);
    const [readNotification, setRead] = useState(0);

    useEffect(() => {
        if(readNotification < notificationsLength){
            setUnread(notificationsLength - readNotification);
        }else if(readNotification > notificationsLength){
            setRead(notificationsLength);
        }
    }, [notificationsLength, readNotification]);

    const handleBell = () =>{
        setUnread(0);
        setRead(notificationsLength);
    };

    return <div onClick={handleBell}>
        <img src="/images/header_bell.png" alt="User Notifications"/>
        {unreadNotification > 0 && <span className="bell_badge">{unreadNotification}</span>}
    </div>
};

export default NotificationBell;