import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import EventsComponent from '../components/EventsComponent';
import {
    switchEvent,
    addEventToList,
    deleteEventFromList,
    switchEventsSelectList,
    setVisibilityAddForm, setEditMode
} from '../actions/filters';
import {complexRequest, getEvents, chooseEvent} from "../actions/apiRequests";


const mapStateToProps = ({ filters, adminData, dashboardData, user, language }) => {
    return {
        showEvent: filters.showEvent,
        showEventsComparison: filters.showEventsComparison,
        chosenEvent: dashboardData.chosenEvent,
        showEventsSelectList: filters.showEventsSelectList,
        eventsList: dashboardData.eventsList,
        malls: adminData.malls,
        events: adminData.events,
        shops: adminData.shops,
        openAddForm: filters.openAddForm,
        user,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchEvent: (state) => dispatch(switchEvent(state)),
        switchEventsSelectList: (state) => dispatch(switchEventsSelectList(state)),
        chooseEvent: (event, eventOptions, toList = false) => dispatch(chooseEvent(event, eventOptions, toList)),
        addEventToList: (event) => dispatch(addEventToList(event)),
        deleteEventFromList: (id) => dispatch(deleteEventFromList(id)),
        getEvents: (options="") => dispatch(getEvents(options)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsComponent));
