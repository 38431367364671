import React, {Component} from 'react'
import EventContainer from '../containers/EventContainer'
import KeyboardEventHandler from "react-keyboard-event-handler"


import "../styles/EventsComparison.sass"


class EventsComparisonComponent extends Component{
    constructor(props) {
        super(props);
        this.modal = React.createRef()
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        if (this.modal.current && ! this.props.editMode && (this.modal.current === event.target || !this.modal.current.contains(event.target))) {
            this.props.switchEventsComparison(false)
        }
    };


    render(){
        const {switchEventsComparison, eventsList, editMode} = this.props;

        return (
            <div className={`comparison events-comparison mx-4 ${editMode ? "editing" :""}`} id="style-4" ref={this.modal}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => switchEventsComparison(false)}/>

                <div className="events_column">
                    {eventsList
                        .filter((item, index) => index % 2 === 0)
                        .map((item) => <EventContainer key={item.id} chosenEvent={item}/>)}
                </div>
                <div className="events_column">
                    {eventsList
                        .filter((item, index) => index % 2 === 1)
                        .map((item) => <EventContainer key={item.id} chosenEvent={item}/>)}
                </div>
            </div>
        )
    }
}

export default EventsComparisonComponent