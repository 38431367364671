import React from 'react'
import {Navbar, NavItem, NavDropdown, InputGroup, FormControl, Button, Dropdown} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'

import UserDropdownTitle from "./UserDropdownTitle"
import Notifications from "./Notifications"
import NotificationBell from "./NotificationBell"
import { languagesList } from "../lang/index"

import '../styles/Header.sass'

const Header = props => {
    const {
        logout,
        user,
        role,
        history,
        location,
        notifications,
        deleteNotification,
        isOpenSidebarMenu,
        setLanguage,
        language,
    } = props;

    const {
        title: languageTitle,
        code: languageCode,
        data: { header, routes, genericLabels },
        direction,
    } = language;

    const {
        profileDropdownTitle,
        logoutDropdownTitle,
    } = header;

    const { searchFormPlaceholder } = genericLabels;

    return (
        <header className={!isOpenSidebarMenu ? "hide" : ""}>
            <div className="row">
                <div className="col-md-6">
                    <div className="header-left">
                        <Navbar className="px-0">
                            <Navbar.Brand>
                                <div className="logo" onClick={() => history.push(role === 'debugger' ? "/admin/debugtool" : "/dashboard")}>
                                    <img src={process.env.REACT_APP_HEADER_LOGO_URL} alt="logo"/>
                                </div>
                            </Navbar.Brand>

                            <NavItem>
                                <div className="bread_crumb">{routes[location.pathname.split("/")[1]]}</div>
                            </NavItem>
                        </Navbar>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="header-right float-right">
                        <Navbar expand="sm" className="px-0">
                            <NavItem>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <Button className="search-btn">
                                            <i className="fa fa-search search-icon"/>
                                        </Button>
                                    </InputGroup.Prepend>

                                    <FormControl placeholder={searchFormPlaceholder} className="search-input"/>
                                </InputGroup>
                            </NavItem>

                            <NavItem>
                                <NavDropdown title={<NotificationBell notificationsLength={notifications.length}/>}
                                             className={`notif_wrap ${notifications.length > 5 ? "scroll" : ""}`}
                                             id="user_notifications">
                                    <Notifications notifications={notifications}
                                                   deleteNotification={deleteNotification}/>
                                </NavDropdown>
                            </NavItem>

                            <NavItem>
                                <Dropdown className="setting-dropdown">
                                    <Dropdown.Toggle>
                                        <UserDropdownTitle name={user.first_name} main_image={user.main_image}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <NavLink to="/profile" className="dropdown-nav_item">{profileDropdownTitle}</NavLink>

                                        <Dropdown
                                            className="dropdown-nav_item language-dropdown"
                                            drop={direction === 'rtl' ? 'right' : 'left'}
                                        >
                                            <Dropdown.Toggle>{languageTitle}</Dropdown.Toggle>

                                            <Dropdown.Menu className="language-dropdown_menu">
                                                {
                                                    languagesList.map(({ code, title }) => {
                                                        return <Dropdown.Item
                                                            key={code}
                                                            onClick={() => setLanguage(code)}
                                                            className={classNames({
                                                                'selected': code === languageCode
                                                            })}
                                                        >
                                                            {title}
                                                        </Dropdown.Item>
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown.Item
                                            className="dropdown-nav_item"
                                            onClick={() => logout()}
                                        >
                                            {logoutDropdownTitle}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </NavItem>
                        </Navbar>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
