import {connect} from 'react-redux';
import FilterTooltip from './FilterTooltip';
import {
    setFilterTooltips,
} from '../../actions/filters';

const mapStateToProps = ({ dashboardData }) => {
    return {
        filterTooltips: dashboardData.filterTooltips,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterTooltips:(state) => dispatch(setFilterTooltips(state)),
    }
}

const FilterTooltipContainer = connect(mapStateToProps, mapDispatchToProps)(FilterTooltip);

export default FilterTooltipContainer
