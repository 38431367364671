import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import Cookies from "js-cookie";
import catchParser from "../helpers/catchParser"
import queryOptionsParser from "../helpers/queryOptionsParser";

console.log('process.env: ', process.env)

let DEFAULT_MALL = process.env.REACT_APP_DEFAULT_MALL || 1;
let API_URL = process.env.REACT_APP_API_URL || "http://192.168.2.85:5040/api/v1/";

// if (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "staging") { // <-- Development in a production environment, it can be commented
//     DEFAULT_MALL = "1";
//     // API_URL = "http://77.124.70.73:5080/api/v1/";
//     // API_URL = "http://vivat.sprava.net:3107/api/v1/";
//     // API_URL = "https://maxstock.arigo-ai.com/api/v1/";
//     // API_URL = "https://analytics.arigo-ai.com/api/v1/";
//     // DEFAULT_MALL = "47";
//     // API_URL = "https://mollengo.transfotech.com/api/v1/";
// }
console.log('API_URL: ', API_URL)

export const apiCall = (method, type, data = null) => {
    // console.log('apiCall: ', type)
    const url = `${API_URL + type}`;

    const CancelToken = axios.CancelToken.source();

    const form = new FormData();

    for (let key in data) {
        form.append(key, data[key])
    }

    const settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": method,
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form,
        "withCredentials": true,
        // "withCredentials": type === 'login' ? false : true,
        cancelToken: CancelToken.token
    };

    setTimeout(() => {  //Canceling request if it too long
        CancelToken.cancel('Too long waiting');
        return []
    }, 300000); //Waiting delay

    return axios(settings)
        .then(response => response.data.response)
};

export const complexRequest = (reqArr, dispatchType, callback = null) => {
    const payload = state => {
        const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;

        return Promise.all(
            reqArr.map(({ method, type, data, name, catchType }) => {
                return apiCall(method, type, data)
                    .catch(e => {
                        console.log('complexRequest catch: ', e)
                        const {
                            result,
                            description,
                        } = catchParser(e, name, { somethingBadHappenWithMessage, errorTitle });
                        toastr.info(result, description);

                        return {
                            content: catchType ? catchType : []
                        }
                    })
            })
        ).then(response => {
            return  reqArr.map(({name}, index) => { //convert array into object with your keys(name)
                return {
                    [name]: response[index].content
                }
            }).reduce((a, b) => Object.assign(a, b), {})
        }).then(res =>{
            if (callback && typeof callback === 'function') {
                callback();
            }
            return res
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: dispatchType,
            payload: payload(getState()),
        })
    }
};

export const getMalls = (options = "") => {
    const payload = apiCall("get", "malls" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_MALLS',
            payload
        });
    };
};

export const getMall_by_allMalls = (params) => {
    console.log('getMall_by_allMalls: ', params)
    const payload = apiCall("get", "malls")
        .then((response) => {
            const mall_id = DEFAULT_MALL
            // const mall_id = response.content[0].id; //for usual prod environment, where will choose first response mall

            return apiCall("get", `mall/${mall_id}${queryOptionsParser(params)}`)
                .then((res) => {
                    return {
                        malls: response.content,
                        mall: res.content
                    }
                })
                .catch((e) => {
                    toastr.info(e.response.data.result, e.response.data.response.errors);
                    return {malls: response.content, mall: {}}
                });
        })
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return {malls: [], mall: {}}
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_MALL_BY_ALL_MALLS',
            payload: payload
        })
    };
};

export const getMall = (id, options = null, admin = false) => {
    const payload = apiCall("get", `mall/${id}${queryOptionsParser(options)}`)
        .then((response) => {
            return response.content
        })
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return {}
        });

    return (dispatch) => {
        dispatch({
            type: admin ? "GET_ADMIN_MALL" : "GET_MALL",
            payload
        })
    }
};

export const getUsers = (options = "") => {
    const payload = apiCall("get", "users" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_USERS',
            payload
        });
    };
};

export const getShops = (options = "") => {
    const payload = apiCall("get", "shops" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_SHOPS',
            payload
        });
    };
};

export const getShopsOwners = (options = "") => {
    const payload = apiCall("get", "shops_owners" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_SHOPS_OWNERS',
            payload
        });
    };
};

export const getEvents = (options = "") => {
    const payload = apiCall("get", "events" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_EVENTS',
            payload
        });
    };
};

export const getCameras = (options = "") => { //mall_id=11
    const payload = apiCall("get", "cameras" + options)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_CAMERAS',
            payload
        });
    };
};

export const getTopFloorShops = (options = "") => {

    // apiCall("get", `mall/${mall_id}${queryOptionsParser(params)}`)

    const payload = apiCall("get", `topshops${queryOptionsParser(options)}`)
        .then((response) => response.content)
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_TOPFLOOR_SHOPS',
            payload
        });
    };
};

export const addUser = data => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "user", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.first_name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_USER',
            payload: payload(getState()),
        });
    };
};

export const addMall = (data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "mall", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenAddedMessage}`);
                if (callback && typeof callback === 'function'){
                    callback(response.content);
                }
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return {}
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_MALL',
            payload: payload(getState()),
        });
    };
};

export const addShop = data => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "shop", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_SHOP',
            payload: payload(getState()),
        });
    };
};

export const addShopOwner = data => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "shop_owner", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.first_name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_SHOP_OWNER',
            payload: payload(getState()),
        });
    };
};

export const addEvent = data => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "event", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_EVENT',
            payload: payload(getState()),
        });
    };
};

export const addCameraMarkers = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            cameraMappingHasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "camera/mapping", data)
            .then(response => {
                toastr.success(successTitle, cameraMappingHasBeenAddedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_CAMERA_MARKERS',
            payload: payload(getState()),
        });
    };
};

export const addCameraPolygons = (id, data) => {
    const payload = apiCall("post", `camera/${id}/polygons`, data)
        .then((response) => {
            // toastr.success('Success', `Camera mapping markers has been added`);
            return response.content
        })
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return null
        });

    return (dispatch) => {
        dispatch({
            type: 'ADD_CAMERA_POLYGONS',
            payload
        });
    };
};

export const addZone = (data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "zone", data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .then(res =>{
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
                return res
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_ZONE',
            payload: payload(getState()),
        });
    };
};

export const addZoneRelations = (data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "byte2story/zones-relations", data)
            .then(response => {
                console.log('addZoneRelations response: ', response)
                toastr.success(successTitle, `${response.content.name} ${hasBeenAddedMessage}`);
                return response.content
            })
            .then(res =>{
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
                return res
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_ZONE_RELATIONS',
            payload: payload(getState()),
        });
    };
};

export const editCameraPolygons = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            cameraMappingHasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `camera/${id}/polygons`, data)
            .then(response => {
                toastr.success(successTitle, cameraMappingHasBeenEditedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_CAMERA_POLYGONS',
            payload: payload(getState()),
        });
    };
};

export const editUser = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `user/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.first_name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_USER',
            payload: payload(getState()),
        });
    };
};

export const editMall = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `mall/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_MALL',
            payload: payload(getState()),
        });
    };
};

export const editShop = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `shop/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_SHOP',
            payload: payload(getState()),
        });
    };
};

export const editShopOwner = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `shop_owner/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.first_name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_SHOP_OWNER',
            payload: payload(getState()),
        });
    };
};

export const editEvent = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `event/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_EVENT',
            payload: payload(getState()),
        });
    };
};

export const editCamera = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `camera/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_CAMERA',
            payload: payload(getState()),
        });
    };
};

export const editCameraMarkers = (id, data) => {
    const payload = state => {
        const {
            successTitle,
            cameraMappingHasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `camera/${id}/mapping`, data)
            .then(response => {
                toastr.success(successTitle, cameraMappingHasBeenEditedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_CAMERA_MARKERS',
            payload: payload(getState()),
        });
    };
};

export const editZone = (id, data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `zone/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                if (callback && typeof callback === 'function') {
                    callback(response.content);
                }
                return response.content
            })
            .then(res =>{
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
                return res
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_ZONE',
            payload: payload(getState()),
        });
    };
};

export const editZoneRelations = (id, data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenEditedMessage,
        } = state.language.data.notifications;

        return apiCall("put", `byte2story/zones-relations/${id}`, data)
            .then(response => {
                toastr.success(successTitle, `${response.content.name} ${hasBeenEditedMessage}`);
                if (callback && typeof callback === 'function') {
                    callback(response.content);
                }
                return response.content
            })
            .then(res =>{
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
                return res
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'EDIT_ZONE_RELATIONS',
            payload: payload(getState()),
        });
    };
};

export const deleteUser = id => {
    const payload = state => {
        const {
            successTitle,
            userHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `user/${id}`)
            .then(response => {
                toastr.success(successTitle, userHasBeenDeletedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_USER',
            payload: payload(getState()),
        });
    };
};

export const deleteMall = id => {
    const payload = state => {
        const {
            successTitle,
            mallHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `mall/${id}`)
            .then(response => {
                toastr.success(successTitle, mallHasBeenDeletedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_MALL',
            payload: payload(getState()),
        });
    };
};

export const deleteShop = id => {
    const payload = state => {
        const {
            successTitle,
            shopHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `shop/${id}`)
            .then(response => {
                toastr.success(successTitle, shopHasBeenDeletedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_SHOP',
            payload: payload(getState()),
        });
    };
};

export const deleteShopOwner = id => {
    const payload = state => {
        const {
            successTitle,
            shopOwnerHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `shop_owner/${id}`)
            .then(response => {
                toastr.success(successTitle, shopOwnerHasBeenDeletedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_SHOP_OWNER',
            payload: payload(getState()),
        });
    };
};

export const deleteEvent = id => {
    const payload = state => {
        const {
            successTitle,
            eventHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `event/${id}`)
            .then(response => {
                toastr.success(successTitle, eventHasBeenDeletedMessage);
                return response.content
            })
            .catch((e) => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_EVENT',
            payload: payload(getState()),
        });
    };
};

export const deleteZones = zonesList => {
    const payload = state => {
        const {
            successTitle,
            zonesHasBeenDeletedMessage,
        } = state.language.data.notifications;

        return apiCall("delete", `zones?zonesId=${zonesList}`)
            .then(response => {
                toastr.success(successTitle, zonesHasBeenDeletedMessage);
                return response.content
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_ZONES',
            payload: payload(getState()),
        });
    };
};

export const logIn = (data, remember) => {
    const payload = state => {
        const {
            loginMessage,
            somethingBadHappenWithMessage,
            errorTitle,
        } = state.language.data.notifications;

        return apiCall("post", "login", data)
            .then(response => response.content)
            .then(response => {
                if (remember) {
                    Cookies.set('isLoggedIn', true, {expires: 365});
                    Cookies.set('role', response.roles, {expires: 365});
                }
                return response
            })
            .catch(e => {
                const { result, description} = catchParser(e, loginMessage, { somethingBadHappenWithMessage, errorTitle });
                toastr.info(result, description);
                return { rejected: true };
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'LOG_IN',
            payload: payload(getState()),
        });
    }
};

export const getCurrentSession = () => {
    const payload = state => {
        const {
            yourSessionMessage,
            somethingBadHappenWithMessage,
            errorTitle,
        } = state.language.data.notifications;

        return Promise.all([
            apiCall("get", "user/me"),
            apiCall("get", "shop-types"),
        ])
            .then(response => ({
                user: response[0].content,
                shopTypes: response[1].content,
            }))
            .catch(e => {
                const {
                    result,
                    description,
                } = catchParser(e, yourSessionMessage, { somethingBadHappenWithMessage, errorTitle });
                toastr.info(result, description);

                Cookies.remove('isLoggedIn');
                Cookies.remove('role');

                return { rejected: true };
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_CURRENT_SESSION',
            payload: payload(getState()),
        });
    }
};

export const logout = () => {
    const payload = state => {
        const {
            withLogoutMessage,
            somethingBadHappenWithMessage,
            errorTitle,
        } = state.language.data.notifications;

        return apiCall("get", "logout")
            .then(response => {
                Cookies.remove('isLoggedIn');
                Cookies.remove('role');
                return response.content
            })
            .catch(e => {
                const {
                    result,
                    description,
                } = catchParser(e, withLogoutMessage, {somethingBadHappenWithMessage, errorTitle});
                toastr.info(result, description);

                return false;
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'LOGGED_OUT',
            payload: payload(getState()),
        });
    }
};

export const forgotPassword = data => {
    const payload = state => {
        const {
            somethingBadHappenWithMessage,
            checkYourEmailMessage,
            errorTitle,
            successTitle,
            resetPasswordRequestMessage,
        } = state.language.data.notifications;

        return apiCall("post", "forgot_password", data)
            .then(response => {
                toastr.success(successTitle, checkYourEmailMessage);
                return response.content
            })
            .catch(e => {
                const {
                    result,
                    description,
                } = catchParser(e, resetPasswordRequestMessage, { somethingBadHappenWithMessage, errorTitle });

                toastr.info(result, description);
                return false;
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'FORGOT_PASSWORD',
            payload: payload(getState()),
        });
    }
};

export const resetPassword = data => {
    const payload = state => {
        const {
            somethingBadHappenWithMessage,
            errorTitle,
            successTitle,
            passwordChangedMessage,
            changePasswordRequestMessage,
        } = state.language.data.notifications;

        return apiCall("post", "reset_password", data)
            .then(response => {
                toastr.success(successTitle, passwordChangedMessage);
                return response.content
            })
            .catch(e => {
                const {
                    result,
                    description,
                } = catchParser(e, changePasswordRequestMessage, { somethingBadHappenWithMessage, errorTitle });

                toastr.info(result, description);
                return false;
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'RESET_PASSWORD',
            payload: payload(getState()),
        });
    }
};

export const getVisitorsAndTopshops = (visitorsOptions, topshopsOptions, topfloorshopsOptions, totalcountOptions, prevtotalcountOptions) => {
    let visitorsParams = '', topshopsParams = '', topfloorshopsParams = '', totalcountParams = '', prevtotalcountParams = ''

    for (let key in visitorsOptions) {
        visitorsParams += `${(!visitorsParams ? '?' : '&')}${key}=${visitorsOptions[key]}`
    }

    for (let key in topshopsOptions) {
        topshopsParams += `${(!topshopsParams ? '?' : '&')}${key}=${topshopsOptions[key]}`
    }

    for (let key in topfloorshopsOptions) {
        topfloorshopsParams += `${(!topfloorshopsParams ? '?' : '&')}${key}=${topfloorshopsOptions[key]}`
    }

    for (let key in totalcountOptions) {
        totalcountParams += `${(!totalcountParams ? '?' : '&')}${key}=${totalcountOptions[key]}`
    }

    for (let key in prevtotalcountOptions) {
        prevtotalcountParams += `${(!prevtotalcountParams ? '?' : '&')}${key}=${prevtotalcountOptions[key]}`
    }

    const payload = Promise.all([
        apiCall("get", "stats" + visitorsParams),
        apiCall("get", "topshops" + topshopsParams),
        apiCall("get", "topshops" + topfloorshopsParams),
        apiCall("get", "count/visitors" + totalcountParams),
        apiCall("get", "count/visitors" + prevtotalcountParams),
    ]).then((response) => {
        return {
            visitors: response[0].content,
            topshops: response[1].content,
            topfloorshops: response[2].content,
            totalcount: response[3].content,
            prev_totalcount: response[4].content,
        }
    }).catch((e) => {
        toastr.info(e.response.data.result, e.response.data.response.errors);
        return false
    });

    return (dispatch) => {
        dispatch({
            type: 'GET_VISITORS_AND_TOPSHOPS',
            payload: payload
        })
    };
};

export const getHeatmap = (options = null) => {
    let params = '';
    for (let key in options) {
        params += `${(!params ? '?' : '&')}${key}=${options[key]}`
    }

    const payload = apiCall("get", "stats" + params)
        .then((response) => response.content)
        .catch((e) => {
            toastr.error(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_HEATMAP',
            payload
        })
    }
};

export const getVisitors = (options = null) => {
    let params = '';
    for (let key in options) {
        params += `${(!params ? '?' : '&')}${key}=${options[key]}`
    }

    const payload = apiCall("get", "stats" + params)
        .then((response) => response.content)
        .catch((e) => {
            toastr.error(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_VISITORS',
            payload
        })
    }
};

export const getShopbars = (options = null) => {
    let params = '';
    for (let key in options) {
        params += `${(!params ? '?' : '&')}${key}=${options[key]}`
    }

    const payload = apiCall("get", "stats" + params)
        .then((response) => response.content)
        .catch((e) => {
            toastr.error(e.response.data.result, e.response.data.response.errors);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_SHOPBARS',
            payload
        })
    }
};

export const getShopsVisitorsData = shopsOptions => {
    const payload = state => {
        const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;

        return Promise.all(
            shopsOptions.map(({start_ts, end_ts, group_range, shop_id, name, ...item}) => {
                return Promise.all([
                    apiCall("get", `stats${queryOptionsParser({start_ts, end_ts, group_range, shop_id})}`)
                        .then(({ content }) => ({ stats: { ...content } }))
                        .catch(e => {
                            const {
                                result,
                                description,
                            } = catchParser(e, name, { somethingBadHappenWithMessage, errorTitle });

                            toastr.info(result, description);
                            return {content: []}
                        }),

                    apiCall("get", `count/visitors${queryOptionsParser({start_ts, end_ts, shop_id})}`)
                        .then((response) => response.content)
                        .catch(e => {
                            const {
                                result,
                                description,
                            } = catchParser(e, name, { somethingBadHappenWithMessage, errorTitle });

                            toastr.info(result, description);
                            return { content: [] }
                        })
                ]).then((response) => {
                    return {...response[0], ...response[1], shop_id, name, ...item}
                })
            })
        ).then(response => response);
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_SHOPS_VISITORS_DATA',
            payload: payload(getState()),
        })
    }
};

export const chooseEvent = (event, eventOptions, toList = false) => {
    const payload = state => {
        const {somethingBadHappenWithMessage, errorTitle} = state.language.data.notifications;

        if (event) {
            return apiCall("get", `count/visitors${queryOptionsParser(eventOptions)}`)
                .then((response) => {
                    return {
                        ...response.content,
                        ...event
                    };
                })
                .catch((e) => {
                    const {
                        result,
                        description,
                    } = catchParser(e, event.name, {somethingBadHappenWithMessage, errorTitle});
                    toastr.info(result, description);

                    return event;
                });
        } else {
            return {}
        }
    };


    return (dispatch, getState) => {
        dispatch({
            type: toList ? "ADD_EVENT_TO_LIST" : 'CHOOSE_EVENT',
            payload: payload(getState()),
        })
    }
};

export const chooseShop = (shop, shopOptions, toList = false) => {
    const payload = state => {
        const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;

        return Promise.all([
            apiCall("get", `count/visitors${queryOptionsParser(shopOptions)}`)
                .then(res => res.content)
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, shop.name, { somethingBadHappenWithMessage, errorTitle });
                    toastr.info(result, description);

                    return null;
                }),
            apiCall("get", `shop/${shop.id}`)
                .then(res => res.content)
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, shop.name, { somethingBadHappenWithMessage, errorTitle });
                    toastr.info(result, description);

                    return null;
                })
        ]).then(response => {
            return {
                ...response[0],
                ...response[1],
                ...shop
            };
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: toList ? "ADD_STORE_TO_LIST" : 'CHOOSE_SHOP',
            payload: payload(getState()),
        })
    }
};

export const getCameraSnapshot = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_CAMERA_SNAPSHOT',
            payload
        });
    };
};

export const getVisitorsComparisonData = optionsArr => {
    const payload = state => {
        const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;

        return Promise.all([
            Promise.all(
                optionsArr.map(({key, ...visitorsOptions}) => {
                    return apiCall("get", `stats${queryOptionsParser(visitorsOptions)}`)   //   <-- this requests array for getting visitors comparison data
                        .then(res => {
                            return {
                                key,
                                timestamp: (res.content && res.content.timestamp) ? res.content.timestamp : [],
                                visitors: (res.content && res.content.visitors) ? res.content.visitors : [],
                            }
                        })
                        .catch(e => {
                            const {
                                result,
                                description,
                            } = catchParser(e, key, {somethingBadHappenWithMessage, errorTitle});
                            toastr.info(result, description);
                            return {key, timestamp: [], visitors: [],}
                        })
                })
            ),
            Promise.all(
                optionsArr.map(({key, ...visitorsOptions}) => {
                    return apiCall("get", `stats/avgtime-ranges${queryOptionsParser(visitorsOptions)}`)   //   <-- this requests array for getting average time comparison data
                        .then(res => {
                            return {
                                key,
                                values: (res.content && res.content[0]) ? res.content[0] : []
                            }
                        })
                        .catch((e) => {
                            const {
                                result,
                                description,
                            } = catchParser(e, key, {somethingBadHappenWithMessage, errorTitle});
                            toastr.info(result, description);

                            return {key, values: []}
                        })
                })
            )
        ]).then((response) => {
            return {
                visitorsComparisonData: response[0],
                avgComparisonData: response[1],
            }
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_COMPARISON_DATA',
            payload: payload(getState()),
        })
    }
};

export const getFloorHeatmapData = (options = null) => {
    const payload = apiCall("get", `stats/heatmap${queryOptionsParser(options)}`)
        .then((response) => {
            return response.content
        })
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return {}
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_FLOOR_HEATMAP_DATA',
            payload
        })
    }
};

export const getFloorHeatmapDebugData = (options = null) => {
    const payload = apiCall("get", `stats/heatmap/debug${queryOptionsParser(options)}`)
        .then((response) => {
            return response.content
        })
        .catch((e) => {
            toastr.info(e.response.data.result, e.response.data.response.errors);
            return {}
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_FLOOR_HEATMAP_DATA',
            payload
        })
    }
};

export const getAverageVisitorsTime = (averageVisitorsTimeOptions) => {
    // const params ={
    //     start_ts:"20191229220000",
    //     end_ts:"20191230215958",
    //     mall_id:47,
    // }

    const payload = apiCall("get", "stats/avgtime-ranges" + queryOptionsParser(averageVisitorsTimeOptions))
        .then((response) => {
            return {
                title: "Yesterday",
                values: response.content[0]
            }
        })
        .catch((e) => {
            const {result, description} = catchParser(e, "Average visitors time");
            toastr.info(result, description);
            return []
        });

    return (dispatch) => {
        dispatch({
            type: 'GET_AVERAGE_VISITORS_TIME',
            payload
        })
    }
};

export const getDetailVisitorsData = ({start_ts, end_ts, group_range, mall_id}) => {
    const payload = state => {
        console.log('getDetailVisitorsData state: ', state)
        const {
            somethingBadHappenWithMessage,
            errorTitle,
            detailVisitorsDataMessage,
        } = state.language.data.notifications;

        return Promise.all([
            apiCall("get", "stats" + queryOptionsParser({start_ts, end_ts, group_range, mall_id}))
                .then(response => response.content)
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, detailVisitorsDataMessage, {somethingBadHappenWithMessage, errorTitle});
                    toastr.info(result, description);

                    return {
                        timestamp:[],
                        visitors:[],
                    }
                }),
            apiCall("get", "stats/avgtime-ranges" + queryOptionsParser({start_ts, end_ts, mall_id}))
                .then(response => response.content)
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, detailVisitorsDataMessage, { somethingBadHappenWithMessage, errorTitle });
                    toastr.info(result, description);

                    return {}
                }),
        ]).then(response => {
            return {
                visitors: response[0],
                avgVisitorsTime: response[1][0], // it's an api, I am not guilty
            }
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_VISITORS_DETAIL_DATA',
            payload: payload(getState()),
        })
    }
};

export const getZoneComparisonWithData = optionsArr => {
    const payload = state => {
        const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;
        const records = []
        return Promise.all(
            optionsArr.map(({...visitorsOptions}) => {
                const {start_ts, end_ts} = visitorsOptions
                const key = {start_ts, end_ts}
                return apiCall("get", `stats/zone/comparison${queryOptionsParser(visitorsOptions)}`)   //   <-- this requests array for getting visitors comparison data
                    .then(res => {
                        res.content.forEach((item) => {
                            const {stats:{timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors}, zone_id} = item
                            records.push({zone_id, key, timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors})
                        })

                        return records
                    })
                    .catch(e => {
                        const {
                            result,
                            description,
                        } = catchParser(e, key, {somethingBadHappenWithMessage, errorTitle});
                        toastr.info(result, description);
                        return {
                            timestamp:[],
                            conversion_rate_by_day:[],
                            engagement:[],
                            percent_visited_zone:[],
                            revenue_per_visitor:[],
                            visitors:[],
                        }
                    })
            })
        ).then((response) => {
            console.log('getZoneComparisonWithData response: ', response)
            return {
                compareData: response[0],
            }
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_ZONE_COMPARISON_WITH_DATA',
            payload: payload(getState()),
        })
    }
};

export const getZoneDetailCompareData = (props) => {
    const { start_ts, end_ts, group_range, zone_id, key } = props
    const payload = state => {
        const {
            somethingBadHappenWithMessage,
            errorTitle,
            detailVisitorsDataMessage,
        } = state.language.data.notifications;
        const records = []
        return Promise.all([
            apiCall("get", `stats/zone/comparison${queryOptionsParser({start_ts, end_ts, group_range, zone_id})}`)
                // .then(response => {
                //     const {stats:{timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors}, zone_id} = response.content[0]
                //     return ({zone_id, key, timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors})
                // })
                .then(res => {
                    res.content.forEach((item) => {
                        const {stats:{timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors}, zone_id} = item
                        records.push({zone_id, key, timestamp, conversion_rate_by_day, engagement, percent_visited_zone, revenue_per_visitor, revenue_per_zone, visitors})
                    })

                    return records
                })
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, detailVisitorsDataMessage, {somethingBadHappenWithMessage, errorTitle});
                    toastr.info(result, description);

                    return {
                        timestamp:[],
                        conversion_rate_by_day:[],
                        engagement:[],
                        percent_visited_zone:[],
                        revenue_per_visitor:[],
                        visitors:[],
                    }
                }),
        ]).then(response => {
            return response[0]
        });
    }
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_ZONE_DETAIL_COMPARE_DATA',
            payload: payload(getState()),
        })
    }
};

// export const getZoneComparisonWithData = optionsArr => {
//     const payload = state => {
//         const { somethingBadHappenWithMessage, errorTitle } = state.language.data.notifications;
//         const records = []
//         // console.log('apiRequests getZoneComparisonWithData optionsArr: ', optionsArr)
//         return Promise.all(//[
//             // Promise.all(
//                 optionsArr.map(({key, ...visitorsOptions}) => {
//                     return apiCall("get", `stats/zone${queryOptionsParser(visitorsOptions)}`)   //   <-- this requests array for getting visitors comparison data
//                         .then(res => {
//                             // console.log('getZoneComparisonWithData res: ', res)
//                             res.content.forEach((item) => {
//                                 // console.log('item: ', item)
//                                 const {stats:{timestamp, visitors}, zone_id} = item
//                                 // records.push({...item, key})
//                                 records.push({zone_id, key, timestamp, visitors})
//                                 // return {zone_id, key, timestamp, visitors}
//                             })

//                             return records
//                             // return res.content.map((item) => {
//                             //     console.log('item: ', item)
//                             //     return ({...item, key})
//                             // })
//                             // return res.content.map(item => ({...item, key}))
//                             // return {
//                             //     key,
//                             //     // timestamp: (res.content && res.content.timestamp) ? res.content.timestamp : [],
//                             //     // visitors: (res.content && res.content.visitors) ? res.content.visitors : [],
//                             //     timestamp: (res.content && res.content.length) ? res.content[0].stats.timestamp : [],
//                             //     visitors: (res.content && res.content.length) ? res.content[0].stats.visitors : [],
//                             // }
//                         })
//                         .catch(e => {
//                             const {
//                                 result,
//                                 description,
//                             } = catchParser(e, key, {somethingBadHappenWithMessage, errorTitle});
//                             toastr.info(result, description);
//                             return {key, timestamp: [], visitors: [],}
//                         })
//                 })
//             // )]
//             // ),
//             // Promise.all(
//             //     optionsArr.map(({key, ...visitorsOptions}) => {
//             //         return apiCall("get", `stats/zone/avgtime-ranges${queryOptionsParser(visitorsOptions)}`)   //   <-- this requests array for getting average time comparison data
//             //             .then(res => {
//             //                 return {
//             //                     key,
//             //                     values: (res.content && res.content[0]) ? res.content[0] : []
//             //                 }
//             //             })
//             //             .catch((e) => {
//             //                 const {
//             //                     result,
//             //                     description,
//             //                 } = catchParser(e, key, {somethingBadHappenWithMessage, errorTitle});
//             //                 toastr.info(result, description);

//             //                 return {key, values: []}
//             //             })
//             //     })
//             // )
//         //]
//         ).then((response) => {
//             return {
//                 visitorsComparisonData: response[0],
//                 avgComparisonData: response[1],
//             }
//         });
//     };

//     return (dispatch, getState) => {
//         dispatch({
//             type: 'GET_ZONE_COMPARISON_WITH_DATA',
//             payload: payload(getState()),
//         })
//     }
// };

export const getZoneDetailVisitorsData = (props) => {
    console.log('apiRequests getZoneDetailVisitorsData props: ', props)
    const { start_ts, end_ts, group_range, zone_id, key } = props
    const payload = state => {
        const {
            somethingBadHappenWithMessage,
            errorTitle,
            detailVisitorsDataMessage,
        } = state.language.data.notifications;

        return Promise.all([
            apiCall("get", `stats/zone${queryOptionsParser({start_ts, end_ts, group_range, zone_id})}`)
                // .then(response => response.content)
                .then(response => {
                    const {stats:{timestamp, visitors}, zone_id} = response.content[0]
                    return {zone_id, key, timestamp, visitors}

                    // console.log('getZoneComparisonWithData res: ', res)
                    // res.content.forEach((item) => {
                    //     // console.log('item: ', item)
                    //     const {stats:{timestamp, visitors}, zone_id} = item
                    //     // records.push({...item, key})
                    //     records.push({zone_id, key, timestamp, visitors})
                    //     // return {zone_id, key, timestamp, visitors}
                    // })

                    // return records
                    // return res.content.map((item) => {
                    //     console.log('item: ', item)
                    //     return ({...item, key})
                    // })
                    // return res.content.map(item => ({...item, key}))
                    // return {
                    //     key,
                    //     // timestamp: (res.content && res.content.timestamp) ? res.content.timestamp : [],
                    //     // visitors: (res.content && res.content.visitors) ? res.content.visitors : [],
                    //     timestamp: (res.content && res.content.length) ? res.content[0].stats.timestamp : [],
                    //     visitors: (res.content && res.content.length) ? res.content[0].stats.visitors : [],
                    // }
                })
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, detailVisitorsDataMessage, {somethingBadHappenWithMessage, errorTitle});
                    toastr.info(result, description);

                    return {
                        timestamp:[],
                        visitors:[],
                    }
                }),
                // .catch(e => {
                //     console.log('apiRequests getZoneDetailVisitorsData error: ', e)
                //     const {
                //         result,
                //         description,
                //     } = catchParser(e, detailVisitorsDataMessage, {somethingBadHappenWithMessage, errorTitle});
                //     toastr.info(result, description);

                //     return {
                //         timestamp:[],
                //         visitors:[],
                //     }
                // })
        ]).then(response => {
            return response[0]
            // return {
            //     visitors: response[0],
            // }
        });
    }
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_ZONE_VISITORS_DETAIL_DATA',
            payload: payload(getState()),
        })
    }
};


export const getZoneDetailVisitorsData2 = ({start_ts, end_ts, group_range, zone_id}) => {
    const payload = state => {
        const {
            somethingBadHappenWithMessage,
            errorTitle,
            detailVisitorsDataMessage,
        } = state.language.data.notifications;

        return Promise.all([
            apiCall("get", "stats/zone" + queryOptionsParser({start_ts, end_ts, group_range, zone_id}))
                .then(response => response.content)
                .catch(e => {
                    const {
                        result,
                        description,
                    } = catchParser(e, detailVisitorsDataMessage, {somethingBadHappenWithMessage, errorTitle});
                    toastr.info(result, description);

                    return {
                        timestamp:[],
                        visitors:[],
                    }
                }),
            // apiCall("get", "stats/avgtime-ranges" + queryOptionsParser({start_ts, end_ts, mall_id}))
            //     .then(response => response.content)
            //     .catch(e => {
            //         const {
            //             result,
            //             description,
            //         } = catchParser(e, detailVisitorsDataMessage, { somethingBadHappenWithMessage, errorTitle });
            //         toastr.info(result, description);

            //         return {}
            //     }),
        ]).then(response => {
            return {
                visitors: response[0],
                // avgVisitorsTime: response[1][0], // it's an api, I am not guilty
            }
        });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'GET_ZONE_VISITORS_DETAIL_DATA',
            payload: payload(getState()),
        })
    }
}

export const sendDebugSchedule = (data, callback = null) => {
    const payload = state => {
        const {
            successTitle,
            hasBeenAddedMessage,
        } = state.language.data.notifications;

        return apiCall("post", "debug-tools/schedule", data)
            .then(response => {
                toastr.success(successTitle, `Debug schedule ${hasBeenAddedMessage}`);
                return response.content
            })
            .then(res =>{
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
                return res
            })
            .catch(e => {
                toastr.info(e.response.data.result, e.response.data.response.errors);
                return null
            });
    };

    return (dispatch, getState) => {
        dispatch({
            type: 'SEND_DEBUG_SCHEDULE',
            payload: payload(getState()),
        });
    };
};
