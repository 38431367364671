import { connect } from 'react-redux';
import PolygonTooltip from './PolygonTooltip';
import { setZonesCheckedList } from '../../actions/filters'

const mapStateToProps = ({ dashboardData }) => {
    return {
        filterTooltips: dashboardData.filterTooltips,
        zonesDailyStats: dashboardData.zonesDailyStats,
        zonesCheckedList: dashboardData.zonesCheckedList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setZonesCheckedList:(state) => dispatch(setZonesCheckedList(state)),
    }
};



const PolygonTooltipContainer = connect(mapStateToProps, mapDispatchToProps)(PolygonTooltip);

export default PolygonTooltipContainer
