import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ShopsOwnersAdminComponent from '../../components/admin/ShopsOwnersAdminComponent';
import {setEditMode, setVisibilityAddForm} from "../../actions/filters";
import {getShopsOwners, addShopOwner, editShopOwner, deleteShopOwner} from "../../actions/apiRequests";


const mapStateToProps = (state) => {
    const { adminData, filters, user, language } = state;
    return {
        shops: adminData.shops,
        malls: adminData.malls,
        shops_owners: adminData.shops_owners,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        role: filters.role,
        user: user,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getShopsOwners: (options="") => dispatch(getShopsOwners(options)),
        addShopOwner: (data)=> dispatch(addShopOwner(data)),
        editShopOwner: (id , data)=> dispatch(editShopOwner(id , data)),
        deleteShopOwner: (id)=> dispatch(deleteShopOwner(id)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
    };
};

const ShopsOwnersAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ShopsOwnersAdminComponent));


export default ShopsOwnersAdminContainer;