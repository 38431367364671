import {connect} from 'react-redux';
import MallMapComponent from '../components/MallMapComponent';
import {
    setSelectedComparisonDates,
    switchPopover,
    switchShop,
    switchTopShopPopover,
    setCoordsTopShopPopover,
    setShoppopover,
    addStoresToList,
    switchStoresSelectList,
    setVisibilityHeatmap,
    setVisibilityHeatmapClustered,
    switchZoneComparison,
    chooseTopZone,
    setMallImageDimensions,
} from '../actions/filters';
import { getShopbars, chooseShop, getFloorHeatmapData, getFloorHeatmapDebugData } from "../actions/apiRequests";


const mapStateToProps = ({ filters, dashboardData, language }) => {
    return {
        chosenStatsBlock: dashboardData.chosenStatsBlock,
        zonesDailyStats: dashboardData.zonesDailyStats,
        zonesCheckedList: dashboardData.zonesCheckedList,

        showShop: filters.showShop,
        showPopover: filters.showPopover,
        mallshops: dashboardData.mallshops,
        zones: dashboardData.zones,
        floorHeatmap: dashboardData.floorHeatmap,
        storesList: dashboardData.storesList,
        daterange: dashboardData.daterange,
        chosenMall: dashboardData.chosenMall,
        showHeatmap: filters.showHeatmap,
        showHeatmapClustered: filters.showHeatmapClustered,
        mallImageDimensions: filters.mallImageDimensions,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedComparisonDates: (state) => dispatch(setSelectedComparisonDates(state)),

        switchShop: (state) => dispatch(switchShop(state)),
        getShopbars:(options=null) => dispatch(getShopbars(options)),
        switchPopover:(state) => dispatch(switchPopover(state)),
        switchTopShopPopover:(state) => dispatch(switchTopShopPopover(state)),
        setCoordsTopShopPopover:(state) => dispatch(setCoordsTopShopPopover(state)),
        setShoppopover:(state) => dispatch(setShoppopover(state)),
        addStoresToList:(store) => dispatch(addStoresToList(store)),
        switchStoresSelectList:(state) => dispatch(switchStoresSelectList(state)),
        chooseShop:(shop, shopOptions, toList) => dispatch(chooseShop(shop, shopOptions, toList)),
        setVisibilityHeatmap: state => dispatch(setVisibilityHeatmap(state)),
        setVisibilityHeatmapClustered: state => dispatch(setVisibilityHeatmapClustered(state)),
        getFloorHeatmapData: (options=null) => dispatch(getFloorHeatmapData(options)),
        getFloorHeatmapDebugData: (options=null) => dispatch(getFloorHeatmapDebugData(options)),
        switchZoneComparison:(state) => dispatch(switchZoneComparison(state)),
        chooseTopZone:(state) => dispatch(chooseTopZone(state)),
        setMallImageDimensions:(state) => dispatch(setMallImageDimensions(state)),        
    };
};
const MallMapContainer = connect(mapStateToProps,mapDispatchToProps)(MallMapComponent);


export default MallMapContainer;
