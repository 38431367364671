import {toastr} from "react-redux-toastr";

function validate (object, { warningTitle, filledInMessage}){
    let validate = true;

    for (let key in object){
        if (object[key].toString().trim() === ""){
            validate = false;
            toastr.info(warningTitle, `${key.charAt(0).toUpperCase() + key.slice(1)} ${filledInMessage}`);
            break;
        }
    }
    return validate
}

export default validate;