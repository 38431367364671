export const setVisibilityAddForm = (state) =>{
    setTimeout(() => document.querySelectorAll(".sorting_desc").forEach(item => item.classList.remove("sorting_desc")), 100); //fix for lib sorting bug
    return {
        type: 'SET_VISIBILITY_ADD_FORM',
        payload: state
    };
};

export const setEditMode = (state) =>{
    return {
        type: 'SET_EDIT_MODE',
        payload: state
    };
};

export const switchEvent = (state) =>{
    return {
        type: 'SHOW_EVENT',
        payload: state
    };
};

export const switchEventsSelectList = (state) =>{
    return {
        type: 'SHOW_EVENTS_SELECT_LIST',
        payload: state
    };
};

export const switchStoresSelectList = (state) =>{
    return {
        type: 'SHOW_STORES_SELECT_LIST',
        payload: state
    };
};

export const chooseEvent = (state) =>{
    return {
        type: 'CHOOSE_EVENT',
        payload: state
    };
};

export const chooseMall = (state) =>{
    return {
        type: 'CHOOSE_MALL',
        payload: state
    };
};

export const switchShop = (state) =>{
    return {
        type: 'SHOW_SHOP',
        payload: state
    };
};

export const switchPopover = (state) =>{
    return {
        type: 'SHOW_POPOVER',
        payload: state
    };
};

export const addEventToList = (event) =>{
    return {
        type: 'ADD_EVENT_TO_LIST',
        payload: event
    };
};

export const deleteEventFromList = (id) =>{
    return {
        type: 'REMOVE_EVENT_FROM_LIST',
        payload: id
    };
};


export const addStoresToList = (store) =>{
    return {
        type: 'ADD_STORE_TO_LIST',
        payload: store
    };
};

export const deleteStoresFromList = (id) =>{
    return {
        type: 'REMOVE_STORE_FROM_LIST',
        payload: id
    };
};

export const newUserInvite = (state) =>{
    return {
        type: 'NEW_USER',
        payload: state
    };
};

export const setTimerange = (state) =>{
    return {
        type: 'SET_TIMERANGE',
        payload: state
    }
};

export const setDaterange = (state) =>{
    return {
        type: 'SET_DATERANGE',
        payload: state
    }
};

export const switchTopShopPopover = (state) =>{
    return {
        type: 'SHOW_TOP_SHOP_POPOVER',
        payload: state
    }
};

export const switchShopComparison = (state) =>{
    return {
        type: 'SHOW_SHOP_COMPARISON',
        payload: state
    }
};

export const switchZoneComparison = (state) =>{
    return {
        type: 'SHOW_ZONE_COMPARISON',
        payload: state
    }
};

export const switchVisibilitySidebar = (state) =>{
    return {
        type: 'SET_VISIBILITY_SIDEBAR_MENU',
        payload: state
    }
};

export const switchEventsComparison = (state) =>{
    return {
        type: 'SHOW_EVENTS_COMPARISON',
        payload: state
    }
};

export const switchSwapStore = (state) =>{
    return {
        type: 'SHOW_SWAP_STORE',
        payload: state
    }
};

export const setCoordsTopShopPopover = (state) =>{
    return {
        type: 'SET_COORDS_TOP_SHOP_POPOVER',
        payload: state
    }
};

export const setVisitorsGroups = (state) =>{
    return {
        type: 'SET_GROUPS',
        payload: state
    }
};

export const setChosenStatsBlock = (state) =>{
    return {
        type: 'SET_CHOSEN_STATS_BLOCK',
        payload: state
    }
};

export const setVisitorsGroupsZoneCompare = (state) =>{
    return {
        type: 'SET_GROUPS_ZONE',
        payload: state
    }
};

export const setShoppopover = (state) =>{
    return {
        type: 'SET_SHOPPOPOVER',
        payload: state
    }
};

export const deleteNotification = (id) =>{
    return {
        type: 'DELETE_NOTIFICATION',
        id
    }
};

export const switchCamerasMap = (state) =>{
    return {
        type: 'SHOW_CAMERAS_MAP',
        payload: state
    }
};

export const setCompareWith = (state) =>{
    return {
        type: 'SET_COMPARE_WITH',
        payload: state
    }
};

// export const setZoneCompareWith = (state) =>{
//     return {
//         type: 'SET_ZONE_COMPARE_WITH',
//         payload: state
//     }
// };

export const setChosentopfloor = (state) =>{
    return {
        type: 'SET_CHOSENTOPFLOOR',
        payload: state
    }
};

export const removeVisitorsComparisonData = () =>{
    return {
        type: 'REMOVE_VISITORS_DATA_COMPARISON'
    }
};

export const setVisibilityHeatmap = (state) =>{
    return {
        type: 'SET_VISIBILITY_HEATMAP',
        payload: state
    }
};

export const setVisibilityHeatmapClustered = (state) =>{
    return {
        type: 'SET_VISIBILITY_HEATMAP_CLUSTERED',
        payload: state
    }
};

export const setCurrentDateRangeFlag = (state) =>{
    return {
        type: 'SET_CURRENT_DATE_RANGE_FLAG',
        payload: state
    }
};

export const setVisibilityVisitorsDetailData = (state) =>{
    return {
        type: 'SET_VISIBILITY_VISITORS_DETAIL_DATA',
        payload: state
    }
};

export const setLanguage = langCode => {
    localStorage.setItem('languageCode', langCode);

    return {
        type: 'SET_LANGUAGE',
        payload: langCode
    };
};

export const chooseZone = (state) =>{
    return {
        type: 'CHOOSE_ZONE',
        payload: state
    };
};

export const chooseTopZone = (state) =>{
    return {
        type: 'CHOOSE_TOPZONE',
        payload: state
    };
};

export const clearZone = () =>{
    return {
        type: 'CLEAR_ZONE'
    };
};

export const setAddedZone = (state) => {
    return {
        type: 'SET_ADDED_ZONE',
        payload: state
    }
};

export const setEditedZone = (state) => {
    return {
        type: 'SET_EDITED_ZONE',
        payload: state
    }
};

export const setRemovedZone = (listId) => {
    return {
        type: 'SET_REMOVED_ZONE',
        payload: listId,
    };
};

export const removeZoneVisitorsComparisonData = () =>{
    return {
        type: 'REMOVE_ZONE_VISITORS_COMPARISON_DATA'
    }
};

export const removeZoneVisitorsComparisonDataDeep = () =>{
    return {
        type: 'REMOVE_ZONE_VISITORS_COMPARISON_DATA_DEEP'
    }
};

export const setZoneVisibilityVisitorsDetailData = (state) =>{
    return {
        type: 'SET_ZONE_VISIBILITY_VISITORS_DETAIL_DATA',
        payload: state
    }
};

export const setZoneVisibilityVisitorsDetailDataDeep = (state) =>{
    return {
        type: 'SET_ZONE_VISIBILITY_VISITORS_DETAIL_DATA_DEEP',
        payload: state
    }
};

export const setFilterTooltips = (state) => {
    return {
        type: 'SET_FILTER_TOOLTIPS',
        payload: state
    }
};

export const setZonesCheckedList = (state) => {
    return {
        type: 'SET_ZONES_CHECKED_LIST',
        payload: state
    }
};

export const setSelectedComparisonDates = (state) =>{
    return {
        type: 'SET_SELECTED_COMPARISON_DATES',
        payload: state
    };
};

export const setShowDetailCompareData = (state) =>{
    return {
        type: 'SET_ZONE_VISIBILITY_DETAIL_COMPARE_DATA',
        payload: state
    }
};

export const setMallImageDimensions = (state) =>{
    return {
        type: 'SET_MALL_IMAGE_DIMENSIONS',
        payload: state
    }
};