import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import EditPolygonTabComponent from './EditPolygonTabComponent';

import { addZone, editZone, complexRequest } from "../../actions/apiRequests";
import { setEditMode, setVisibilityAddForm, chooseZone, setAddedZone, setEditedZone } from "../../actions/filters";

const mapStateToProps = ({ dashboardData, adminData, filters, language }) => {
    return {
        chosenMallDashboard: dashboardData.chosenMall,
        chosenMall: adminData.chosenMall,
        chosenZone: adminData.chosenZone,
        editMode: filters.editMode,
        camerasMapPolygons: adminData.camerasMapPolygons,
        // malls: adminData.malls,
        // editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        // chosenMall: adminData.chosenMall,
        // showCamerasMap: filters.showCamerasMap,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addZone: (data, callback)=> dispatch(addZone(data, callback)),
        editZone: (id , data, callback)=> dispatch(editZone(id , data, callback)),
        chooseZone: (data)=> dispatch(chooseZone(data)),
        // 
        // addMall: data => dispatch(data),
        // editMall: (id , data)=> dispatch(editMall(id , data)),
        // editMallData: data => dispatch(data),
        // deleteMall: (id)=> dispatch(deleteMall(id)),
        // chooseMall: (mall) => dispatch(chooseMall(mall)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        // getMalls: () => dispatch(getMalls()),
        // switchCamerasMap: (state) => dispatch(switchCamerasMap(state)),
        // getMall: (id, options, admin) => dispatch(getMall(id, options, admin)),
        setAddedZone: (state) => dispatch(setAddedZone(state)),
        setEditedZone: (state) => dispatch(setEditedZone(state)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    };
};
const EditPolygonTabContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(EditPolygonTabComponent));

export default EditPolygonTabContainer;
