import {connect} from 'react-redux';
import LeftSidebar from '../components/LeftSidebar';
import {withRouter} from 'react-router-dom';
import {switchVisibilitySidebar} from '../actions/filters';


const mapStateToProps = ({filters, language}) => {
    return {
        role: filters.role,
        isOpenSidebarMenu: filters.isOpenSidebarMenu,
        routes: language.data.routes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchVisibilitySidebar: (state) => dispatch(switchVisibilitySidebar(state))
    };
};
const LeftSidebarContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(LeftSidebar));


export default LeftSidebarContainer;
