import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import ZonesFormComponent from './ZonesFormComponent';
import { addZone, editZone, /*getMall,*/ complexRequest } from "../../actions/apiRequests";
// import {deleteMall, editMall, getMalls, getMall} from "../../actions/apiRequests";
import { setEditMode, setVisibilityAddForm, chooseZone, setAddedZone, setEditedZone } from "../../actions/filters";

const mapStateToProps = ({ dashboardData, adminData, filters, language }) => {
    return {
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        language: language.data,

        // chosenMallDashboard: dashboardData.chosenMall,
        // chosenMall: adminData.chosenMall,
        // chosenZone: adminData.chosenZone,
        // editMode: filters.editMode,
        // camerasMapPolygons: adminData.camerasMapPolygons,
        // openAddForm: filters.openAddForm,
        // language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        chooseZone: (data)=> dispatch(chooseZone(data)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },

        // addZone: (data, callback)=> dispatch(addZone(data, callback)),
        // editZone: (id , data, callback)=> dispatch(editZone(id , data, callback)),
        // chooseZone: (data)=> dispatch(chooseZone(data)),
        // setForm: (form, editMode)=> {
        //     dispatch(setVisibilityAddForm(form));
        //     dispatch(setEditMode(editMode));
        // },
        // setAddedZone: (state) => dispatch(setAddedZone(state)),
        // setEditedZone: (state) => dispatch(setEditedZone(state)),
        // complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    };
};
const ZonesFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ZonesFormComponent));

export default ZonesFormContainer;
