import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CamersAdminHeatmapComponent from '../../components/admin/CamersAdminHeatmapComponent'

import { complexRequest, addCameraMarkers, editCameraMarkers } from "../../actions/apiRequests";
// import {editCamera, complexRequest, getCameraSnapshot} from "../../actions/apiRequests";
// import {setEditMode, setVisibilityAddForm} from "../../actions/filters";


const mapStateToProps = ({ adminData, language }) => {
    return {
        malls: adminData.malls,
        cameras: adminData.cameras,
        chosenMall: adminData.chosenMall,
        chosenCamera: adminData.chosenCamera,
        mappingMarkers: adminData.mappingMarkers,
        mappingSnapshotPolygons: adminData.mappingSnapshotPolygons,
        mappingMapPolygons: adminData.mappingMapPolygons,
        language,
        // camerasMapPolygons: adminData.camerasMapPolygons,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCameraMarkers: (id, data)=> dispatch(addCameraMarkers(id, data)),
        editCameraMarkers: (id, data)=> dispatch(editCameraMarkers(id, data)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    }
};

const CamersAdminHeatmapContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(CamersAdminHeatmapComponent));

export default CamersAdminHeatmapContainer
