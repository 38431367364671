import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";

import { setLanguage } from "../actions/filters";
import { languagesList } from "../lang";


const ChangeLanguageButton = ({ setLanguage, language }) => {
    const { code: languageCode } = language;

    return (
        <Dropdown id="language-selector-dropdown" className="language-selector">
            <Dropdown.Toggle>{<img src="/images/language.svg" alt="language"/>}</Dropdown.Toggle>

            <Dropdown.Menu className="language-dropdown_menu">
                {
                    languagesList.map(({ code, title }) => {
                        return <Dropdown.Item
                            key={code}
                            onClick={() => setLanguage(code)}
                            className={classNames({ selected: code === languageCode })}
                        >
                            {title}
                        </Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};

const mapStateToProps = ({ language}) => {
    return {
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLanguage: langCode => dispatch(setLanguage(langCode)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguageButton);
