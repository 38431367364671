const spinner = (state = 0,  action /* {type} */) => {
    const { type } = action
    // console.log('spinner', state, action,  (type.match(/_PENDING$/) || {}), (type.match(/_FULFILLED$/) || {}), (type.match(/_REJECTED$/) || {}))
    // writing my actions and reducers with thunks that dispatch _PENDING, _FULFILLED, and _REJECTED actions
    switch (type) {
        case (type.match(/_PENDING$/) || {}).input:
            return state + 1;
        case (type.match(/_FULFILLED$/) || {}).input:
            return state - 1;
        case (type.match(/_REJECTED$/) || {}).input:
            return state - 1;
        case 'STOP_SPINNER':
            return 0;
        default:
            return state;
    }
};
export default spinner;