const defaultState = [];

const notifications = (state = defaultState, action) =>{
    switch (action.type) {
        // case '@ReduxToastr/toastr/ADD': {
        //     const {type, title, message, ignoreToastr} = action.payload;
        //     if (ignoreToastr){
        //         return state
        //     }
        //     return [{type,title, message, id: `f${(~~(Math.random()*1e8)).toString(16)}`}, ...state]
        // }
        case 'DELETE_NOTIFICATION': {
            return state.filter(({id}) => action.id !== id)
        }
        default:
            return state;
    }
};

export default  notifications;