import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MallsDashboardComponent from '../components/MallsDashboardComponent';
import {addMall, deleteMall, editMall, getMalls} from "../actions/apiRequests";
import {chooseMall, setEditMode, setVisibilityAddForm} from "../actions/filters";


const mapStateToProps = ({ adminData, filters, language }) => {
    return {
        malls: adminData.malls,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addMall: (data)=> dispatch(addMall(data)),
        editMall: (id , data)=> dispatch(editMall(id , data)),
        deleteMall: (id)=> dispatch(deleteMall(id)),
        chooseMall: (mall) => dispatch(chooseMall(mall)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getMalls: () => dispatch(getMalls()),
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MallsDashboardComponent));
