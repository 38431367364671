import _ from 'lodash'
import moment from "moment";

const dasetsParser = (label, data = [], color) => {
    return {
        label,
        data,
        backgroundColor: color,
        hoverBackgroundColor: color,
        borderWidth: 0,
    }
};

const hoursLabels = (timestamps = [], hourLabel, direction = 'ltr') => {
    if (_.isEmpty(timestamps)) {
        if (direction === 'rtl') {
            return [
                `${hourLabel} 00`,
                `${hourLabel} 02`,
                `${hourLabel} 04`,
                `${hourLabel} 06`,
                `${hourLabel} 08`,
                `${hourLabel} 10`,
                `${hourLabel} 12`,
                `${hourLabel} 14`,
                `${hourLabel} 16`,
                `${hourLabel} 18`,
                `${hourLabel} 20`,
                `${hourLabel} 22`
            ];
            // return [
            //     `${hourLabel} 00-02`,
            //     `${hourLabel} 02-04`,
            //     `${hourLabel} 04-06`,
            //     `${hourLabel} 06-08`,
            //     `${hourLabel} 08-10`,
            //     `${hourLabel} 10-12`,
            //     `${hourLabel} 12-14`,
            //     `${hourLabel} 14-16`,
            //     `${hourLabel} 16-18`,
            //     `${hourLabel} 18-20`,
            //     `${hourLabel} 20-22`,
            //     `${hourLabel} 22-24`
            // ];
        }
        return [
            `00${hourLabel}`,
            `02${hourLabel}`,
            `04${hourLabel}`,
            `06${hourLabel}`,
            `08${hourLabel}`,
            `10${hourLabel}`,
            `12${hourLabel}`,
            `14${hourLabel}`,
            `16${hourLabel}`,
            `18${hourLabel}`,
            `20${hourLabel}`,
            `22${hourLabel}`
        ]
        // return [
        //     `00-02${hourLabel}`,
        //     `02-04${hourLabel}`,
        //     `04-06${hourLabel}`,
        //     `06-08${hourLabel}`,
        //     `08-10${hourLabel}`,
        //     `10-12${hourLabel}`,
        //     `12-14${hourLabel}`,
        //     `14-16${hourLabel}`,
        //     `16-18${hourLabel}`,
        //     `18-20${hourLabel}`,
        //     `20-22${hourLabel}`,
        //     `22-24${hourLabel}`
        // ]
    } else {
        const stepHour = moment.duration(moment(moment.utc(timestamps[1]).local()).diff(moment(moment.utc(timestamps[0]).local()))).asHours();
        // const format = stepHour % 2 === 0 ? "HH" : "HH:mm";
        const format = "HH"

        return timestamps.map(timestamp => {
            const ts = moment.utc(timestamp).local();
            const nextLabel = moment(ts).add({hours: stepHour}).format(format);

            if (direction === 'rtl'){
                return `${format === "HH" ? hourLabel : ""} ${ts.format(format)}`
                // return `${format === "HH" ? hourLabel : ""} ${ts.format(format)}-${+ts.format("HH") + stepHour <= 23.5 ? nextLabel : (format === "HH" ? "24" : "24:00")}`
            }
            return `${ts.format(format)}${format === "HH" ? hourLabel : ""}`
            // return `${ts.format(format)}-${+ts.format("HH") + stepHour <= 23.5 ? nextLabel : (format === "HH" ? "24" : "24:00")}${format === "HH" ? hourLabel : ""}`
        })
    }
};

export default function visitorsZoneParser(selectedZonesList, daterangeKey, data, colors, format, daterangesLabels, hourLabel, dataFieldName, dailyData, diffDays){
    if(_.isEmpty(data)){
        return {
            datasets: [],
            labels:[]
        }
    } else {
        const diff = moment(data[0].timestamp[data[0].timestamp.length - 1]).diff(moment(data[0].timestamp[0]), "days");
        console.log('visitorsZoneParser diff: ', diff)

        let labels = [];
        let datasets = []
        if (!dailyData) {
            // calculates datasets
            datasets = data.map((item, index) => (
                dasetsParser(
                    selectedZonesList.find(({id}) => id === item.zone_id).name,
                    item[dataFieldName],
                    colors[index % colors.length],
                )
            ));
            // calculates labels
            if (!_.isEmpty(data[0]) && !_.isEmpty(data[0].timestamp)) {
                if (diff === 0) {
                    labels = hoursLabels(data[0].timestamp, hourLabel)
                } else {
                    // labels = data[0].timestamp.map(item => `${moment.utc(item).local().format('DD')}-${moment.utc(item).add(1,'days').local().format('DD')}`)
                    if (moment.duration(moment(moment.utc(data[0].timestamp[1]).local()).diff(moment(moment.utc(data[0].timestamp[0]).local()))).asHours() < 24) {
                        labels = hoursLabels(data[0].timestamp, hourLabel)
                    } else {
                        labels = data[0].timestamp.map(item => moment.utc(item).local().format(format))
                    }
                }
            }
        } else {
            // calculates datasets
            datasets = data.map((item, index) => {
                if (diff === 0) {
                    return dasetsParser(
                        selectedZonesList.find(({id}) => id === item.zone_id).name,
                        [_.sum(item[dataFieldName])],
                        colors[index % colors.length],
                    )
                } else {
                    return dasetsParser(
                        selectedZonesList.find(({id}) => id === item.zone_id).name,
                        item[dataFieldName],
                        colors[index % colors.length],
                    )
                }
            });
            // calculates labels
            if (!_.isEmpty(data[0]) && !_.isEmpty(data[0].timestamp)) {
                if (diff === 0) {
                    // labels = [moment(data[0].timestamp[0]).format('DD/MM')]
                    labels = [moment.utc(data[0].timestamp[0]).local().format('DD/MM')]
                } else {
                    // labels = data[0].timestamp.map(item => `${moment.utc(item).local().format('DD')}-${moment.utc(item).add(1,'days').local().format('DD')}`)
                    labels = data[0].timestamp.map(item => moment.utc(item).local().format(format))
                }
            }
        }

        return {
            labels,
            datasets,
        };

        // switch (daterangeKey) {
        //     case 'today':
        //     case 'yesterday': {
        //         const datasets = data.map((item, index) => dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, item.visitors, colors[index % colors.length]));

        //         return {
        //             labels: hoursLabels(data[0].timestamp, hourLabel),
        //             datasets
        //         };
        //     }
        //     case 'thisWeek':
        //     case 'lastWeek': {
        //         // const datasets = data.map(({key, visitors}, index) =>  dasetsParser(daterangesLabels[key], visitors, colors[index % colors.length]));
        //         const datasets = data.map((item, index) =>  dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, item.visitors, colors[index % colors.length]));

        //         // const labels = (!isEmpty(data) && !isEmpty(data[0]) && !isEmpty(data[0].timestamp))
        //         //     ? data[0].timestamp.map(it => moment.utc(it).local().format('dddd'))
        //         //     : [];
        //         const labels = (!isEmpty(data) && !isEmpty(data[0]) && !isEmpty(data[0].timestamp))
        //             ? data[0].timestamp.map(it => moment.utc(it).local().format('dddd'))
        //             : [];

        //         return {
        //             labels,
        //             datasets
        //         };
        //     }
        //     case 'thisMonth':
        //     case 'lastMonth': {
        //         // const datasets = data.map(({key, visitors}, index) =>  dasetsParser(daterangesLabels[key], visitors, colors[index % colors.length]));
        //         const datasets = data.map((item, index) =>  dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, item.visitors, colors[index % colors.length]));

        //         // const labels = !isEmpty(data) && !isEmpty(data[0])
        //         //     ? data[0].timestamp.map(it => `${moment.utc(it).local().format('DD')}-${moment.utc(it).add(1,'days').local().format('DD')}`)
        //         //     : [];
        //         const labels = !isEmpty(data) && !isEmpty(data[0])
        //             ? data[0].timestamp.map(it => `${moment.utc(it).local().format('DD')}-${moment.utc(it).add(1,'days').local().format('DD')}`)
        //             : [];

        //         return {
        //             labels,
        //             datasets
        //         };
        //     }
        //     case 'thisYear':
        //     case 'lastYear': {
        //         // const datasets = data.map(({key, visitors, timestamp}, index) => {
        //         //     const values = map(new Array(12), () => 0);

        //         //     timestamp.forEach((it, i) => values[moment.utc(it).local().month()] += visitors[i]);

        //         //     return dasetsParser(daterangesLabels[key], values, colors[index % colors.length])
        //         // });
        //         const datasets = data.map((item, index) => {
        //             const values = map(new Array(12), () => 0);

        //             item.timestamp.forEach((it, i) => values[moment.utc(it).local().month()] += item.visitors[i]);

        //             return dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, values, colors[index % colors.length])
        //         });

        //         return {
        //             labels: moment.monthsShort(),
        //             datasets,
        //         };
        //     }
        //     case 'customTimeShow':
        //     case 'customTime': {
        //         // const datasets = data.map(({key, visitors}, index) => dasetsParser(daterangesLabels[key], visitors, colors[index % colors.length]));
        //         // const datasets = data.map((item, index) => dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, item.visitors, colors[index % colors.length]));
        //         const datasets = data.map((item, index) => dasetsParser(selectedZonesList.find(({id}) => id === item.zone_id).name, item.visitors, colors[index % colors.length]));
        //         let labels = [];

        //         // const diff = moment(data[0].timestamp[data[0].timestamp.length - 1]).diff(moment(data[0].timestamp[0]), "days");
        //         // const diff = moment(data[0].timestamp[data[0].timestamp.length - 1]).diff(moment(data[0].timestamp[0]), "days");
        //         const diff = moment(data[0].timestamp[data[0].timestamp.length - 1]).diff(moment(data[0].timestamp[0]), "days");

        //         // if (!isEmpty(data[0]) && !isEmpty(data[0].timestamp)) {
        //         if (!isEmpty(data[0]) && !isEmpty(data[0].timestamp)) {
        //             if (diff === 0) {
        //                 // labels = hoursLabels(data[0].timestamp, hourLabel);
        //                 labels = hoursLabels(data[0].timestamp, hourLabel);
        //             } else {
        //                 // labels = (!isEmpty(data[0]) && !isEmpty(data[0].timestamp)) ? data[0].timestamp.map(item => moment.utc(item).local().format(format)) : [];
        //                 labels = (!isEmpty(data[0]) && !isEmpty(data[0].timestamp)) ? data[0].timestamp.map(item => moment.utc(item).local().format(format)) : [];
        //             }
        //         }

        //         return {
        //             labels,
        //             datasets,
        //         };
        //     }
        //     default:
        //         return {
        //             datasets: [],
        //             labels:[]
        //         }
        // }
    }
}
