const getScheduleGroupRange = (hours = 0, minBars = 8, maxBars = 12) => {
    const group_range = 3600;  // 1h

    if (hours && hours <= 24 && minBars > 0 && minBars < 24 && maxBars > 0 && maxBars < 24 && minBars < maxBars) {
        if (hours >= 23) {
            return 10800 // if schedule more than 23 hours it better get 3h step or it can change on 2h
        } else if (hours < 8) {
            return 3600 // if schedule less than 8 hours it better get 1h step
        } else {
            return 7200
            // for (let i = 1; i <= 24 / 8; i++) {  // i it's step in hours
            //     console.log('i:  ', i)
            //     if (minBars <= (hours / i) && (hours / i) <= maxBars) {
            //         return group_range * i;
            //     } else {
            //         const extraHalf = i + 0.5; // if the number of bar doesn't satisfied the minimum and maximum, add half an hour to the hour iterator

            //         if (!(minBars <= (hours / (i + 1)) && (hours / (i + 1)) <= maxBars)
            //             && (minBars <= (hours / extraHalf) && (hours / extraHalf) <= maxBars)) {
            //             return group_range * extraHalf;
            //         }
            //     }
            // }
        }
    } else {
        return group_range
    }
};

export default getScheduleGroupRange;
