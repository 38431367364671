import React, { useState, useEffect, useCallback } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useEmblaCarousel } from 'embla-carousel/react'
import moment from 'moment'

import { statBlocks } from '../../helpers/helperStatBlocks'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './StatsSlider.sass'

export const PrevButton = ({ enabled, onClick }) => (
	<button
		className="embla__button embla__button--prev"
		onClick={onClick}
		disabled={!enabled}
	>
		&lt;
	</button>
)

export const NextButton = ({ enabled, onClick }) => (
	<button
		className="embla__button embla__button--next"
		onClick={onClick}
		disabled={!enabled}
	>
		&gt;
	</button>
)

const StatsSlider = (props) => {
	const { showTimeline, chosenStatsBlock, setChosenStatsBlock, mallDailyStats, filterTooltips, setFilterTooltips } = props

	const [emblaRef, embla] = useEmblaCarousel({
		dragFree: true,
		containScroll: "trimSnaps",
	})
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	const onSelect = useCallback(() => {
		if (!embla) return;
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla])

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect)
		onSelect();
	}, [embla, onSelect])

	const handleChosenStatsBlockClick = (item) => {
		const { id, name, apiKey } = item
		const newFilterTooltip = [{ id, value:id, label: name, apiKey }]

		// if (filterTooltips.length < 3) {
		// 	setFilterTooltips([...newFilterTooltip, ...filterTooltips]) 
	   	// }
		setFilterTooltips(newFilterTooltip)
		setChosenStatsBlock(item)
	} 
	
	const getStatBlockValue = (item) => {
		let res = mallDailyStats[item.apiKey]
		if (item.apiKey === 'dwell_time') {
			res = moment.duration(res, 'seconds').format('mm:ss', {trim: false})
		}

		return res || 0
	}

	// console.log('PROPS StatsSlider mallDailyStats: ', mallDailyStats)
	return (
		<div className={`statsSlider ${!showTimeline && 'collapsed'}`}>
			<div className="embla">
				<div className="embla__viewport" ref={emblaRef}>
					<div className="embla__container">
						{statBlocks.map((item) => {
							if (showTimeline) {
								return (
									<div
										className="embla__slide"
										key={item.id}
									>
										<div 
											className={`embla__slide__inner statsBlock ${chosenStatsBlock.id.toString() === (item.id).toString() ? 'active' : ''}`}
											onClick={() => handleChosenStatsBlockClick(item)}
										>
											<div className="sliderHeaderWrapper" style={{ textAlign: item.id === 6 && 'left', paddingLeft: item.id === 6 && '10px' }}>
												<span className="name">{item.name}</span>
												<OverlayTrigger
														overlay={<Tooltip>{item.tooltip}</Tooltip>}
												>
														<div className="infoIcon" />
												</OverlayTrigger>
											</div>
											<div className="valueWrapper">
												{`${getStatBlockValue(item)} ${item.unit}`}
											</div>
											<div className="exploreWrapper">
												<div style={{ marginRight: '8px' }}>Explore</div>
												<div className="exploreIcon" />
											</div>
										</div>
									</div>
								)
							} else {
								return (
										<div
											className="embla__slide"
											key={item.id}
											style={{ minWidth: item.id === 6 ? '192px' : '143px' }}
										>
											<div
												className={`embla__slide__inner statsBlock collapsed ${chosenStatsBlock.id.toString() === (item.id).toString() ? 'active' : ''}`}
												onClick={() => handleChosenStatsBlockClick(item)}
											>
												<span className="name">{item.name}</span>
											</div>
										</div>
								)
							}
						})}
					</div>
				</div>
				<PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
				<NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
			</div>
		</div>
	)
}

export default StatsSlider
