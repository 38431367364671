import React, {Component} from 'react';
import {Button, Dropdown, DropdownButton, Form} from "react-bootstrap";
import DatePicker from "react-datepicker/es";
import moment from "moment";
import {pickBy} from "lodash";

import validate from "../helpers/validate";

import "../styles/CreateUser.sass"

class CreateUser extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            userType: props.language.data.adminSection.usersPage.userTypes[2],
            first_name: "",
            last_name: "",
            birthday: new Date(),
            roles: "marketer",
            malls_id: "",
            phone_number: "",
        };

        this.modal = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    changeBirthday = date => this.setState({birthday: date});

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.props.setForm(false, false);
        }
    };

    addData(){
        const { birthday, userType, ...request } = this.state;
        const { setForm, addUser, language } = this.props;
        const { warningTitle, filledInMessage } = language.data.notifications;

        if (validate(request, { warningTitle, filledInMessage })){
            setForm(false, false);
            addUser(pickBy({ ...request, birthday: moment(birthday).format("YYYY-MM-DD")}))
        }
    }

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    render() {
        const { email, password, first_name, last_name, userType, birthday , phone_number, malls_id} = this.state;
        const { malls, role, user, setForm, language } = this.props;

        const { data, code } = language;
        const {
            genericLabels,
            adminSection,
            columns,
        } = data;

        const { welcomeTitle } = genericLabels;

        const {
            addUserTitle,
            selectRegistrationTypeTitle,
            userTypes,
            chooseMallTitle,
            createUserButtonTitle,
        } = adminSection.usersPage;
        const {
            nameLabel,
            surnameLabel,
            birthdayLabel,
            passwordLabel,
            emailLabel,
            phoneNumberLabel,
            mallLabel,
        } = columns;

        return (
            <div className="centralise open">
                <div className="create_user" ref={this.modal}>
                    <button className="close_btn" onClick={() => setForm(false, false)}/>

                    <Form className="sign_up_block">
                        <h3>{addUserTitle}</h3>
                        <p className="description">
                            {selectRegistrationTypeTitle}
                        </p>

                        <div className="select-type_wrap">
                            {userTypes.map((item, index) => {
                                if (role !== 'admin' && item.name.toLowerCase() !== 'marketer') {
                                    return (
                                        <div
                                            className="type disabled"
                                            key={index}
                                        >
                                            <img alt="" src={item.icon} />
                                            <p className="name">{item.name}</p>
                                        </div>
                                    )
                                }
                                return (
                                    <div
                                        className={`type ${item.name === userType.name && 'active'}`}
                                        key={index}
                                        onClick={() => this.setState({
                                            userType: item,
                                            roles: item.name.toLowerCase(),
                                        })}
                                    >
                                        <img alt="" src={item.icon} />
                                        <p className="name">{item.name}</p>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="user-type-description">
                            {userType.description}
                        </div>

                        <div className="inputs-wrap four">
                            <Form.Group>
                                <Form.Label>{nameLabel}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={nameLabel}
                                    value={first_name}
                                    onKeyDown={this.handleEnter}
                                    onChange={e => this.setState({first_name: e.target.value})}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{surnameLabel}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={surnameLabel}
                                    value={last_name}
                                    onKeyDown={this.handleEnter}
                                    onChange={e => this.setState({last_name: e.target.value})}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{emailLabel}</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={emailLabel}
                                    value={email}
                                    onKeyDown={this.handleEnter}
                                    onChange={e => this.setState({email: e.target.value})}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{passwordLabel}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={passwordLabel}
                                    value={password}
                                    onKeyDown={this.handleEnter}
                                    onChange={e => this.setState({password: e.target.value})}/>
                            </Form.Group>
                        </div>

                        <div className="inputs-wrap three wrap_for_dropdown-buttons">
                            <Form.Group>
                                <Form.Label>{phoneNumberLabel}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={phoneNumberLabel}
                                    value={phone_number}
                                    onKeyDown={this.handleEnter}
                                    onChange={e => this.setState({phone_number: e.target.value})}
                                />
                            </Form.Group>

                            <Form.Group className="d-flex flex-column">
                                <Form.Label>{birthdayLabel}</Form.Label>

                                <DatePicker
                                    selected={birthday}
                                    onChange={this.changeBirthday}
                                    dateFormat="MMMM d, yyyy"
                                    locale={code}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{mallLabel}</Form.Label>

                                <DropdownButton
                                    id="dropdown--button-for_malls"
                                    title={ malls_id === "" ? chooseMallTitle : role === "manager" ? user.malls_name : malls.filter(mall => mall.id === malls_id)[0].name }
                                    variant="outline-dark"
                                >
                                    {role === "admin"
                                    && (
                                        malls.map((mall, index) =>
                                            <Dropdown.Item
                                                value={mall.name}
                                                key={index}
                                                onClick={() => this.setState({malls_id: mall.id})}
                                            >
                                                {mall.name}
                                            </Dropdown.Item>)
                                    )}

                                    {role === "manager"
                                    && (
                                        <Dropdown.Item
                                            value={user.malls_name}
                                            onClick={() => this.setState({malls_id: user.malls_id})}
                                        >
                                            {user.malls_name}
                                        </Dropdown.Item>
                                    )}
                                </DropdownButton>
                            </Form.Group>
                        </div>
                        <Button
                            variant="outline-dark"
                            className="submit login"
                            onClick={() => this.addData()}
                        >
                            {createUserButtonTitle}
                        </Button>
                    </Form>

                    {/*<div className="description_user-type">*/}
                    {/*    <div className="picture"><img src={userType.icon} alt=""/></div>*/}

                    {/*    <div className="info_wrap">*/}
                    {/*        <div className="head_wrap">*/}
                    {/*            <h5>{welcomeTitle}</h5>*/}
                    {/*            <h4>{process.env.REACT_APP_PROJECT_TITLE}</h4>*/}
                    {/*        </div>*/}

                    {/*        <h5 className="describe">{userType.describe}</h5>*/}

                    {/*        <p className="description">{userType.description}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default CreateUser;
