import { connect } from 'react-redux'
import SwapStore from './SwapStore'
import {
    switchSwapStore
} from '../../actions/filters'
import { complexRequest } from '../../actions/apiRequests'

const mapStateToProps = ({ dashboardData, adminData, language }) => {
    return {
        chosenMall:dashboardData.chosenMall,
        malls: adminData.malls,
        daterange:dashboardData.daterange,
        language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchSwapStore: state => dispatch(switchSwapStore(state)),
        complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
    }
};

const SwapStoreContainer = connect(mapStateToProps, mapDispatchToProps)(SwapStore);

export default SwapStoreContainer
