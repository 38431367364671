import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Row, Col, Form, Dropdown, Button } from 'react-bootstrap'
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import DateTime from 'react-datetime'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import _ from "lodash"
import { Select as  VirtualizedSelect} from "react-select-virtualized"
import ReactPlayer from 'react-player'
// import Clappr from 'clappr'
// import Select from 'react-virtualized-select'
// import VirtualizedSelect from 'react-virtualized-select'
// import 'react-select/dist/react-select.css'
// import 'react-virtualized-select/styles.css'

// Then import the virtualized Select HOC


import queryOptionsParser from "../../helpers/queryOptionsParser";

import 'react-datetime/css/react-datetime.css'
import "./DebugToolComponent.sass"

// this.player = null;

const DebugToolComponent = (props) => {
    // console.log('PROPS DebugToolComponent: ', props)
    const DEFAULT_CHOSEN_MALL = { id: '', name: 'all' }

    const [chosenMall, setСhosenMall] = useState(DEFAULT_CHOSEN_MALL)
    const [selectedFloor, setSelectedFloor] = useState('all')
    const [scheduleStartTs, setScheduleStartTs] = useState(moment(moment().add(1, 'day').format('YYYYMMDD100000'), 'YYYYMMDDHHmmss'))
    const [scheduleEndTs, setScheduleEndTs] = useState(moment(moment().add(1, 'day').format('YYYYMMDD110000'), 'YYYYMMDDHHmmss'))
    // const [scalemap, setScalemap] = useState(0.2)
    // const [selectedDebuggedDay, setSelectedDebuggedDay] = useState()
    // const [selectedDebuggedStartTs, setSelectedDebuggedStartTs] = useState(moment(moment().format('YYYYMMDD000000'), 'YYYYMMDDHHmmss'))
    // const [selectedDebuggedEndTs, setSelectedDebuggedEndTs] = useState(moment(moment().format('YYYYMMDD235959'), 'YYYYMMDDHHmmss'))
    // const [selectedCluster, setSelectedCluster] = useState(0)
    // const [selectedCamera, setSelectedCamera] = useState(0)
    // const [selectedZone, setSelectedZone] = useState(0)
    // const [selectedImages, setSelectedImages] = useState([])
    // const [hoveredImage, setHoveredImage] = useState()
    // const [clickedImage, setClickedImage] = useState()
    // const [sortDirection, setSortDirection] = useState('asc')
    // const [showNewCalculation, setShowNewCalculation] = useState(false)
    // const [clickedImageNewCalculation, setClickedImageNewCalculation] = useState()
    const [selectedDebuggedStartTs, setSelectedDebuggedStartTs] = useState(moment(moment().format('YYYYMMDD100000'), 'YYYYMMDDHHmmss'))
    const [selectedDebuggedEndTs, setSelectedDebuggedEndTs] = useState(moment(moment().format('YYYYMMDD110000'), 'YYYYMMDDHHmmss'))
    const [selectedCamera, setSelectedCamera] = useState(0)
    const [showCameraPolygonTooltip, setShowCameraPolygonTooltip] = useState(false)
    const [selectedCamerasPolygonName, setSelectedCamerasPolygonName] = useState('')

    const {
        availableDebuggedDays,
        cameras,
        // clusters,
        // clusterImages,
        // newCalculationImages,
        sendDebugSchedule,
        complexRequest,
        chosenMallDashboard,
        camerasMapPolygons,
        malls,
        role,
        language
    } = props
    const { 
        notifications,
        genericLabels,
        adminSection: { debugToolPage }        
    } = language;
    const {
        successTitle,
    } = notifications;
    const {
        allMallButtonTitle,
        allTitle,
        allFloorsTitle,
        floorTitle,
        deleteSelectedTitle,
        tableCountPaginationLabels,
        searchFormPlaceholder,
    } = genericLabels;
    const {
        mainTitle,
        selectClusterTitle,
        selectLabel,
        galaryTitle,
        mapTitle,
        SelectDebuggedDateTitle,
        ScheduleDebuggingTitle,
        MallNotHaveImageTitle,
        ClusterNotHaveImagesTitle
    } = debugToolPage

    useEffect(() => {
        const mallsOptions = {
            floor: true,
        }

        complexRequest([
                { name: "availabledays", method: "get", type: `debug-tools/available-days` },
                { name: "malls", method: "get", type: `malls${queryOptionsParser(mallsOptions)}`},
                {name: "allcameraspolygons", method: "get", type: `cameras/polygons`},
            ],
            "GET_DEBUGTOOL_AVAILABLE_DAYS"
        )
    }, []);

    useEffect(() => {
        // console.log('!!!!!!!!!!!!!!!!useEffect: ', roles, malls_id, chosenMall)
        if (!_.isEmpty(chosenMallDashboard)) {
            setСhosenMall(chosenMallDashboard)
            setSelectedFloor(1)
        }
    }, [chosenMallDashboard]);

    let floorsAmount = 4
    if (chosenMall.name !== 'all') {
        const obj = malls.find(({name}) => name === chosenMall.name)
        if (obj && obj.floors_amount) {
            floorsAmount = obj.floors_amount + 1
        } else {
            floorsAmount = 1
        }
    }

    const availableCameras = []
    if (cameras.length) {
        cameras.forEach(({ id, name, title }) => {
            availableCameras.push({ value: id, label: `${name} (${title})` })
        })
    }
    // availableCameras = _.uniqBy(_.orderBy(availableCameras, ['label'], ['asc']), 'value')

    const selectedFloorItem = (chosenMall.floors && selectedFloor && selectedFloor !== 'all')  ? chosenMall.floors.find((item) => item.number === selectedFloor ) : ''

    const validScheduleStartTs = (current) => {
        return current.isSameOrAfter(moment())
    }
    const validScheduleEndTs = (current) => {
        return current.isSameOrAfter(moment())
    }

    const validDebuggedDay = (current) => {
        return (_.indexOf(availableDebuggedDays, current.format('YYYY-MM-DD')) !== -1)
    }
    
    const handleSendScheduleClick = () => {
        if (scheduleEndTs.isAfter(scheduleStartTs)) {
            sendDebugSchedule({ start_ts: scheduleStartTs.utc().format('YYYYMMDDHHmmss'), end_ts: scheduleEndTs.utc().format('YYYYMMDDHHmmss') })
        } else {
            toastr.error('End date should be bigger than Start date')
        }
    }

    const handleDebuggedStartTsChange = (current) => {
        setSelectedDebuggedStartTs(current)
        if (selectedDebuggedEndTs.format('DDMMYYYY') !== current.format('DDMMYYYY')) {
            setSelectedDebuggedEndTs(moment(moment(current).format('YYYYMMDD')+selectedDebuggedEndTs.format('HHmmss'), 'YYYYMMDDHHmmss'))
        }
    }

    const handleDebuggedEndTsChange = (current) => {
        setSelectedDebuggedEndTs(current)
        if (selectedDebuggedStartTs.format('DDMMYYYY') !== current.format('DDMMYYYY')) {
            setSelectedDebuggedStartTs(moment(moment(current).format('YYYYMMDD')+selectedDebuggedStartTs.format('HHmmss'), 'YYYYMMDDHHmmss'))
        }
    }

    const handleDebuggedDayChange = () => {
        if (!selectedDebuggedEndTs || !selectedDebuggedStartTs) {
            toastr.error('You have to select datetime range')
            return  
        }
        if (!selectedDebuggedEndTs.isAfter(selectedDebuggedStartTs)) {
            toastr.error('End date should be bigger than Start date')
            return            
        } 

        setSelectedCamera(0)

        const clusterOptions = {
            start_ts: moment(selectedDebuggedStartTs).utc().format('YYYYMMDDHHmmss'),
            end_ts: moment(selectedDebuggedEndTs).utc().format('YYYYMMDDHHmmss'),
        }

        complexRequest([
                { name: "cameras", method: "get", type: `debug-tools/video/cameras${queryOptionsParser(clusterOptions)}`},
            ],
            "GET_DEBUGTOOL_CAMERAS"
        )
    }
    
    const handleCameraChange = (argValue) => {
        setSelectedCamera(argValue)
    }

    let video = '';
    if (selectedCamera) {
        video = cameras.find(({id}) => id === selectedCamera.value)
    }

    const handleCamerasPolygonMouseOver = (e, camerasName) => {
        const tooltipSpan = document.getElementById('camera-polygon-tooltip');
        const x = e.clientX
        const y = e.clientY

        tooltipSpan.style.top = (y + 5) + 'px';
        tooltipSpan.style.left = (x + 5) + 'px';

        setSelectedCamerasPolygonName(camerasName)
        setShowCameraPolygonTooltip(true)
    }

    const handleCamerasPolygonMouseOut = (e) => {
        setShowCameraPolygonTooltip(false)
    }


    // displays the all of green zones polygons on the Map
    const polysSelectedCamera = []
    const polysCameras = []
    camerasMapPolygons
        .filter(({ camera_id, floor_number }) => floor_number === selectedFloor /*&& camera_id !== id*/)
        .forEach( (camera, i) => {
            // console.log('selectedCamera: ', selectedCamera)
            // console.log('camera: ', camera)
            const poly = _.flatten(JSON.parse(camera.map_coords))
            if (camera.camera_id == selectedCamera.value) {
                polysSelectedCamera.push(
                    <polygon
                        key={i}
                        points={poly}
                        onMouseOver={ (e) => handleCamerasPolygonMouseOver(e, camera.camera_title || camera.camera_name) }
                        onMouseOut={ handleCamerasPolygonMouseOut }
                    />
                )
            } else {
                polysCameras.push(
                    <polygon
                        key={i}
                        points={poly}
                        onMouseOver={ (e) => handleCamerasPolygonMouseOver(e, camera.camera_title || camera.camera_name) }
                        onMouseOut={ handleCamerasPolygonMouseOut }
                    />
                )
            }
        })


    // console.log('STATE selectedDebuggedStartTs: ', selectedDebuggedStartTs.format('YYYYMMDDHHmmss'), selectedDebuggedStartTs)
    // console.log('STATE selectedDebuggedEndTs: ', selectedDebuggedEndTs.format('YYYYMMDDHHmmss'), selectedDebuggedEndTs)

    // console.log('STATE selectedCamera: ', selectedCamera)
    // console.log('STATE video: ', video)
    // console.log('PROPS DebugToolComponent: ', props)

    return (
        <div className="content debug-tool">
            <div className="wrap_header">
                <h2 className="page_title">{mainTitle}</h2>

                {role === "admin"
                && (
                    <Dropdown className="dropdown_btn">
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                            {chosenMall.name === "all" ? allMallButtonTitle : chosenMall.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setСhosenMall(DEFAULT_CHOSEN_MALL)
                                    setSelectedFloor('all')
                                }}
                            >
                                {allTitle}
                            </Dropdown.Item>

                            {malls.map((item) =>
                                <Dropdown.Item
                                    key={item.id}
                                    onClick={() => {
                                        setСhosenMall(item)
                                        setSelectedFloor('all')
                                    }}
                                >
                                    {item.name}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                <Dropdown className="dropdown_btn">
                    <Dropdown.Toggle id="dropdown-basic">
                        {selectedFloor === "all" ? allFloorsTitle : `${floorTitle} ${selectedFloor}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setSelectedFloor('all')}
                        >
                            {allTitle}
                        </Dropdown.Item>
                        {
                            _.range(1, floorsAmount).map((number) =>
                                <Dropdown.Item
                                    key={number}
                                    onClick={() => setSelectedFloor(number)}
                                >
                                    {number}
                                </Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="camera-mapping-anchor">
                <div style={{ marginTop: '20px' }}>
                    <Form.Group>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                            <div className="selected-range form-inline">
                                <div className="select-title" style={{ display: 'inline-block' }}>
                                    {ScheduleDebuggingTitle}
                                </div>

                                <DateTime
                                    timeFormat="hh:mm:ss A"
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{ readOnly: true }}
                                    value={moment(scheduleStartTs)}
                                    closeOnSelect
                                    isValidDate={validScheduleStartTs}
                                    onChange={(current) => setScheduleStartTs(current)}
                                />
                                <span style={{ margin: '0 10px' }}>-</span>
                                <DateTime
                                    timeFormat="hh:mm:ss A"
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{ readOnly: true }}
                                    value={moment(scheduleEndTs)}
                                    closeOnSelect
                                    isValidDate={validScheduleEndTs}
                                    onChange={(current) => setScheduleEndTs(current)}
                                />
                                <Button
                                    className="btn_new_item"
                                    variant="outline-dark"
                                    style={{ marginLeft: '20px' }}
                                    onClick={handleSendScheduleClick}
                                >
                                    Send Schedule
                                </Button>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                            <div className="selected-range form-inline">
                                <div className="select-title" style={{ display: 'inline-block' }}>
                                    {SelectDebuggedDateTitle}
                                </div>
                                <DateTime
                                    timeFormat="hh:mm:ss A"
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{ readOnly: true }}
                                    // value={moment(selectedDebuggedDay).format('DD/MM/YYYY')}
                                    value={moment(selectedDebuggedStartTs)}
                                    // dateFormat="DD/MM/YYYY"
                                    // timeFormat={false}
                                    closeOnSelect
                                    isValidDate={validDebuggedDay}
                                    onChange={handleDebuggedStartTsChange}
                                />
                                <span style={{ margin: '0 10px' }}>-</span>
                                <DateTime
                                    timeFormat="hh:mm:ss A"
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{ readOnly: true }}
                                    value={moment(selectedDebuggedEndTs)}
                                    closeOnSelect
                                    isValidDate={validDebuggedDay}
                                    onChange={handleDebuggedEndTsChange}
                                />
                                <Button
                                    className="btn_new_item"
                                    variant="outline-dark"
                                    style={{ marginLeft: '20px' }}
                                    onClick={handleDebuggedDayChange}
                                >
                                    Get Cameras
                                </Button>
                            </div>
                            <div className="dropdown_wrap" style={{alignItems: 'center', marginLeft: '30px'}}>
                                <div className="select-title">{selectClusterTitle}</div>
                                <div className="select-wrapper" style={{ marginRight: '20px' }}>
                                    <VirtualizedSelect
                                        // menuIsOpen={true}
                                        isSearchable = {true}
                                        // isDisabled  = {disabledCustomTimeZonesCompareWith}
                                        placeholder={selectLabel}
                                        options = {availableCameras}
                                        className = 'react-select-container'
                                        classNamePrefix = "react-select"
                                        value = {selectedCamera}
                                        onChange = {handleCameraChange}
                                        // onChange = {e => setSelectedCluster(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                {` (${availableCameras.length}) available cameras`}
                            </div>
                        </div>
                    </Form.Group>
                </div>

                <Row>
                    <Col md={7}>
                        <div className="map-block imagesList row" style={{ overflow: 'auto', height: '620px' }}>
                            {video
                            ? (
                                <ReactPlayer
                                    url={video.m3u8}
                                    controls={true}
                                    width='100%'
                                    height='100%'
                                    playing={true}
                                />
                            ) : ( 
                                <div className="no-floor-image">
                                    {ClusterNotHaveImagesTitle}
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="map-block drop-target-map">
                            {selectedFloorItem && selectedFloorItem.floor_image 
                            ? (
                                <div>
                                    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1201 1309">
                                        <g fill="transparent" stroke="#19AB27" strokeWidth={2} fillOpacity="0.5" strokeOpacity="1" strokeDasharray="6,3" r={3}>
                                            {polysCameras}
                                        </g>
                                        <g fill="transparent" stroke="#475ED1" strokeWidth={4} fillOpacity="0.5" strokeOpacity="1" r={5}>
                                            {polysSelectedCamera}
                                        </g>
                                    </svg>
                                    <img className="drop-image-map" src={selectedFloorItem.floor_image} alt="" style={{ width: '100%' }} />
                                    <div
                                        id="camera-polygon-tooltip"
                                        role="tooltip"
                                        className={`fade remove-marker-tooltip tooltip ${showCameraPolygonTooltip && 'show'}`}
                                        style={{ position: 'fixed' }}
                                        // onMouseOver={ () => this.setState({ showCameraPolygonTooltip: true })}
                                        // onMouseOut={ () => this.setState({ showCameraPolygonTooltip: false })}
                                    >
                                        <div className="tooltip-inner">
                                            {selectedCamerasPolygonName}
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className="no-floor-image">
                                    {MallNotHaveImageTitle}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DebugToolComponent
