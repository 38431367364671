import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {toastr} from "react-redux-toastr";
import KeyboardEventHandler from "react-keyboard-event-handler";

import ChangeLanguageButton from "./ChangeLanguageButton";

const queryString = require('query-string');

class ResetPassword extends Component{
    state={
        password: "",
        repeatPassword: "",
        token: null
    };

    componentDidMount(){
        const token = queryString.parse(this.props.location.hash).token;

        if (token) {
            this.setState({token})
        }
    }

    changePassword(){
        const { password, repeatPassword, token } = this.state;
        const { resetPassword, history, language } = this.props;
        const { attentionTitle, enterAllFieldMessage, newPasswordValidationMessage } = language.notifications;

        if (password.trim() === "" || repeatPassword.trim() === "") {
            toastr.info(attentionTitle, enterAllFieldMessage);
        } else {
            if (password === repeatPassword) {
                resetPassword({token, password});
                history.push("/");
            } else {
                toastr.info(attentionTitle, newPasswordValidationMessage);
            }
        }
    }

    render(){
        const {password, repeatPassword} = this.state;
        const { history, language } = this.props;
        const {
            changePasswordTitle,
            newPasswordTitle,
            repeatPasswordTitle,
            changeTitle,
        } = language.unauthorizedSection;

        return(
            <>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => history.push("/login")}/>
                <KeyboardEventHandler handleEventType="keyup" handleKeys={['enter']} onKeyEvent={() => this.changePassword()}/>
                <div className="main_block">
                    <Form className="log_in_block">
                        <ChangeLanguageButton />

                        <Link className="close_btn" to="/login"/>

                        <h3>{changePasswordTitle}</h3>

                        <Form.Group>
                            <Form.Label>{newPasswordTitle}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={newPasswordTitle}
                                value={password}
                                onKeyPress={(e) => e.key === "Enter" && this.changePassword()}
                                onChange={(e) => this.setState({password: e.target.value})}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{repeatPasswordTitle}</Form.Label>
                            <Form.Control
                                type="password"
                                value={repeatPassword}
                                placeholder={repeatPasswordTitle}
                                onKeyPress={(e) => e.key === "Enter" && this.changePassword()}
                                onChange={(e) => this.setState({repeatPassword: e.target.value})}/>
                        </Form.Group>

                        <Button variant="outline-success"
                                onClick={() => this.changePassword()}
                                className="submit">
                            {changeTitle}
                        </Button>
                    </Form>
                </div>
            </>
        )
    }
}

export default withRouter(ResetPassword)
