import {Chart} from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'; //lib for charts labels

Chart.pluginService.register({
    afterInit: function (chart) {
        if (chart.options.increaseLegendHeight) {
            chart.legend.afterFit = function() {
                this.height = this.height + chart.options.increaseLegendHeight;
            };
        }
    }
});
