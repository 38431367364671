import languages, { languagesList } from '../lang';

const languageCode = localStorage.getItem('languageCode') || navigator.language || navigator.userLanguage;

const initialLanguageCode = (languageCode && languagesList.map(({code}) => code).includes(languageCode))
    ? languageCode
    : 'en';

const language = (state = languages[initialLanguageCode], { type, payload }) => {
    switch (type) {
        case 'SET_LANGUAGE': {
            const {
                title,
                code,
                direction,
                data,
            } = languages[payload];

            return {
                title,
                code,
                direction,
                data,
            };
        }
        default:
            return state;
    }
};

export default language;
