import {connect} from 'react-redux';
import Event from '../components/Event';
import {withRouter} from 'react-router-dom';
import {chooseEvent, setEditMode, setVisibilityAddForm, switchEvent, switchEventsComparison, switchEventsSelectList} from '../actions/filters';


const mapStateToProps = (state, ownProps) => {
    const {
        filters,
        dashboardData,
        language,
    } = state;
    return {
        showEvent: filters.showEvent,
        chosenEvent: ownProps.chosenEvent ? ownProps.chosenEvent : dashboardData.chosenEvent,
        eventsListLength: dashboardData.eventsList.length,
        editMode: filters.editMode,
        showEventsComparison: filters.showEventsComparison,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchEvent: (state) => dispatch(switchEvent(state)),
        switchEventsSelectList: (state) => dispatch(switchEventsSelectList(state)),
        switchEventsComparison: (state) => dispatch(switchEventsComparison(state)),
        chooseEvent: (event) => dispatch(chooseEvent(event)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
    };
};
const EventContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Event));


export default EventContainer;
