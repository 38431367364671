import React, {Component} from 'react';
import {Button, Form} from "react-bootstrap";
import moment from 'moment'
import DatePicker from "react-datepicker/es";

import KeyboardEventHandler from "react-keyboard-event-handler";
import readImage from "../helpers/readImage";

import "../styles/Profile.sass"
import "../styles/Form.sass"


class ProfileComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            id:"",
            birthday: new Date(),
        };

        this.changeBirthday = this.changeBirthday.bind(this);

        this.file = React.createRef();
        this.modal = React.createRef();
    }

    changeBirthday(date) {
        this.setState({birthday: date})
    }

    switchEditMode(User){
        const { setForm } = this.props;
        setForm(true, true);
        this.setState({
            email: User.email,
            first_name: User.first_name,
            last_name: User.last_name,
            id: User.id,
            phone_number: User.phone_number,
            birthday: new Date(User.birthday),
            main_image: User.main_image,
        });
    }

    editData(){
        const { id, birthday, main_image, ...request } = this.state;
        const { setForm, editUser } = this.props;
        if (typeof main_image === "object" && main_image !== null){
            request.main_image = main_image
        }else if(main_image === ""){
            request.main_image = ""
        }
        editUser(id, {birthday: moment(birthday).format("YYYY-MM-DD"), ...request});
        setForm(false, false);
        this.setState({
            email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            main_image: "",
        });
        this.file.current.value = "";
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.props.setForm(false, false);
        }
    };

    componentDidUpdate(prevProps){
        if (this.props.openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount(){
        this.props.setForm(false, false)
    }

    render(){
        const { user, openAddForm, editMode, setForm, language } = this.props;
        const { first_name, last_name, phone_number, email, birthday, main_image } = this.state;
        const { data, code } = language;
        const { profilePage, columns } = data;
        const {
            mainTitle,
            editUserTitle,
            profileTitle,
        } = profilePage;
        const {
            nameLabel,
            surnameLabel,
            phoneNumberLabel,
            emailLabel,
            birthdayLabel,
            mallLabel,
            roleLabel,
        } = columns;

        return (
            <div className={`content prof ${(openAddForm && editMode) ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => setForm(false, false)}/>

                <div className={`centralise ${(openAddForm && editMode) ? "open": ""}`}>
                    <Form className="add_new_data open" ref={this.modal}>
                        <h3>{mainTitle}</h3>

                        <button className="close_btn"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setForm(false, false)}
                                }/>

                        <div className="main_data mb-3">
                            <div className="main_info">
                                <div className="prof_wrap">
                                    <Form.Group>
                                        <Form.Label>{nameLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={nameLabel}
                                            value={first_name}
                                            onKeyPress={(e) => e.key === "Enter" && this.editData()}
                                            onChange={e => this.setState({first_name: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{surnameLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={surnameLabel}
                                            value={last_name}
                                            onKeyPress={(e) => e.key === "Enter" && this.editData()}
                                            onChange={e => this.setState({last_name: e.target.value})}/>
                                    </Form.Group>
                                </div>

                                <div className="prof_wrap">
                                    <Form.Group>
                                        <Form.Label>{phoneNumberLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={phoneNumberLabel}
                                            value={phone_number}
                                            onKeyPress={(e) => e.key === "Enter" && this.editData()}
                                            onChange={e => this.setState({phone_number: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{emailLabel}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder={emailLabel}
                                            value={email}
                                            onKeyPress={(e) => e.key === "Enter" && this.editData()}
                                            onChange={e => this.setState({email: e.target.value})}/>
                                    </Form.Group>
                                </div>

                                <Form.Group>
                                    <Form.Label>{birthdayLabel}</Form.Label>
                                    <DatePicker
                                        selected={birthday}
                                        onChange={this.changeBirthday}
                                        dateFormat="MMMM d, yyyy"
                                        locale={code}
                                    />
                                </Form.Group>
                            </div>

                            <div className="avatar_block">
                                <button className="close_btn"
                                        type="button"
                                        onClick={() => {
                                            this.file.current.value = "";
                                            this.setState({main_image: ""})}
                                        }/>

                                <img className="photo"
                                     id="profile_image"
                                     src={`${main_image ? main_image : "/images/profile.png"}`}
                                     alt="Avatar"/>

                                <Form.Group>
                                    <Form.Label htmlFor="profileFile" className="labelForPhoto">
                                        <img src="/images/plus_green.svg" alt="plus"/>
                                    </Form.Label>

                                    <Form.Control
                                        type="file"
                                        id="profileFile"
                                        name="file"
                                        className="inputfile"
                                        ref={this.file}
                                        onChange={e => {
                                            readImage("profileFile","profile_image");
                                            this.setState({main_image: e.target.files[0]})
                                        }}/>
                                </Form.Group>
                            </div>
                        </div>

                        <Button variant="outline-success"
                                className="submit"
                                onClick={() => this.editData()}
                        >{editUserTitle}</Button>
                    </Form>
                </div>

                <div className="profile">
                    <div className="head">{profileTitle}</div>
                    <div className="main">
                        <button className="edit" onClick={() => this.switchEditMode(user)}></button>

                        <div className="avatar">
                            <img src={`${user.main_image ? user.main_image : "/images/profile.png"}`} alt="Avatar"/>
                        </div>

                        <table>
                            <tbody>
                            <tr>
                                <td>{nameLabel}</td>
                                <td>{user.first_name}</td>
                            </tr>
                            <tr>
                                <td>{surnameLabel}</td>
                                <td>{user.last_name}</td>
                            </tr>
                            <tr>
                                <td>{emailLabel}</td>
                                <td>{user.email}</td>
                            </tr>
                            <tr>
                                <td>{birthdayLabel}</td>
                                <td>{user.birthday.slice(5, 16)}</td>
                            </tr>
                            <tr>
                                <td>{phoneNumberLabel}</td>
                                <td>{user.phone_number}</td>
                            </tr>
                            <tr>
                                <td>{mallLabel}</td>
                                <td>{user.malls_name}</td>
                            </tr>
                            <tr>
                                <td>{roleLabel}</td>
                                <td>{user.roles}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileComponent
