import momentEn from "moment/locale/en-gb";
import momentHe from "moment/locale/he";
import momentRu from "moment/locale/ru";
import {
    enGB as reactDateRangeEnLocale,
    he as reactDateRangeHeLocale,
    ru as reactDateRangeRuLocale,
} from 'react-date-range/dist/locale';
import reactDatePickerEnLocale from "date-fns/locale/en-GB"
import reactDatePickerHeLocale from "date-fns/locale/he"
import reactDatePickerRuLocale from "date-fns/locale/ru"

import langHe from "./he";
import langEn from "./en";
import langRu from "./ru";

import checkObjectsHaveSameKeys from "../helpers/checkObjectsHaveSameKeys";

const languages = {
    'en': {
        title: 'English',
        code: 'en',
        direction: 'ltr',
        data: langEn,
        momentLocale: momentEn,
        reactDateRangeLocale: reactDateRangeEnLocale,
        reactDatePickerLocale: reactDatePickerEnLocale,
    },
    'he': {
        title: 'עִברִית',
        code: 'he',
        direction: 'rtl',
        data: langHe,
        momentLocale: momentHe,
        reactDateRangeLocale: reactDateRangeHeLocale,
        reactDatePickerLocale: reactDatePickerHeLocale,
    },
    'ru': {
        title: 'Русский',
        code: 'ru',
        direction: 'ltr',
        data: langRu,
        momentLocale: momentRu,
        reactDateRangeLocale: reactDateRangeRuLocale,
        reactDatePickerLocale: reactDatePickerRuLocale,
    },
};

export const languagesList = Object.values(languages);

export default languages

if (process.env.REACT_APP_NODE_ENV === "development") {
    try {
        checkObjectsHaveSameKeys(languagesList.map(({ data }) => data), true);
    } catch (e) {
        throw Error(`Dictionaries error, ${e.message}`);
    }
}
