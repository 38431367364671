import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CamersAdminComponent from '../../components/admin/CamersAdminComponent'
import {editCamera, complexRequest, getCameraSnapshot} from "../../actions/apiRequests";
import {setEditMode, setVisibilityAddForm} from "../../actions/filters";


const mapStateToProps = ({ adminData, filters, dashboardData, language }) => {
    return {
        malls: adminData.malls,
        cameras: adminData.cameras,
        shops: adminData.shops,
        camerasMapPolygons: adminData.camerasMapPolygons,
        chosenMall: dashboardData.chosenMall,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        role: filters.role,
        language: language.data,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editCamera: (id , data) => dispatch(editCamera(id , data)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getCameraSnapshot: (payload) => dispatch(getCameraSnapshot(payload)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    }
};

const CamerasAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(CamersAdminComponent));

export default CamerasAdminContainer
