const defaultState = {
    birthday: '',
    email: '',
    first_name: '',
    last_name: '',
    malls_name: '',
    phone_number: '',
    main_image: '',
};


const user = (state = defaultState, action) =>{
    switch (action.type) {
        case 'LOG_IN_FULFILLED': {
            return action.payload.rejected ? state : action.payload
        }
        case 'GET_CURRENT_SESSION_FULFILLED': {
            return action.payload.rejected ? state : action.payload.user
        }
        case 'EDIT_USER_FULFILLED': {
            if (state.id === action.payload.id) {
                return Object.assign({}, state, {
                    ...action.payload
                })
            }
            return state
        }
        case 'LOGGED_OUT_FULFILLED': {
            return defaultState
        }
        default:
            return state;
    }
};

export default  user;