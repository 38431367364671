import React, { useState, useEffect } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { MDBDataTable } from 'mdbreact'
import { isEmpty, range, map } from 'lodash'
import KeyboardEventHandler from "react-keyboard-event-handler"
import { toastr } from "react-redux-toastr"

import ZonesFormContainer from "./ZonesFormContainer"
import queryOptionsParser from "../../helpers/queryOptionsParser"

import "./ZonesAdminComponent.sass"

const ZonesAdminComponent = (props) => {
    const DEFAULT_CHOSEN_MALL = { id: '', name: 'all' }
    const {
        user: {roles, malls_id},
        zones,
        malls,
        role,
        openAddForm,
        language,
        setForm,
        setRemovedZone,
        complexRequest,
        chosenMallDashboard,
        chooseZone,
        deleteZones,
    } = props;

    const [chosenMallName, setСhosenMallName] = useState(DEFAULT_CHOSEN_MALL)
    const [selectedFloor, setSelectedFloor] = useState('all')
    // const [chosenZone, setChosenZone] = useState('')
    const [zonesCheckedList, setZonesCheckedList] = useState([])

    const {
        notifications,
        genericLabels,
        adminSection,
        columns,
    } = language;

    const {
        needChooseStoreMessage,
        deleteActionConfirmationMessage,
    } = notifications;

    const {
        mainTitle,
        addNewZoneTitle,
    } = adminSection.zonesPage;

    const {
        checkAllTitle,
        uncheckAllTitle,
        allMallButtonTitle,
        allTitle,
        allFloorsTitle,
        floorTitle,
        deleteSelectedTitle,
        tableCountPaginationLabels,
        searchFormPlaceholder,
    } = genericLabels;
    const {
        nameLabel,
        floorLabel,
        engagementLabel,
        descriptionLabel,
    } = columns;

    const isAllSelected = zonesCheckedList.length;

    const tableOptions = {
        columns: [
            {
                label: <input
                    type="checkbox"
                    className="customCheckbox comparison-checkbox"
                    onChange={() => isAllSelected ? onZonesUncheckAll() : onZonesCheckAll()}
                    checked={isAllSelected}
                    title={isAllSelected ? uncheckAllTitle : checkAllTitle}
                />,
                field: 'check',
                minimal: 'comparison none_sort',
                sort: 'disabled',
                width: 100
            },
            {
                label: nameLabel,
                field: 'name',
                sort: 'asc',
            },
            {
                label: floorLabel,
                field: 'floor_number',
                sort: 'asc',
            },
            {
                label: engagementLabel,
                field: 'engagement',
                sort: 'asc',
            },
            {
                label: descriptionLabel,
                field: 'description',
                sort: 'asc',
                minimal: 'lg'
            },
        ],

        rows: (!isEmpty(zones) && (!isEmpty(malls) || roles === "manager"))
            ?
                zones
                .filter((item) => { if (chosenMallName.id) { return item.mall_id === chosenMallName.id }  return item })
                .filter((item) => { if (selectedFloor !== 'all') { return item.floor_number === selectedFloor }  return item })
                .map((item) => {
                    const { id, name, description, floor_number, engagement /*, mall_id*/ } = item

                    return {
                        check: <div className="form-group">
                            <input
                                label=""
                                type="checkbox"
                                className="customCheckbox"
                                onChange={e => onZoneCheck(item, e)}
                                checked={!isEmpty(zonesCheckedList.filter((item) => item.id === id))}
                            />
                        </div>,
                        name: name,
                        floor_number: floor_number ? floor_number : "",
                        engagement: engagement ? engagement : "",
                        description: description  ? description : "",
                        clickEvent: (e) => switchEditMode(e, item)
                    }
                })
            : []
    }

    // console.log('ZonesAdminComponent props: ', props)
    // console.log('ZonesAdminComponent states chosenMallName, selectedFloor, zonesCheckedList: ', chosenMallName, selectedFloor, zonesCheckedList)

    useEffect(() => {
        // console.log('!!!!!!!!!!!!!!!!useEffect: ')
        if (!isEmpty(chosenMallDashboard)) {
            setСhosenMallName(chosenMallDashboard)
        }

        const zoneOptions = {}
        if (roles) {
            if (roles === "manager" && malls_id) {
                zoneOptions.mall_id = malls_id
            }

            complexRequest([
                    { name: "zones", method: "get", type: `zones${queryOptionsParser(zoneOptions)}` },
                    { name: "departments", method: "get", type: `byte2story/departments` },
                    { name: "subdepartments", method: "get", type: `byte2story/subdepartments` },
                    { name: "deepdepartments", method: "get", type: `byte2story/deepdepartments` },
                ],
                "GET_ZONES"
            )
        }
    }, [chosenMallDashboard]);

    const onZoneCheck = (zone, e) => {
        if (e.target.checked) {
            setZonesCheckedList([ ...zonesCheckedList, zone ])
        } else {
            setZonesCheckedList(zonesCheckedList.filter(({id}) => zone.id !== id))
        }
    }

    const onZonesCheckAll = () => {
        setZonesCheckedList([ ...zones ])
    }

    const onZonesUncheckAll = () => {
        setZonesCheckedList([])
    }

    const switchEditMode = (e, zone) => {
        if ( e.target.type &&  e.target.type === 'checkbox' ) {
            return
        }

        setForm(true, true)
        chooseZone(zone)
        // setChosenZone(zone)
    }

    const handleChangeForm = () => {
        if (!chosenMallName.id) {
            toastr.info(needChooseStoreMessage)
        } else {
            setForm(!openAddForm, false)
        }
    }

    const handleZonesDelete = () => {
        toastr.confirm(
            deleteActionConfirmationMessage,
            {onOk: () => {
                const listId = map(zonesCheckedList, 'id').join(',')
                deleteZones(listId)
                setRemovedZone(listId)
                setZonesCheckedList([])
            }}
        )
    }


    let floorsAmount = 4
    if (chosenMallName.name !== 'all') {
        let obj
        if (roles === 'manager') {
            obj = chosenMallName
        } else {
            obj = malls.find(({name}) => name === chosenMallName.name)
        }

        if (obj && obj.floors_amount) {
            floorsAmount = obj.floors_amount + 1
        } else {
            floorsAmount = 1
        }
    }

    return (
        <div className={`block_wrapper admin ${openAddForm ? "disabled" : ""}`}>
            <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={handleChangeForm}/>

            <div className="wrap_header">
                <h2 className="page_title">{mainTitle}</h2>

                {role === "admin"
                && (
                    <Dropdown className="dropdown_btn">
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                            {chosenMallName.name === "all" ? allMallButtonTitle : chosenMallName.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setСhosenMallName(DEFAULT_CHOSEN_MALL)
                                    setSelectedFloor('all')
                                }}
                            >
                                {allTitle}
                            </Dropdown.Item>

                            {malls.map((item) =>
                                <Dropdown.Item
                                    key={item.id}
                                    onClick={() => {
                                        setСhosenMallName(item)
                                        setSelectedFloor('all')
                                    }}
                                >
                                    {item.name}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                )}

                <Dropdown className="dropdown_btn">
                    <Dropdown.Toggle id="dropdown-basic">
                        {selectedFloor === "all" ? allFloorsTitle : `${floorTitle} ${selectedFloor}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setSelectedFloor('all')}
                        >
                            {allTitle}
                        </Dropdown.Item>

                        {
                            range(1, floorsAmount).map((number) =>
                                <Dropdown.Item
                                    key={number}
                                    onClick={() => setSelectedFloor(number)}
                                    // onClick={() => this.setState({shop: {...shop, floor_number: it}})}
                                >
                                    {number}
                                </Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>

                <Button
                    variant="outline-danger"
                    className={`btnDelete btn_new_item ${!zonesCheckedList.length && 'btn-disabled'}`}
                    disabled={!zonesCheckedList.length}
                    onClick={() => zonesCheckedList.length && handleZonesDelete()}
                >
                    {deleteSelectedTitle}
                </Button>

                <Button
                    className="btn_new_item"
                    variant="outline-dark"
                    onClick={handleChangeForm}
                >
                    {addNewZoneTitle}
                </Button>

                {openAddForm
                && (
                    <div className="centralise open">
                        <ZonesFormContainer
                            mallId={chosenMallName.id}
                            // zoneId={chosenZone.id}
                        />
                    </div>
                )}
            </div>

            <MDBDataTable
                className="data_table"
                striped
                data={tableOptions}
                infoLabel={tableCountPaginationLabels}
                searchLabel={searchFormPlaceholder}
            />
        </div>
    )
}

export default ZonesAdminComponent
