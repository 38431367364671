import { connect } from 'react-redux'
import DashboardCalendarCompare from './DashboardCalendarCompare'
import {
    setTimerange,
    setDaterange,
    removeVisitorsComparisonData,
    setVisibilityVisitorsDetailData,
    setSelectedComparisonDates,
} from '../../actions/filters'
import { complexRequest } from "../../actions/apiRequests"

const mapStateToProps = ({ dashboardData, filters, adminData, user, language, zoneData }) => {
    return {
        selectedComparisonDates: zoneData.selectedComparisonDates,
        chosenMall: dashboardData.chosenMall,
        daterange: dashboardData.daterange,
        showDetailVisitorsData: filters.showDetailVisitorsData,
        showHeatmap: filters.showHeatmap,
        showHeatmapClustered: filters.showHeatmapClustered,
        language,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedComparisonDates: (state) => dispatch(setSelectedComparisonDates(state)),
        setDaterange: (state) => dispatch(setDaterange(state)),
        setTimerange: (state) => dispatch(setTimerange(state)),
        complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
        removeVisitorsComparisonData:() => dispatch(removeVisitorsComparisonData()),
        setVisibilityVisitorsDetailData:state => dispatch(setVisibilityVisitorsDetailData(state)),
    }
}

const DashboardCalendarCompareContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardCalendarCompare)

export default DashboardCalendarCompareContainer
