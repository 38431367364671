import { connect } from 'react-redux';
import Header from '../components/Header';
import { withRouter } from 'react-router-dom';
import { logout } from "../actions/apiRequests";
import { deleteNotification, setLanguage } from "../actions/filters";


const mapStateToProps = ({ user, notifications, filters, language }) => {
    return {
        user,
        role: filters.role,
        notifications,
        isOpenSidebarMenu: filters.isOpenSidebarMenu,
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        deleteNotification: id => dispatch(deleteNotification(id)),
        setLanguage: langCode => dispatch(setLanguage(langCode)),
    };
};

const HeaderContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));


export default HeaderContainer;
