export default function tableSort (data, key, sortable, forward){
    switch (sortable) {
        case "attr":{
            return data.sort(((a, b) => {
                const x = a[key] === "entrance";
                const y = b[key] === "entrance";

                if (forward){
                    return Number(x) - Number(y)
                }else {
                    return Number(y) - Number(x)
                }
            }))
        }
        case "numeric":{
            return data.sort((a, b) => {
                const x = a[key] || 0;
                const y = b[key] || 0;
                if (forward){
                    return  y - x
                }else {
                    return  x - y
                }
            });
        }
        case "cam_name":{
            const regex = /[+-]?\d+(?:\.\d+)?/g;

            return data.sort((a, b) => {
                const x = parseInt(a[key].match(regex)[0]) || 0;
                const y = parseInt(b[key].match(regex)[0]) || 0;
                if (forward){
                    return  y - x
                }else {
                    return  x - y
                }
            });
        }
        default: {
            return data.sort((a,b)=>{
                const x = a[key] || "";
                const y = b[key] || "";
                if (forward){
                    return x.localeCompare(y)
                }else {
                    return y.localeCompare(x)
                }
            });
        }
    }
}