import Cookies from "js-cookie"

const defaultState = {
    role: Cookies.get("role"),
    openAddForm: false,
    editMode: false,
    isLoggedIn: !!Cookies.getJSON("isLoggedIn"),
    isOpenSidebarMenu: !localStorage.getItem('isHideSidebarMenu'),
    isDateRangeConsistTodayDate: false,
    showEvent: false,
    showEventsSelectList: false,
    showStoresSelectList: false,
    showShop: false,
    showPopover: false,
    newUser: false,
    showTopShopPopover: false,
    coordsTopShopPopover: {left:0, top:0},
    showShopComparison: false,
    showZoneComparison: false,
    showEventsComparison: false,
    showCamerasMap: false,
    showHeatmap: false,
    showHeatmapClustered: false,
    showDetailVisitorsData: false,
    mallImageDimensions: {width: 1201, height: 1309},
};

const filters = (state = defaultState, action) =>{
    switch (action.type) {
        case 'SET_VISIBILITY_ADD_FORM': {
            return Object.assign({}, state, {
                openAddForm: action.payload
            });
        }
        case 'SET_EDIT_MODE': {
            return Object.assign({}, state, {
                editMode: action.payload
            });
        }
        case 'LOG_IN_FULFILLED': {
            return Object.assign({}, state, {
                role: action.payload.rejected ? state.role : action.payload.roles,
                isLoggedIn: !action.payload.rejected
            });
        }
        case 'GET_CURRENT_SESSION_FULFILLED': {
            return Object.assign({}, state, {
                role: action.payload.rejected ? state.role : action.payload.user.roles,
                isLoggedIn: action.payload.rejected ? false : state.isLoggedIn
            });
        }
        case 'LOGGED_OUT_FULFILLED': {
            return Object.assign({}, state, {
                isLoggedIn: action.payload ? false : state.isLoggedIn,
                role: Cookies.get("role"),
                newUser: false
            });
        }
        case 'SET_VISIBILITY_SIDEBAR_MENU': {
            return Object.assign({}, state, {
                isOpenSidebarMenu: action.payload,
            });
        }
        case 'SET_EVENT_FULFILLED':
        case 'SHOW_EVENT': {
            return Object.assign({}, state, {
                showEvent: action.payload
            });
        }
        case 'SHOW_EVENTS_SELECT_LIST': {
            return Object.assign({}, state, {
                showEventsSelectList: action.payload
            });
        }
        case 'SHOW_STORES_SELECT_LIST': {
            return Object.assign({}, state, {
                showStoresSelectList: action.payload
            });
        }
        case 'CHOOSE_SHOP_FULFILLED': {
            return Object.assign({}, state, {
                showShop: true
            });
        }
        case 'SHOW_SHOP': {
            return Object.assign({}, state, {
                showShop: action.payload
            });
        }
        case 'SHOW_POPOVER': {
            return Object.assign({}, state, {
                showPopover: action.payload
            });
        }
        case 'SHOW_TOP_SHOP_POPOVER': {
            return Object.assign({}, state, {
                showTopShopPopover: action.payload
            })
        }
        case 'SHOW_SHOP_COMPARISON': {
            return Object.assign({}, state, {
                showShopComparison: action.payload
            })
        }
        case 'SHOW_ZONE_COMPARISON': {
            return Object.assign({}, state, {
                showZoneComparison: action.payload
            })
        }
        case 'SHOW_EVENTS_COMPARISON': {
            return Object.assign({}, state, {
                showEventsComparison: action.payload
            })
        }
        case 'SHOW_SWAP_STORE': {
            return Object.assign({}, state, {
                showSwapStore: action.payload
            })
        }
        case 'SET_COORDS_TOP_SHOP_POPOVER': {
            return Object.assign({}, state, {
                coordsTopShopPopover: action.payload
            })
        }
        case 'NEW_USER': {
            return Object.assign({}, state, {
                newUser: action.payload
            });
        }
        case 'SHOW_CAMERAS_MAP': {
            return Object.assign({}, state, {
                showCamerasMap: action.payload
            })
        }
        case 'SET_VISIBILITY_HEATMAP': {
            return Object.assign({}, state, {
                showHeatmap: action.payload,
                showHeatmapClustered: false,
            });
        }
        case 'SET_VISIBILITY_HEATMAP_CLUSTERED': {
            return Object.assign({}, state, {
                showHeatmapClustered: action.payload,
            });
        }
        case 'SET_CURRENT_DATE_RANGE_FLAG': {
            return Object.assign({}, state, {
                isDateRangeConsistTodayDate: action.payload
            });
        }
        case 'GET_VISITORS_DETAIL_DATA_FULFILLED': {
            return Object.assign({}, state, {
                showDetailVisitorsData: true
            });
        }
        case 'SET_VISIBILITY_VISITORS_DETAIL_DATA': {
            return Object.assign({}, state, {
                showDetailVisitorsData: action.payload
            });
        }
        case 'SET_MALL_IMAGE_DIMENSIONS': {
            return Object.assign({}, state, {
                mallImageDimensions: action.payload
            })
        }

        default:
            return state;
    }
};

export default  filters;
