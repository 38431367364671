import {connect} from 'react-redux';
import ProfileComponent from '../components/ProfileComponent';
import {editUser} from "../actions/apiRequests";
import {setEditMode, setVisibilityAddForm} from "../actions/filters";


const mapStateToProps = state => {
    const { user, filters, language } = state;

    return {
        user: user,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editUser: (id, data) => dispatch(editUser(id, data)),
        setForm: (form, editMode) => {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
    };
};
const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);


export default ProfileContainer;
