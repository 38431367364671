import {connect} from 'react-redux'
import CamerasMapComponent from '../../components/admin/CamerasMapComponent'
import {switchCamerasMap} from "../../actions/filters"


const mapStateToProps = ({ language }) => {
    return {
        language: language.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        switchCamerasMap: (state) => dispatch(switchCamerasMap(state)),
    }
}

const CamerasMapContainer = connect(mapStateToProps,mapDispatchToProps)(CamerasMapComponent)

export default CamerasMapContainer
