import React, { Fragment, useEffect, useCallback, useState } from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import moment from 'moment';
import {Helmet} from "react-helmet";
import { registerLocale } from "react-datepicker";

import BaseContainer from './containers/BaseContainer';
import SpinnerContainer from './containers/SpinnerContainer';
import StartPageContainer from './containers/StartPageContainer';

import languages from './lang';


const App = ({ isLoggedIn, language }) => {
    const { code: languageCode, direction } = language;
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    // force update date format language when languageCode was changed
    useEffect(() => {
        moment.updateLocale(languageCode, languages[languageCode].momentLocale);
        registerLocale(languageCode, languages[languageCode].reactDatePickerLocale);
        forceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode]);

    return (
        <Switch>
            <Route path="/" render={() => {
                return (
                    <Fragment>
                        <Helmet>
                            <title>{process.env.REACT_APP_PROJECT_TITLE}</title>
                            <link rel="shortcut icon" href={process.env.REACT_APP_FAVICON_HREF}/>
                            <link rel="manifest" href={process.env.REACT_APP_MANIFEST_HREF}/>
                        </Helmet>

                        {isLoggedIn ? <BaseContainer/> : <StartPageContainer/>}
                        <SpinnerContainer/>
                        <ReduxToastr
                            preventDuplicates
                            timeOut={10000}
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            closeOnToastrClick
                            className={direction}
                        />
                    </Fragment>
                )
            }}/>
        </Switch>
    )
};


const mapStateToProps = ({ filters, language }) => {
    return {
        isLoggedIn: filters.isLoggedIn,
        language,
    };
};

export default connect(mapStateToProps)(App);
