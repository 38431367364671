import React,{Component} from 'react';
import {isEmpty} from 'lodash';
import moment from "moment";
import KeyboardEventHandler from "react-keyboard-event-handler";

import EventCard from "./EventCard";
import search from "../helpers/search";

import "../styles/EventsSelectList.sass"

class EventsSelectList extends Component{
    state={
        eventsList: this.props.eventsList
    };

    componentDidUpdate(prevProps){
        const {eventsList, switchEventsSelectList} = this.props;
        if (eventsList !== prevProps.eventsList) {
            this.setState({eventsList: eventsList});
            if(isEmpty(eventsList)){
                switchEventsSelectList(false);
            }
        }
    }

    doSearch = value =>{
        const { eventsList } = this.props;
        if (value.trim() === ""){
            this.setState({eventsList: eventsList})
        } else {
            this.setState({eventsList: search(value, eventsList,false)})
        }
    };

    chooseEvent = event => {
        const {chooseEvent, switchEvent, switchEventsSelectList} = this.props;
        switchEventsSelectList(false);
        switchEvent(true);

        const {start_datetime, end_datetime, shop_id} = event;
        const eventOptions = {
            start_ts: moment(start_datetime).utc().format('YYYYMMDDHHmmss'),
            end_ts: moment(end_datetime).utc().format('YYYYMMDDHHmmss'),
            shop_id
        };
        chooseEvent(event, eventOptions)
    };

    close(){
        const {addEventToList, switchEventsSelectList, showEventsSelectList, eventsList} = this.props;
        eventsList.forEach(({id}) => {
            const item = document.getElementById(`comparison_checkbox${id}`);
            item && (item.checked = false)
        });
        if(showEventsSelectList){
            addEventToList(false);
            switchEventsSelectList(false);
        }
    }

    render(){
        const { deleteEventFromList, switchEventsComparison, language } = this.props;
        const { eventsList } = this.state;
        const { eventsPage, genericLabels } = language;
        const { mainTitle, searchEventsPlaceholder } = eventsPage;
        const { selectedTitle } = genericLabels;

        return (
            <div className="events_list-wrap">
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.close()}/>

                <div className="header">
                    <button className="close_btn" type="button" onClick={() => this.close()}/>

                    <div className="wrap_for_amount_of_list">
                        <h3>{eventsList.length}</h3>

                        <div className="selected">
                            <p style={{textTransform: 'uppercase'}}>{selectedTitle}</p>
                            <h6>{mainTitle}</h6>
                        </div>
                    </div>

                    <div className="balance">
                        <button onClick={() => switchEventsComparison(true)}>
                            <img alt="" src="/images/balance.svg"/>
                        </button>
                    </div>
                </div>

                <div className="data">
                    <input className="search_input"
                           onChange={e => this.doSearch(e.target.value)}
                           placeholder={searchEventsPlaceholder}/>

                    <div className="events_wrap scrollbar" id="style-1">
                        {eventsList.map((event) => {
                            return <EventCard
                                key={event.id}
                                event={event}
                                deleteEventFromList={deleteEventFromList}
                                chooseEvent={this.chooseEvent}
                                language={language}
                            />
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default EventsSelectList
