import React, {Component} from 'react';
import {Form, Button, DropdownButton, Dropdown, Row, Col} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmpty, pickBy} from "lodash";
import {toastr} from "react-redux-toastr";
import KeyboardEventHandler from "react-keyboard-event-handler";

import validate from "../helpers/validate";
import readImage from "../helpers/readImage";
import checkCorrectDate from "../helpers/checkCorrectDate";

class EventsFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: {
                startDate: new Date(),
                endDate: new Date(),
                name: "",
                budget: 0,
                description: "",
                shop_id: "",
                id: ""
            }
        };
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);

        this.modal = React.createRef();
    }

    componentDidUpdate(prevProps){
        const {chosenEvent, editMode, switchEvent, openAddForm} = this.props;
        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        if (!isEmpty(chosenEvent) && prevProps.editMode !== editMode && editMode){
            const {start_datetime, end_datetime, ...rest} = chosenEvent;
            switchEvent(false);

            this.setState({
                event: {
                    startDate: new Date(start_datetime),
                    endDate: new Date(end_datetime),
                    ...rest
                }
            });
        }
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    addData(){
        const { startDate, endDate, name, budget, shop_id, ...request} = this.state.event;
        const {
            attentionTitle,
            validationDateMessage,
            warningTitle,
            filledInMessage,
        } = this.props.language.data.notifications;

        if (
            validate({ name, budget }, { warningTitle, filledInMessage })
            &&
            checkCorrectDate(startDate, endDate, { attentionTitle, validationDateMessage })
        ){
            this.props.addEvent(pickBy({
                start_datetime: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
                end_datetime: moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss"),
                name,
                budget,
                shop_id,
                ...request
            }));
            this.handleChangeForm();
        }
    }

    editData(){
        const { startDate, endDate, id, name, budget, shop_id, main_image, ...request } = this.state.event;
        const { setForm, editEvent, language } = this.props;
        const {
            attentionTitle,
            validationDateMessage,
            warningTitle,
            filledInMessage,
        } = language.data.notifications;

        if (
            validate({ name, budget }, { warningTitle, filledInMessage })
            &&
            checkCorrectDate(startDate, endDate, { attentionTitle, validationDateMessage })
        ){
            if (typeof main_image === "object" && main_image !== null){
                request.main_image = main_image
            }else if(main_image === ""){
                request.main_image = ""
            }

            editEvent(id, {
                name,
                budget,
                start_datetime: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
                end_datetime: moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss"),
                shop_id,
                ...request
            });
            setForm(false, false);

            this.handleChangeForm();
        }
    }

    changeStartDate(date) {this.setState({event: {...this.state.event, startDate: date}})}

    changeEndDate(date) {this.setState({event: {...this.state.event, endDate: date}})}

    handleChangeForm(){
        const { setForm, openAddForm, chooseEvent, editMode } = this.props;
        setForm(!openAddForm, false);
        editMode && chooseEvent({});
        this.setState({
            event:{
                name: "",
                budget: 0,
                description: "",
                shop_id: "",
                id: ""
            }
        })
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.handleChangeForm();
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    render(){
        const { event } = this.state;
        const {
            startDate,
            endDate,
            name,
            budget,
            shop_id,
            description,
            id,
            main_image,
        } = event;

        const {
            shops,
            editMode,
            openAddForm,
            deleteEvent,
            language,
        } = this.props;

        const { data, code } = language;
        const {
            eventsPage,
            genericLabels: { deleteButtonTitle },
            notifications: { deleteActionConfirmationMessage },
            columns,
            images: { noImageUrl },
        } = data;

        const {
            createEventButtonTitle,
            editEventTitle,
            registrationEventTitle,
            chooseShopTitle,
        } = eventsPage;
        const {
            eventNameLabel,
            budgetLabel,
            descriptionLabel,
            shopLabel,
            startDateLabel,
            endDateLabel,
        } = columns;

        return (<div className={`centralise ${openAddForm ? "open" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <Form className="add_new_data" ref={this.modal}>
                    <h3 className="mb-4">
                        {editMode ? editEventTitle : registrationEventTitle}
                    </h3>

                    <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/>

                    <div className="main_data" style={{display:'block'}}>
                    <Row>
                        <Col md={8}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>{eventNameLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={eventNameLabel}
                                            value={name}
                                            onKeyDown={this.handleEnter}
                                            onChange={(e) => this.setState({event:{...event, name: e.target.value}})}/>
                                    </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>{budgetLabel}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder={budgetLabel}
                                            value={budget}
                                            onKeyDown={this.handleEnter}
                                            onChange={(e) => this.setState({event:{...event, budget: e.target.value}})}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>{descriptionLabel}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="5"
                                            type="text"
                                            placeholder={descriptionLabel}
                                            value={description}
                                            onChange={(e) => this.setState({event:{...event, description: e.target.value}})}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className="avatar_block" style={{width: '100%'}}>
                                {main_image &&
                                <button
                                    className="close_btn local"
                                    type="button"
                                    onClick={() => {
                                        this.file.current.value = "";
                                        this.setState({shop: {...event, main_image: ""}})
                                    }}
                                />}

                                <img
                                    className="photo"
                                    src={main_image ? main_image : noImageUrl}
                                    onErrorCapture={e => e.target.src = noImageUrl}
                                    id="event_image"
                                    alt="event view"
                                />

                                <Form.Group>
                                    <Form.Label htmlFor="eventFile" className="labelForPhoto">
                                        <img src="/images/plus_green.svg" alt="plus"/>
                                    </Form.Label>

                                    <Form.Control
                                        type="file"
                                        id="eventFile"
                                        name="eventFile"
                                        className="inputfile"
                                        ref={this.file}
                                        onChange={(e) => {
                                            readImage("eventFile", "event_image");
                                            this.setState({event: {...event, main_image: e.target.files[0]}})
                                        }}
                                        placeholder="image"/>
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    </div>

                    <div className="wrap_for_date">
                        <Form.Group>
                            <Form.Label>{shopLabel}</Form.Label>

                            <DropdownButton
                                title={shop_id ? shops.filter(shop => shop.id === shop_id)[0].name : chooseShopTitle}
                                className={`${shops.length > 15 && "scrollDrop"}`}
                                id="chooseShop"
                                variant="outline-dark">
                                {shops.map(({name, id}) =>
                                    <Dropdown.Item
                                        value={name}
                                        key={id}
                                        onClick={() => this.setState({
                                            event: {...event, shop_id: id},
                                            chosenShop: name
                                        })}>
                                        {name}
                                    </Dropdown.Item>)}
                            </DropdownButton>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{startDateLabel}</Form.Label>

                            <DatePicker
                                selected={startDate}
                                onChange={this.changeStartDate}
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="MMMM d, yyyy HH:mm"
                                locale={code}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>{endDateLabel}</Form.Label>

                            <DatePicker
                                selected={endDate}
                                onChange={this.changeEndDate}
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="MMMM d, yyyy HH:mm"
                                locale={code}
                            />
                        </Form.Group>
                    </div>

                    {editMode ?
                        <div className="wrap_for_submit-buttons">
                            <Button variant="outline-success" onClick={() =>this.editData()}>
                                {editEventTitle}
                            </Button>

                            <Button
                                onClick={
                                    () => toastr.confirm(
                                        deleteActionConfirmationMessage,
                                        {onOk: () => deleteEvent(id)},
                                    )
                                }
                                variant="outline-danger">
                                {deleteButtonTitle}
                            </Button>
                        </div>
                        :
                        <Button variant="outline-success" className="submit" onClick={() => this.addData()}>
                            {createEventButtonTitle}
                        </Button>}
                </Form>
            </div>
        )
    }
}

export default EventsFormComponent
