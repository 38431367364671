import React, { Component, Fragment } from 'react'
import { Form, Button, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
// import { toastr } from "react-redux-toastr"
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { isEmpty, pickBy, flatten } from 'lodash'

import validate from "../../helpers/validate"
import queryOptionsParser from "../../helpers/queryOptionsParser"
import Circle from "../../components/Circle"
// import ModalRelationsByte2Story from './ModalRelationsByte2Story'

import "./ZonesAdminComponent.sass"

class EditPolygonTabComponent extends Component {
    constructor(props) {
        super(props);

        this.state ={
            selectedFloor: '',
            zone: {
                name: '',
                description: '',
                floor_number: '',
                engagement: '',
                mall_id: props.mallId,
                id: '',
            },
            scalemap: 1,
            // showPolygonRemoveTooltip: false,
            showPolygonEditTooltip: false,
            showCircleRemoveTooltip: false,
            selectedPolygonIndex: null,
            showCameraPolygonTooltip: false,
            // showRelationsByte2Story: false,
            selectedCamerasPolygonName: '',
            drawingNew: false,
            drawingEdit: false,
            circles: [],
            polygons: [],
            circlesEdit: [],
            circleNew: {},
        };

        this.modal = React.createRef();
    }

    componentDidMount() {
        const { mallId, /*zoneId, getMall,*/ chosenZone, editMode, complexRequest } = this.props;
        const mallParams = {nvr: true, floor: true};

        if (!isEmpty(chosenZone) && editMode) {

            const {mall_id, polygons, ...rest} = chosenZone
            this.setState({
                zone: {
                    mall_id,
                    ...rest
                },
                polygons: polygons ?  JSON.parse(polygons) : [],
            });

            complexRequest(
                [
                    {name: "mall", method: "get", type: `mall/${mall_id}${queryOptionsParser(mallParams)}`},
                    {name: "allpolygons", method: "get", type: `cameras/polygons`},
                    {name: "zonerelations", method: "get", type: `byte2story/zones-relations/${chosenZone.id}`},
                ],
                "GET_MALL_AND_POLYGONS"
            );
        } else if (mallId) { // it is used for the Add New Zone feature to pass selected mall id
            complexRequest(
                [
                    {name: "mall", method: "get", type: `mall/${mallId}${queryOptionsParser(mallParams)}`},
                    {name: "allpolygons", method: "get", type: `cameras/polygons`},
                    // {name: "zonerelations", method: "get", type: `byte2story/zones-relations/${chosenZone.id || 0}`},
                ],
                "GET_MALL_AND_POLYGONS"
            );
        }
    }

    // componentWillUnmount() {
    //     document.removeEventListener('mousedown', this.handleClickOutside);
    //     this.props.setForm(false, false);
    //     this.props.chooseMall({});
    // }

    // componentDidUpdate(prevProps) {
    //     const {chosenMall, editMode} = this.props;

    //     if (prevProps.chosenMall !== chosenMall && !_.isEmpty(chosenMall) && editMode){
    //         const mall = _.cloneDeep(chosenMall);

    //         mall.open_time = new Date(moment(mall.open_time, "HHmmss").format());
    //         mall.close_time = new Date(moment(mall.close_time, "HHmmss").format());

    //         const nvrmodules = mall.nvrs || [],
    //         floors = mall.floors || []

    //         delete mall.nvrs
    //         delete mall.floors

    //         this.setState({ mall, nvrmodules, floors })
    //     }
    // }

    componentDidUpdate(prevProps) {
        const {chosenZone, chosenMall, editMode, openAddForm} = this.props;
        // console.log('!!!componentDidUpdate EditPolygonTabComponent: ', chosenMall, chosenZone, editMode, openAddForm, prevProps, this.props)
        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        // if (!isEmpty(chosenZone) && prevProps.editMode !== editMode && editMode) {
        if (editMode
            && (prevProps.chosenZone !== chosenZone || prevProps.chosenMall !== chosenMall)
        ) {
            const {floor_number, ...rest} = chosenZone
            const selectedFloor = (chosenMall.floors && floor_number)  ? chosenMall.floors.find((item) => item.number === floor_number ) : ''
            this.setState({
                selectedFloor,
                zone: {
                    floor_number,
                    ...rest
                }
            });
        }
    }

    componentWillUnmount() {
        const { setForm } = this.props
        setForm(false, false);
    }

    handleChangeForm = () => {
        const { setForm, openAddForm,  chooseZone, editMode } = this.props;
        setForm(!openAddForm, false)
        editMode && chooseZone({});

        // this.setState({
        //     zone: {
        //         name: '',
        //         description: '',
        //         floor_number: '',
        //         mall_id: '',
        //         id: '',
        //     }
        // })
    }

    // handleRelationsClick = () => {
    //     this.setState({ showRelationsByte2Story: true })
    // }

    // closeRelations = () => {
    //     this.setState({ showRelationsByte2Story: false })
    // }

	// getB2SRelations = () => {
	// 	const { chosenZone, complexRequest } = this.props
    // 	complexRequest(
	// 		[
	// 			{name: "zonerelations", method: "get", type: `byte2story/zone-relations/${chosenZone.id}`},
	// 		],
	// 		"GET_B2S_ZONE_RELATIONS"
	// 	)
	// }

    handleClickOutside = (event) => {
        const { showRelationsByte2Story } = this.state
        if (this.modal.current && !this.modal.current.contains(event.target) && !showRelationsByte2Story) {
            this.handleChangeForm();
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    }

    clearData = () => {
        const { mallId } =  this.props

        this.setState({
            selectedFloor: '',
            zone: {
                name: '',
                description: '',
                floor_number: '',
                engagement: '',
                mall_id: mallId,
                id: '',
            },
            scalemap: 1,
            // showPolygonRemoveTooltip: false,
            showPolygonEditTooltip: false,
            showCircleRemoveTooltip: false,
            selectedPolygonIndex: null,
            showCameraPolygonTooltip: false,
            selectedCamerasPolygonName: '',
            drawingNew: false,
            drawingEdit: false,
            circles: [],
            polygons: [],
            circlesEdit: [],
            circleNew: {},
        })
    }

    addData = (e, createNew=false) => {
        e.stopPropagation()
        const { zone, polygons, drawingEdit, circlesEdit } = this.state;
        const { mall_id, name, floor_number, ...request } = zone;
        const { setForm, addZone, language, chosenMallDashboard, setAddedZone } = this.props;
        const { warningTitle, filledInMessage } = language.notifications;

        if (drawingEdit) {
            const arr = []
            circlesEdit.forEach((item) => arr.push(item.x, item.y))
            polygons.push(arr)
        }

        if (validate({ mall_id, name, floor_number }, { warningTitle, filledInMessage })){
            // addZone(pickBy({ mall_id, name, floor_number, ...request, polygons: JSON.stringify(map(polygons, 'screen_coords')), }))
            addZone(
                pickBy({ mall_id, name, floor_number, ...request, polygons: JSON.stringify(polygons), }),
                (addedZone) => { if (chosenMallDashboard.id === mall_id) {setAddedZone(addedZone)} }
            )


            if (createNew) {
                this.clearData()
            } else {
                setForm(false, false);
            }
        }
    }

    editData = (e, createNew=false) => {
        e.stopPropagation()
        const { zone, polygons, drawingEdit, circlesEdit } = this.state;
        const { id, mall_id, name, floor_number, ...request } = zone;
        const { setForm, editZone, language, chosenMallDashboard, setEditedZone } = this.props;
        const { warningTitle, filledInMessage } = language.notifications;

        if (drawingEdit) {
            const arr = []
            circlesEdit.forEach((item) => arr.push(item.x, item.y))
            polygons.push(arr)
        }

        if (validate({ id, mall_id, name, floor_number }, { warningTitle, filledInMessage })) {
            // editZone(pickBy({ mall_id, name, floor_number, ...request }))
            editZone(
                id,
                {
                    name,
                    floor_number,
                    ...request,
                    // polygons: JSON.stringify(map(polygons, 'screen_coords')),
                    polygons: JSON.stringify(polygons),
                },
                (editedZone) => { if (chosenMallDashboard.id === mall_id) {setEditedZone(editedZone)} }
            );


            if (createNew) {
                this.clearData()
                setForm(true, false);
            } else {
                setForm(false, false);
            }
        }
    }

    handleFormClick = (e) => {
        e.preventDefault()
        const { drawingEdit, circlesEdit, polygons } = this.state

        if (drawingEdit) {
            if (circlesEdit.length > 2) {
                const arr = []
                circlesEdit.forEach((item) => arr.push(item.x, item.y))
                polygons.push(arr)
                this.setState({ polygons, circlesEdit: [], drawingEdit: false });
            }

        // const { polygons } = this.state

        // const arrC = polygons[index].reduce((result, value, index, array) => {
        //     if (index % 2 === 0) {
        //         result.push({x: array.slice(index, index + 1)[0], y: array.slice(index+1, index + 2)[0] }); }
        //     return result;
        // }, []);

        // const arrP = polygons.filter((item, i) => i !== index)

        // this.setState({ drawingEdit: true, circlesEdit: arrC, polygons: arrP })
        }
    }

    handleCamerasPolygonMouseOver = (e, camerasName) => {
        const tooltipSpan = document.getElementById('camera-polygon-tooltip');
        const x = e.clientX
        const y = e.clientY

        tooltipSpan.style.top = (y + 5) + 'px';
        tooltipSpan.style.left = (x + 5) + 'px';

        this.setState({ showCameraPolygonTooltip: true,  selectedCamerasPolygonName: camerasName })
    }

    handleCamerasPolygonMouseOut = (e) => {
        const tooltipSpan = document.getElementById('camera-polygon-tooltip');
        tooltipSpan.style.top = '0';
        tooltipSpan.style.left = '0';


        this.setState({ showCameraPolygonTooltip: false })
    }

    startDrawing = () => {
        const { drawingNew /*, circlesEdit*/ } = this.state
        this.setState({
            // drawing: circlesEdit.length ? false : !drawing,
            drawingNew: !drawingNew,
            circles: [],
            drawingEdit: false,
            circlesEdit: [],
        })
    }

    handleMouseClick = (e) => {
        e.preventDefault()
        const { drawingNew, scalemap, circles } = this.state

        if (drawingNew) {
            const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
            const drawWrapperRect = drawWrapper.getBoundingClientRect()

            circles.push({ x: (e.clientX - drawWrapperRect.x)/scalemap, y: (e.clientY - drawWrapperRect.y)/scalemap })
            this.setState({ circles })
        }
    }

    handleCircleClick = (e, circleCoords) => {
        e.preventDefault();
        e.stopPropagation();

        const { circles, polygons } = this.state

        if (circles.length > 2) {
            const arr = []
            circles.forEach((item) => arr.push(item.x, item.y))

            // polygons.push({ index: polygons.length, screen_coords: arr })
            polygons.push(arr)
            this.setState({ polygons, circles: [], drawingNew: false });
        }
    }

    handlePolygonClick = (e, index) => {
        e.stopPropagation();

        const { polygons, drawingEdit, circlesEdit } = this.state

        if (drawingEdit) {
            const arr = []
            circlesEdit.forEach((item) => arr.push(item.x, item.y))
            polygons.push(arr)
        }

        const arrC = polygons[index].reduce((result, value, index, array) => {
            if (index % 2 === 0) {
                result.push({x: array.slice(index, index + 1)[0], y: array.slice(index+1, index + 2)[0] }); }
            return result;
        }, []);

        const arrP = polygons.filter((item, i) => i !== index)

        this.setState({ drawingEdit: true, circlesEdit: arrC, polygons: arrP })
    }

    handleClearPolygon = (e) => {
        e.stopPropagation();

        this.setState({ drawingEdit: false, circlesEdit: [] })
    }

    // handlePolygonMouseOver = (e, index) => {
    //     console.log('handlePolygonMouseOver')
    //     const tooltipSpan = document.getElementById('remove-polygon-tooltip');
    //     const x = e.clientX
    //     const y = e.clientY

    //     tooltipSpan.style.top = (y + 1) + 'px';
    //     tooltipSpan.style.left = (x + 1) + 'px';


    //     const tooltipSpan1 = document.getElementById('edit-polygon-tooltip');
    //     tooltipSpan1.style.top = '0';
    //     tooltipSpan1.style.left = '0';

    //     const tooltipSpan2 = document.getElementById('remove-circle-tooltip');
    //     tooltipSpan2.style.top = '0';
    //     tooltipSpan2.style.left = '0';

    //     this.setState({ showPolygonEditTooltip: false, showCircleRemoveTooltip: false, showPolygonRemoveTooltip: true, selectedPolygonIndex: index })
    // }

    // handlePolygonMouseOut = () => {
    //     console.log('handlePolygonMouseOut')
    //     // const tooltipSpan = document.getElementById('remove-polygon-tooltip');
    //     // tooltipSpan.style.top = '0';
    //     // tooltipSpan.style.left = '0';

    //     // const tooltipSpan1 = document.getElementById('edit-polygon-tooltip');
    //     // tooltipSpan1.style.top = '0';
    //     // tooltipSpan1.style.left = '0';


    //     this.setState({ showPolygonRemoveTooltip: false, showPolygonEditTooltip: false, showCircleRemoveTooltip: false })
    // }

    // handlePolyContextMenu = (e, index) => {
    //     e.preventDefault()
    //     console.log('handlePolyContextMenu')

    //     // const tooltipSpan = document.getElementById('remove-polygon-tooltip');
    //     // tooltipSpan.style.top = '0';
    //     // tooltipSpan.style.left = '0';

    //     const tooltipSpan1 = document.getElementById('edit-polygon-tooltip');
    //     const x = e.clientX
    //     const y = e.clientY
    //     tooltipSpan1.style.top = (y-2) + 'px';
    //     tooltipSpan1.style.left = (x-2) + 'px';

    //     this.setState({ showPolygonRemoveTooltip: false, showPolygonEditTooltip: true, selectedPolygonIndex: index })
    // }

    handleCircleContextMenu = (e, index) => {
        e.preventDefault()

        // const tooltipSpan = document.getElementById('remove-polygon-tooltip');
        // tooltipSpan.style.top = '0';
        // tooltipSpan.style.left = '0';

        const tooltipSpan1 = document.getElementById('remove-circle-tooltip');
        const x = e.clientX
        const y = e.clientY
        tooltipSpan1.style.top = (y-2) + 'px';
        tooltipSpan1.style.left = (x-2) + 'px';

        this.setState({ /*showPolygonRemoveTooltip: false,*/ showCircleRemoveTooltip: true, selectedPolygonIndex: index })
    }

    // handleClickRemovePolygon = (e) => {
    //     const { selectedPolygonIndex, polygons } = this.state

    //     let arr = []
    //     arr = polygons.filter((item, index) => index !== selectedPolygonIndex)
    //     // if (has(selectedPolygonIndex, 'polygon_id')) {
    //     //     arr = polygons.filter((item, index) => item.polygon_id !== selectedPolygonIndex.polygon_id)
    //     // } else if (has(selectedPolygonIndex, 'index')) {
    //     //     arr = polygons.filter((item, index) => item.index !== selectedPolygonIndex.index)
    //     // }

    //     // const tooltipSpan = document.getElementById('remove-polygon-tooltip');
    //     // tooltipSpan.style.top = '0';
    //     // tooltipSpan.style.left = '0';

    //     this.setState({ polygons: arr, showPolygonRemoveTooltip: false })
    // }

    // handleClickEditPolygon = (e) => {
    //     console.log('handleClickEditPolygon')
    //     const { selectedPolygonIndex, polygons } = this.state

    //     const arrC = polygons[selectedPolygonIndex].reduce((result, value, index, array) => {
    //         if (index % 2 === 0) {
    //             result.push({x: array.slice(index, index + 1)[0], y: array.slice(index+1, index + 2)[0] }); }
    //         return result;
    //     }, []);

    //     const arrP = polygons.filter((item, index) => index !== selectedPolygonIndex)

    //     const tooltipSpan = document.getElementById('edit-polygon-tooltip');
    //     tooltipSpan.style.top = '0';
    //     tooltipSpan.style.left = '0';

    //     this.setState({ showPolygonEditTooltip: false, drawingEdit: true, circlesEdit: arrC, polygons: arrP })
    // }

    handleClickRemoveCircle = (e) => {
        e.stopPropagation();
        const { selectedPolygonIndex, circlesEdit } = this.state

        let arr = []
        arr = circlesEdit.filter((item, index) => index !== selectedPolygonIndex)
        // if (has(selectedPolygonIndex, 'polygon_id')) {
        //     arr = polygons.filter((item, index) => item.polygon_id !== selectedPolygonIndex.polygon_id)
        // } else if (has(selectedPolygonIndex, 'index')) {
        //     arr = polygons.filter((item, index) => item.index !== selectedPolygonIndex.index)
        // }

        const tooltipSpan = document.getElementById('remove-circle-tooltip');
        tooltipSpan.style.top = '0';
        tooltipSpan.style.left = '0';

        this.setState({ circlesEdit: arr, showCircleRemoveTooltip: false })
    }

    setProps = (states) => {
		this.setState( states )
	}

    render() {
        // console.log('PROPS EditPolygonTabComponent: ', this.props)
        // console.log('STATES EditPolygonTabComponent: ', this.state)
        const { editMode, chosenMall, camerasMapPolygons, language } = this.props;
        const { zone, selectedFloor, scalemap, /*showPolygonRemoveTooltip, showPolygonEditTooltip,*/ showCircleRemoveTooltip, showCameraPolygonTooltip, selectedCamerasPolygonName, drawingNew, drawingEdit, circles, polygons, circlesEdit, circleNew, showRelationsByte2Story } = this.state
        const { floors } = chosenMall
        const { name, description, floor_number, engagement } = zone
        const { genericLabels, adminSection } = language;
        const {
            editZoneTitle,
            newZoneTitle,
            zoneNameTitle,
            zoneNamePlaceholder,
            zoneDescriptionTitle,
            zoneDescriptionPlaceholder,
            zoneEngagementTitle,
            zoneEngagementPlaceholder,
            markZoneTitle,
            drawPolygonMessage,
            boldContinueDrawPolygonMessage,
            zoneNotHaveImageMessage,
            saveAndAddZoneTitle,
            relationsByte2StoryTitle,
        } = adminSection.zonesPage;

        const {
            floorTitle,
            drawTitle,
            polygonTitle,
            mapTitle,
            removeCircleTitle,
            cancelTitle,
            saveTitle,
            clearPolygonTitle,
        } = genericLabels;

        let circleNewScreen = null
        if (!isEmpty(circleNew)) {
            circleNewScreen = <circle cx={circleNew.x} cy={circleNew.y} r={5/scalemap} stroke="#f00" />
        }

        const circlesEditScreen = []
        if (drawingEdit) {
            circlesEdit.forEach( ({x,y,id}, i) => {
                circlesEditScreen.push(
                    <Circle
                        key={'сe'+x+y+i}
                        index={i}
                        x={x}
                        y={y}
                        scalemap={scalemap}
                        circlesEdit={circlesEdit}
                        handleCircleContextMenu={this.handleCircleContextMenu}
                        setProps={this.setProps}
                    />
                    // <circle
                    //     key={'сe'+x+y+i}
                    //     cx={x}
                    //     cy={y}
                    //     r={5/scalemap}
                    //     // onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                    //     onContextMenu ={(e) => this.handleCircleContextMenu(e,i)}
                    // />
                )

                // if (id === 'new') {
                //     circlesEditScreen.push(
                //         <circle key={'сeNew'+x+y+i} cx={x} cy={y} r={5/scalemap} stroke="#f00"
                //             // onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                //         />
                //     )
                // } else {
                //     circlesEditScreen.push(
                //         <circle key={'сe'+x+y+i} cx={x} cy={y} r={5/scalemap}
                //             onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                //         />
                //     )
                // }

                if (i) {
                    const x1 = circlesEdit[i - 1].x < x ? circlesEdit[i - 1].x + 2 : circlesEdit[i - 1].x - 2
                    const x2 = x > circlesEdit[i - 1].x ? x - 2 : x + 2
                    const y1 = circlesEdit[i - 1].y < y ? circlesEdit[i - 1].y + 2 : circlesEdit[i - 1].y - 2
                    const y2 = y > circlesEdit[i - 1].y ? y - 2 : y + 2

                    circlesEditScreen.push(
                        <line
                            key={'le'+x+y+i} x1={x1} x2={x2} y1={y1} y2={y2}
                            onMouseOver={ (e) => {
                                const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
                                const drawWrapperRect = drawWrapper.getBoundingClientRect()

                                // this.setState({ circleNew: { x: (e.clientX - drawWrapperRect.x)/scalemap, y: (e.clientY - drawWrapperRect.y)/scalemap, id: 'newCircle'} })
                                this.setState({ circleNew: {x: (e.clientX - drawWrapperRect.x)/scalemap, y: (e.clientY - drawWrapperRect.y)/scalemap} })
                                // circlesEditScreen.push(
                                //     <circle key={'сeTmp'} cx={xTmp} cy={yTmp} r={5/scalemap} stroke="#f00"
                                //         // onClick={ (e1) => this.handleCircleClick(e1, {x,y}) }
                                //     />
                                // )
                            }}
                            onMouseOut={ (e) => {
                                this.setState({ circleNew: {} })
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const newData = circlesEdit.slice(0); // copy
                                newData.splice(i, 0, circleNew);

                                // const arr = circlesEdit.splice(i-1, 0, ...circleNew)
                                // console.log('arr: ', circlesEdit, circleNew, i, arr)
                                this.setState({ circlesEdit: newData })
                            }}
                        />
                    )
                }
            })



            const x1 = circlesEdit[0].x < circlesEdit[circlesEdit.length-1].x ? circlesEdit[0].x + 2 : circlesEdit[0].x - 2
            const x2 = circlesEdit[circlesEdit.length-1].x > circlesEdit[0].x ? circlesEdit[circlesEdit.length-1].x - 2 : circlesEdit[circlesEdit.length-1].x + 2
            const y1 = circlesEdit[0].y < circlesEdit[circlesEdit.length-1].y ? circlesEdit[0].y + 2 : circlesEdit[0].y - 2
            const y2 = circlesEdit[circlesEdit.length-1].y > circlesEdit[0].y ? circlesEdit[circlesEdit.length-1].y - 2 : circlesEdit[circlesEdit.length-1].y + 2
            circlesEditScreen.push(
                <line key={'le0'} x1={x1} x2={x2} y1={y1} y2={y2}
                    onMouseOver={ (e) => {
                        const drawWrapper = document.querySelectorAll('.draw-wrapper')[0]
                        const drawWrapperRect = drawWrapper.getBoundingClientRect()

                        this.setState({ circleNew: { x: (e.clientX - drawWrapperRect.x)/scalemap, y: (e.clientY - drawWrapperRect.y)/scalemap, id: 'newCircle'} })
                        // circlesEditScreen.push(
                        //     <circle key={'сeTmp'} cx={xTmp} cy={yTmp} r={5/scalemap} stroke="#f00"
                        //         // onClick={ (e1) => this.handleCircleClick(e1, {x,y}) }
                        //     />
                        // )
                    }}
                    onMouseOut={ (e) => {
                        this.setState({ circleNew: {} })
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const newData = circlesEdit.slice(0); // copy
                        newData.splice(0, 0, circleNew);

                        // const arr = circlesEdit.splice(i-1, 0, ...circleNew)
                        // console.log('arr: ', circlesEdit, circleNew, i, arr)
                        this.setState({ circlesEdit: newData })
                    }}

                />
            )
        }

        const cirlsScreen = []
        circles.forEach( ({x,y}, i) => {
            cirlsScreen.push(
                <circle key={'с'+x+y+i} cx={x} cy={y} r={5/scalemap}
                    onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                />
            )

            if (i) {
                const x1 = circles[i - 1].x < x ? circles[i - 1].x + 2 : circles[i - 1].x - 2
                const x2 = x > circles[i - 1].x ? x - 2 : x + 2
                const y1 = circles[i - 1].y < y ? circles[i - 1].y + 2 : circles[i - 1].y - 2
                const y2 = y > circles[i - 1].y ? y - 2 : y + 2

                cirlsScreen.push(
                    <line key={'l'+x+y+i} x1={x1} x2={x2} y1={y1} y2={y2} />
                )
            }
        })

        const polysScreen = [] //flatten(JSON.parse(zone.polygons))
        polygons.forEach( (poly, i) => {
            polysScreen.push(
                <polygon
                    key={i}
                    // points={poly.screen_coords}
                    points={poly}
                    onClick={(e) => this.handlePolygonClick(e, i)}
                    // onMouseOver={(e) => this.handlePolygonMouseOver(e, i)}
                    // onMouseOut={this.handlePolygonMouseOut}
                    // onContextMenu={(e) => this.handlePolyContextMenu(e, i)}
                />
            )
        })

        // const polysScreenOld = []
        // zone.polygons && JSON.parse(zone.polygons).forEach( (poly, i) => {
        //     polysScreenOld.push(
        //         <polygon key={i} points={poly} />
        //     )
        // })

        const polysCameras = []
        camerasMapPolygons.filter((item) => item.floor_number === floor_number).forEach( (camera, i) => {
            const poly = flatten(JSON.parse(camera.map_coords))
            polysCameras.push(
                <polygon key={i} points={poly}
                    onMouseOver={ (e) => !drawingNew && this.handleCamerasPolygonMouseOver(e, camera.camera_title || camera.camera_name) }
                    onMouseOut={ this.handleCamerasPolygonMouseOut }
                />
            )
        })

        return (
            <Fragment>
                {/* <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/> */}

                {/* <Form
                    ref={this.modal}
                    className="add_new_data zonesForm"
                    onClick={this.handleFormClick}
                >
                    <h3 className="mb-4">
                        {editMode ? editZoneTitle : newZoneTitle}
                    </h3>

                    <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/> */}
                <Fragment>
                    <div className="bodyWrapper">
                        <Row>
                            <Col md={4}>
                                <div className="zoneOption">
                                    <Row>
                                        <Col md={8}>
                                            <Form.Group>
                                                <Form.Label>{zoneNameTitle}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={zoneNamePlaceholder}
                                                    min="0"
                                                    value={name}
                                                    onKeyDown={this.handleEnter}
                                                    onChange={e => this.setState({ zone: {...zone, name: e.target.value} })}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{floorTitle}</Form.Label>
                                                <DropdownButton
                                                    title={floor_number ? floor_number : "#"}
                                                    id="drop_for_mall_floors"
                                                    variant="outline-dark"
                                                >
                                                    {/* {mall_id && range(1, malls.find(({id}) => mall_id === id).floors_amount+1).map((it, index) => {
                                                        return <Dropdown.Item
                                                            key={index}
                                                            onClick={() => this.setState({shop: {...shop, floor_number: it}})}
                                                        >
                                                            {it}
                                                        </Dropdown.Item>
                                                    })} */}
                                                    {!isEmpty(chosenMall) && !isEmpty(floors)
                                                    && (
                                                        floors
                                                        .sort((a,b) => a.number - b.number)
                                                        .map((item) => {
                                                            return <Dropdown.Item
                                                                key={item.id}
                                                                onClick={() => this.setState({ selectedFloor: item, zone: {...zone, floor_number: item.number} })}
                                                            >
                                                                {item.number}
                                                            </Dropdown.Item>
                                                        })
                                                    )}
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group>
                                        <Form.Label>{zoneEngagementTitle}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder={zoneEngagementPlaceholder}
                                            step="0.1"
                                            min="0"
                                            value={engagement || ''}
                                            onKeyDown={this.handleEnter}
                                            onChange={e => this.setState({ zone: {...zone, engagement: e.target.value} })}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{zoneDescriptionTitle}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="6"
                                            placeholder={zoneDescriptionPlaceholder}
                                            style={{maxHeight:"200px"}}
                                            value={description | ''}
                                            onChange={(e) => this.setState({ zone: {...zone, description: e.target.value} })}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{markZoneTitle}</Form.Label>
                                        <p className="draw-polygon-description">{drawPolygonMessage} <b>{boldContinueDrawPolygonMessage}</b></p>
                                        <div className="buttons d-flex">
                                            <Button
                                                variant="outline-primary"
                                                // className={`button add-polygon ${(drawingNew || drawingEdit) && 'btnDelete'}`}
                                                className={`add-polygon zones ${drawingEdit ? 'btn-disabled' : ''}`}
                                                onClick={this.startDrawing}
                                                // disabled={(polygons.length > 7)}
                                            >
                                                {/* {`${(drawingNew || drawingEdit) ? clearTitle : drawTitle} ${polygonTitle}`} */}
                                                {`${drawTitle+' '+polygonTitle}`}
                                            </Button>

                                            <Button
                                                variant="outline-danger"
                                                className={`clear-polygon ${!drawingEdit && 'btn-disabled'}`}
                                                // className={`btnDelete btn_new_item ${!zonesCheckedList.length && 'btn-disabled'}`}
                                                // disabled={!zonesCheckedList.length}
                                                // onClick={() => zonesCheckedList.length && handleZonesDelete()}
                                                onClick={this.handleClearPolygon}
                                            >
                                                {clearPolygonTitle}
                                            </Button>
                                        </div>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className="map-title">{mapTitle}</div>
                                <div className="map-block">
                                    {selectedFloor && selectedFloor.floor_image
                                    ? (
                                        <TransformWrapper
                                            // minScale={1} maxScale={5} scale={s} positionX={x} positionY={y}
                                            defaultScale={1}
                                            defaultPositionX={0}
                                            defaultPositionY={0}
                                            options={{
                                                limitToBounds: false,
                                                centerContent: true,
                                            }}
                                            pan={{
                                                disabled: drawingNew,
                                                velocity: false,
                                            }}
                                            scalePadding={{
                                                animationTime: 0,
                                            }}
                                            doubleClick={{
                                                disabled: true,
                                            }}
                                            // onZoomChange={ (a) => {console.log('onZoomChange: ', a);} }
                                            onWheelStop={ (a) => {this.setState({ scalemap: a.scale})} }
                                        >
                                            <TransformComponent>
                                                <DropTarget
                                                    targetKey="drop-target-map"
                                                    onHit={this.handleDrop}
                                                >
                                                    <span
                                                        className={`draw-wrapper ${drawingNew && 'drawing'}`}
                                                        style={{ position: 'relative', display: 'inline-table' }}
                                                        onClick={this.handleMouseClick}
                                                    >
                                                        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                            <g fill="transparent" stroke="#19AB27" strokeWidth={2/scalemap} fillOpacity="0.5" strokeOpacity="1" strokeDasharray="6,3" r={3/scalemap}>
                                                                {polysCameras}
                                                            </g>
                                                            <g fill="transparent" stroke="#475ED1" strokeWidth={4/scalemap} fillOpacity="0.5" strokeOpacity="1" r={5/scalemap}>
                                                                {cirlsScreen}
                                                                {circleNewScreen}
                                                                {circlesEditScreen}
                                                                {polysScreen}
                                                                {/* {polysScreenOld} */}
                                                                {/* <DragDropContainer
                                                                    targetKey="drop-target-map"
                                                                    dragClone={false}
                                                                    // dragData={{number: item.number, type: 'map'}}
                                                                >
                                                                    <circle cx={250} cy={150} r={5/scalemap} stroke="#f00" />

                                                                    {/* <circle cx={x} cy={y} r={5/scalemap}
                                                                        onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                                                                        onContextMenu ={(e) => this.handleCircleContextMenu(e,i)}
                                                                    /> */}
                                                                    {/* <img
                                                                        alt=""
                                                                        src={`/images/mapping/idle_${item.number}.png`}
                                                                        onMouseDown = {self.handleMarkerMouseDown}
                                                                    /> */}
                                                                {/*</DragDropContainer> */}
                                                            </g>
                                                        </svg>

                                                        {/* <DragDropContainer
                                                            targetKey="drop-target-map"
                                                            dragClone={false}
                                                            // dragData={{number: item.number, type: 'map'}}
                                                        >
                                                            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                                <g fill="transparent" stroke="#475ED1" strokeWidth={4/scalemap} fillOpacity="0.5" strokeOpacity="1" r={5/scalemap}>
                                                                    {/* <DragDropContainer
                                                                        targetKey="drop-target-map"
                                                                        dragClone={false}
                                                                        // dragData={{number: item.number, type: 'map'}}
                                                                    > */}
                                                                        {/* <circle cx={250} cy={150} r={5/scalemap} stroke="#f00" /> */}

                                                                        {/* <circle cx={x} cy={y} r={5/scalemap}
                                                                            onClick={ (e) => this.handleCircleClick(e, {x,y}) }
                                                                            onContextMenu ={(e) => this.handleCircleContextMenu(e,i)}
                                                                        /> */}
                                                                        {/* <img
                                                                            alt=""
                                                                            src={`/images/mapping/idle_${item.number}.png`}
                                                                            onMouseDown = {self.handleMarkerMouseDown}
                                                                        /> */}
                                                                    {/* </DragDropContainer> */}
                                                                {/*</g>
                                                            </svg>
                                                        </DragDropContainer> */}

                                                        {/* <svg viewBox="0 0 400 400" width="400" height="400">
                                                            <Circle />
                                                        </svg> */}

                                                        <img className="drop-image-map" src={selectedFloor.floor_image} alt="" />
                                                    </span>
                                                </DropTarget>
                                            </TransformComponent>

                                            <div
                                                id="camera-polygon-tooltip"
                                                role="tooltip"
                                                className={`fade remove-marker-tooltip tooltip ${showCameraPolygonTooltip && 'show'}`}
                                                style={{ position: 'fixed' }}
                                            >
                                                <div className="tooltip-inner">
                                                    {selectedCamerasPolygonName}
                                                </div>
                                            </div>

                                            {/* <div
                                                id="edit-polygon-tooltip"
                                                role="tooltip"
                                                className={`remove-marker-tooltip edit-icon tooltip ${showPolygonEditTooltip && 'show'}`}
                                                // className={`remove-marker-tooltip remove-icon tooltip show}`}
                                                style={{ position: 'fixed' }}
                                                onMouseOver={ () => { console.log('onMouseOver'); this.setState({ showPolygonEditTooltip: true }) } }
                                                // onMouseOut={ () => this.setState({ showPolygonRemoveTooltip: false })}
                                                // onMouseOut={ this.handlePolygonMouseOut }
                                                onMouseOut={ () => {
                                                    console.log('onMouseOut')
                                                    // const tooltipSpan = document.getElementById('edit-polygon-tooltip');
                                                    // tooltipSpan.style.top = '0';
                                                    // tooltipSpan.style.left = '0';

                                                    this.setState({ showPolygonEditTooltip: false })
                                                }}
                                                onClick= {this.handleClickEditPolygon}
                                            >
                                                <div className="tooltip-inner">
                                                    <div className="remove-marker">{editPolygonTitle}</div>
                                                </div>
                                            </div> */}

                                            {/* <div
                                                id="remove-polygon-tooltip"
                                                role="tooltip"
                                                className={`remove-marker-tooltip remove-icon tooltip ${showPolygonRemoveTooltip  && 'show'}`}
                                                style={{ position: 'fixed' }}
                                                onMouseOver={ () => this.setState({ showPolygonRemoveTooltip: true }) }
                                                // onMouseOut={ () => this.setState({ showPolygonRemoveTooltip: false })}
                                                // onMouseOut={ this.handlePolygonMouseOut }
                                                onMouseOut={ () => {
                                                    const tooltipSpan = document.getElementById('remove-polygon-tooltip');
                                                    tooltipSpan.style.top = '0';
                                                    tooltipSpan.style.left = '0';

                                                    this.setState({ showPolygonRemoveTooltip: false })
                                                }}
                                                onClick= {this.handleClickRemovePolygon}
                                            >
                                                <div className="tooltip-inner">
                                                    <div className="remove-marker">{removePolygonTitle}</div>
                                                </div>
                                            </div> */}

                                            <div
                                                id="remove-circle-tooltip"
                                                role="tooltip"
                                                className={`remove-marker-tooltip remove-point-icon tooltip ${showCircleRemoveTooltip  && 'show'}`}
                                                style={{ position: 'fixed' }}
                                                onMouseOver={ () => this.setState({ showCircleRemoveTooltip: true }) }
                                                // onMouseOut={ () => this.setState({ showPolygonRemoveTooltip: false })}
                                                // onMouseOut={ this.handlePolygonMouseOut }
                                                onMouseOut={ () => {
                                                    const tooltipSpan = document.getElementById('remove-circle-tooltip');
                                                    tooltipSpan.style.top = '0';
                                                    tooltipSpan.style.left = '0';

                                                    this.setState({ showCircleRemoveTooltip: false })
                                                }}
                                                onClick= {this.handleClickRemoveCircle}
                                            >
                                                <div className="tooltip-inner">
                                                    <div className="remove-marker">{removeCircleTitle}</div>
                                                </div>
                                            </div>
                                        </TransformWrapper>
                                    )
                                    : (
                                        <div className="no-floor-image">
                                            {zoneNotHaveImageMessage}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row
                        className="footer"
                        style={{ marginTop: "20px" }}
                    >
                        <Col className="left">
                            <Button
                                variant="outline-primary"
                                className="button mr20"
                                onClick={this.handleChangeForm}
                            >
                                {cancelTitle}
                            </Button>
                            {/* <Button
                                variant="outline-primary"
                                className="button"
                                onClick={this.handleRelationsClick}
                            >
                                {relationsByte2StoryTitle}
                            </Button> */}
                        </Col>
                        <Col className="right text-right">
                            <Button
                                variant="outline-primary"
                                className={`button mr20 ${!polygons.length && 'btn-disabled'}`}
                                onClick={(e) => editMode ? this.editData(e, true) : this.addData(e, true)}
                                disabled={!polygons.length}
                            >
                                {saveAndAddZoneTitle}
                            </Button>
                            <Button
                                variant="outline-success"
                                className={`button ${!polygons.length && 'btn-disabled'}`}
                                onClick={(e) => editMode ? this.editData(e) : this.addData(e)}
                                disabled={!polygons.length}
                            >
                                {saveTitle}
                            </Button>
                        </Col>
                    </Row>
                {/* </Form> */}
                </Fragment>

                {/* {showRelationsByte2Story
                && (
                    <ModalRelationsByte2Story
                        zoneId={zone.id}
                        onHide={this.closeRelations}
                        getB2SRelations={this.getB2SRelations}
                    />
                )} */}
            </Fragment>
        )
    }
}

export default EditPolygonTabComponent
