import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DebugToolComponent from './DebugToolComponent'
import {sendDebugSchedule, complexRequest} from "../../actions/apiRequests";


const mapStateToProps = ({ dashboardData, adminData, debugtoolData, filters, language }) => {
    return {
        chosenMallDashboard: dashboardData.chosenMall,
        malls: adminData.malls,
        role: filters.role,
        availableDebuggedDays: debugtoolData.availableDebuggedDays,
        // clusters: debugtoolData.clusters,
        // clusterImages: debugtoolData.clusterImages,
        // newCalculationImages: debugtoolData.newCalculationImages,
        cameras: debugtoolData.cameras,
        camerasMapPolygons: adminData.camerasMapPolygons,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendDebugSchedule: (data, callback)=> dispatch(sendDebugSchedule(data, callback)),
        complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
    };
};
const DebugToolContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(DebugToolComponent));

export default DebugToolContainer;
