import React, {useEffect, useRef, useState} from 'react'
import {ClipLoader} from "react-spinners";

const Image = ({src, height = null, width = null, failedSrc = "/images/no_image_en.jpg", spinnerSize = 100, alt="", ...rest}) => {
    const [isLoad, setLoading] = useState(false);

    const img = useRef();
    const wrapper = useRef();

    useEffect(() => {
        const picture = img.current;
        setLoading(false);
        if (picture.complete){
            onLoad()
        }else {
            picture.style.display = "none";
            picture.onload = () => onLoad();

            return () => {
                setLoading(false);
                picture.onload = null;
            };
        }
    }, [src]);

    const onLoad = () => {
        setLoading(true);
        wrapper.current.removeAttribute("style");
        img.current.style.display = "block"
    };

    const onError = e => {
        wrapper.current.removeAttribute("style");
        e.target.src = failedSrc
    };

    return <div className="spin_image form-group" ref={wrapper} >
        <ClipLoader
            sizeUnit={"px"}
            size={spinnerSize}
            color={'#201158'}
            loading={!isLoad}
        />

        <img
            src={src ? src : failedSrc}
            ref={img}
            style={{height, width}}
            onErrorCapture={onError}
            alt={alt}
            {...rest}
        />
    </div>
};

export default Image;
