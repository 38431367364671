import {connect} from 'react-redux';
import Spinner from '../components/Spinner';

const mapStateToProps = ({ spinner, filters, language }) => {
    return {
        show: spinner,
        event: filters.showEvent,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEvent: (state) => dispatch({type:"SET_EVENT", payload: Promise.resolve(state)}),
        stopSpinner: () => dispatch({type:"STOP_SPINNER"})
    };
};

const SpinnerContainer = connect(mapStateToProps,mapDispatchToProps)(Spinner);


export default SpinnerContainer;