import React, { Component } from 'react'
import { Card, Dropdown, DropdownButton, Row, Col, Button } from "react-bootstrap"
import KeyboardEventHandler from "react-keyboard-event-handler"
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'

import UserDropdownTitle from "../UserDropdownTitle"

import "../../styles/CamerasMapComponent.sass"


class CamerasMapComponent extends Component{
    constructor(props) {
        super(props);

        this.state = {
            cameras: [],
            dragging: true,
        }

        this.dragging = true
        this.scale = [1, 1]

        this.shift = {shiftY: 0, shiftX: 0}
        this.resize = {x: 0, y: 0}

        this.modal = React.createRef()
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.props.switchCamerasMap(false)
        }
    }

    handleDrop = (e) => {
        const cameras = this.state.cameras;

        const dropImage = document.querySelectorAll('.drop-image')[0]
        const imgRect = dropImage.getBoundingClientRect()

        if (e.dragData.type === 'master') {
            const y = e.y - imgRect.y - this.shift.shiftY
            const x = e.x - imgRect.x - this.shift.shiftX

            const item = {
                y:   y,
                x:   x,
                active: true,
            }
            this.setState({
                cameras: [...cameras.map((item)=>{item.active = false; return item;}), item]
            })
        }
        else {
            const y = e.y - imgRect.y - this.shift.shiftY
            const x = e.x - imgRect.x - this.shift.shiftX

            const idx = e.dragData.index
            cameras[idx] = {...cameras[idx], ...{x, y}}
            this.setState({ cameras })
        }
    }

    handleMouseDown = (e) => {
        const dragImage = document.querySelectorAll('.drag-image')[0]
        const getCoords = (elem) => {
            const box = elem.getBoundingClientRect()
            return {
              top: box.top, // + pageYOffset,
              left: box.left, // + pageXOffset
            }
        }
        const coords = getCoords(dragImage),
        shiftX = e.clientX - coords.left,
        shiftY = e.clientY - coords.top

        this.shift = {shiftY, shiftX}
    }

    handleCloneMouseDown = (e) => {
        const dragImage = document.querySelectorAll('.clone-drag-image.active')[0]

        const getCoords = (elem) => {
            const box = elem.getBoundingClientRect()
            return {
              top: box.top, // + pageYOffset,
              left: box.left, // + pageXOffset
            }
        }
        const coords = getCoords(dragImage),
        shiftX = e.clientX - coords.left,
        shiftY = e.clientY - coords.top

        this.shift = {shiftY, shiftX}
    }

    handleCloneClick = (idx) => {
        const cameras = this.state.cameras
        this.setState({
            cameras: [...cameras.map((item, index)=>{ item.active = (index === idx); return item;})]
        })
    }

    handleMouseMoveRotation = (e) => {
        const activeImage = document.querySelectorAll('.clone-image-wrapper.active')[0]

        const offset = activeImage.getBoundingClientRect(),
        // var center_x = (offset.left) + (activeImage.width() / 2);
        center_x = (offset.left) + (offset.width / 2),
        // var center_y = (offset.top) + (activeImage.height() / 2);
        center_y = (offset.top) + (offset.height / 2),
        mouse_x = e.clientX,
        mouse_y = e.clientY,
        radians = Math.atan2(mouse_x - center_x, mouse_y - center_y),
        degree = (radians * (180 / Math.PI) * -1) - 90

        document.querySelectorAll('.clone-image-wrapper.active').forEach( (el) =>
            el.setAttribute('style', 'transform: rotate(' + degree + 'deg); -moz-transform: rotate(' + degree + 'deg); -webkit-transform: rotate(' + degree + 'deg); -o-transform: rotate(' + degree + 'deg); -ms-transform: rotate(' + degree + 'deg); ')
        )
    }

    handleMouseUpRotation = (e) => {
        this.setState({ dragging: true })
        document.removeEventListener('mousemove', this.handleMouseMoveRotation)
        document.removeEventListener('mouseup', this.handleMouseUpRotation)
    }


    handleMouseMoveResize = (e) => {
        const activeImage = document.querySelectorAll('.clone-drag-image.active')[0]
        const width = activeImage.width + e.clientX - this.resize.x
        const height = activeImage.height + e.clientY - this.resize.y

        if (width > 24 && height > 15) {
            document.querySelectorAll('.clone-drag-image.active').forEach( (el) => {
                el.style.width = width + 'px'
                el.style.height = height + 'px'
            })
        }

        this.resize = { x: e.clientX, y: e.clientY }
    }

    handleMouseUpResize = (e) => {
        this.setState({ dragging: true })
        document.removeEventListener('mousemove', this.handleMouseMoveResize)
        document.removeEventListener('mouseup', this.handleMouseUpResize)
    }

    render() {
        const { switchShopComparison, floor, language } = this.props;
        const { cameras, dragging } = this.state;
        const self = this;

        const {
            genericLabels: { saveTitle },
            adminSection: { camerasPage },
            columns,
        } = language;
        const { dragDropTitle } = camerasPage;
        const { camerasMapLabel } = columns;

        return (
            <div className="comparison shop-comparison mx-4">
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => switchShopComparison(false)}/>

                <Card className="timeline-wrapper scrollbar" id="style-3" ref={this.modal}>
                <Card.Header>
                    <div className="panel-header dark">
                        {camerasMapLabel}

                        <div className="dropdown_wrap" style={{alignItems: 'center'}}>
                            <DropdownButton
                                alignRight
                                id="shop_comparison_dropdown"
                                title={<UserDropdownTitle v='d'/>}
                                className={"btn-empty btn-menu"}>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={11}>

                            <div className="drop-target" style={{maxHeight: 'calc(100vh - 280px)', overflow: 'auto', position: 'relative'}}>
                                <DropTarget
                                    targetKey="drop-target"
                                    onHit={this.handleDrop}
                                >
                                    <img alt="" src={floor.floor_image} className="drop-image" />

                                    {
                                        cameras.map( (item,index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    style={{lineHeight:0, position:'absolute', top:`${item.y}px`, left:`${item.x}px`}}
                                                    className={`clone-drag-wrapper ${item.active && "active"}`}
                                                >

                                                    <DragDropContainer
                                                        targetKey="drop-target"
                                                        dragClone={false}
                                                        dragData={{'index': index, type: 'clone'}}
                                                        noDragging={!dragging || !item.active}
                                                    >
                                                        <div
                                                            className={`clone-image-wrapper ${item.active && "active"}`}
                                                            style={{position:'relative'}}
                                                            onMouseDown = {this.handleCloneMouseDown}
                                                        >
                                                            <img
                                                                alt=""
                                                                src='/images/camera.png'
                                                                className={`clone-drag-image ${item.active && "active"}`}
                                                                onClick={()=>this.handleCloneClick(index)}
                                                            />

                                                            {item.active &&
                                                                <div className="border-editor" style={{ border: '2px dashed red', position: 'absolute',
                                                                    top: '-15px',
                                                                    left: '-15px',
                                                                    right: '-15px',
                                                                    bottom: '-15px'
                                                                }}>
                                                                    <div
                                                                        className="rotatable-handle"
                                                                        style={{ userSelect: 'none', position: 'absolute', width: '9px', height: '9px', top: '-2px', left: '-2px', cursor: 'grab', backgroundColor: 'red' }}
                                                                        onMouseDown={ (e) => {
                                                                            e.preventDefault();
                                                                            self.dragging = false
                                                                            self.setState({ dragging: false })

                                                                            document.addEventListener('mousemove', self.handleMouseMoveRotation);
                                                                            document.addEventListener('mouseup', self.handleMouseUpRotation);
                                                                        }}
                                                                    >
                                                                    </div>
                                                                    <div
                                                                        className="resizable-handle"
                                                                        onMouseDown={ (e) => {
                                                                            e.preventDefault();
                                                                            self.setState({ dragging: false });
                                                                            this.resize = {x:e.clientX, y:e.clientY};

                                                                            document.addEventListener('mousemove', self.handleMouseMoveResize);
                                                                            document.addEventListener('mouseup', self.handleMouseUpResize);
                                                                        }}
                                                                    >
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </DragDropContainer>
                                                </span>
                                            )
                                        })

                                    }
                                </DropTarget >
                            </div>
                        </Col>
                        <Col className="text-center right-sidebar">
                            <div className="form-group">{dragDropTitle}</div>
                            <div className="form-group" style={{marginBottom: '40px'}}>
                                <DragDropContainer
                                    targetKey="drop-target"
                                    dragClone={true}
                                    dragElemOpacity={0.6}
                                    dragData={{type: 'master'}}
                                    // onDrop={ (e, t) => console.log('The MASTER drag is dropped:', e, t) }
                                    // onDragStart={this.handleDragStart}
                                >
                                    <img alt="" src='/images/camera.png' className="drag-image" onMouseDown = {this.handleMouseDown}/>
                                </DragDropContainer>
                            </div>
                            <Button>{saveTitle}</Button>
                        </Col>
                    </Row>

                </Card.Body>
                </Card>
            </div>
        )
    }
}

export default CamerasMapComponent
