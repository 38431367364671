import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { max } from 'lodash'
import Toggle from 'react-toggle'

import formatNumber from '../helpers/formatNumber'
import { statBlocks } from '../helpers/helperStatBlocks'

import '../styles/TopShopsComponent.sass'

const ZoneNode = (props) => {
    const { zoneStats, chosenStatsBlock, zonesCheckedList, setZonesCheckedList } = props
    const { zone_id } = zoneStats
    const checked = zonesCheckedList.includes(zone_id)

    const handleCheckboxClick = () => {
		if (zonesCheckedList.includes(zone_id)) {
			setZonesCheckedList(zonesCheckedList.filter((item) => item !== zone_id))
		} else {
			setZonesCheckedList([ ...zonesCheckedList, zone_id ])
		}
	}

    // console.log('PROPS ZoneNode: ', this.props)
    return (
        <li className="zoneRow">
            <div className="column zoneName">
                <input
                    type='checkbox'
                    checked={checked}
                    className='zoneCheckbox'
                    onChange={handleCheckboxClick}
                />
                {zoneStats.zone_name}
            </div>
            <div className="column active">
                {`${zoneStats[chosenStatsBlock.apiKey]}${chosenStatsBlock.unit}`}
            </div>
            {
                statBlocks.map((item) => {
                    if (chosenStatsBlock.id.toString() !== item.id.toString()) {
                        return (
                            <div key={item.id} className="column">
                                {`${zoneStats[item.apiKey]}${item.unit}`}
                            </div>
                        )
                    }
                })
            }
        </li>
    )
}

const TopZonesComponent = (props) => {
    const {
        zonesDailyStats,
        zonesCheckedList,
        chosenStatsBlock,
        setZonesCheckedList,

        topzones,
        switchTopShopPopover,
        switchZoneComparison,
        // chooseShop,
        daterange,
        language,
        chooseTopZone,
    } = props
    const { data, direction } = language
    const { visitorsTitle, minutesTitle } = data.genericLabels

    const [displaySelectedZones, setDisplaySelectedZones] = useState(false)
    const [zonesFull, setZonesFull] = useState(false)

    const handleSelectAllClick = () => {
		if (zonesCheckedList.length) {
            setZonesCheckedList([])
            setDisplaySelectedZones(false)
		} else {
			setZonesCheckedList(zonesDailyStats.map(({zone_id}) => zone_id))
		}
    }
    
    let tmpZonesDailyStats = zonesDailyStats
    if (displaySelectedZones && zonesCheckedList.length > 1) {
        tmpZonesDailyStats = zonesDailyStats.filter((item) => zonesCheckedList.includes(item.zone_id))
    }

    return(
        <div className={`topshopsWrapper ${zonesFull && 'full'}`}>
            <Card className="topshops">
                <div
                    className="arrow"
                    onClick={() => setZonesFull(!zonesFull)}
                />                    
                <Card.Header>
                    {zonesCheckedList.length > 1
                    && (
                        <div className="toggleWrapper">
                            <span>
                                {`Show only Selected Zones (${zonesCheckedList.length})`}
                            </span>

                            <Toggle
                                checked={displaySelectedZones}
                                onChange={() => setDisplaySelectedZones(!displaySelectedZones)}
                            />
                        </div>
                    )}
                    <div className="zoneRow">
                        <div className="column zoneName">
                            <input
                                type='checkbox'
                                checked={zonesCheckedList.length}
                                // checked={devices.length && selectedDevices.length === devices.length} onChange={(e) => this.props.selectAllDevices(e)}
                                className='zoneCheckbox'
                                onChange={handleSelectAllClick}
                            />
                            <div className="title">
                                ZONE NAME
                            </div>
                        </div>
                        <div className="column title active">
                            {chosenStatsBlock.name.toUpperCase()}
                        </div>
                        {
                            statBlocks.map((item) => {
                                if (chosenStatsBlock.id.toString() !== item.id.toString()) {
                                    return (
                                        <div key={item.id} className="column title">
                                            {item.name.toUpperCase()}
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </Card.Header>
                <Card.Body>
                    <div id="style-3" className={`list-group ${displaySelectedZones && 'displaySelectedZones'}`}>
                    {
                        tmpZonesDailyStats
                            .sort((a,b) => b[chosenStatsBlock.apiKey] - a[chosenStatsBlock.apiKey])
                            .map((item) => {
                                return <ZoneNode
                                    key={item.zone_id}
                                    daterange={daterange}
                                    // chooseShop={chooseShop}
                                    chooseTopZone={chooseTopZone}
                                    // onPopover={onPopover}
                                    // outPopover={outPopover}
                                    switchTopShopPopover={switchTopShopPopover}
                                    switchZoneComparison={switchZoneComparison}
                                    visitorsTitle={visitorsTitle}
                                    minutesTitle={minutesTitle}
                                    direction={direction}
                                    zoneStats={item}
                                    chosenStatsBlock={chosenStatsBlock}
                                    zonesCheckedList={zonesCheckedList}
                                    setZonesCheckedList={setZonesCheckedList}
                                />
                            })
                    }
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default TopZonesComponent
