import React, {Component} from 'react';
import KeyboardEventHandler from "react-keyboard-event-handler";

import parseDate from "../helpers/parseDate";

import "../styles/Event.sass"


class Event extends Component{
    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    componentDidMount() {
        !this.props.showEventsComparison && document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.close();
        }
    };

    close = () => {
        const {
            switchEvent,
            switchEventsSelectList,
            eventsListLength,
            chooseEvent,
            showEventsComparison,
            switchEventsComparison,
            showEvent
        } = this.props;

        if (showEventsComparison) {
            switchEventsComparison(false)
        } else if (showEvent) {
            switchEvent(false);
            chooseEvent({});
            if (eventsListLength > 0) {
                switchEventsSelectList(true);
            }
        }
    };

    edit(event){
        const {chooseEvent, showEventsComparison, setForm} = this.props;

        if (showEventsComparison) {
            chooseEvent(event)
        }
        setForm(true, true);
    };

    render(){
        const {
            showEventsComparison,
            editMode,
            chosenEvent,
            language,
        } = this.props;
        const {
            name,
            shop_name,
            description,
            budget,
            start_datetime,
            end_datetime,
            main_image,
            visits,
        } = chosenEvent;
        let date, time;

        const {
            genericLabels,
            columns,
            images: { noImageUrl },
        } = language;
        const { eventTitle, visitorsTitle } = genericLabels;
        const { budgetLabel } = columns;

        if (parseDate(start_datetime, "DD") === parseDate(end_datetime, "DD")) {
            date = parseDate(start_datetime, "DD MMM YYYY");
            time = `${parseDate(start_datetime, "dddd")} ${parseDate(start_datetime, "HH:mm")}-${parseDate(end_datetime, "HH:mm")}`;
        } else {
            date = `${parseDate(start_datetime, "DD")}-${parseDate(end_datetime, "DD")} ${parseDate(start_datetime, "MMM YYYY")}`;
            time = `${parseDate(start_datetime, "dddd")} ${parseDate(start_datetime, "HH:mm")}-${parseDate(end_datetime, "dddd")} ${parseDate(end_datetime, "HH:mm")}`;
        }

        // let  men = 0, women = 0, girl = 0, boy = 0;
        // if (visits) {
        //     const {count, man, woman, girls, boys} = visits;
        //     men = count*man;
        //     women = count*woman;
        //     girl = girls*count;
        //     boy = boys*count;
        // }

        return (
            <div className={`event ${editMode ? 'editing':""}`} ref={this.modal}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this.close}/>

                <div className="line"/>
                <div className="head">
                    <h4 className="title">{eventTitle}</h4>

                    <div className="btn_wrap">
                        <button className="edit" onClick={() => this.edit(chosenEvent)}/>

                        {!showEventsComparison && <button className="close_btn" onClick={() => this.close()}/>}
                    </div>
                </div>

                <div className="event_name">
                    <h3 className="name">{name}</h3>
                    <p className="location">{shop_name}</p>

                    <div className="date_wrap">
                        <p className="title">{date}</p>
                        <span className="description">{time}</span>
                    </div>
                </div>

                <div className="information">
                    <div className="top_content">
                        <div className="logo">
                            <img src={main_image || noImageUrl}
                                 onErrorCapture={e => e.target.src = noImageUrl}
                                 alt="event_image"
                            />
                        </div>

                        <div className="data">
                            <div className="info">
                                <div className="data_content">
                                    <div>
                                        <h5 className="title">{budgetLabel}</h5>
                                        <span className="price">{budget ? `${budget}$` : ""}</span>
                                    </div>

                                    <div style={{marginTop: "20px"}}>
                                        <h5 className="title">{visitorsTitle}</h5>
                                        <span className="price">{visits && visits.count ? visits.count : 0}</span>
                                    </div>
                                    {/*<div className="radar_wrap">*/}
                                    {/*    <Radar className="radar" data={radar} options={{legend: {display: false}, maintainAspectRatio: false,}}/>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="data_content">
                                    <p className="text_info">{description && `${description.slice(0, 300)}...`}</p>
                                </div>
                            </div>

                            {/*<div className="info">*/}
                            {/*    <Line id="line" data={data} options={{legend: {display: false, labels:false}}} />*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*<div className="bottom_content">*/}
                    {/*    <div className="comments">*/}
                    {/*        <h5 className="title mb-3">Comments</h5>*/}

                    {/*        <ol>{comments.map((comment, index)=>{*/}
                    {/*            return <li className="comment" key={index}>{comment}</li>*/}
                    {/*        })}</ol>*/}
                    {/*    </div>*/}

                    {/*    /!*<div className="graph"><Line data={data} options={{legend: {display: false, labels:false}}} /></div>*!/*/}

                    {/*    <div className="percentages">*/}
                    {/*        <div className="percent percent-brown">*/}
                    {/*            {_.random(1, 99)}%*/}
                    {/*            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid inventore non quo repellendus</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="percent percent-blue">*/}
                    {/*            {_.random(1, 99)}%*/}
                    {/*            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid inventore non quo repellendus</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="percent percent-dark-blue">*/}
                    {/*            {_.random(1, 99)}%*/}
                    {/*            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid inventore non quo repellendus</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className="line black"/>

                {/*<div className="footer">*/}
                {/*    <div className="stat-wrapper">*/}
                {/*        <img alt="" src="/images/male.png"/>*/}
                {/*        <span className="percentage male-blue">*/}
                {/*            {_.random(1, 99)}%*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className="stat-wrapper">*/}
                {/*        <img alt="" src="/images/female.png"/>*/}
                {/*        <span className="percentage female-blue">*/}
                {/*            {_.random(1, 99)}%*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className="stat-wrapper">*/}
                {/*        <img alt="" src="/images/boy.png"/>*/}
                {/*        <span className="percentage male-blue">*/}
                {/*            {_.random(1, 99)}%*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div className="stat-wrapper">*/}
                {/*        <img alt="" src="/images/girl.png"/>*/}
                {/*        <span className="percentage girl-pink">*/}
                {/*            {_.random(1, 99)}%*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}
export default Event;
