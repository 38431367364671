import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";

import MallsAdminComponent from "../../components/admin/MallsAdminComponent";
import UsersAdminContainer from "../../containers/admin/UsersAdminContainer";
import ZonesAdminContainer from "../../pages/zones/ZonesAdminContainer";
import ShopsAdminContainer from "../../containers/admin/ShopsAdminContainer";
import ShopsOwnersAdminContainer from "../../containers/admin/ShopsOwnersAdminContainer";
import EventsAdminContainer from "../../containers/admin/EventsAdminContainer";
import CamersAdminContainer from "../../containers/admin/CamersAdminContainer";
import CamersAdminHeatmapContainer from "../../containers/admin/CamersAdminHeatmapContainer";
import DebugToolContainer from "../../pages/debugtool/DebugToolContainer";

const AdminComponent = () => (
    <Switch>
        <Route path="/admin/users" component={UsersAdminContainer}/>
        <Route path="/admin/malls" component={MallsAdminComponent}/>
        <Route path="/admin/zones" component={ZonesAdminContainer}/>
        <Route path="/admin/shops" component={ShopsAdminContainer}/>
        <Route path="/admin/shops_owners" component={ShopsOwnersAdminContainer}/>
        <Route path="/admin/events" component={EventsAdminContainer}/>
        <Route path="/admin/cameras/heatmap" component={CamersAdminHeatmapContainer}/>
        <Route path="/admin/cameras" component={CamersAdminContainer}/>
        <Route path="/admin/debugtool" component={DebugToolContainer}/>

        {/* <Redirect to="/admin/users"/> */}
        <Redirect to="/admin/zones"/>
    </Switch>
);

export default AdminComponent
