import {connect} from 'react-redux';
import StartPage from '../components/StartPage';
import {withRouter} from 'react-router-dom';
import {logIn, forgotPassword, resetPassword} from "../actions/apiRequests"
import {newUserInvite} from "../actions/filters"


const mapStateToProps = ({ filters, language}) => {
    return {
        startPageType: filters.startPageType,
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logIn: (data, remember) => dispatch(logIn(data, remember)),
        newUserInvite: (state) => dispatch(newUserInvite(state)),
        forgotPassword: (data) => dispatch(forgotPassword(data)),
        resetPassword: (data) => dispatch(resetPassword(data)),
    };
};

const StartPageContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(StartPage));


export default StartPageContainer;
