import {connect} from 'react-redux';
import CreateUser from '../components/CreateUser';
import {setEditMode, setVisibilityAddForm} from '../actions/filters';
import {addUser} from "../actions/apiRequests";


const mapStateToProps = ({ adminData, filters, user, language }) => {
    return {
        malls: adminData.malls,
        role: filters.role,
        user: user,
        language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addUser: (data)=>dispatch(addUser(data)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(CreateUser);
