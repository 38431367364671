import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import { toastr } from "react-redux-toastr"
import { connect } from 'react-redux'
import _ from "lodash"

import { apiCall } from '../../actions/apiRequests'
import catchParser from "../../helpers/catchParser";

const ModalRelationsByte2Story = ({ zoneId, b2sZoneRelations, departments, subdepartments, deepdepartments, language, onHide, getB2SRelations }) => {
	const [relations, setRelations] = useState([])

	useEffect(() => {
		const arr = b2sZoneRelations.map(({id, zone_id, department_id, subdepartment_id, deepdepartment_id}) => {
			const obj = {
				id,
				zone_id,
				department: null,
				subdepartment: null,
				deepdepartment: null,
			}
			if (department_id) {
				const objFound = departments.find(({id}) => id === department_id)
				if (objFound) {
					obj.department = {value: objFound.id, label: objFound.name}
				}
			}
			if (subdepartment_id) {
				const objFound = subdepartments.find(({id}) => id === subdepartment_id)
				if (objFound) {
					obj.subdepartment = {value: objFound.id, label: objFound.name}
				}
			}
			if (deepdepartment_id) {
				const objFound = deepdepartments.find(({id}) => id === deepdepartment_id)
				if (objFound) {
					obj.deepdepartment = {value: objFound.id, label: objFound.name}
				}
			}

			return obj
		})
		setRelations(arr)
    }, []);

	const { genericLabels, notifications: { deleteActionConfirmationMessage, somethingBadHappenWithMessage, errorTitle, successTitle } } = language;
	const {
		cancelTitle,
		saveTitle,
	} = genericLabels;
		
	const availableDepartments = ( departments.length ? departments.map(({id, name}) => ({ value: id, label: name })) : [] )
	const availableSubdepartments = ( subdepartments.length ? subdepartments.map(({id, name}) => ({ value: id, label: name })) : [] )
	const availableDeepdepartments = ( deepdepartments.length ? deepdepartments.map(({id, name}) => ({ value: id, label: name })) : [] )

	const addRow = () => {
        const item = {
            zone_id: zoneId,
            department: '',
            subdepartment: '',
            deepdepartment: '',
        }
        setRelations([...relations, item])
	}

	const handleRowChange = (name, item, idx) => {
		console.log('handleRowChange: ', name, item, idx )
		console.log('handleRowChange1 relations: ', relations )

		

		const arr = [...relations]
		if (_.isEqual(arr[idx][name], item)) {
			return
		}

		arr[idx][name] = item
		switch (name) {
		case 'department': {
			arr[idx]['subdepartment'] = ''
			arr[idx]['deepdepartment'] = ''
			break
		}
		case 'subdepartment': {
			arr[idx]['deepdepartment'] = ''
			break
		}
		default:
			break
        }

        setRelations(arr)
    }
	
	const removeRow = (idx) => {
		const arr = [...relations]
		arr.splice(idx, 1)
        setRelations(arr)
	}

	const getAvailableSubdepartments = (idx) => {
		const selectedDepartament = relations[idx].department
		return ( 
			subdepartments.length && selectedDepartament 
			? subdepartments
				.filter(({ parent_id }) => parent_id === selectedDepartament.value)
				.map(({id, name}) => ({ value: id, label: name })) 
			: [] 
		)
	}

	const getAvailableDeepdepartments = (idx) => {
		const selectedSubdepartament = relations[idx].subdepartment
		return ( 
			deepdepartments.length && selectedSubdepartament 
			? deepdepartments
				.filter(({ parent_id }) => parent_id === selectedSubdepartament.value)
				.map(({id, name}) => ({ value: id, label: name })) 
			: [] 
		)
	}

	const handleSaveClick = () => {
		// console.log('relations: ', relations)
		const newRelations = relations
			.filter((item) => item.department)
			.map((item) => {
				return {
					id: item.id || null,
					zone_id: item.zone_id || null,
					department_id: item.department ? item.department.value : null,
					subdepartment_id: item.subdepartment ? item.subdepartment.value : null,
					deepdepartment_id: item.deepdepartment ? item.deepdepartment.value : null,
				}
		})
		// console.log('newRelations: ', newRelations)
		// console.log('b2sZoneRelations: ', b2sZoneRelations)
		
		if (_.isEqual(newRelations, b2sZoneRelations)) {
			toastr.info('', 'There were no changes');
		} else {
			const requestArray = [];

			const changedData = []
			newRelations.forEach((item, i) => {
				if (item.id) {
					const existRelation = b2sZoneRelations.find(({id}) => id === item.id)
					if (existRelation) {
						const changedObj = _.reduce(_.keys(item), (result, key) => {
							if (!Object.prototype.hasOwnProperty.call(existRelation, key) || !_.isEqual(existRelation[key], item[key])) {
								result[key] = item[key]
							}
							return result;
						}, {});
						if (!_.isEmpty(changedObj)) {
							changedData.push({id: existRelation.id, ...changedObj})
						}
					}
				}
			})
			// console.log('changedData: ', changedData)
			const newData = []
			newRelations.forEach((item) => {if (!item.id) {newData.push(item)} })
			// console.log('newData: ', newData)
			const removedData = _.difference(_.map(b2sZoneRelations, "id"), _.map(newRelations, "id"))
			// console.log('removedData: ', removedData)

			//* removes deleted relations
			removedData.forEach((item) => requestArray.push(
				apiCall('delete', `byte2story/zone-relations/${item}`)
					.catch((e) => {
						const error = catchParser(e, zoneId, { somethingBadHappenWithMessage, errorTitle });
						toastr.info(error.result, error.description);
					}))
			)
			//* adds new relations
			newData.forEach((item) => requestArray.push(
				apiCall('post', 'byte2story/zone-relations', {zone_id: zoneId, ...item})
					.catch((e) => {
						const error = catchParser(e, zoneId, { somethingBadHappenWithMessage, errorTitle });
						toastr.info(error.result, error.description);
					}))
			)                
			//* updates the changed existing relations
			changedData.map((item) => requestArray.push(
				apiCall('put', `byte2story/zone-relations/${item.id}`, {...item})
					.catch((e) => {
						const error = catchParser(e, zoneId, { somethingBadHappenWithMessage, errorTitle });
						toastr.info(error.result, error.description);
					}))
			)

			if (requestArray.length) {
				Promise.all(requestArray)
					.then((res) => {
						// console.group('Promise res:', res)
						toastr.success(successTitle, `Relations has been saved`);
	
						getB2SRelations()
						onHide()
					})
					.catch((e) => {
						console.log('Promise error: ', e)
						const error = catchParser(e, zoneId, { somethingBadHappenWithMessage, errorTitle });
						toastr.info(error.result, error.description);
					});
			}
		}
	}
	// console.log('STATE relations:', relations)

	return (
		<Modal
			show
			size="xl"
			onHide={onHide}
			className="moodal-batch-function"
		>
			<Modal.Header closeButton style={{ borderBottom: '2px solid #00ADE4' }}>
				<Modal.Title>Byte2Story Zone Relations</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="byte2storyRelations">
					<Button
						variant="outline-primary"
						className="button mb-3"
						title="Add relation"
						onClick={addRow}
					>
						Add Relation
					</Button>
					<Row style={{ fontWeight: '500', marginBottom: '10px', paddingRight: '55px' }}>
						<Col md={4}>
							<label className="form-label">
								Departments
							</label>
						</Col>
						<Col md={4}>
							<label className="form-label">
								Sub Departments
							</label>
						</Col>
						<Col md={4}>
							<label className="form-label">
								Deep Departments
							</label>
						</Col>
					</Row>
					{ 
						relations.map(({department, subdepartment, deepdepartment}, index) => {
							return (
								<Row key={index} style={{ paddingRight: '55px', position: 'relative' }}>
									<Col md={4}>
										<Form.Group className="mb-3">
											<Select
												isSearchable = {true}
												name = "department"
												placeholder = "Select department..."
												options     = {availableDepartments}
												// styles      = {customSelectStyles}
												className   = 'react-select-container'
												classNamePrefix = "react-select"
												value       = {department}
												onChange    = {(items) => handleRowChange('department', items, index)}
											/>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group className="mb-3">
											<Select
												isSearchable = {true}
												name = "subdepartment"
												placeholder = "Select sub department..."
												// options     = {availableSubdepartments}
												options     = {getAvailableSubdepartments(index)}
												// styles      = {customSelectStyles}
												className   = 'react-select-container'
												classNamePrefix = "react-select"
												value       = {subdepartment}
												onChange    = {(items) => handleRowChange('subdepartment', items, index)}
											/>
										</Form.Group>
									</Col>

									<Col md={4}>
										<Form.Group className="mb-3">
											<Select
												isSearchable = {true}
												name = "deepdepartment"
												placeholder = "Select deep department..."
												// options     = {availableDeepdepartments}
												options     = {getAvailableDeepdepartments(index)}
												// styles      = {customSelectStyles}
												className   = 'react-select-container'
												classNamePrefix = "react-select"
												value       = {deepdepartment}
												onChange    = {(items) => handleRowChange('deepdepartment', items, index)}
											/>
										</Form.Group>
									</Col>
									<div
										className="button btn btn-outline-primary"
										style={{ position: 'absolute', right: '10px', top: '0px' }}
										title="Delete relation"
										onClick={() => toastr.confirm(
											deleteActionConfirmationMessage,
											{onOk: () => removeRow(index)}
											)}
									>
										<span style={{ fontWeight: '600' }}>-</span>
									</div>
								</Row>
							)
						})
					}
					<Row
                        className="footer mt-3 mb-3"
                    >
                        <Col md={6}>
							<Button
                                variant="outline-primary"
								className="button"
								style={{ width: '100%' }}
                                onClick={onHide}
                            >
                                {cancelTitle}
                            </Button>
						</Col>
						<Col md={6}>
                            <Button
                                variant="outline-success"
								className="button"
								style={{ width: '100%' }}
                                onClick={handleSaveClick}
                            >
                                {saveTitle}
                            </Button>
                        </Col>
                    </Row>
				</div>
			</Modal.Body>
		</Modal>
	)
}

ModalRelationsByte2Story.propTypes = {
	b2sZoneRelations: PropTypes.arrayOf(PropTypes.any).isRequired,
	departments: PropTypes.arrayOf(PropTypes.any).isRequired,
	subdepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
	deepdepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
	language: PropTypes.objectOf(PropTypes.any).isRequired,
	zoneId: PropTypes.number.isRequired,
	onHide: PropTypes.func.isRequired,
	getB2SRelations: PropTypes.func.isRequired,
};

const mapStateToProps = ({ zoneData: {b2sZoneRelations, b2sDepartments, b2sSubdepartments, b2sDeepdepartments}, language }) => ({
	b2sZoneRelations,
	departments: b2sDepartments,
    subdepartments: b2sSubdepartments,
	deepdepartments: b2sDeepdepartments,
	language: language.data,
});

export default connect(mapStateToProps)(ModalRelationsByte2Story)
