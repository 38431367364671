import React, {Component} from 'react';
import {Button, DropdownButton, Dropdown} from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import moment from "moment";
import {find, isEmpty} from "lodash"
import KeyboardEventHandler from "react-keyboard-event-handler";
import EventsFormContainer from "../../containers/EventsFormContainer";

class EventsAdminComponent extends Component {
    state={
        chosenShopName: "all"
    };

    componentDidMount(){
        const { user: {roles, malls_id}, complexRequest } = this.props;
        if (roles) {
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`},
                    {name: "events", method: "get", type: `events${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`}
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }
    }

    componentDidUpdate(prevProps){
        const { user, shops, complexRequest, openAddForm, location } = this.props, {roles, malls_id} = user;
        const {chosenShopName} = this.state;

        if(prevProps.user !== user){
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`},
                    {name: "events", method: "get", type: `events${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`}
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }

        if(prevProps.location.key !== location.key && malls_id){  // this condition give possibility get actual data during click on current link of page
            let event_opt = "";

            if(chosenShopName === "all"){
                if (roles === "manager"){
                    event_opt = `/?mall_id=${malls_id}`
                } else {
                    event_opt = ""
                }
            }else {
                event_opt = `/?shop_id=${find(shops, ['name', chosenShopName]).id}`
            }

            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${malls_id}` : ""}`},
                    {
                        name: "events",
                        method: "get",
                        type: `events${event_opt}`
                    }
                ],
                "GET_SHOPS_AND_EVENTS"
            );
        }

        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    switchEditMode(event){
        const { setForm, chooseEvent } = this.props;
        setForm(true, true);
        chooseEvent(event)
    }


    handleChangeForm(){
        const { setForm, openAddForm } = this.props;
        setForm(!openAddForm, false);
    }

    render(){
        const {
            events,
            shops,
            openAddForm,
            getEvents,
            user,
            language,
        } = this.props;

        const { columns, adminSection, genericLabels } = language;
        const {
            nameLabel,
            descriptionLabel,
            shopLabel,
            budgetLabel,
            startDateLabel,
            endDateLabel,
        } = columns;

        const { mainTitle, addNewEventTitle, allShopsTitle } = adminSection.eventsPage;
        const { allTitle, tableCountPaginationLabels, searchFormPlaceholder } = genericLabels;

        const data = {
            columns: [
                {
                    label: nameLabel,
                    field: 'name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: descriptionLabel,
                    field: 'description',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: shopLabel,
                    field: 'shop',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: budgetLabel,
                    field: 'budget',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: startDateLabel,
                    field: 'start_datetime',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: endDateLabel,
                    field: 'end_datetime',
                    sort: 'asc',
                    width: 200
                }
            ],
            rows: (!isEmpty(events) && !isEmpty(shops)) ? events.map((item) => {
                return {
                    name: item.name,
                    description: item.description ? item.description.slice(0, 45) : "",
                    shop: item.shop_name ? item.shop_name : "",
                    budget: item.budget ? item.budget : "",
                    start_datetime: item.start_datetime ? moment(item.start_datetime).local().format("ddd, DD MMMM, HH:mm") : "",
                    end_datetime: item.end_datetime ? moment(item.end_datetime).local().format("ddd, DD MMMM, HH:mm") : "",
                    clickEvent: () => this.switchEditMode(item)
                }}) : []
        };

        return (
            <div className={`block_wrapper admin ${openAddForm ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <div className="wrap_header">
                    <h2 className="page_title">{mainTitle}</h2>

                    <Button
                        className="btn_new_item"
                        variant="outline-dark"
                        onClick={() => this.handleChangeForm()}
                    >
                        {addNewEventTitle}
                    </Button>

                    <DropdownButton
                        className={`dropdown_btn ${shops.length > 15 && "scrollDrop"}`}
                        title={this.state.chosenShopName === "all" ? allShopsTitle : this.state.chosenShopName}
                        variant="dark">
                        <Dropdown.Item onClick={() => {
                            getEvents(user.roles === "manager" ? `/?mall_id=${user.malls_id}` : "");
                            this.setState({chosenShopName: "all"});
                        }}>
                            {allTitle}
                        </Dropdown.Item>

                        {shops.map(({name, id}) =>
                            <Dropdown.Item
                                key={id}
                                onClick={() => {
                                    this.setState({chosenShopName: name});
                                    getEvents(`/?shop_id=${id}`)
                                }}>
                                {name}
                            </Dropdown.Item>)}
                    </DropdownButton>

                    <EventsFormContainer/>
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                />
            </div>
        )
    }
}

export default EventsAdminComponent
