import React from 'react'

const Circle = (props) => {
    const [position, setPosition] = React.useState({
        x: props.x, //100,
        y: props.y, //100,
        active: false,
        offset: { }
    });

    const handlePointerDown = e => {
        console.log('handlePointerDown: ', e.target, e.button)
        e.preventDefault()
        e.stopPropagation()
        if (e.button === 2) { // Right mouse button - context menu
            return
        }
        // e.preventDefault()

        const el = e.target;
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        el.setPointerCapture(e.pointerId);
        setPosition({
            ...position,
            active: true,
            offset: {
            x,
            y
            }
        });
    };
    const handlePointerMove = e => {
        console.log('handlePointerMove: ')
        e.preventDefault()
        e.stopPropagation()
        const bbox = e.target.getBoundingClientRect();
        const x = e.clientX - bbox.left;
        const y = e.clientY - bbox.top;
        if (position.active) {
            setPosition({
            ...position,
            x: position.x - (position.offset.x - x),
            y: position.y - (position.offset.y - y)
            });
        }
    };
    const handlePointerUp = e => {
        console.log('handlePointerUp: ')
        e.preventDefault()
        e.stopPropagation()
        if (e.button === 2) { // Right mouse button - context menu
            return
        }
        // setPosition({
        //     ...position,
        //     active: false
        // });

        const circlesEdit = props.circlesEdit.map((item, i) => {
            if (i === props.index) {
                return Object.assign({}, item, {
                    // ...action.payload
                    x: position.x,
                    y: position.y,
                })
            }
            return item;
        })
        props.setProps({ circlesEdit })
    };

    return (
        <circle
            cx={position.x}
            cy={position.y}
            // r={50}
            r={5/props.scalemap}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            onContextMenu ={(e) => props.handleCircleContextMenu(e, props.index)}
            stroke={position.active ? "#19AB27" : "#475ED1"}
            strokeDasharray={position.active ? "1" : "none"}
            // fill={position.active ? "green" : "white"}
        />
    );
  };

  export default Circle
