import React,{Component} from 'react';
import {isEmpty} from 'lodash';
import KeyboardEventHandler from "react-keyboard-event-handler";

import Image from "./Image";
import search from "../helpers/search";
import formatNumber from "../helpers/formatNumber";

import "../styles/StoresSelectList.sass"

class StoresSelectList extends Component{
    state={
        storesList: this.props.storesList
    };

    componentDidUpdate(prevProps){
        const {storesList, switchStoresSelectList} = this.props;
        if (storesList !== prevProps.storesList) {
            this.setState({storesList});
            if (isEmpty(storesList)) {
                switchStoresSelectList(false);
            }
        }
    }

    componentWillUnmount(){
        this.close()
    }

    close = () =>{
        this.props.addStoresToList(false);
        this.props.switchStoresSelectList(false);
    };

    doSearch(value){
        const {storesList } = this.props;

        if (value.trim() === ""){
            this.setState({storesList: storesList})
        } else {
            this.setState({storesList: search(value, storesList, false, "name")})
        }
    }

    showShopComparison = () => this.props.switchShopComparison(true);

    render(){
        const { deleteStoresFromList, language} = this.props;
        const {storesList} = this.state;
        const {
            columns:{ shopLabel, rentLabel },
            genericLabels:{ selectedTitle },
            dashboardPage,
            images: { noImageUrl },
        } = language;
        const { searchSelectedStoresTitle } = dashboardPage.shopsComparisonBlock;

        return (
            <div className="store_list-wrap">
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.close()}/>

                <div className="header">
                    <button className="close_btn" type="button" onClick={() => this.close()}/>

                    <div className="wrap_head">
                        <div className="wrap_for_amount_of_list">
                            <h3>{storesList.length}</h3>

                            <div className="selected">
                                <p style={{textTransform: 'uppercase'}}>{selectedTitle}</p>
                                <h6>{shopLabel}</h6>
                            </div>
                        </div>

                        <div className="balance">
                            <button onClick={this.showShopComparison}>
                                <img src="/images/scales.svg" alt="scales"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="data">
                    <input
                        className="search_input"
                        onChange={e => this.doSearch(e.target.value)}
                        placeholder={searchSelectedStoresTitle}
                    />

                    <div className="events_wrap scrollbar" id="style-2">
                        {storesList.map(({name, id, rent, main_image, owner_image, owner, floor, visitors}) => {
                            let  men = 0, women = 0, girl = 0, boy = 0;
                            if (visitors) {
                                const {count, man, woman, girls, boys} = visitors;
                                men = formatNumber((count * (man/100)).toFixed(0));
                                women = formatNumber((count * (woman/100)).toFixed(0));
                                girl = formatNumber((count * (girls/100)).toFixed(0));
                                boy = formatNumber((count * (boys/100)).toFixed(0));
                            }

                            return (<div className="event_card" key={id}>
                                <button className="close_btn" onClick={() => deleteStoresFromList(id)}/>

                                <div className="head">
                                    <p className="location">{name}</p>
                                    <p className="date">{floor}</p>
                                </div>

                                <div className="img">
                                    <Image src={main_image} failedSrc={noImageUrl}/>
                                </div>

                                <div className="wrap_for_content">
                                    <div className="wrap">
                                        <h4 className="name">{name}</h4>
                                        <div className="user">
                                            <div className="avatar">
                                                <img src={owner_image ? owner_image : "/images/profile.png"}
                                                     alt="avatar"
                                                     onErrorCapture={e => e.target.src = "/images/profile.png"}/>
                                            </div>
                                            <p className="user_name">{owner}</p>
                                        </div>
                                    </div>

                                    <div className="wrap">
                                        <p className="rent">{rentLabel}</p>
                                        <p className="sum">{rent}</p>
                                    </div>
                                </div>

                                <div className="gender">
                                    <div className="stat-wrapper">
                                        <img alt="" src="/images/male.png"/>
                                        <span className="percentage male-blue">
                                            {men}
                                        </span>
                                    </div>
                                    <div className="stat-wrapper">
                                        <img alt="" src="/images/female.png"/>
                                        <span className="percentage female-blue">
                                            {women}
                                        </span>
                                    </div>
                                    <div className="stat-wrapper">
                                        <img alt="" src="/images/boy.png"/>
                                        <span className="percentage male-blue">
                                            {boy}
                                        </span>
                                    </div>
                                    <div className="stat-wrapper">
                                        <img alt="" src="/images/girl.png"/>
                                        <span className="percentage girl-pink">
                                            {girl}
                                        </span>
                                    </div>
                                    <div className="stat-wrapper">
                                        <img alt="" src="/images/returns.jpg"/>
                                        <span className="percentage returns">
                                            0
                                        </span>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default StoresSelectList
