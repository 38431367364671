import React from 'react'
import { Card } from "react-bootstrap"
import moment from 'moment'
import KeyboardEventHandler from "react-keyboard-event-handler"

import queryOptionsParser from "../../helpers/queryOptionsParser"

const SwapStore = ({ chosenMall, malls, daterange, language, switchSwapStore, complexRequest }) => {
    const { data } = language;
    const { dashboardPage } = data;
    const { swapStoreLabels } = dashboardPage;
    const { selectStore } = swapStoreLabels;

    const onPopupClose = () => {
        switchSwapStore(false)        
    }

    const handleMallClick = (e, id) => {
        e.preventDefault()
        const mallOptions = {
            nvr: true,
            floor: true,
        }
        const mallDailyStatsOptions= {
            start_ts: moment.utc(daterange.startDate).format('YYYYMMDDHHmmss'),
            end_ts: moment.utc(daterange.endDate).format('YYYYMMDDHHmmss'),
            mall_id: id,
        }
        const zonesOptions= {
            mall_id: id
        }

        complexRequest(
            [
                {name: "mall", method: "get", type: `mall/${id}${queryOptionsParser(mallOptions)}`, catchType: {}},
                {name: "mallDailyStats", method: "get", type: `reports/analysis/daily${queryOptionsParser(mallDailyStatsOptions)}`},
                // {name: "zonesDailyStats", method: "get", type: `reports/analysis/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
                {name: "zonesDailyStats", method: "get", type: `crossline/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
                {name: "zones", method: "get", type: `zones${queryOptionsParser(zonesOptions)}`},
            ],
            "CHANGE_MALL",
            () => {
                switchSwapStore(false)
            }
        );
    }

    return (
        <div className="comparison swapStorePopup">
            <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => onPopupClose(false)}/>

            <Card>
                <Card.Header>
                    <div>
                        <div className="title">
                            {selectStore}
                            <button className="close_btn" type="button" onClick={() => onPopupClose(false)} />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {malls.map(({id, name}) => {
                        return (
                            <a
                                key={id}
                                href="/"
                                className={`mallItem ${id === chosenMall.id && 'active'}`}
                                onClick={(e) => handleMallClick(e, id)}
                            >
                                {name}
                            </a>
                        )
                    })}
                </Card.Body>
            </Card>
        </div>
    )
}

export default SwapStore
