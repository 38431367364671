export const DATERANGES = [
    // {key:'today',      range:{day: 0},  prev_range:{day: 1},  subst_unit: 'day',  current: 1, group_range:7200,  display_range:{}},
    {key:'yesterday',  range:{day: 1},  prev_range:{day: 2},  subst_unit: 'day',     current: 0, group_range:7200,  display_range:{day: 1}},
    {key:'thisWeek',  range:{week: 0}, prev_range:{week: 1}, subst_unit: 'isoWeek', current: 1, group_range:86400, display_range:{}},
    {key:'lastWeek',  range:{week: 1}, prev_range:{week: 2}, subst_unit: 'isoWeek', current: 0, group_range:86400, display_range:{week: 1}},
    {key:'thisMonth', range:{month: 0},prev_range:{month: 1},subst_unit: 'month',   current: 1, group_range:172800,display_range:{}},
    {key:'lastMonth', range:{month: 1},prev_range:{month: 2},subst_unit: 'month',   current: 0, group_range:172800,display_range:{month: 1}},
    {key:'thisYear',  range:{year: 0}, prev_range:{year: 1}, subst_unit: 'year',    current: 1, group_range:86400, display_range:{}},
    {key:'lastYear',  range:{year: 1}, prev_range:{year: 2}, subst_unit: 'year',    current: 0, group_range:86400, display_range:{year: 1}}
];

export const DOUGHNUTS = [
    {type:'all',    backgroundColor: ['#f1f1f1','#FEDA1A']},
    {type:'woman',  backgroundColor: ['#f1f1f1','#FEDA1A']},
    {type:'girls',  backgroundColor: ['#f1f1f1','#FEDA1A']},
    {type:'man',    backgroundColor: ['#f1f1f1','#FEDA1A']},
    {type:'boys',   backgroundColor: ['#f1f1f1','#FEDA1A']},    
];

// export const COMPARISONS = [
//     // {key:'today',      range:{day: 0},    permission: ["yesterday"]},
//     // {key:'yesterday',  range:{day: 1},    permission: ["today"]},
//     {key:'thisWeek',  range:{week: 0},   permission: ["lastWeek"]},
//     {key:'lastWeek',  range:{week: 1},   permission: [/*"today",*/ "yesterday", "thisWeek"]},
//     {key:'thisMonth', range:{month: 0},  permission: ["lastMonth", ]},
//     {key:'lastMonth', range:{month: 1},  permission: [/*"today",*/ "yesterday", "thisWeek", "thisMonth", "lastWeek"]},
//     {key:'thisYear',  range:{year: 0},   permission: ["lastYear"]},
//     {key:'lastYear',  range:{year: 1},   permission: [/*"today",*/ "yesterday", "thisWeek", "thisMonth", "thisYear", "lastWeek", "lastMonth"]}
// ];
export const COMPARISONS = [
    {key:'lastWeek',  range:{week: 1},   permission: [/*"today",*/ "yesterday", "thisWeek"]},
    {key:'prevWeek',  range:{week: 2},   permission: ["lastWeek"]},
    {key:'lastMonth', range:{month: 1},  permission: [/*"today",*/ "yesterday", "thisWeek", "lastWeek", "thisMonth"]},
    {key:'prevMonth', range:{month: 2},  permission: ["lastMonth"]},
    {key:'lastYear',  range:{year: 1},   permission: [/*"today",*/ "yesterday", "thisWeek", "lastWeek", "thisMonth", "lastMonth", "thisYear"]},
    {key:'prevYear',  range:{year: 2},   permission: ["lastYear"]},
    {key:'customTime',  range:{},   permission: []},
    {key:'customTimeAnd',  range:{},   permission: []},
];

export const CUSTOM_COMPARISONS = [
    // {key:'yesterday',  range:{day: 1}, permission: ["day"]},
    {key:'lastWeek',  range:{week: 1}, permission: ["day", "isoWeek"]},
    {key:'lastMonth', range:{month: 1}, permission: ["day", "isoWeek", "month"]},
    {key:'lastYear',  range:{year: 1}, permission: ["day", "isoWeek", "month", "year"]}
];
