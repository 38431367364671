import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ZonesAdminComponent from './ZonesAdminComponent';

import { complexRequest, deleteZones } from "../../actions/apiRequests";
import { setEditMode, setVisibilityAddForm, chooseZone, setRemovedZone } from "../../actions/filters";


const mapStateToProps = state => {
    const {
        adminData,
        dashboardData,
        filters,
        user,
        language,
    } = state;

    return {
        chosenMallDashboard: dashboardData.chosenMall,
        zones: adminData.zones,
        malls: adminData.malls,
        user,
        role: filters.role,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        chooseZone: (data)=> dispatch(chooseZone(data)),
        deleteZones: (zonesList)=> dispatch(deleteZones(zonesList)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        setRemovedZone: (listId) => dispatch(setRemovedZone(listId)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType)),
    };
};
const ZonesAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(ZonesAdminComponent));

export default ZonesAdminContainer;