import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MallsFormComponent from '../components/MallsFormComponent';
import {deleteMall, editMall, getMalls, getMall, addMall} from "../actions/apiRequests";
import {setEditMode, setVisibilityAddForm, chooseMall, switchCamerasMap} from "../actions/filters";

const mapStateToProps = ({ adminData, filters, language }) => {
    return {
        malls: adminData.malls,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        chosenMall: adminData.chosenMall,
        showCamerasMap: filters.showCamerasMap,
        language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addMall: (data, callback) => dispatch(addMall(data, callback)),
        editMall: (id , data)=> dispatch(editMall(id , data)),
        editMallData: data => dispatch(data),
        deleteMall: (id)=> dispatch(deleteMall(id)),
        chooseMall: (mall) => dispatch(chooseMall(mall)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getMalls: () => dispatch(getMalls()),
        switchCamerasMap: (state) => dispatch(switchCamerasMap(state)),
        getMall: (id, options, admin) => dispatch(getMall(id, options, admin)),
    };
};
const MallsFormContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(MallsFormComponent));


export default MallsFormContainer;
