import React, { Component, Fragment, memo , createRef } from "react";
import {ClipLoader} from "react-spinners";
import { isEmpty, max, map } from "lodash";
import h337 from "heatmapjs";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'

import { statBlocks } from '../helpers/helperStatBlocks'
import PolygonTooltipContainer from "../pages/dashboard/PolygonTooltipContainer";

// import testDataForFloor3 from "../testHeatmapData";

import "../styles/MapImageTools.sass"

class MapImageTools extends Component{
    constructor(props) {
        super(props);
        this.state={
            isFloorImgLoaded: true
        };

        this.zonesCvg = createRef();
    }

    componentDidMount() {
        const { wrapperId, imgId, mapId, zoom, floor } = this.props;

        // this.glass = document.getElementById("zoomGlass");
        this.img = document.getElementById(imgId);
        // this.map = document.getElementById(mapId);
        this.wrapper = document.getElementById(wrapperId);
        this.zoom = zoom;

        // this.img.addEventListener("mousemove", this.moveMagnifier);
        // this.map.addEventListener("mousemove", this.moveMagnifier);
        // this.wrapper.addEventListener("mouseout", this.hiddenWrapperHandler);
        // window.addEventListener('resize', this.initHeatMapLayer)
        window.addEventListener('resize', () => {
            // const { wrapper } = this

            // const img = document.getElementById("image-mall")
            // const imgHeight = img.clientHeight + "px"
            // const imgWidth = img.clientWidth + "px"
            // console.log('imgWidth, imgHeight: ', imgWidth, imgHeight)
            // wrapper.style.height = imgHeight;
            // wrapper.style.width = imgWidth;

            // if (this.heatmap) {
            //     this.heatmap.repaint()
            // }
            // this.initHeatMapLayer()        
        })
        // this.wrapper.addEventListener("resize", this.initHeatMapLayer);

        if (!isEmpty(floor) && floor.floor_image) {
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        const { showHeatmap, floor, floorHeatmap} = this.props;

        if (prevProps.showHeatmap !== showHeatmap || prevProps.floorHeatmap !== floorHeatmap) {
            if (showHeatmap) {
                this.initHeatMapLayer();
                // if (this.heatmap) {
                //     this.heatmap.repaint()
                // } else {
                //     this.initHeatMapLayer();
                // }
                // setTimeout(() => {
                //     this.initHeatMapLayer();
                // }, 5000);
            } else {
                this.removeCanvas();
            }
        }

        if (prevProps.floor !== floor) {  //Redraw after changing image
            if (!isEmpty(prevProps.floor)) { // first render
                if (!this.img.complete) {
                    this.setState({ isFloorImgLoaded: false });
                    this.img.style.display = "none";
                }
                this.removeCanvas();
            }
            this.init();
        }
    }

    componentWillUnmount() {
        // this.img.removeEventListener("mousemove", this.moveMagnifier);
        // this.map.removeEventListener("mousemove", this.moveMagnifier);
        // this.wrapper.removeEventListener("mouseout", this.hiddenWrapperHandler);
        // window.removeEventListener('resize', this.initHeatMapLayer)
    }

    // hiddenWrapperHandler = () => this.glass.style.display = "none";

    hiddenTooltipHandler = () => document.getElementById("tooltip").style.display = "none";

    init = () => {
        const {img, wrapper, props: {showHeatmap}, initHeatMapLayer /*, magnify*/} = this;

        img.onload = () => {
            this.setState({ isFloorImgLoaded: true });
            img.style.display = "block";
            const imgHeight = img.clientHeight + "px"
            const imgWidth = img.clientWidth + "px"
            // const imgHeight = img.naturalHeight + "px"
            // const imgWidth = img.naturalWidth + "px"
            // const imgHeight = "1201px"
            // const imgWidth = "1309px"

            console.log('init img.load: ', imgWidth, imgHeight)

            wrapper.style.height = imgHeight;
            wrapper.style.width = imgWidth;
            if (this.zonesCvg.current){
                this.zonesCvg.current.style.height = imgHeight;
                this.zonesCvg.current.style.width = imgWidth;
            }

            if (showHeatmap) {
                initHeatMapLayer();
                // setTimeout(() => {
                //     initHeatMapLayer();
                // }, 5000);
            } 
            /*else {
                magnify()
            }*/
        };
    };

    initHeatMapLayer = () => {
        console.log('initHeatMapLayer')
        const { floorHeatmap, wrapperId } = this.props;
        const wrapper = document.getElementById(wrapperId)
        // const img = document.getElementById("image-mall")
        // console.log('wrapper imgWidth, imgHeight: ', wrapper.clientWidth, wrapper.clientHeight)
        // console.log('image-mall imgWidth, imgHeight: ', img.clientWidth, img.clientHeight)
        // this.wrapper = document.getElementById(wrapperId)
        // const img = document.getElementById("image-mall")
        // const imgHeight = img.clientHeight + "px"
        // const imgWidth = img.clientWidth + "px"
        // console.log('imgWidth, imgHeight: ', imgWidth, imgHeight)
        // this.wrapper.style.height = imgHeight
        // this.wrapper.style.width = imgWidth
        // wrapper.style.height = imgHeight
        // wrapper.style.width = imgWidth




        if (this.heatmap) {
            this.heatmap._renderer.canvas.remove()
            // this.heatmap = null
        }

        document.getElementById("legend").style.display="none";

        if (isEmpty(floorHeatmap)) {
            return
        }

        console.log('wrapper: ', wrapper)
        // setTimeout(() => {
        this.heatmap = h337.create({
            // container: wrapper,
            container: this.wrapper,
            // defaultGradient: { 0:"#B0B0CA", .25: "rgb(0,0,255)", .5: "rgb(0,255,0)", .75: "yellow", 1: "rgb(255,0,0)" },
            defaultGradient: { 0:"#CCC", .25: "rgb(0,0,255)", .5: "rgb(0,255,0)", .75: "yellow", 1: "rgb(255,0,0)" },
            defaultBlur: .7, //The higher the blur factor is, the smoother the gradients will be(0-1)
        });

        this.heatmap.setData({
            radius: 60,
            maxOpacity: .8,
            minOpacity: .1,
            max: max(map(floorHeatmap, 'value')) || 1000,
            min: 0,
            data: !isEmpty(floorHeatmap) ? floorHeatmap : [],
            valueField: '',
            // data: (floor.id === 15 && floor.mall_id === 47) ? testDataForFloor3 : [], // test data for 3 floor on 47 mall
            // data: testDataForFloor3, // test data for 3 floor on 47 mall
        });
        // }, 1000)


        const gradientCfg = this.heatmap._config.gradient || this.heatmap._config.defaultGradient || {};
        if (!isEmpty(gradientCfg) && !isEmpty(this.heatmap.getData().data)) {
            const legendCanvas = document.getElementById("gradient");

            const minLegend = document.querySelector('#min');
            const maxLegend = document.querySelector('#max');
            const legendCtx = legendCanvas.getContext('2d');

            minLegend.innerHTML = this.heatmap.getData().min;
            maxLegend.innerHTML = Math.floor(this.heatmap.getData().max);

            // regenerate gradient image
            let gradient = legendCtx.createLinearGradient(0, 0, 120, 1);
            for (let key in gradientCfg) {
                gradient.addColorStop(key, gradientCfg[key]);
            }
            legendCtx.fillStyle = gradient;
            legendCtx.fillRect(0, 0, 120, 10);
            document.getElementById("legend").style.display="block";

            document.querySelector(".heatmap-canvas").addEventListener('mousemove', this.onMove);
            document.querySelector(".heatmap-canvas").addEventListener('mouseout', this.hiddenTooltipHandler);
        } else {
            document.getElementById("legend").style.display="none";
        }

    };

    removeCanvas = () => {
        document.querySelectorAll(".heatmap-canvas").forEach(el => el.remove());
        this.wrapper.removeEventListener('mousemove', this.onMove);
        this.wrapper.removeEventListener('mouseout', this.hiddenTooltipHandler);
    };

    // magnify = () =>{
    //     const {glass, zoom, img} = this;
    //     img.parentElement.insertBefore(glass, img);
    //     glass.style.display = "none";
    //     glass.style.backgroundImage = "url('" + img.src + "')";
    //     glass.style.backgroundRepeat = "no-repeat";
    //     glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    // };

    // moveMagnifier = (e) => {
    //     e.preventDefault();
    //     const {getCursorPos, glass, zoom, img} = this;
    //     const {x, y, imgX, imgY} = getCursorPos(e, img);

    //     glass.style.display = "block";
    //     glass.style.left = x + "px";
    //     glass.style.top = y + "px";
    //     glass.style.backgroundPosition = "-" + ((imgX * zoom) - 50) + "px -" + ((imgY * zoom) - 47) + "px";
    // };

    getCursorPos = (e, elem) =>{
        const a = elem.getBoundingClientRect();
        e = e || window.event;
        return {
            x : e.clientX + 5,
            y : e.clientY - 70,
            imgX : e.pageX - a.left - window.pageXOffset,
            imgY : e.pageY - a.top - window.pageYOffset,
        };
    };

    onMove = e => {
        e.preventDefault();
        const {imgX, imgY} = this.getCursorPos(e, this.wrapper);
        const tooltip = document.getElementById("tooltip");
        const value = this.heatmap.getValueAt({x: imgX, y: imgY});

        if (value) {
            tooltip.innerText = value;
            tooltip.style.display = 'block';
            tooltip.style.left = (imgX + 20)+"px";
            tooltip.style.top = (imgY - 10)+"px";
        } else {
            tooltip.style.display = 'none';
        }

        // this.heatmap.addData({x: imgX, y:  imgY, value: 50, radius: 15,});
        // console.log(JSON.stringify(this.heatmap.getData().data)) //draw data
    };

    showZoneComparison = (zone) => {
        // const { id, name, description, floor_number, switchZoneComparison, chooseTopZone } = this.props
        const { switchZoneComparison, chooseTopZone } = this.props
        // const zone = {
        //     id,
        //     name,
        //     description,
        //     floor_number,
        // };
        // console.log('showZoneComparison: ', this.props)
        chooseTopZone(zone)
        switchZoneComparison(true)
    }

    renderZones = () => {
        const { floor: { number }, zones, chosenStatsBlock, zonesDailyStats } = this.props
        const objStatBlock = statBlocks.find(({id}) => id === chosenStatsBlock.id)
        const zonesDailyStatsSorted = _.orderBy(zonesDailyStats, [objStatBlock.apiKey], ['desc'])
        const arrBestZoneIds = zonesDailyStatsSorted.slice(0, 5).map(({zone_id}) => zone_id)
        const arrWorstZoneIds = zonesDailyStatsSorted.slice(zonesDailyStats.length - 5).map(({zone_id}) => zone_id)

        return zones
            .filter(({ floor_number }) => floor_number === number)
            .map(zone => {
                const { polygons, name, mall_id, floor_number, id } = zone;
                const tmpZone = JSON.parse(polygons)

                return tmpZone.map((poly, index) => {
                    const key = `${mall_id}-${floor_number}-${id}-${index}`

                    return (
                        <PolygonTooltipContainer
                            key={key}
                            zoneId={id}
                            zoneBest={arrBestZoneIds.includes(id) && 'zoneBest'}
                            zoneWorst={arrWorstZoneIds.includes(id) && 'zoneWorst'}
                            name={name}
                            poly={poly}
                        />
                        // <OverlayTrigger
                        //     // trigger="click"
                        //     key={key}
                        //     placement="top"
                        //     delay={0}
                        //     overlay={<Tooltip id={`tooltip-for-${key}`} className={`zone_tooltip ${arrBestZoneIds.includes(id) && 'zoneBest'} ${arrWorstZoneIds.includes(id) && 'zoneWorst'}`}>{name}</Tooltip>}
                        // >
                        //     <polygon
                        //         points={poly}
                        //         className={`zone ${arrBestZoneIds.includes(id) && 'zoneBest'} ${arrWorstZoneIds.includes(id) && 'zoneWorst'}`}
                        //         // onClick={() => this.showZoneComparison(zone)}
                        //     />
                        // </OverlayTrigger>
                    )
                })
            })
    }

    handleImageLoad = ({target:img}) => {
        // console.log('handleImageLoad1: ', img.width, img.height)
        // console.log('handleImageLoad2: ', img.clientWidth, img.clientHeight)
        // console.log('handleImageLoad3: ', img.offsetWidth, img.offsetHeight)
        // console.log('handleImageLoad4: ', img.naturalWidth, img.naturalHeight)
        const { setMallImageDimensions } = this.props
        setMallImageDimensions({ width: img.naturalWidth, height: img.naturalHeight  })
    }

    render() {
        // console.log('!!!!!!!!!!! PROPS MapImageTools: ', this.props)
        const {
            showHeatmap,
            // zones,
            floor:{ floor_image, /*number*/ },
            noImageUrl,
            mallImageDimensions: {width, height},            
        } = this.props;

        // const polysScreen = []
        // const polysText = []
        // zones.filter((item) => item.floor_number === number).forEach( (zone, i) => {
        //     const tmpZone = JSON.parse(zone.polygons)
        //     tmpZone.forEach( (poly, j) => {
        //         polysScreen.push(
        //             <polygon
        //                 key={'z'+i+'p'+j}
        //                 points={poly}
        //                 // onMouseOver={ (e) => !drawing && this.handleCamerasPolygonMouseOver(e, camera.camera_title || camera.camera_name) }
        //                 // onMouseOut={ this.handleCamerasPolygonMouseOut }
        //             />
        //         )
        //         polysText.push(
        //             <text
        //                 key={'z'+i+'p'+j}
        //                 x={poly[0]}
        //                 y={poly[1]}
        //                 dx={4}
        //                 dy={10}
        //                 // textAnchor="middle"
        //                 strokeWidth="0.8"
        //                 textLength={zone.name ? zone.name.length*8 : 0}
        //                 className="polyText"
        //                 onClick={() => this.showZoneComparison(zone)}
        //             >
        //                 {zone.name}
        //             </text>
        //         )
        //     })
        // })

        return <Fragment>
            <div className="floor-spinner_wrap" style={{display: this.state.isFloorImgLoaded ? "none" : "flex"}}>
                <ClipLoader sizeUnit={"px"} size={100} color={'#201158'} loading={true}/>
            </div>

            {/* <div className="img-magnifier-glass" id="zoomGlass"/> */}

            {showHeatmap
            && (
                <Fragment>
                    <div className="legend-area" id="legend">
                        <div className="minmax">
                            <span id="min"/>
                            <span id="max"/>
                        </div>
                        <canvas id="gradient" width={120} height={10}/>
                    </div>

                    <div id="tooltip"/>
                </Fragment>
            )}

            <svg width="100%" height="auto" xmlns="http://www.w3.org/2000/svg" version="1.1" ref={this.zonesCvg} viewBox={`0 0 ${width} ${height}`}>
                <g>{this.renderZones()}</g>
            </svg>

            <img
                src={floor_image}
                useMap="#image-map"
                id="image-mall"
                alt=""
                // style={{ width: '100%' }}
                onLoad={this.handleImageLoad}
                onErrorCapture={e =>{
                    e.target.src = noImageUrl;
                    e.target.style.display = "block";
                    this.setState({isFloorImgLoaded: true});
                }}
                
            /> 
        </Fragment>
    }
}

export default memo(MapImageTools)
