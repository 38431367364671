function search (value, data, filterAllItem = false, filterProperties = "name"){
    return data.filter(item => {
        if (filterAllItem) {
            for (let key in item) {
                if (item[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return item
                }
            }
        } else {
            if (item[filterProperties].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                return item
            }
        }
        return false
    })
}

export default search;