import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
// import { Row, Col, Card, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl, Button } from 'react-bootstrap'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import {isEmpty, clone, debounce, merge} from 'lodash'
// import InputRange from 'react-input-range';
import InputRange from 'react-input-range-rtl';
// import { DateRange, DateRangePicker, defaultStaticRanges, createStaticRanges, defineds } from 'react-date-range'
// import {toastr} from "react-redux-toastr";
import _ from 'lodash'

// import languages from '../../lang';

import DashboardCalendarContainer from "./DashboardCalendarContainer";
import StatsSliderContainer from "./StatsSliderContainer";
import queryOptionsParser from "../../helpers/queryOptionsParser";
import getScheduleGroupRange from "../../helpers/getScheduleGroupRange";
// import { getSubstUnit, getDataGroup, tipFormatter } from "../../helpers/dashboardUtils";
import { tipFormatter } from "../../helpers/dashboardUtils";
import "../../helpers/chartPlugins"; // chart plugins

import {DATERANGES} from "../../constants"

// import VisitorsContainer from '../../containers/VisitorsContainer'
import MallMapContainer from '../../containers/MallMapContainer'
// import TopShopsContainer from '../containers/TopShopsContainer'
import TopZonesContainer from '../../containers/TopZonesContainer'
// import TopShopPopoverContainer from '../containers/TopShopPopoverContainer'
// import CalendarHeatmapComponent from '../../components/CalendarHeatmapComponent'
// import ShopContainer from "../containers/ShopContainer"
import StoresSelectListContainer from "../../containers/StoresSelectListContainer"
// import ShopComparisonContainer from "../containers/ShopComparisonContainer"
import ZoneComparisonContainer from "./ZoneComparisonContainer"
import SwapStoreContainer from "./SwapStoreContainer"

import 'react-input-range/lib/css/index.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'// theme css fil
import './Dashboard.sass'

momentDurationFormatSetup(moment);

class Dashboard extends Component {
    constructor(props){
        super(props);

        this.state = {
            timeValues: props.timerange,
            minmaxTime: {
                minTime: props.timerange.min,
                maxTime: props.timerange.max,
            },
            showTimeline: true,
            // calendar: {
            //     startDate: new Date(),
            //     endDate: null,
            // }
        };
    }

    componentDidMount() {
        console.log('!!! componentDidMount Dashboard')
        // window.addEventListener('scroll', debounce(this.handleScroll, 50), true);
        const {
            mallDailyStats,

            complexRequest,
            chosenMall,
            daterange,
            // chosenTopFloor,
            isDateRangeConsistTodayDate,
            visitors,
            setTimerange,
            setDaterange
        } = this.props;

        // chosenMall.open_time = 090000 
        // chosenMall.close_time = 220000
        // diff between start of day and open_time = 32400000ms (9 hours)
        // diff between start of day and close_time = 79200000ms (22 hours)  
        const min = chosenMall.open_time && moment(chosenMall.open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 32400000
        const max = chosenMall.close_time && moment(chosenMall.close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 79200000

        const mallOpenTime = moment.duration(min, 'milliseconds').format('HH:mm', {trim: false}) // 09:00 
        const mallCloseTime = moment.duration(max, 'milliseconds').format('HH:mm', {trim: false}) // 22:00

        const daterangeOpenTime = daterange.add && moment.duration(daterange.add.milliseconds, 'milliseconds').format('HH:mm', {trim: false});
        const daterangeCloseTime = daterange.subtract && moment.duration(86400000 - daterange.subtract.milliseconds - 60000, 'milliseconds').format('HH:mm', {trim: false});

        const isChosenMallScheduleChange = mallOpenTime !== daterangeOpenTime || mallCloseTime !== daterangeCloseTime;

        // Current code done
        // if user changes pages and daterange consist today date
        // if user load other page then go on Dashboard and redux hasn't necessary data(visitors/totalcount/etc)
        // if was changed chosenMall schedule on admin page
        // TODO need investigation correction of condition
        if (!isEmpty(chosenMall) && chosenMall.id && (isDateRangeConsistTodayDate || isEmpty(mallDailyStats) || isChosenMallScheduleChange)) {
            const differenceTime = moment(chosenMall.close_time, 'HHmmss').diff(moment(chosenMall.open_time, 'HHmmss'), 'hours');
            const scheduleConfig = daterange.subst_unit === "day"
                ? {
                    group_range: getScheduleGroupRange(differenceTime),
                    add: {milliseconds: min},
                    subtract: {milliseconds: 86400000 - max - 60000 /*1min*/},
                } : {};
            const date_range = merge(clone(daterange), scheduleConfig);
            const { key, range, prev_range, startDate, endDate, group_range, subst_unit, add = 0, subtract = 0 } = date_range;

            const visitorsOptions = {
                start_ts: key === 'сustomTime'
                    ? moment.utc(startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),
                end_ts: key === 'сustomTime'
                    ? moment.utc(endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
                group_range,
                mall_id: chosenMall.id
            }
            const heatmapOptions = {
                start_ts: moment(moment().subtract(6, 'day').format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
                end_ts: moment(moment().format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
                group_range: 3600,
                mall_id: chosenMall.id
            }
            const totalcountOptions = {
                start_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),

                end_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
                mall_id: chosenMall.id
            }
            const prevtotalcountOptions = {
                start_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(prev_range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),

                end_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(prev_range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
                mall_id: chosenMall.id
            }
            const averageVisitorsTimeOptions = {
                start_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),

                end_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
                mall_id: chosenMall.id
            }
            const topzonesOptions= {
                start_ts: daterange.key === 'customTime'
                    ? moment.utc(startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),
                end_ts: daterange.key === 'customTime'
                    ? moment.utc(endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
                mall_id: chosenMall.id
            }
            const zonesOptions= {
                mall_id: chosenMall.id
            }

            complexRequest(
                [
                    {name: "heatmap", method: "get", type: `stats${queryOptionsParser(heatmapOptions)}`},
                    {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsOptions)}`, catchType:{timestamp:[], visitors:[]}},
                    // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsOptions)}`},
                    // {name: "mallshops", method: "get", type: `shops${queryOptionsParser(mallshopsOptions)}`},
                    // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsOptions)}`},
                    {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
                    {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
                    {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`, catchType: {}},
                    {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(topzonesOptions)}`},
                    {name: "zones", method: "get", type: `zones${queryOptionsParser(zonesOptions)}`},
                ],
                "GET_DASHBOARD_DATA",
                () => {
                    this.setState({
                        timeValues: {min, max},
                        minmaxTime: {minTime: min, maxTime: max}
                    }, () => setTimerange({min, max}));
                    setDaterange(date_range);
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        // console.log('!!! componentDidUpdate Dashboard')

        const {
            chosenMall,
            complexRequest,
            daterange,
            // chosenTopFloor,
            setCurrentDateRangeFlag,
            location,
            isDateRangeConsistTodayDate,
            setTimerange,
            setDaterange
        } = this.props;
        const { id, open_time, close_time } = chosenMall // 1, 090000, 220000

        const mallDataHasCame = chosenMall !== prevProps.chosenMall && isEmpty(prevProps.chosenMall);
        const onClickedOnDashboardNavLink = prevProps.location.key !== location.key;

        //Current code almost always done when page reload or download at first time and we wait on chosenMall and mall_id where it necessary
        //or
        // was clicked on Dashboard NavLink
        if ((mallDataHasCame || (onClickedOnDashboardNavLink && isDateRangeConsistTodayDate)) && !isEmpty(chosenMall)) {
            // diff between start of day and open_time = 32400000ms (9 hours)
            // diff between start of day and close_time = 79200000ms (22 hours) 
            const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 32400000 
            const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 79200000

            const differenceTime  = moment(close_time, 'HHmmss').diff(moment(open_time, 'HHmmss'), 'hours') // 13 = 22-9
            const scheduleConfig = daterange.subst_unit === "day"
                ? {
                    group_range: getScheduleGroupRange(differenceTime),
                    add: {milliseconds: min},
                    subtract: {milliseconds: 86400000 - max - 60000 /*+1min*/},
                }
                : {};

            const obj = merge(clone(daterange), scheduleConfig);

            const mallDailyStatsOptions= {
                start_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(obj.range).startOf(obj.subst_unit).add(obj.add).utc().format('YYYYMMDDHHmmss'),
                end_ts: daterange.key === 'customTime'
                    ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
                    : moment().subtract(obj.range).endOf(obj.subst_unit).subtract(obj.subtract).utc().format('YYYYMMDDHHmmss'),
                mall_id: id
            }
            const zonesOptions= {
                mall_id: id
            }

            complexRequest(
                [
                    {name: "mallDailyStats", method: "get", type: `reports/analysis/daily${queryOptionsParser(mallDailyStatsOptions)}`},
                    {name: "zonesDailyStats", method: "get", type: `crossline/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
                    // {name: "zonesDailyStats", method: "get", type: `reports/analysis/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
                    {name: "zones", method: "get", type: `zones${queryOptionsParser(zonesOptions)}`},
                ],
                "GET_DASHBOARD_DATA",
                () => {
                    this.setState({
                        timeValues: { min, max },
                        minmaxTime: { minTime: min, maxTime: max }
                    }, () => setTimerange({   min, max }));
                    setDaterange(obj);
                }
            )

            // const visitorsOptions= {
            //     start_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).startOf(obj.subst_unit).add(obj.add).utc().format('YYYYMMDDHHmmss'),
            //     end_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).endOf(obj.subst_unit).subtract(obj.subtract).utc().format('YYYYMMDDHHmmss'),
            //     group_range: obj.group_range,
            //     mall_id: id
            // }
            // const heatmapOptions= {
            //     start_ts: moment(moment().subtract(6, 'day').format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
            //     end_ts: moment(moment().format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
            //     group_range: 3600,
            //     mall_id: id
            // }
            // const totalcountOptions = {
            //     start_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).startOf(obj.subst_unit).add(obj.add).utc().format('YYYYMMDDHHmmss'),
            //     end_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).endOf(obj.subst_unit).subtract(obj.subtract).utc().format('YYYYMMDDHHmmss'),
            //     mall_id: id
            // }
            // const prevtotalcountOptions = {
            //     start_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.prev_range).startOf(obj.subst_unit).add(obj.add).utc().format('YYYYMMDDHHmmss'),
            //     end_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.prev_range).endOf(obj.subst_unit).subtract(obj.subtract).utc().format('YYYYMMDDHHmmss'),
            //     mall_id: id
            // }
            // const averageVisitorsTimeOptions = {
            //     start_ts: daterange.key === 'customTime'
            //         ? moment(daterange.startDate).utc().format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).startOf(obj.subst_unit).add(obj.add).utc().format('YYYYMMDDHHmmss'),

            //     end_ts: daterange.key === 'customTime'
            //         ? moment(daterange.endDate).utc().format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).endOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss'),
            //     mall_id: id
            // }
            // const topzonesOptions= {
            //     start_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.startDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).startOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss'),
            //     end_ts: daterange.key === 'customTime'
            //         ? moment.utc(daterange.endDate).format('YYYYMMDDHHmmss')
            //         : moment().subtract(obj.range).endOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss'),
            //     mall_id: id
            // }
            // const zonesOptions= {
            //     mall_id: id
            // }

            // complexRequest(
            //     [
            //         {name: "heatmap", method: "get", type: `stats${queryOptionsParser(heatmapOptions)}`},
            //         {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsOptions)}`, catchType:{timestamp:[], visitors:[]}},
            //         // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsOptions)}`},
            //         // {name: "mallshops", method: "get", type: `shops${queryOptionsParser(mallshopsOptions)}`},
            //         // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsOptions)}`},
            //         {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
            //         {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
            //         {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`, catchType: {}},
            //         {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(topzonesOptions)}`},
            //         {name: "zones", method: "get", type: `zones${queryOptionsParser(zonesOptions)}`},
            //     ],
            //     "GET_DASHBOARD_DATA",
            //     () => {
            //         this.setState({
            //             timeValues: { min, max },
            //             minmaxTime: { minTime: min, maxTime: max }
            //         }, () => setTimerange({   min, max }));
            //         setDaterange(obj);
            //     }
            // );
        }

        if(prevProps.daterange !== daterange){
            const isCurrentDateRange = ['today', 'thisWeek', "thisMonth", "thisYear"].indexOf(daterange.key) >= 0;

            const isCustomDateRangeConsistTodayDate =
                daterange.key === 'customTime' &&
                (moment(daterange.endDate).isSame(moment(), 'day') || moment(daterange.endDate).isAfter(moment()));

            setCurrentDateRangeFlag(isCurrentDateRange || isCustomDateRangeConsistTodayDate);
        }
    }

    componentWillUnmount = () => {
        const {open_time = "000000", close_time = "235959"} = this.props.chosenMall;
        const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
        const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');

        this.props.setTimerange({min, max});
        // window.removeEventListener('scroll', this.handleScroll, true)
    }

    // changeMall = (mall) => {
    //     const {id, open_time, close_time} = mall;
    //     const {
    //         daterange,
    //         complexRequest,
    //         setChosentopfloor,
    //         removeVisitorsComparisonData,
    //         setVisibilityVisitorsDetailData,
    //         showDetailVisitorsData,
    //         setTimerange,
    //         setDaterange,
    //         setVisibilityHeatmap
    //     } = this.props;

    //     const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
    //     const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
    //     const differenceTime = moment(close_time, 'HHmmss').diff(moment(open_time, 'HHmmss'), 'hours');
    //     const scheduleConfig = daterange.subst_unit === "day"
    //         ? {
    //             group_range: getScheduleGroupRange(differenceTime),
    //             add: {milliseconds: min},
    //             subtract: {milliseconds: 86400000 - max - 60000 /*+1min*/}
    //         }
    //         : {};

    //     const date_range = merge(clone(daterange), scheduleConfig),
    //     {key, subst_unit, startDate, endDate, range, prev_range, add = 0, subtract = 0} = date_range;

    //     const report_start_ts = key === 'customTime'
    //         ? (
    //             subst_unit === "day"
    //             ? moment(startDate).startOf("day").add(add).utc().format('YYYYMMDDHHmmss')
    //             : moment.utc(startDate).format('YYYYMMDDHHmmss')
    //         )
    //         : moment().subtract(range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss');

    //     const report_end_ts = key === 'customTime'
    //         ? (
    //             subst_unit === "day"
    //             ? moment(endDate).endOf("day").subtract(subtract).utc().format('YYYYMMDDHHmmss')
    //             : moment.utc(endDate).format('YYYYMMDDHHmmss')
    //         )
    //         : moment().subtract(range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss');

    //     date_range.startDate = moment.utc(report_start_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss');
    //     date_range.endDate = moment.utc(report_end_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss');

    //     const visitorsOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             group_range: date_range.group_range,
    //             mall_id: id
    //         },
    //         // topshopsOptions = {
    //         //     start_ts: report_start_ts,
    //         //     end_ts: report_end_ts,
    //         //     count: 5,
    //         //     mall_id: id
    //         // },
    //         heatmapOptions = {
    //             start_ts: moment(moment().subtract(6, 'day').format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
    //             end_ts: moment(moment().format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
    //             group_range: 3600,
    //             mall_id: id
    //         },
    //         mall_option = {
    //             nvr: true,
    //             floor: true
    //         },
    //         // topfloorshopsOptions = {
    //         //     start_ts: report_start_ts,
    //         //     end_ts: report_end_ts,
    //         //     count: 5,
    //         //     mall_id: id,
    //         //     floor: 1,
    //         // },
    //         totalcountOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         prevtotalcountOptions = {
    //             start_ts: key === 'customTime'
    //                 ? report_start_ts
    //                 : moment().subtract(prev_range).startOf(subst_unit).add(add).utc().format('YYYYMMDDHHmmss'),
    //             end_ts: key === 'customTime'
    //                 ? report_end_ts
    //                 : moment().subtract(prev_range).endOf(subst_unit).subtract(subtract).utc().format('YYYYMMDDHHmmss'),
    //             mall_id: id
    //         },
    //         averageVisitorsTimeOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         topzonesOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         zonesOptions = {
    //             mall_id: id
    //         };

    //     setVisibilityHeatmap(false)
    //     complexRequest(
    //         [
    //             {name: "heatmap", method: "get", type: `stats${queryOptionsParser(heatmapOptions)}`},
    //             {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsOptions)}`, catchType:{timestamp:[], visitors:[]}},
    //             // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsOptions)}`},
    //             // {name: "mallshops", method: "get", type: `shops${queryOptionsParser({mall_id: id})}`},
    //             {name: "mall", method: "get", type: `mall/${id}${queryOptionsParser(mall_option)}`, catchType: {}},
    //             // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsOptions)}`},
    //             {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
    //             {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
    //             {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`, catchType: {}},
    //             {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(topzonesOptions)}`},
    //             {name: "zones", method: "get", type: `zones${queryOptionsParser(zonesOptions)}`},
    //         ],
    //         "CHANGE_MALL",
    //         () => {
    //             this.setState({
    //                 timeValues: {min, max},
    //                 minmaxTime: {minTime: min, maxTime: max}
    //             }, () => setTimerange({min, max}));
    //             setChosentopfloor(1);
    //             setDaterange(date_range);
    //             setTimerange({min, max});
    //             removeVisitorsComparisonData();
    //             showDetailVisitorsData && setVisibilityVisitorsDetailData(false);
    //         }
    //     );
    // };

    // handleDataRangeClick = (eventKey, event) => {
    //     event.preventDefault();
    //     const {
    //         chosenMall,
    //         // chosenTopFloor,
    //         removeVisitorsComparisonData,
    //         complexRequest,
    //         setVisibilityVisitorsDetailData,
    //         showDetailVisitorsData,
    //         setTimerange,
    //         setDaterange,
    //         showHeatmap
    //     } = this.props;
    //     const {id, open_time, close_time} = chosenMall;

    //     const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
    //     const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
    //     const differenceTime = moment(close_time, 'HHmmss').diff(moment(open_time, 'HHmmss'), 'hours');

    //     let obj = DATERANGES.find(({key}) => key === eventKey);

    //     const report_start_ts = (['today', 'yesterday'].indexOf(obj.key) > -1)
    //         ? moment().subtract(obj.range).startOf(obj.subst_unit).add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
    //         : moment().subtract(obj.range).startOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss');

    //     const report_end_ts = (['today', 'yesterday'].indexOf(obj.key) > -1)
    //         ? moment().subtract(obj.range).startOf(obj.subst_unit).add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
    //         : moment().subtract(obj.range).endOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss');

    //     const scheduleConfig = ((['today', 'yesterday'].indexOf(obj.key) > -1))
    //         ? {
    //             group_range: getScheduleGroupRange(differenceTime),
    //             add: {milliseconds: min},
    //             subtract: {milliseconds: 86400000 - max - 60000 /*1min*/},
    //         }
    //         : {};


    //     obj = merge(
    //         clone(obj),
    //         {
    //             key: eventKey,
    //             startDate: moment(report_start_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
    //             endDate: moment(report_end_ts, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
    //         },
    //         scheduleConfig
    //     );

    //     const visitorsParams = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             group_range: obj.group_range,
    //             mall_id: id
    //         },
    //         // topshopsParams = {
    //         //     start_ts: report_start_ts,
    //         //     end_ts: report_end_ts,
    //         //     count: 5,
    //         //     mall_id: id
    //         // },
    //         // topfloorshopsParams = {
    //         //     start_ts: report_start_ts,
    //         //     end_ts: report_end_ts,
    //         //     count: 5,
    //         //     mall_id: id,
    //         //     floor: chosenTopFloor
    //         // },
    //         totalcountOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         prevtotalcountOptions = {
    //             start_ts:(['today', 'yesterday'].indexOf(obj.key) > -1)
    //                 ? moment().subtract(obj.prev_range).startOf(obj.subst_unit).add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
    //                 : moment().subtract(obj.prev_range).startOf(obj.subst_unit).utc().format('YYYYMMDDHHmmss'),
    //             end_ts: (['today', 'yesterday'].indexOf(obj.key) > -1)
    //                 ? moment().subtract(obj.prev_range).startOf(obj.subst_unit).subtract({second: 1}).add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
    //                 : moment().subtract(obj.prev_range).endOf(obj.subst_unit).subtract({second: 1}).utc().format('YYYYMMDDHHmmss'),
    //             mall_id: id
    //         },
    //         averageVisitorsTimeOptions = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         zonesParams = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id
    //         },
    //         heatmapParams = {
    //             start_ts: report_start_ts,
    //             end_ts: report_end_ts,
    //             mall_id: id,
    //             floor: 1
    //         };


    //     const arrComplexRequest = [
    //         {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsParams)}`, catchType:{timestamp:[], visitors:[]}},
    //         // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsParams)}`},
    //         // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsParams)}`},
    //         {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
    //         {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
    //         {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`},
    //         {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(zonesParams)}`},
    //     ]
    //     if (showHeatmap) {
    //         arrComplexRequest.push({
    //             name: "floorHeatmap", method: "get", type: `stats/heatmap${queryOptionsParser(heatmapParams)}`
    //         })
    //     }
    //     complexRequest(
    //         arrComplexRequest,
    //         "GET_VISITORS_AND_TOPSHOPS",
    //         () => {
    //             this.setState({ timeValues: {min, max}}, () => {
    //                 setDaterange(obj);
    //                 setTimerange({min, max});
    //                 removeVisitorsComparisonData();
    //                 showDetailVisitorsData && setVisibilityVisitorsDetailData(false);
    //             });
    //         }
    //     );
    // }

    // handleScroll = () => {
    //     const elem = ReactDOM.findDOMNode(this.refs.elementToScroll)
    //     const row =  document.getElementById("row")

    //     if (window.scrollY > 150 && elem && elem.classList && !elem.classList.contains('sticky')) {
    //         elem.classList.add("sticky");
    //         row.style.marginTop = "155px";
    //     }
    //     else if (window.scrollY <= 150 && elem && elem.classList && elem.classList.contains('sticky')) {
    //         this.setState({ showTimeline: true }, () => {
    //             elem.classList.remove("sticky");
    //             row.style.marginTop = "0";
    //         })
    //     }
    // };

    handleSwapStoreClick = (e) => {
        e.preventDefault()
        const { switchSwapStore } = this.props
        switchSwapStore(true)
    }

    handleCollapseClick = () => {
        const { showTimeline } = this.state
        this.setState({ showTimeline: !showTimeline })
    }

    handleSliderChange = ({min, max}) => {
        // console.log('handleSliderChange: ', min, max)
        const { setTimerange, daterange, chosenMall, complexRequest, showHeatmap, showHeatmapClustered, showHeaqtmapClustered } = this.props;
        const { startDate, endDate } = daterange;
        const report_start_ts = moment(startDate).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
        const report_end_ts = moment(endDate).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
        const { id } = chosenMall

        setTimerange({min, max})

        const mallDailyStatsOptions = {
            start_ts: report_start_ts,
            end_ts:  report_end_ts,
            mall_id: id,
        }
        const heatmapParams = {
            start_ts: report_start_ts,
            end_ts: report_end_ts,
            mall_id: id,
            floor: 1
        }
        const arrComplexRequest = [
            {name: "mallDailyStats", method: "get", type: `reports/analysis/hourly${queryOptionsParser(mallDailyStatsOptions)}`},
            // {name: "zonesDailyStats", method: "get", type: `reports/analysis/hourly/zones${queryOptionsParser(mallDailyStatsOptions)}`},
            {name: "zonesDailyStats", method: "get", type: `crossline/hourly/zones${queryOptionsParser(mallDailyStatsOptions)}`},
        ]
        if (showHeatmap) {
            if (showHeatmapClustered) {
                arrComplexRequest.push({
                    name: "floorHeatmap", method: "get", type: `stats/heatmap/debug${queryOptionsParser(heatmapParams)}`
                })
            } else {
                arrComplexRequest.push({
                    name: "floorHeatmap", method: "get", type: `stats/heatmap${queryOptionsParser(heatmapParams)}`
                })
            }
        }
        complexRequest(
            arrComplexRequest,
            "GET_VISITORS_AND_TOPSHOPS"/*,
            () => {
                setProps({ timeValues: {min, max}}, () => setTimerange({min, max}));
                setDaterange(date_range);
                removeVisitorsComparisonData();
                showDetailVisitorsData && setVisibilityVisitorsDetailData(false);
            }*/
        );

        // const obj = DATERANGES.find(({key}) => key === daterange.key);
        // const totalcountOptions = {
        //     start_ts: daterange.key === 'customTime'
        //         ? moment(daterange.endDate).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.range).startOf(obj.subst_unit).add({milliseconds: min}).utc().format('YYYYMMDDHHmmss'),
        //     end_ts: daterange.key === 'customTime'
        //         ? moment(daterange.endDate).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.range).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss'),
        //     mall_id: chosenMall.id,
        //     respdict:"visitsvisitors"
        // }
        // const prevtotalcountOptions = {
        //     start_ts:  daterange.key === 'customTime'
        //         ? moment(daterange.endDate).subtract({day: 1}).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.prev_range).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss'),
        //     end_ts: daterange.key === 'customTime'
        //         ? moment(daterange.endDate).subtract({day: 1}).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.prev_range).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss'),
        //     mall_id: chosenMall.id,
        //     respdict:"visitsvisitors"
        // }
        // const heatmapParams = {
        //     start_ts: daterange.key === 'customTime'
        //         ? moment(daterange.endDate).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.range).startOf(obj.subst_unit).add({milliseconds: min}).utc().format('YYYYMMDDHHmmss'),
        //     end_ts: daterange.key === 'customTime'
        //         ? moment(daterange.endDate).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss')
        //         : moment().subtract(obj.range).startOf("day").add({milliseconds: max}).utc().format('YYYYMMDDHHmmss'),
        //     mall_id: chosenMall.id,
        //     floor: 1
        // }
        // const arrComplexRequest = [
        //     {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
        //     {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
        // ]
        // if (showHeatmap) {
        //     arrComplexRequest.push({
        //         name: "floorHeatmap", method: "get", type: `stats/heatmap${queryOptionsParser(heatmapParams)}`
        //     })
        // }
        // complexRequest(
        //     arrComplexRequest,
        //     "GET_TOTALCOUNT"
        // )
    }

    setProps = (states) => {
		this.setState( states )
	}

    render() {
        const { timeValues, showTimeline, /*calendar,*/ minmaxTime: {minTime, maxTime} } = this.state
        const {
            showSwapStore,
            chosenStatsBlock,

            daterange,
            // showTopShopPopover,
            showShop,
            // showShopComparison,
            showZoneComparison,
            heatmap,
            malls,
            user: {roles},
            chosenMall,
            showStoresSelectList,
            language,
        } = this.props;
        const { open_time, close_time } = chosenMall;
        const { data, code, direction } = language;
        const { genericLabels, dashboardPage } = data;
        const { daterangesLabels, calendarHeatmapBlock, swapStoreLabels } = dashboardPage;
        const { selectDateRangeTitle, applyTitle } = genericLabels;
        const { swapStore } = swapStoreLabels;

        // const min = chosenMall.open_time && moment(chosenMall.open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');
        // const max = chosenMall.close_time && moment(chosenMall.close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds');

        // const mallOpenTime = moment.duration(min, 'milliseconds').format('HH:mm', {trim: false});
        // const mallCloseTime = moment.duration(max, 'milliseconds').format('HH:mm', {trim: false});

        // console.log('open_time, close_time: ', open_time, close_time)
        // console.log('minTime, maxTime: ', minTime, maxTime)
        // console.log('open_time: ', moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'hours'))
        // console.log('close_time: ', moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'hours'))

        const startHoursWork = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'hours')
        const finishHoursWork = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'hours')

        return (
            <div className={`content dashboardPage ${(showSwapStore || showZoneComparison) ? "shop_modal disabled" : ""} ${showStoresSelectList ? "visible_shops_list" : ""}`}>
                <div className={`headerWrapper row ${!showTimeline && 'collapsed'}`}>
                    <div className="col-md-3">
                        <div style={{ width: 'fit-content' }}>
                            <h2 className="page_title">
                                {(chosenMall && chosenMall.name) ? chosenMall.name : "Absent"}
                            </h2>
                            <button
                                className="swapStore"
                                onClick={this.handleSwapStoreClick}
                            >
                                {swapStore}
                            </button>
                        </div>
                    </div>

                    <div className="col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                        {showTimeline
                        && (
                            <div className="timeline-wrapper">
                                <div className="card-body1">
                                    <div className="stats-slider">
                                        <InputRange
                                            minValue={minTime}
                                            maxValue={maxTime}
                                            // step={900000}
                                            draggableTrack={false}
                                            step={3600000}
                                            disabled={daterange.subst_unit !== "day"}
                                            value={timeValues}
                                            onChange={timeValues => this.setState({timeValues})}
                                            onChangeComplete={(timeValues) => this.handleSliderChange(timeValues)}
                                            formatLabel={tipFormatter}
                                            direction={direction}
                                        />
                                        <ul className="timelineLabelsWrapper">
                                            {
                                                _.range(startHoursWork, finishHoursWork+1).map((item) =>
                                                    <li
                                                        key={item}
                                                        className="timelineLabel"
                                                    >
                                                        {`${item < 10 ? '0': ''}${item}:00`}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        <DashboardCalendarContainer 
                            setProps={this.setProps}
                        />

                        {!showTimeline
                        && (
                            <Fragment>
                                <span style={{ margin: '0 8px 0 16px' }}>
                                    Sorted by
                                </span>
                                <StatsSliderContainer
                                    showTimeline={showTimeline}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>

                {showTimeline
                && (
                    <StatsSliderContainer
                        showTimeline={showTimeline}
                    />
                )}

                <div className={`separatorCollapse ${!showTimeline && 'collapsed'}`}>
                    <Button
                        variant="primary"
                        className="collapseBtn"
                        onClick={this.handleCollapseClick}
                    >
                        <span className="title">{showTimeline ? 'Collapse' : 'Expand'}</span>
                        <i className={`fas fa-angle-${showTimeline ? 'up' : 'down'}`} />
                    </Button>
                </div>

                <div id="row" style={{ display: 'flex', position: 'relative', overflowY: 'auto', overflowX: 'hidden' }}>
                    <div>
                        <MallMapContainer />
                    </div>
                    
                    <TopZonesContainer />
                </div>

                {showStoresSelectList && <StoresSelectListContainer />}

                {showZoneComparison && <ZoneComparisonContainer />}

                {showSwapStore && <SwapStoreContainer />}
            </div>
        )
    }
}

export default Dashboard
