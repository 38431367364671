import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router";
import queryString from "query-string";
import classNames from "classnames";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

import "../styles/StartPage.sass";



class StartPage extends Component{
    componentDidMount(){
        const { location, newUserInvite } = this.props;

        if (location.pathname === "/new_user" && queryString.parse(location.search).profile) {
            newUserInvite(true);
        }
    }

    render() {
        const { logIn, forgotPassword, resetPassword, language } = this.props;
        const { data, direction } = language;

        return (
            <div className={classNames('start_page_wrapper', direction)}>
                <Switch>
                    <Route path="/login" render={() => <Login logIn={logIn} language={data}/>}/>
                    <Route path="/forgot" render={() => <ForgotPassword forgotPassword={forgotPassword} language={data}/>}/>
                    <Route path="/reset" render={() => <ResetPassword resetPassword={resetPassword} language={data}/>}/>

                    <Redirect to="/login"/>
                </Switch>
            </div>
        )
    }
}

export default StartPage;
