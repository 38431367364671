import { connect } from 'react-redux'
import DashboardCalendar from './DashboardCalendar'
import {
    setTimerange,
    setDaterange,
    removeVisitorsComparisonData,
    setVisibilityVisitorsDetailData,
} from '../../actions/filters'
import { complexRequest } from "../../actions/apiRequests"

const mapStateToProps = ({ dashboardData, filters, adminData, user, language }) => {
    return {
        chosenMall: dashboardData.chosenMall,
        daterange: dashboardData.daterange,
        showDetailVisitorsData: filters.showDetailVisitorsData,
        showHeatmap: filters.showHeatmap,
        showHeatmapClustered: filters.showHeatmapClustered,
        language,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDaterange: (state) => dispatch(setDaterange(state)),
        setTimerange: (state) => dispatch(setTimerange(state)),
        complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
        removeVisitorsComparisonData:() => dispatch(removeVisitorsComparisonData()),
        setVisibilityVisitorsDetailData:state => dispatch(setVisibilityVisitorsDetailData(state)),
    }
}

const DashboardCalendarContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardCalendar)

export default DashboardCalendarContainer
