import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import {toastr} from "react-redux-toastr";
import KeyboardEventHandler from "react-keyboard-event-handler";
import _ from "lodash";
import readImage from "../../helpers/readImage";
import validate from "../../helpers/validate";
import Image from "../Image";

class ShopsOwnersAdminComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            id: ""
        };

        this.file = React.createRef();
        this.modal = React.createRef();
    }

    componentDidMount(){
        const {user, role, getShopsOwners } = this.props;
        if (role !== null && user.id){
            getShopsOwners();
        }
    }

    componentDidUpdate(prevProps){
        const { user, getShopsOwners, openAddForm, location } = this.props;
        if(user !== prevProps.user || prevProps.location.key !== location.key){
            getShopsOwners()
        }

        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    addData(){
        const { id, main_image, ...request } = this.state;
        const { setForm, addShopOwner, language } = this.props;
        const { warningTitle, filledInMessage } = language.notifications;

        if (validate(request, { warningTitle, filledInMessage })){
            if (typeof main_image === "object" && main_image !== null){
                request.main_image = main_image
            }

            setForm(false, false);
            addShopOwner(_.pickBy(request))
        }
    }

    editData(){
        const { id, main_image, ...request } = this.state;
        const { setForm, editShopOwner, language } = this.props;
        const { warningTitle, filledInMessage } = language.notifications;

        if (validate(request, { warningTitle, filledInMessage })){
            if (typeof main_image === "object" && main_image !== null){
                request.main_image = main_image
            }else if(main_image === ""){
                request.main_image = ""
            }

            editShopOwner(id, request);
            setForm(false, false);

            this.setState({
                first_name: "",
                last_name: "",
                id: "",
                main_image: ""
            });
            this.file.current.value = "";
        }
    }

    switchEditMode(owner){
        const { setForm } = this.props;
        setForm(true, true);
        this.setState({...owner});
    }

    handleChangeForm(){
        const { setForm, openAddForm } = this.props;
        setForm(!openAddForm, false);
        this.setState({
            first_name: "",
            last_name: "",
            id: "",
            main_image: ""
        });
        this.file.current.value = "";
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.handleChangeForm();
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    render(){
        const { first_name, last_name, main_image, id } = this.state;
        const {
            shops_owners,
            deleteShopOwner,
            editMode,
            openAddForm,
            language
        } = this.props;

        const { columns, adminSection, genericLabels, notifications } = language;
        const { deleteActionConfirmationMessage } = notifications;
        const {
            photoLabel,
            nameLabel,
            surnameLabel,
        } = columns;
        const {
            mainTitle,
            addOwnerTitle,
            editShopOwnerTitle,
        } = adminSection.shopsOwnersPage;
        const {
            tableCountPaginationLabels,
            searchFormPlaceholder,
            deleteButtonTitle,
        } = genericLabels;

        const data = {
            columns: [
                {
                    label: photoLabel,
                    field: 'main_image',
                    sort: 'disabled',
                    minimal: 'sm none_sort'
                },
                {
                    label: nameLabel,
                    field: 'first_name',
                    sort: 'asc'
                },
                {
                    label: surnameLabel,
                    field: 'last_name',
                    sort: 'asc'
                }
            ],
            rows: !_.isEmpty(shops_owners) ? shops_owners.map((item) => {
                return {
                    main_image: <Image src={item.main_image} alt="avatar" className="ava" spinnerSize={40} failedSrc="/images/profile.png"/>,
                    first_name: item.first_name ? item.first_name : "",
                    last_name: item.last_name ? item.last_name : "",
                    clickEvent: () => this.switchEditMode(item)
                }
            }) : []
        };

        return (
            <div className={`block_wrapper admin ${openAddForm ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <div className="wrap_header">
                    <h2 className="page_title">{mainTitle}</h2>

                    <Button
                        className="btn_new_item"
                        variant="outline-dark"
                        onClick={() => this.handleChangeForm()}
                    >
                        {addOwnerTitle}
                    </Button>

                    <div className={`centralise ${openAddForm ? "open" : ""}`}>
                        <Form className="add_new_data" ref={this.modal}>
                            <h3 className="mb-4">{editMode ? editShopOwnerTitle : addOwnerTitle}</h3>

                            <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/>

                            <div className="main_data mb-3">
                                <div className="main_info owners">
                                    <Form.Group>
                                        <Form.Label>{nameLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={nameLabel}
                                            value={first_name}
                                            onKeyDown={this.handleEnter}
                                            onChange={(e) => this.setState({first_name: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>{surnameLabel}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={surnameLabel}
                                            value={last_name}
                                            // onKeyPress={(e) => e.key === "Enter" && (editMode ? this.editData() : this.addData())}
                                            onChange={(e) => this.setState({last_name: e.target.value})}/>
                                    </Form.Group>
                                </div>

                                <div className="avatar_block owners">
                                    {main_image &&
                                    <button className="close_btn local"
                                            type="button"
                                            onClick={() => {
                                                this.file.current.value = "";
                                                this.setState({main_image: ""})
                                            }}
                                    />}
                                    <img className="photo"
                                         src={main_image ? main_image : "/images/profile.png"}
                                         onErrorCapture={e => e.target.src = "/images/profile.png"}
                                         id="owner_image"
                                         alt="owner avatar"/>

                                    <Form.Group>
                                        <Form.Label htmlFor="ownerFile" className="labelForPhoto">
                                            <img src="/images/plus_green.svg" alt="plus"/>
                                        </Form.Label>

                                        <Form.Control
                                            type="file"
                                            id="ownerFile"
                                            name="ownerFile"
                                            className="inputfile"
                                            ref={this.file}
                                            onChange={(e) => {
                                                this.setState({main_image: e.target.files[0]});
                                                readImage("ownerFile", "owner_image");
                                            }}
                                            placeholder="image"/>
                                    </Form.Group>
                                </div>
                            </div>

                            {editMode ?
                                <div className="wrap_for_submit-buttons">
                                    <Button
                                        variant="outline-success"
                                        onClick={() => this.editData()}>
                                        {editShopOwnerTitle}
                                    </Button>

                                    <Button
                                        onClick={() => toastr.confirm(
                                            deleteActionConfirmationMessage,
                                            {onOk: () => deleteShopOwner(id)},
                                            )}
                                        variant="outline-danger">
                                        {deleteButtonTitle}
                                    </Button>
                                </div>
                                :
                                <Button
                                    variant="outline-success"
                                    className="submit"
                                    onClick={() => this.addData()}
                                >
                                    {addOwnerTitle}
                                </Button>}
                        </Form>
                    </div>
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                />
            </div>
        )
    }
}

export default ShopsOwnersAdminComponent
