import moment from "moment"

export function getSubstUnit (diff){
    if (diff < 1) {
        return"day"
    }else if (diff < 7) {
        return"isoWeek"
    }else if (diff < 31) {
        return "month"
    }else if (diff < 365) {
        return "year"
    }else {
        return false
    }
}


export function getDataGroup(startDate, endDate){
    const diff = moment(endDate).diff(moment(startDate), "days");

    if (diff === 0) {
        return 7200
    }else if(diff > 0 && diff < 5){
        return diff * 10800
    }else if(diff > 5 && diff <= 30){
        return 86400
    }else if(diff >= 30 && diff < 365){
        return 172800 * moment(endDate).diff(moment(startDate), "months")
    }else {
        return 86400
    }
}

export function getDataGroupNew(startDate, endDate){
    const diff = moment(endDate).diff(moment(startDate), "days");

    if (diff === 0) {
        return 7200
    }else if(diff > 0 && diff <= 30){
        return 86400
    }else if(diff > 30 && diff < 365){
        return 172800 * moment(endDate).diff(moment(startDate), "months")
    }else {
        return 86400
    }
}

export function getDaterangeTitle(key, genericLabels){
    switch (key) {
        case 'thisWeek':
        case 'lastWeek':
            return genericLabels.week;
        case 'thisMonth':
        case 'lastMonth':
            return genericLabels.month;
        case 'thisYear':
        case 'lastYear':
            return genericLabels.year;
        case 'customTime':
            return genericLabels.custom;
        default:
            return genericLabels.date
    }
}

export function tipFormatter (value){
    if (value === 86400000) {
        return '23:59'
    }else if (value === 0) {
        return '00:00'
    } else {
        return moment.duration(value, 'milliseconds').format('hh:mm', {trim: false})
    }
}
