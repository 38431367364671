import moment from "moment";
import {toastr} from "react-redux-toastr";

export default function checkCorrectDate(startDate, endDate, { attentionTitle,  validationDateMessage }){
    if(moment(startDate).isBefore(endDate)){
        return true
    }else {
        toastr.info(attentionTitle, validationDateMessage);
        return false
    }
}