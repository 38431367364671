const defaultState = {  
    availableDebuggedDays: [],
    // clusters: [],
    // clusterImages: [
    //     // {
    //     //   "age": "0", 
    //     //   "file_path": "https://maxstock.arigo-ai.com/api/v1/imgs/debug-tools/16399/63_0.77814007_0.436632.jpg", 
    //     //   "frame_no": 63, 
    //     //   "frame_time": "Mon, 07 Dec 2020 13:02:09 GMT", 
    //     //   "gender": "0", 
    //     //   "id": 41231004, 
    //     //   "map_x": 935.0, 
    //     //   "map_y": 572.0
    //     // }, 
    //     // {
    //     //   "age": "0", 
    //     //   "file_path": "https://maxstock.arigo-ai.com/api/v1/imgs/debug-tools/16399/64_0.7783217_0.43634582.jpg", 
    //     //   "frame_no": 64, 
    //     //   "frame_time": "Mon, 07 Dec 2020 13:02:11 GMT", 
    //     //   "gender": "0", 
    //     //   "id": 41231006, 
    //     //   "map_x": 935.0, 
    //     //   "map_y": 571.0
    //     // }, 
    //     // {
    //     //   "age": "0", 
    //     //   "file_path": "https://maxstock.arigo-ai.com/api/v1/imgs/debug-tools/16399/65_0.77837425_0.43645263.jpg", 
    //     //   "frame_no": 65, 
    //     //   "frame_time": "Mon, 07 Dec 2020 13:02:13 GMT", 
    //     //   "gender": "0", 
    //     //   "id": 41231008, 
    //     //   "map_x": 935.0, 
    //     //   "map_y": 571.0
    //     // }
    // ],
    // newCalculationImages: [],
    cameras: [],
};

const debugtoolData = (state = defaultState, action) => {
    switch (action.type) {
        case 'GET_DEBUGTOOL_AVAILABLE_DAYS_FULFILLED': {
            if (action.payload) {
                return Object.assign({}, state, {
                    availableDebuggedDays: action.payload.availabledays
                });
            }
            return state
        }
        // case 'GET_DEBUGTOOL_CLUSTERS_FULFILLED': {
        //     if (action.payload) {
        //         return Object.assign({}, state, {
        //             clusters: action.payload.clusters,
        //             clusterImages: [],
        //         });
        //     }
        //     return state
        // }
        // case 'GET_DEBUGTOOL_CLUSTER_IMAGES_FULFILLED': {
        //     if (action.payload) {
        //         return Object.assign({}, state, {
        //             clusterImages: action.payload.images
        //         });
        //     }
        //     return state
        // }
        // case 'GET_DEBUGTOOL_NEWCALCULATION_IMAGES_FULFILLED': {
        //     if (action.payload) {
        //         return Object.assign({}, state, {
        //             newCalculationImages: action.payload.images
        //         });
        //     }
        //     return state
        // }
        case 'GET_DEBUGTOOL_CAMERAS_FULFILLED': {
            if (action.payload) {
                return Object.assign({}, state, {
                    cameras: action.payload.cameras,
                });
            }
            return state
        }
        default:
            return state;
    }
};

export default  debugtoolData
