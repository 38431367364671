import React  from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { statBlocks } from '../../helpers/helperStatBlocks'

import './FilterTooltip.sass'

const FilterTooltip = ({ filterTooltips, setFilterTooltips }) => {
	const options = statBlocks.map(({id, name, apiKey}) => ({ id, value:id, label: name, apiKey }))
	
	function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
		/*if (value && value.some((o) => o.value === "*")) {
			return `${placeholderButtonLabel}: All`;
		} else {*/
		return `${placeholderButtonLabel} ${value.length}/3`;
		//}
	}
	
	function onChange(value, event) {
		if (value && value.length > 3) {
		 	return false
		}
		// if (event.action === "select-option" && event.option.value === "*") {
		//   this.setState(this.options);
		// } else if (
		//   event.action === "deselect-option" &&
		//   event.option.value === "*"
		// ) {
		//   this.setState([]);
		// } else if (event.action === "deselect-option") {
		//   	this.setState(value.filter((o) => o.value !== "*"));
		// } else if (value.length === this.options.length - 1) {
		//   	this.setState(this.options);
		// } else {
		//   	this.setState(value);
		// }
		this.setState(value);
	}	

	// console.log('PROPS FilterTooltip selectedOptions: ', selectedOptions)
	return (
		<div className="filterTooltip">
			<ReactMultiSelectCheckboxes
				// options={[{ label: "All", value: "*" }, ...options]}
				className={"filterTooltipDropdown"}
				options={[ ...options ]}
				placeholderButtonLabel="Filter Tool tip"
				getDropdownButtonLabel={getDropdownButtonLabel}
				value={filterTooltips}
				onChange={onChange}
				setState={setFilterTooltips}
			/>
		</div>
	)
}

export default FilterTooltip
