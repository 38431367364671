import React, { Component } from 'react';
import { Dropdown, DropdownButton, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isEmpty, range, some } from "lodash";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mouseWheelZoom } from 'mouse-wheel-zoom'

// import { MapInteractionCSS } from 'react-map-interaction';
// import PanAndZoomImage from './PanAndZoomImage'

import "../../styles/CamersAdminHeatmapComponent.sass"

class CameraMappingAnchor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedFloor: '',
            // markers: [],
            // floorImageWidth: 100,
            // snapshotWidth: 100,
            // refresh: 0,

            scalemap: 1,
            scalesnapshot: 1,
            // value: {
            //     scale: 1,
            //     translation: { x: 0, y: 0 }
            // },
        };

        this.shift = {shiftY: 0, shiftX: 0}
    }

    handleImageLoad = () => {
        const wz = mouseWheelZoom({
            element: document.querySelector('[data-wheel-zoom]'),
            zoomStep: .25
        });

        // reset zoom
        wz.reset();
    }
    // componentDidMount() {
    //     const { mappingMarkers } = this.props;

    //     if (!isEmpty(mappingMarkers)) {
    //         const markers = cloneDeep(mappingMarkers);

    //         this.setState({ markers })
    //     }
    // }

    // componentDidUpdate(prevProps) {
    //     const { mappingMarkers } = this.props;

    //     if (prevProps.mappingMarkers !== mappingMarkers) {
    //         const markers = cloneDeep(mappingMarkers);

    //         this.setState({ markers })
    //     }
    // }

    // componentDidMount() {
    //     const { complexRequest, location: {state} } = this.props;
    //     const { mall_id, camera_id} = state
    //     console.log('!componentDidMount CamersAdminHeatmapComponent: ', state)

    //     // const {chosenMall, editMode, setForm, getMall, location: {state}} = this.props;

    //     if ( state && mall_id && camera_id ) {
    //         const mallParams = { floor: true };
    //         complexRequest(
    //             [
    //                 {name: "mall", method: "get", type: `mall/${mall_id}${queryOptionsParser(mallParams)}`},
    //                 {name: "camera", method: "get", type: `camera/${camera_id}`},
    //             ],
    //             "GET_MALL_AND_CAMERA"
    //         );
    //     }
    // }

    handleMarkerClick = (markerNumber) => {
        const { markers, setProps } = this.props
        const mmm = [...markers]
        mmm.push({ number: markerNumber, snapshot: {x:50, y: 50}, map: {x: 50, y: 50} })
        setProps({ markers: mmm })
    }

    handleMarkerRemoveClick = (markerNumber) => {
        const { markers, setProps } = this.props

        setProps({ markers: markers.filter(({number}) => number !== markerNumber)})
    }

    handleDrop = (e) => {
        const { markers, setProps } = this.props;
        const { scalemap, scalesnapshot } = this.state;
        const { number, type} = e.dragData
        const mmm = [...markers]
        // console.log('handleDrop scalemap, scalesnapshot: ', scalemap, scalesnapshot)
        // console.log('handleDrop number, type: ', number, type)

        // const dropBlock = document.querySelectorAll('.drop-target-' + type)[0]
        // const blockRect = dropBlock.getBoundingClientRect()
        const dropImage = document.querySelectorAll('.drop-image-' + type)[0]
        const imgRect = dropImage.getBoundingClientRect()


        // const y = e.y - imgRect.y - blockRect.y - this.shift.shiftY
        // const x = e.x - imgRect.x - blockRect.x - this.shift.shiftX
        // const y = (e.y - imgRect.y)/scalemap - this.shift.shiftY
        const y = ((e.y - imgRect.y)/*/this.state['scale' + type]*/ - this.shift.shiftY) / this.state['scale' + type]
        const x = ((e.x - imgRect.x)/*/this.state['scale' + type]*/ - this.shift.shiftX) / this.state['scale' + type]

        // console.log('dropImage: ', dropImage)
        // console.log('imgRect y,x: ', JSON.stringify(imgRect), JSON.stringify(imgRect.y), JSON.stringify(imgRect.x))
        // console.log('mouse e y,x: ', JSON.stringify(e.y), JSON.stringify(e.x))
        // console.log('this.shift y,x: ', JSON.stringify(this.shift.shiftY), JSON.stringify(this.shift.shiftX))
        // console.log('y,x: ', JSON.stringify(y), JSON.stringify(x))

        const activeMarker = mmm.find((item) => item.number === number )
        activeMarker[type] = {x, y}
        setProps({ markers: mmm })
    }

    handleMarkerMouseDown = (e) => {
        console.log('!!!!!!handleMarkerMouseDown')
        e.stopPropagation()
        const dragImage = e.target

        const getCoords = (elem) => {
            const box = elem.getBoundingClientRect()
            return {
              top: box.top,
              left: box.left,
            }
        }
        const coords = getCoords(dragImage)
        const shiftX = e.clientX - coords.left
        const shiftY = e.clientY - coords.top

        this.shift = {shiftY, shiftX}
        console.log('!!!handleMarkerMouseDown', this.shift)
    }


    render() {
        // console.log('PROPS CameraMappingAnchor: ', this.props)
        // console.log('!CamersAdminHeatmapComponent render state: ', this.state)
        const self = this
        const { markers, chosenMall, chosenCamera, selectedFloor, setProps, language } = this.props
        const { scalesnapshot, scalemap } = this.state
        const { floors } = chosenMall
        const { snapshot } = chosenCamera

        const {
            genericLabels,
            adminSection: { camerasPage },
        } = language;
        const {
            snapshotTitle,
            emptyFloorImagesMessage,
            selectFloorTitle,
            cameraMappingTitle,
        } = camerasPage;

        const {
            clickToAddTitle,
            clickToRemove,
            selectFloorTooltipMessage,
            floorTitle,
            mapTitle,
        } = genericLabels;

        if (isEmpty(chosenMall) || isEmpty(chosenCamera)) {
            return (
                <div className="content block_wrapper admin">
                    <h3 className="mb-4 edit_title">{cameraMappingTitle}</h3>
                </div>
            )
        }

        // const { refresh, floorImageWidth, snapshotWidth } = this.state
        // const { name, title, snapshot, floor_number } = chosenCamera
        // const selectedFloor = this.props.selectedFloor || ((floors && floor_number)  ? floors.find((item) => item.number === floor_number ) : '')

        return (
            <div className="camera-mapping-anchor">
                <Row>
                    <Col md={6}>
                        <div className="map-title">{snapshotTitle}</div>
                        <div className="map-block drop-target-snapshot">
                            {/* <img src={selectedFloor.floor_image} width={`${floorImageWidth}%`} alt="" /> */}
                            {selectedFloor && selectedFloor.floor_image
                            ? (
                                <TransformWrapper
                                    // minScale={1} maxScale={5} scale={s} positionX={x} positionY={y}
                                    defaultScale={1}
                                    defaultPositionX={0}
                                    defaultPositionY={0}
                                    options={{
                                        limitToBounds: false,
                                        centerContent: true,
                                    }}
                                    pan={{
                                        velocity: false,
                                    }}
                                    scalePadding={{
                                        animationTime: 0,
                                    }}
                                    doubleClick={{
                                        disabled: true,
                                    }}
                                    // onZoomChange={ (a) => {console.log('onZoomChange: ', a);} }
                                    onWheelStop={ (a) => {this.setState({ scalesnapshot: a.scale}); console.log('onWheelStop: ', a);} }
                                >
                                    <TransformComponent>
                                        <DropTarget
                                            targetKey="drop-target-snapshot"
                                            onHit={this.handleDrop}
                                        >
                                            <img className="drop-image-snapshot" src={snapshot} alt="" />
                                        </DropTarget>

                                        {
                                            markers.map( (item, index) => {
                                                return (
                                                    <span
                                                        key={index}
                                                        style={{ transformOrigin: '0 0', transform:`translate(0,0) scale(${1/scalesnapshot})`, lineHeight:0, position:'absolute', top:`${item.snapshot.y}px`, left:`${item.snapshot.x}px` }}
                                                        // style={{ transformOrigin: '0 0', transform:`translate(${item.snapshot.x}px, ${item.snapshot.y}px) scale(${1/scalesnapshot})`, lineHeight:0, position:'absolute', top:0, left:0 }}
                                                        onMouseDown = {self.handleMarkerMouseDown}
                                                    >
                                                        <DragDropContainer
                                                            targetKey="drop-target-snapshot"
                                                            dragClone={false}
                                                            dragData={{number: item.number, type: 'snapshot'}}
                                                        >
                                                            <img
                                                                alt=""
                                                                src={`/images/mapping/idle_${item.number}.png`}
                                                                onMouseDown = {self.handleMarkerMouseDown}
                                                            />
                                                        </DragDropContainer>
                                                    </span>
                                                )
                                            })

                                        }
                                    </TransformComponent>
                                </TransformWrapper>

                                // <DropTarget
                                //     targetKey="drop-target-snapshot"
                                //     onHit={this.handleDrop}
                                // >
                                //     <img className="drop-image-snapshot" src={snapshot} alt="" style={{ height: 600, width: '100%' }} />

                                //     {
                                //         markers.map( (item, index) => {
                                //             return (
                                //                 <span
                                //                     key={index}
                                //                     style={{lineHeight:0, position:'absolute', top:`${item.snapshot.y}px`, left:`${item.snapshot.x}px`}}
                                //                 >
                                //                     <DragDropContainer
                                //                         targetKey="drop-target-snapshot"
                                //                         dragClone={false}
                                //                         dragData={{number: item.number, type: 'snapshot'}}
                                //                     >
                                //                         {/* <OverlayTrigger
                                //                             placement="right"
                                //                             delay={{ show: 200, hide: 500 }}
                                //                             trigger="hover"
                                //                             overlay={
                                //                                 <Tooltip
                                //                                     className="remove-marker-tooltip"
                                //                                     onClick={() => this.handleMarkerRemoveClick(item.number)}
                                //                                 >
                                //                                     <div className="remove-marker">Remove Marker</div>
                                //                                 </Tooltip>
                                //                             }
                                //                         >
                                //                             <img
                                //                                 alt=""
                                //                                 src={`/images/mapping/idle_${item.number}.png`}
                                //                                 onMouseDown = {this.handleMarkerMouseDown}
                                //                             />
                                //                         </OverlayTrigger> */}
                                //                         <img
                                //                             alt=""
                                //                             src={`/images/mapping/idle_${item.number}.png`}
                                //                             onMouseDown = {this.handleMarkerMouseDown}
                                //                         />
                                //                     </DragDropContainer>
                                //                 </span>
                                //             )
                                //         })
                                //     }
                                // </DropTarget>

                                // <TransformWrapper>
                                //     <TransformComponent>
                                //         <img src={selectedFloor.floor_image} alt="" style={{ maxHeight: 600, maxWidth: 780 }} />
                                //     </TransformComponent>
                                // </TransformWrapper>
                            )
                            : (
                                <div className="no-floor-image">
                                    {emptyFloorImagesMessage}
                                </div>
                            )
                        }
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="map-title">{mapTitle}</div>
                        <div className="map-block drop-target-map">
                                {/* <DropTarget
                                    targetKey="drop-target-map"
                                    onHit={this.handleDrop}
                                >
                                    <MapInteractionCSS
                                        // value={this.state.value1}
                                        onChange={(value) => { console.log('value1: ', value); this.setState({ value1: value }); } }
                                    >
                                        <img className="drop-image-map" src={snapshot} alt="" />
                                    </MapInteractionCSS>

                                    {
                                        markers.map( (item, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    style={{lineHeight:0, position:'absolute', top:`${item.map.y}px`, left:`${item.map.x}px`}}
                                                    onMouseDown = {(e) => e.stopPropagation()}
                                                    onMouseMove = {(e) => e.stopPropagation()}
                                                >
                                                    <DragDropContainer
                                                        targetKey="drop-target-map"
                                                        dragClone={false}
                                                        dragData={{number: item.number, type: 'map'}}
                                                    >
                                                        <img
                                                            alt=""
                                                            src={`/images/mapping/idle_${item.number}.png`}
                                                            onMouseDown = {this.handleMarkerMouseDown}
                                                            onMouseMove = {(e) => e.stopPropagation()}
                                                        />
                                                    </DragDropContainer>
                                                </span>
                                            )
                                        })
                                    }
                                </DropTarget> */}
                            {/* <DropTarget
                                targetKey="drop-target-map"
                                onHit={this.handleDrop}
                            >
                                <img className="drop-image-map image" data-wheel-zoom src={selectedFloor.floor_image} alt="" onLoad={this.handleImageLoad} style={{ height: 600, width: '100%' }} />

                                {
                                    markers.map( (item, index) => {
                                        return (
                                            <span
                                                key={index}
                                                style={{lineHeight:0, position:'absolute', top:`${item.map.y}px`, left:`${item.map.x}px`}}
                                                onMouseDown = {(e) => e.stopPropagation()}
                                                onMouseMove = {(e) => e.stopPropagation()}
                                            >
                                                <DragDropContainer
                                                    targetKey="drop-target-map"
                                                    dragClone={false}
                                                    dragData={{number: item.number, type: 'map'}}
                                                >
                                                    <img
                                                        alt=""
                                                        src={`/images/mapping/idle_${item.number}.png`}
                                                        onMouseDown = {this.handleMarkerMouseDown}
                                                        onMouseMove = {(e) => e.stopPropagation()}
                                                    />
                                                </DragDropContainer>
                                            </span>
                                        )
                                    })

                                }
                            </DropTarget> */}
                            {/* <PanAndZoomImage
                                src={selectedFloor.floor_image}
                            /> */}
                            {/* <DropTarget
                                targetKey="drop-target-map"
                                onHit={this.handleDrop}
                            >
                                //<img className="drop-image-map" src={selectedFloor.floor_image} alt="" style={{ height: 600, width: '100%' }} />
                                <TransformWrapper
                                    options={{
                                        limitToBounds: true,
                                    }}
                                    // scale={1}
                                    // reset={{ disabled: !refresh }}
                                    // pan={{ paddingSize: 0, velocityBaseTime: 0 }}
                                    // scalePadding={{ disabled: !refresh }}
                                    pan={{
                                        velocity: false,
                                        enableVelocity:false,
                                        velocityTimeBasedOnMove:false,
                                        velocityAnimationSpeed:0,
                                        minVelocity:0,
                                        minVelocityScale:0,
                                        velocitySensitivity:0
                                    }}

                                    defaultScale={1}
                                    defaultPositionX={300}
                                    defaultPositionY={300}
                                    // onPanningStart={ (a,b,c) => console.log('onPanningStart: ',a,b,c)}
                                    // onPanning={ (a,b,c) => console.log('onPanning: ',a,b,c)}
                                    onPanningStop={ (a,b,c) => console.log('onPanningStop: ',a,b,c)}
                                    // onPinchingStart={ (a,b,c) => console.log('onPinchingStart: ',a,b,c)}
                                    // onPinching={ (a,b,c) => console.log('onPinching: ',a,b,c)}
                                    onPinchingStop={ (a,b,c) => console.log('onPinchingStop: ',a,b,c)}
                                >
                                    <TransformComponent>
                                        <img className="drop-image-map" src={selectedFloor.floor_image} alt="" />

                                        {
                                            markers.map( (item, index) => {
                                                return (
                                                    <span
                                                        key={index}
                                                        style={{lineHeight:0, position:'absolute', top:`${item.map.y}px`, left:`${item.map.x}px`}}
                                                        onMouseDown = {(e) => e.stopPropagation()}
                                                        onMouseMove = {(e) => e.stopPropagation()}
                                                    >
                                                        <DragDropContainer
                                                            targetKey="drop-target-map"
                                                            dragClone={false}
                                                            dragData={{number: item.number, type: 'map'}}
                                                        >
                                                            <img
                                                                alt=""
                                                                src={`/images/mapping/idle_${item.number}.png`}
                                                                onMouseDown = {this.handleMarkerMouseDown}
                                                                onMouseMove = {(e) => e.stopPropagation()}
                                                            />
                                                        </DragDropContainer>
                                                    </span>
                                                )
                                            })

                                        }
                                    </TransformComponent>
                                </TransformWrapper>
                            </DropTarget> */}


                                <TransformWrapper
                                    // minScale={1} maxScale={5} scale={s} positionX={x} positionY={y}
                                    defaultScale={1}
                                    defaultPositionX={0}
                                    defaultPositionY={0}
                                    options={{
                                        limitToBounds: false,
                                        centerContent: true,
                                    }}
                                    pan={{
                                        velocity: false,
                                    }}
                                    scalePadding={{
                                        animationTime: 0,
                                    }}
                                    doubleClick={{
                                        disabled: true,
                                    }}
                                    // onZoomChange={ (a) => {console.log('onZoomChange: ', a);} }
                                    onWheelStop={ (a) => {this.setState({ scalemap: a.scale}); console.log('onWheelStop: ', a);} }
                                >
                                    <TransformComponent>
                                        <DropTarget
                                            targetKey="drop-target-map"
                                            onHit={this.handleDrop}
                                        >
                                            <img className="drop-image-map" src={selectedFloor.floor_image} alt="" />
                                        </DropTarget>

                                        {
                                            markers.map( (item, index) => {
                                                return (
                                                    <span
                                                        key={index}
                                                        style={{ lineHeight:0, position:'absolute', top:`${item.map.y}px`, left:`${item.map.x}px`, transformOrigin: '0 0', transform:`translate(0,0) scale(${1/scalemap})` }}
                                                        onMouseDown = {self.handleMarkerMouseDown}
                                                    >
                                                        <DragDropContainer
                                                            targetKey="drop-target-map"
                                                            dragClone={false}
                                                            dragData={{number: item.number, type: 'map'}}
                                                        >
                                                            <img
                                                                alt=""
                                                                src={`/images/mapping/idle_${item.number}.png`}
                                                                onMouseDown = {self.handleMarkerMouseDown}
                                                            />
                                                        </DragDropContainer>
                                                    </span>
                                                )
                                            })

                                        }
                                    </TransformComponent>
                                </TransformWrapper>

                            {/* <MapInteractionCSS
                                value={this.state.value}
                                onChange={(value) => this.setState({ value })}
                            >

                                <div>
                                    <img src={snapshot} width="100%" alt="" />
                                </div>
                            </MapInteractionCSS> */}
                            {/* <img src={snapshot} width={`${snapshotWidth}%`} alt="" /> */}
                        </div>
                    </Col>
                </Row>
                <Row style={{ position: 'relative' }}>
                    {selectedFloor
                    ? (
                        <div className="markers-block">
                            {
                                range(1, 9).map((item) => {
                                    if (some(markers, ['number', item])) {
                                        return (
                                            <OverlayTrigger
                                                key={item}
                                                overlay={<Tooltip className="markers-block-disabled">{clickToRemove}</Tooltip>}
                                            >
                                                <img className="marker disabled" src={`/images/mapping/disabled_${item}.png`} alt="" onClick={ () => this.handleMarkerRemoveClick(item) }/>
                                            </OverlayTrigger>
                                        )
                                    } else {
                                        return (
                                            <OverlayTrigger
                                                key={item}
                                                overlay={<Tooltip className="markers-block-disabled">{clickToAddTitle}</Tooltip>}
                                            >
                                                <img className="marker" src={`/images/mapping/idle_${item}.png`} alt="" onClick= { () => this.handleMarkerClick(item) } />
                                            </OverlayTrigger>
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                    : (
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip className="markers-block-disabled">{selectFloorTooltipMessage}</Tooltip>}
                        >
                            <div className="markers-block">
                                { range(1, 9).map((item) => <img key={item} className="marker disabled" src={`/images/mapping/disabled_${item}.png`} alt="" />) }
                            </div>
                        </OverlayTrigger>
                    )}

                    <div className="floor-dropdown">
                        <DropdownButton
                            title={selectedFloor ? `${floorTitle} ${selectedFloor.number}` : selectFloorTitle}
                            id="drop_for_mall_floors"
                            variant="outline-dark"
                        >
                            {!isEmpty(chosenMall) && !isEmpty(floors) && floors
                                .sort((a,b) => a.number - b.number)
                                .map((item) => {
                                    return <Dropdown.Item
                                        key={item.id}
                                        onClick={() => setProps({ selectedFloor: item })}
                                    >
                                        {floorTitle} {item.number}
                                    </Dropdown.Item>
                                }
                            )}
                        </DropdownButton>
                    </div>
                </Row>
                {/* <Row style={{marginTop:"20px"}}>
                    <Col>
                        <Button
                            variant="outline-success"
                            className={`btn-block ${(markers.length < 4) && 'btn-disabled'}`}
                            disabled={markers.length < 4}
                            onClick={ () => markers.lengt > 3 && handleSelectTab(2) }
                        >
                            Continue
                        </Button>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

const mapStateToProps = ( {adminData, filters, language }) => {
    return {
        chosenMall: adminData.chosenMall,
        chosenCamera: adminData.chosenCamera,
        language: language.data,
        // mappingMarkers: adminData.mappingMarkers,
    }
};

export default withRouter(connect(mapStateToProps)(CameraMappingAnchor));
