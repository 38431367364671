import React from 'react';

const ItemsComponent = () => {
    return (
        <div className="content">ItemsComponent</div>
    )
};

export default ItemsComponent

