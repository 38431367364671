import React from 'react';

const Notifications = ({notifications, deleteNotification}) => {
    if (notifications.length === 0) {
        return (<div className={`notif info`}>
            <h6>Notifications are absent</h6>
        </div>)
    }

    return (notifications.map(({type, title, message, id}, index) => {
        return (<div key={index} className={`notif ${type}`}>
            <h6>{title}</h6>
            <span>{message}</span>
            <button className="close_btn" onClick={() => deleteNotification(id)}/>
        </div>)
    }))
};

export default Notifications

