import { connect } from 'react-redux'
import ZoneComparisonComponent from './ZoneComparisonComponent'
import { switchZoneComparison, removeZoneVisitorsComparisonData, setShowDetailCompareData } from '../../actions/filters'
// import { setVisitorsGroupsZoneCompare, switchZoneComparison, clearZone, removeZoneVisitorsComparisonData, removeZoneVisitorsComparisonDataDeep, setZoneVisibilityVisitorsDetailData, setZoneVisibilityVisitorsDetailDataDeep /*, setZoneCompareWith */ } from '../actions/filters'
// import { getZoneComparisonWithData, complexRequest, getZoneDetailVisitorsData } from '../actions/apiRequests'
import { getZoneComparisonWithData } from '../../actions/apiRequests'

const mapStateToProps = ({ dashboardData, zoneData, language }) => {
    return {
        daterange: dashboardData.daterange,
        zonesCheckedList: dashboardData.zonesCheckedList,
        zones: dashboardData.zones,
        selectedComparisonDates: zoneData.selectedComparisonDates,
        compareData: zoneData.compareData,
        language,


        // chosenMall: dashboardData.chosenMall,
        // daterange: dashboardData.daterange,
        // timerange: dashboardData.timerange,
        // zones: dashboardData.zones,
        // chosenTopZone: zoneData.chosenTopZone,
        // groups: zoneData.groups,
        // visitors: zoneData.visitors,
        // visitorsDetailData: zoneData.visitorsDetailData,
        // avgVisitorsTime: zoneData.avgVisitorsTime,
        // visitorsComparisonData: zoneData.visitorsComparisonData,
        // visitorsComparisonZone: zoneData.visitorsComparisonZone,
        // avgComparisonData: zoneData.avgComparisonData,
        // totalcount: zoneData.totalcount,
        // totalcountStore: zoneData.totalcountStore,
        // showDetailVisitorsData: zoneData.showDetailVisitorsData,
        // showDetailVisitorsDataDeep: zoneData.showDetailVisitorsDataDeep,
        // language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchZoneComparison: (state) => dispatch(switchZoneComparison(state)),
        getZoneComparisonWithData: optionsArr => dispatch(getZoneComparisonWithData(optionsArr)),
        removeZoneVisitorsComparisonData: () => dispatch(removeZoneVisitorsComparisonData()),
        // setShowDetailCompareData: state => dispatch(setShowDetailCompareData(state)),


        // complexRequest: (reqArr, dispatchType, callback) => dispatch(complexRequest(reqArr, dispatchType, callback)),
        // clearZone: () => dispatch(clearZone()),
        // getZoneComparisonWithData: optionsArr => dispatch(getZoneComparisonWithData(optionsArr)),
        // getZoneDetailVisitorsData: optionsArr => dispatch(getZoneDetailVisitorsData(optionsArr)),
        // removeZoneVisitorsComparisonData: () => dispatch(removeZoneVisitorsComparisonData()),
        // removeZoneVisitorsComparisonDataDeep: () => dispatch(removeZoneVisitorsComparisonDataDeep()),
        // switchZoneComparison: (state) => dispatch(switchZoneComparison(state)),
        // setVisitorsGroupsZoneCompare: state => dispatch(setVisitorsGroupsZoneCompare(state)),
        // setZoneVisibilityVisitorsDetailData: state => dispatch(setZoneVisibilityVisitorsDetailData(state)),
        // setZoneVisibilityVisitorsDetailDataDeep: state => dispatch(setZoneVisibilityVisitorsDetailDataDeep(state)),
    }
};

const ZoneComparisonContainer = connect(mapStateToProps, mapDispatchToProps)(ZoneComparisonComponent);

export default ZoneComparisonContainer
