import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { isEmpty, cloneDeep, isEqual, has } from "lodash";
import { toastr } from "react-redux-toastr";

import CameraMappingAnchor from "./CameraMappingAnchor";
import CameraMappingDemarcation from "./CameraMappingDemarcation";

import queryOptionsParser from "../../helpers/queryOptionsParser";

import "../../styles/CamersAdminHeatmapComponent.sass"

class CamersAdminHeatmapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 1,
            editMode: 1,
            selectedFloor: '',
            markers: [],
            polygons: [],
        };

        this.shift = {shiftY: 0, shiftX: 0}
    }

    componentDidMount() {
        const { chosenMall, chosenCamera, mappingMarkers, mappingSnapshotPolygons, complexRequest, location: {state} } = this.props;
        const { mall_id, camera_id} = state

        // const {chosenMall, editMode, setForm, getMall, location: {state}} = this.props;

        if ( state && mall_id && camera_id ) {
            const mallParams = { floor: true };
            complexRequest(
                [
                    {name: "mall", method: "get", type: `mall/${mall_id}${queryOptionsParser(mallParams)}`},
                    {name: "camera", method: "get", type: `camera/${camera_id}`},
                    {name: "mapping", method: "get", type: `camera/${camera_id}/mapping`},
                    {name: "allpolygons", method: "get", type: `cameras/polygons`},
                ],
                "GET_MALL_AND_CAMERA"
            );
        }

        if (!isEmpty(mappingMarkers)) {
            const markers = cloneDeep(mappingMarkers);

            this.setState({ markers })
        }
        if (!isEmpty(mappingSnapshotPolygons)) {

            const polygons = cloneDeep(mappingSnapshotPolygons);

            this.setState({ polygons })
        }
        if (!isEmpty(chosenMall) && !isEmpty(chosenCamera)) {
            const { floors } = chosenMall
            const { floor_number } = chosenCamera
            const selectedFloor = /*this.props.selectedFloor ||*/ ((floors && floor_number)  ? floors.find((item) => item.number === floor_number ) : '')

            this.setState({ selectedFloor })
        }
    }

    componentDidUpdate(prevProps) {
        const { mappingMarkers, mappingSnapshotPolygons, chosenMall, chosenCamera } = this.props;

        if (prevProps.mappingMarkers !== mappingMarkers) {
            const markers = cloneDeep(mappingMarkers);

            this.setState({ markers })
        }
        if (prevProps.mappingSnapshotPolygons !== mappingSnapshotPolygons) {
            const polygons = cloneDeep(mappingSnapshotPolygons);

            this.setState({ polygons })
        }
        if (prevProps.chosenMall !== chosenMall || prevProps.chosenCamera !== chosenCamera) {
            const { floors } = chosenMall
            const { floor_number } = chosenCamera
            const selectedFloor = /*this.props.selectedFloor ||*/ ((floors && floor_number)  ? floors.find((item) => item.number === floor_number ) : '')

            this.setState({ selectedFloor })
        }
    }

    handleSelectTab = (tabKey) => {
		this.setState({ tabKey })
    }

    handleSaveMarkers = () => {
        const { mappingMarkers, addCameraMarkers, editCameraMarkers, location: {state} } = this.props
        const { camera_id} = state
        const { markers, selectedFloor } = this.state

        if (isEmpty(mappingMarkers)) {
            addCameraMarkers(camera_id, {camera_id, floor_number: selectedFloor.number, markers: JSON.stringify(markers)});
        } else if (!isEqual(mappingMarkers, markers)) {
            editCameraMarkers(camera_id, {camera_id, floor_number: selectedFloor.number, markers: JSON.stringify(markers)});
        }

        this.setState({ tabKey: 2 })
    }

    handleCloseClick = () => {
        const { applyToMapMessage,
            newTitle,
            polygonTitle,
            polygonsTitle,
        } = this.props.language.data.notifications.polygonsApplyValidation;
        const { polygons } = this.state;

        // const diff = (polygons.length - mappingMapPolygons.length)
        const diff = polygons.filter((item) => has(item, 'index')).length;
        if (diff) {
            toastr.info(`${applyToMapMessage} ${diff} ${newTitle} ${diff === 1 ? polygonTitle : polygonsTitle}`);
        } else {
            this.props.history.push("/admin/cameras");
        }
    }

    setProps = (states) => {
		this.setState( states )
	}

    render() {
        // console.log('PROPS CamersAdminHeatmapComponent: ', this.props)
        // console.log('STATE CamersAdminHeatmapComponent: ', this.state)
        const { chosenMall, chosenCamera, mappingMarkers, mappingMapPolygons, language } = this.props
        const { tabKey, selectedFloor, markers, polygons } = this.state
        const { name, title } = chosenCamera;
        const { data, direction } = language;
        const { genericLabels, adminSection: { camerasPage } } = data;
        const { closeTitle, continueTitle } = genericLabels;
        const {
            cameraMappingTitle,
            setAnchorTitle,
            setDemarcationTitle,
        } = camerasPage;

        if (isEmpty(chosenMall) || isEmpty(chosenCamera)) {
            return (
                <div className="content block_wrapper admin">
                        <h3 className="mb-4 edit_title">{cameraMappingTitle}</h3>
                </div>
            )
        }

        return (
            <div className="content camera-heatmap">
                <h3 className="mb-4 edit_title">
                    {
                        direction === 'rtl'
                            ? `${cameraMappingTitle} ${title || name}`
                            : `${title || name} ${cameraMappingTitle}`
                    }
                </h3>

                <div className="stepper">
                    { (mappingMarkers.length && mappingMapPolygons.length)
                        ?
                            <React.Fragment>
                                <span className={`stepper-number ${tabKey === 1 && 'active'}`}>
                                    <span className="stepper-index accepted"></span>
                                    <span className="link" onClick={ () => this.handleSelectTab(1) }>{setAnchorTitle}</span>
                                </span>
                                <hr className="stepper-connector" />
                                <span className={`stepper-number ${tabKey === 2 && 'active'}`}>
                                    <span className="stepper-index accepted"></span>
                                    <span className="link" onClick={ () => this.handleSelectTab(2) }>{setDemarcationTitle}</span>
                                </span>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                {mappingMarkers.length
                                    ?
                                        <span className={`stepper-number ${tabKey === 2 && 'disabled'}`}>
                                            <span className="stepper-index accepted"></span>
                                            {tabKey === 1
                                                ? <span>{setAnchorTitle}</span>
                                                : <span className="link" onClick={ () => this.handleSelectTab(1) }>{setAnchorTitle}</span>
                                            }
                                        </span>
                                    :
                                        <span className='stepper-number'>
                                            {tabKey === 1
                                                ? <span className="stepper-index">1</span>
                                                : <span className="stepper-index accepted"></span>
                                            }
                                            {tabKey === 1
                                                ? <span>{setAnchorTitle}</span>
                                                : <span className="link" onClick={ () => this.handleSelectTab(1) }>{setAnchorTitle}</span>
                                            }
                                        </span>
                                }
                                <hr className="stepper-connector" />
                                <span className={`stepper-number ${tabKey === 1 && 'disabled'}`}>
                                    <span className="stepper-index">2</span>
                                    <span>{setDemarcationTitle}</span>
                                </span>
                            </React.Fragment>

                    }
                    {/* {!editMode
                        ?
                            <React.Fragment>
                                <span className={`stepper-number ${tabKey === 2 && 'disabled'}`} onClick={ () => this.handleSelectTab(1) }>
                                    <span className="stepper-index">1</span> <span>Set Anchor</span>
                                </span>
                                <hr className="stepper-connector" />
                                <span className={`stepper-number ${tabKey === 1 && 'disabled'}`} onClick={ () => this.handleSelectTab(2) }>
                                    <span className="stepper-index">2</span> <span>Set Demarcation</span>
                                </span>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <span className={`stepper-number ${tabKey === 2 && 'disabled'}`}>
                                    {tabKey === 2
                                        ? <span className="stepper-index accepted"></span>
                                        : <span className="stepper-index">1</span>
                                    }
                                    {tabKey === 2
                                        ? <span className="link" onClick={ () => this.handleSelectTab(1) }>Set Anchor</span>
                                        : <span>Set Anchor</span>
                                    }
                                </span>
                                <hr className="stepper-connector" />
                                <span className={`stepper-number ${tabKey === 1 && 'disabled'}`}>
                                    {tabKey === 1
                                        ? <span className="stepper-index accepted"></span>
                                        : <span className="stepper-index">2</span>
                                    }
                                    {tabKey === 1
                                        ? <span className="link" onClick={ () => this.handleSelectTab(2) }>Set Demarcation</span>
                                        : <span>Set Demarcation</span>
                                    }
                                </span>
                            </React.Fragment>
                    } */}
                </div>

                <div onSelect={this.onSelectTab} id="controlled-tab-example">
                    {tabKey === 1
                    && (
                        <CameraMappingAnchor
                            markers={markers}
                            selectedFloor={selectedFloor}
                            setProps={this.setProps}
                        />
                    )}
                    {tabKey === 2
                    && (
                        <CameraMappingDemarcation
                            polygons={polygons}
                            selectedFloor={selectedFloor}
                            setProps={this.setProps}
                        />
                    )}
                </div>

                <Row style={{marginTop:"20px"}}>
                    <Col>
                        { tabKey === 1
                        && (
                            markers.length > 3
                            ?
                                <Button
                                    variant="outline-success"
                                    className="btn-block"
                                    onClick={ this.handleSaveMarkers }
                                >
                                    {continueTitle}
                                </Button>
                            :
                                <Button
                                    variant="outline-success"
                                    className="btn-block btn-disabled"
                                    disabled={true}
                                >
                                    {continueTitle}
                                </Button>
                        )}
                        { tabKey === 2
                        && (
                            polygons.length
                            ?
                                <Button
                                    variant="outline-success"
                                    className="btn-block"
                                    onClick={ this.handleCloseClick }
                                >
                                    {closeTitle}
                                </Button>
                            :
                                <Button
                                    variant="outline-success"
                                    className="btn-block btn-disabled"
                                    disabled={true}
                                >
                                    {closeTitle}
                                </Button>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CamersAdminHeatmapComponent
