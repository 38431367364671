import { connect } from 'react-redux';
import TopZonesComponent from '../components/TopZonesComponent';
import { switchTopShopPopover, setCoordsTopShopPopover, setShoppopover, setChosentopfloor, switchZoneComparison, chooseTopZone, setZonesCheckedList } from '../actions/filters'
import { chooseShop, getTopFloorShops } from '../actions/apiRequests'

const mapStateToProps = ({ dashboardData, language }) => {
    return {
        zonesDailyStats: dashboardData.zonesDailyStats,
        zonesCheckedList: dashboardData.zonesCheckedList,
        chosenStatsBlock: dashboardData.chosenStatsBlock,

        chosenMall: dashboardData.chosenMall,
        topzones: dashboardData.topzones,
        topfloorshops: dashboardData.topfloorshops,
        chosenTopFloor: dashboardData.chosenTopFloor,
        daterange: dashboardData.daterange,
        language,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setZonesCheckedList:(state) => dispatch(setZonesCheckedList(state)),

        switchTopShopPopover:(state) => dispatch(switchTopShopPopover(state)),
        setCoordsTopShopPopover:(state) => dispatch(setCoordsTopShopPopover(state)),
        setShoppopover:(state) => dispatch(setShoppopover(state)),
        getTopFloorShops: (options="") => dispatch(getTopFloorShops(options)),
        setChosentopfloor: (state) => dispatch(setChosentopfloor(state)),
        chooseShop:(shop, shopOptions) => dispatch(chooseShop(shop, shopOptions)),
        switchZoneComparison:(state) => dispatch(switchZoneComparison(state)),
        chooseTopZone:(state) => dispatch(chooseTopZone(state)),
    }
};

const TopZonesContainer = connect(mapStateToProps, mapDispatchToProps)(TopZonesComponent);

export default TopZonesContainer
