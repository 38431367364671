import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container'
// import { toastr } from "react-redux-toastr"
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { isEmpty, pickBy, flatten } from 'lodash'

import validate from "../../helpers/validate"
import queryOptionsParser from "../../helpers/queryOptionsParser"
import Circle from "../../components/Circle"
import ModalRelationsByte2Story from './ModalRelationsByte2Story'

import "./ZonesAdminComponent.sass"


import EditPolygonTabContainer from './EditPolygonTabContainer'
import ProductMatchTabContainer from './ProductMatchTabContainer'

const ZonesFormComponent = (props) => {
    console.log('ZonesFormComponent props: ', props)
    const { mallId, openAddForm,  editMode, language, chooseZone, setForm } = props;
    const { adminSection } = language;
    const {
        editZoneTitle,
        newZoneTitle,
    } = adminSection.zonesPage;

    const modal = useRef(null)
    const [tabKey, setTabKey] = useState(1)

    useEffect(() => {
        if (openAddForm){
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    const handleChangeForm = () => {
        setForm(!openAddForm, false)
        editMode && chooseZone({});
    }

    const handleClickOutside = (event) => {
        if (modal.current && !modal.current.contains(event.target)) {
            handleChangeForm();
        }
    };

    const handleSelectTab = (key) => {
        setTabKey(key)
    }

    return (
        <Fragment>
            <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={handleChangeForm}/>

            <Form
                ref={modal}
                className="add_new_data zonesForm"
                // onClick={this.handleFormClick}
            >
                <h3 className="mb-3">
                    {editMode ? editZoneTitle : newZoneTitle}
                </h3>

                <button className="close_btn" type="button" onClick={handleChangeForm}/>

                <Tabs 
                    className="navTabs mb-3"
                    activeKey={tabKey} 
                    onSelect={handleSelectTab}
                >
                    <Tab eventKey={1} title="Edit Polygon">
                        <EditPolygonTabContainer
                            mallId={mallId}
                            handleChangeForm={handleChangeForm}
                        />
                    </Tab>
                    {editMode && // the Product Match tab should not be displayed when is created a new zone 
                        <Tab eventKey={2} title="Product Match">
                            <ProductMatchTabContainer
                                handleChangeForm={handleChangeForm}
                            />
                        </Tab>
                    }
                </Tabs> 

            </Form>
        </Fragment>
    )
}

export default ZonesFormComponent
