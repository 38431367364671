import React, {Fragment} from "react";

const UserDropdownTitle = ({v, name, main_image}) => {
    return (name ?
        <Fragment>
            <img
                src={`${main_image ? main_image : "/images/profile.png"}`}
                className="user-avatar"
                alt="User Avatar"
            />

            <p className="user-name">
                {name}
            </p>
        </Fragment>

        : <img alt="" src={`/images/menu_dots_${v}.png`}/>)
};

export default UserDropdownTitle

