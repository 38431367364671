import React, { type ElementConfig, useState, useRef } from 'react'
import { Overlay, Popover, Button /*, OverlayTrigger, Tooltip,  InputGroup, FormControl*/ } from 'react-bootstrap'
import { DateRangePicker, createStaticRanges/*, defaultStaticRanges*/ } from 'react-date-range'
import { merge } from 'lodash'
import {toastr} from "react-redux-toastr"
import moment from 'moment'
import Select, { components } from 'react-select'
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
} from 'date-fns'

import languages from "../../lang"
import queryOptionsParser from "../../helpers/queryOptionsParser"
import getScheduleGroupRange from "../../helpers/getScheduleGroupRange"
import { getSubstUnit, getDataGroup } from "../../helpers/dashboardUtils"

const definedsDateRanges = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
}

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
          <div className="DropdownIndicator">
            <i className="fas fa-plus" />
          </div>
      </components.DropdownIndicator>
    );
  };
  

const DashboardCalendarCompare = (props) => {
    const {
        selectedComparisonDates,
        chosenMall,
        daterange,
        language,
        showDetailVisitorsData,
        showHeatmap,
        showHeatmapClustered,
        setProps,
        complexRequest,
        removeVisitorsComparisonData,
        setVisibilityVisitorsDetailData,
        setDaterange,
        setTimerange,
        setSelectedComparisonDates,
    } = props;
    const { id, open_time, close_time } = chosenMall;
    const { data, code } = language;
    const { genericLabels, dashboardPage } = data;
    const { selectDateRangeTitle } = genericLabels;
    const { calendarComparison } = dashboardPage;
    const { mainTitle, compareButtonTitle, selectButtonTitle } = calendarComparison;

    const overlayDataRange = useRef(null);
    const overlaySelect = useRef(null);
    const [showCalendar, setShowCalendar] = useState(false)
    const [calendar, setCalendar] = useState({ 
        startDate: new Date(), 
        endDate: new Date(), 
        // startDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].startDate).toDate() : new Date(), 
        // endDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].endDate).toDate() : new Date(), 
    })
    // const [focusedRange, setFocusedRange] = useState([0, 0])
    // const [activeTab, setActiveTab] = useState('select')


    // const selectedRange = () => {
    //     const { startDate, endDate, key } = daterange;
    //     // const start_ts = moment.utc(startDate).local().format('DD/MM/YYYY');
    //     // const end_ts = moment.utc(key === 'customTime' ? endDate : moment.utc()).local().format('DD/MM/YYYY');
    //     const start_ts = moment.utc(startDate).local();
    //     const end_ts = moment.utc(key === 'customTime' ? endDate : moment.utc()).local();

    //     // return `${start_ts}${moment(endDate).diff(moment(startDate), 'days') < 1 ? "" : ` - ${end_ts}`}`

    //     console.log('selectedRange startDate: ', startDate)
    //     console.log('selectedRange endDate: ', endDate)
    //     console.log('selectedRange start_ts: ', start_ts)
    //     console.log('selectedRange end_ts: ', end_ts)
    //     if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'day')) {
    //         return start_ts.format('DD/MM/YYYY')
    //     } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'month')) {
    //         return `${start_ts.format('DD')} - ${end_ts.format('DD/MM/YYYY')}`
    //     } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'year')) {
    //         return `${start_ts.format('DD/MM')} - ${end_ts.format('DD/MM/YYYY')}`
    //     } else {
    //         return `${start_ts.format('DD/MM/YYYY')} - ${end_ts.format('DD/MM/YYYY')}`
    //     }
    // }

    const handleCalendarEnter = () => {
        const { startDate, endDate } = daterange;

        setCalendar({
            startDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].startDate).toDate() : new Date(), 
            endDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].endDate).toDate() : new Date(), 
            // startDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].startDate).toDate() : new Date(moment.utc(startDate).format()), 
            // endDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].endDate).toDate() : new Date(moment.utc(endDate).format()), 
            // startDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].startDate).toDate() : new Date(moment.utc(startDate).format()), 
            // endDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].endDate).toDate() : new Date(moment.utc(endDate).format()), 
            // startDate: new Date(moment.utc(startDate).format()),
            // endDate: new Date(moment.utc(endDate).format()),
        })
    }

    const handleCalendarChange = ({ select }) => {
        console.log('handleCalendarChange select: ', select)
        const { startDate, endDate } = select;
        const selectStartDate = startDate
        let selectEndDate = endDate
        if (selectEndDate.getHours() === 0) {
            selectEndDate = new Date(selectEndDate.getTime() + 24*60*60*1000 - 1000)
        }
        
        if (selectedComparisonDates.length) {
            const selectedDiff = Math.round((selectEndDate - selectStartDate)/(1000*60*60*24))
            const firstSelectedDiff = moment(selectedComparisonDates[0].endDate).diff(moment(selectedComparisonDates[0].startDate), 'days') + 1
            if (selectedDiff === firstSelectedDiff) {
                setCalendar({ startDate: selectStartDate, endDate: selectEndDate })
            }
        } else {
            setCalendar({ startDate: selectStartDate, endDate: selectEndDate })
        }
    }

    const handleCalendarApply = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const {
            attentionTitle,
            sameDateMessage,
            futureDateValidationMessage,
            wastePeriodMessage,
            lessThanOneYeaMessage,
        } = language.data.notifications;
        const { startDate, endDate } = calendar;
        const diff = moment(endDate).diff(moment(startDate), 'days')
        let openTime = '00:00:00'
        let closeTime = '23:59:59'
        if (diff === 0) {
            if (open_time) {
                openTime = `${open_time.substring(0,2)}:${open_time.substring(2,4)}:${open_time.substring(4)}`
            }
            if (close_time) {
                closeTime = `${close_time.substring(0,2)}:${close_time.substring(2,4)}:${close_time.substring(4)}`
            }
        }
        const strStartDate = moment(moment(startDate).format(`YYYY-MM-DD ${openTime}`)).utc().format('YYYY-MM-DD HH:mm:ss')
        const strEndDate = moment(moment(endDate).format(`YYYY-MM-DD ${closeTime}`)).utc().format('YYYY-MM-DD HH:mm:ss')

        if (selectedComparisonDates.length) {
            const firstDate = selectedComparisonDates[0]
            const firstDiff = moment(firstDate.endDate).diff(moment(firstDate.startDate), 'days')
            // console.log('firstDiff: ', firstDiff)
            if (firstDiff !== diff) {
                toastr.info(attentionTitle, `Selected range should be ${firstDiff+1} days`);
                return
            }
        }

        let validSameRange = false
        selectedComparisonDates.forEach((item) => {
            if (moment(item.startDate).isSame(moment(strStartDate))
                && moment(item.endDate).isSame(moment(strEndDate))) {
                validSameRange = true
                return
            }
        })
        if (validSameRange) {
            toastr.info(attentionTitle, sameDateMessage);
            return
        }

        setSelectedComparisonDates([...selectedComparisonDates, {startDate: strStartDate, endDate: strEndDate}])

        // const {
        //     attentionTitle,
        //     sameDateMessage,
        //     futureDateValidationMessage,
        //     wastePeriodMessage,
        //     lessThanOneYeaMessage,
        // } = language.data.notifications;

        // // diff between start of day and open_time = 32400000ms (9 hours)
        // // diff between start of day and close_time = 79200000ms (22 hours)  
        // const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 32400000
        // const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 79200000
        // const diff = moment(endDate).diff(moment(startDate), "days") // 0
        // const differenceTime  = moment(close_time, 'HHmmss').diff(moment(open_time, 'HHmmss'), 'hours') // 13 = 22 - 9

        // const subst_unit = getSubstUnit(diff);
        // const report_start_ts = subst_unit === "day"
        //     ? moment(startDate).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
        //     : moment(moment(startDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss');
        // const report_end_ts = subst_unit === "day"
        //     ? moment(startDate).startOf("day").add({milliseconds: max + 60000 /*+1min*/}).utc().format('YYYYMMDDHHmmss')
        //     : moment(moment(endDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss');
        // const group_range = subst_unit === "day" ? getScheduleGroupRange(differenceTime) : getDataGroup(startDate, endDate);


        // if (moment(daterange.startDate).isSame(moment(report_start_ts, "YYYYMMDDHHmmss"))
        //     && moment(daterange.endDate).isSame(moment(report_end_ts, "YYYYMMDDHHmmss"))) {
        //     toastr.info(attentionTitle, sameDateMessage);
        //     return false
        // }

        // if (moment(startDate).isAfter(moment())
        //     || moment(endDate).isAfter(moment())) {
        //     toastr.info(attentionTitle, futureDateValidationMessage);
        //     return false
        // }

        // if (!subst_unit) {
        //     toastr.info(wastePeriodMessage, lessThanOneYeaMessage);
        //     return false
        // }

        // const scheduleConfig = subst_unit === "day"
        //     ? {add: {milliseconds: min}, subtract: {milliseconds: 86400000 - max - 60000 /*1min*/}}
        //     : {};

        // const date_range = merge({
        //     key: 'customTime',
        //     startDate: moment(report_start_ts, "YYYYMMDDHHmmss").format('YYYY-MM-DD HH:mm:ss'),
        //     endDate: moment(report_end_ts, "YYYYMMDDHHmmss").format('YYYY-MM-DD HH:mm:ss'),
        //     group_range,
        //     subst_unit
        // }, scheduleConfig);


        // const mallDailyStatsOptions = {
        //     start_ts: report_start_ts,
        //     end_ts:  report_end_ts,
        //     mall_id: id,
        // }
        // // const visitorsParams = {
        // //     start_ts: report_start_ts,
        // //     end_ts:  report_end_ts,
        // //     group_range,
        // //     mall_id: id,
        // // }
        // // const totalcountOptions = {
        // //     start_ts:   report_start_ts,
        // //     end_ts:     report_end_ts,
        // //     mall_id:    id,
        // // }
        // // const prevtotalcountOptions = {
        // //     start_ts:   report_start_ts,
        // //     end_ts:     report_end_ts,
        // //     mall_id:    id,
        // // }
        // // const averageVisitorsTimeOptions = {
        // //     start_ts:   report_start_ts,
        // //     end_ts:     report_end_ts,
        // //     mall_id:    id,
        // // }
        // // const zonesParams = {
        // //     start_ts:   report_start_ts,
        // //     end_ts:     report_end_ts,
        // //     mall_id:    id,
        // // }
        // const heatmapParams = {
        //     start_ts: report_start_ts,
        //     end_ts: report_end_ts,
        //     mall_id: id,
        //     floor: 1
        // }

        // const arrComplexRequest = [
        //     {name: "mallDailyStats", method: "get", type: `reports/analysis/daily${queryOptionsParser(mallDailyStatsOptions)}`},
        //     {name: "zonesDailyStats", method: "get", type: `reports/analysis/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
        //     // {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsParams)}`, catchType:{timestamp:[], visitors:[]}},
        //     // // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsParams)}`},
        //     // // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsParams)}`},
        //     // {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
        //     // {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
        //     // {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`},
        //     // {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(zonesParams)}`},
        // ]
        // if (showHeatmap) {
        //     arrComplexRequest.push({
        //         name: "floorHeatmap", method: "get", type: `stats/heatmap${queryOptionsParser(heatmapParams)}`
        //     })
        // }

        // complexRequest(
        //     arrComplexRequest,
        //     "GET_VISITORS_AND_TOPSHOPS",
        //     () => {
        //         setProps({ timeValues: {min, max}}, () => setTimerange({min, max}));
        //         setDaterange(date_range);
        //         removeVisitorsComparisonData();
        //         showDetailVisitorsData && setVisibilityVisitorsDetailData(false);
        //     }
        // );
        // // this.refs.overlayDataRange.hide()
        // overlayDataRange.current.hide()
    }

    const handleCompareZonesInDatesSelect = (argTypesZones, options) => {
        // console.log('!!!handleCompareZonesInDatesSelect: ', argTypesZones, options)
        if (options.action === "clear") {
            setSelectedComparisonDates([])
        } else if (options.action === "remove-value") {
            const arr = selectedComparisonDates.filter((item, index) => index !== options.removedValue.value)
            setSelectedComparisonDates([...arr])
        }

        setTimeout(() => {
            setShowCalendar(false)
        }, 10);
    }

    const multySelectValues = selectedComparisonDates.map((item, index) => {
        const start_ts = moment.utc(item.startDate).local();
        const end_ts = moment.utc(item.endDate).local();

        if (moment.utc(item.endDate).local().isSame(moment.utc(item.startDate).local(), 'day')) {
            return {value: index, label:start_ts.format('DD/MM/YYYY')}
        } else if (moment.utc(item.endDate).local().isSame(moment.utc(item.startDate).local(), 'month')) {
            return {value: index, label:`${start_ts.format('DD')} - ${end_ts.format('DD/MM/YYYY')}`}
        } else if (moment.utc(item.endDate).local().isSame(moment.utc(item.startDate).local(), 'year')) {
            return {value: index, label:`${start_ts.format('DD/MM')} - ${end_ts.format('DD/MM/YYYY')}`}
        } else {
            return {value: index, label:`${start_ts.format('DD/MM/YYYY')} - ${end_ts.format('DD/MM/YYYY')}`}
        }
    })

    let firstSelectionDiff = null
    let rangePreviousDay = {
        startDate: definedsDateRanges.startOfYesterday,
        endDate: definedsDateRanges.endOfYesterday,
    }
    let rangePreviousWeek = {
        startDate: definedsDateRanges.startOfLastWeek,
        endDate: definedsDateRanges.endOfLastWeek,
    }
    let rangePreviousMonth = {
        startDate: definedsDateRanges.startOfLastMonth,
        endDate: definedsDateRanges.endOfLastMonth,
    }
    let rangePreviousYear = {
        startDate: definedsDateRanges.startOfLastYear,
        endDate: definedsDateRanges.endOfLastYear,
    }
    
    if (selectedComparisonDates.length) {
        firstSelectionDiff = moment(selectedComparisonDates[0].endDate).diff(moment(selectedComparisonDates[0].startDate), 'days')

        if (firstSelectionDiff > 0) { // if selected more then 1 day
            rangePreviousDay = {}
        } else {
            rangePreviousDay = {
                startDate: startOfDay(addDays(moment.utc(selectedComparisonDates[0].startDate).valueOf(), -1)),
                endDate: endOfDay(addDays(moment.utc(selectedComparisonDates[0].startDate).valueOf(), -1)),
            }
        }
        
        if (firstSelectionDiff > 6) { // if selected more then 7 days
            rangePreviousWeek = {}
        } else {
            rangePreviousWeek = {
                startDate: startOfDay(addDays(moment.utc(selectedComparisonDates[0].startDate).valueOf(), -7)),
                endDate: endOfDay(addDays(moment.utc(selectedComparisonDates[0].endDate).valueOf(), -7)),
            }
        }

        if (firstSelectionDiff > 30) { // if selected more then 31 day
            rangePreviousMonth = {}
        } else {
            rangePreviousMonth = {
                startDate: startOfDay(addMonths(moment.utc(selectedComparisonDates[0].startDate).valueOf(), -1)),
                endDate: endOfDay(addMonths(moment.utc(selectedComparisonDates[0].endDate).valueOf(), -1)),
            }
        }

        if (firstSelectionDiff > 365) { // if selected more then 31 day
            rangePreviousYear = {}
        } else {
            rangePreviousYear = {
                startDate: startOfDay(addYears(moment.utc(selectedComparisonDates[0].startDate).valueOf(), -1)),
                endDate: endOfDay(addYears(moment.utc(selectedComparisonDates[0].endDate).valueOf(), -1)),
            }
        }
    }
    
    return (
        <div
            className="select-wrapper dashboardCalendar"
            onClick={() => { setShowCalendar(true); }}
        >
            <Select
                ref={overlaySelect}
                isMulti = {true}
                isSearchable = {false}
                isDisabled = {false}
                isOptionDisabled={true}
                menuIsOpen ={false}
                // isDisabled  = {disabledCustomTimeZonesCompareWith}
                name = "inDates"
                // placeholder={selectDateRangeTitle}
                // placeholder = {selectShopTitle}
                options = {[]}
                // styles      = {customSelectStyles}
                className = 'react-select-container'
                classNamePrefix = "react-select"
                value = {multySelectValues}
                components={{ DropdownIndicator }}
                onChange = {handleCompareZonesInDatesSelect}
            />
                <Overlay
                    // ref={overlayDataRange}
                    show={showCalendar}
                    target={overlaySelect.current}
                    rootClose
                    placement="bottom"
                    onEnter = {handleCalendarEnter}
                    onHide={() => {console.log('Overlay onHide'); setShowCalendar(false)}}
                    // trigger="click"
                >
                    <Popover
                        id='calendar-data-range'
                        title={mainTitle}
                        // title={selectDateRangeTitle}
                        className="DashboardCalendarPopover DashboardCalendarComparePopover"
                    >
                        <DateRangePicker
                            showSelectionPreview
                            moveRangeOnFirstSelection
                            dragSelectionEnabled={false}
                            className='DashboardCalendar'
                            months={2}
                            locale={languages[code].reactDateRangeLocale}
                            ranges={[{
                                // startDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].startDate).toDate() : calendar.startDate,
                                // endDate: selectedComparisonDates.length ? moment.utc(selectedComparisonDates[0].endDate).toDate() : calendar.endDate,
                                startDate: calendar.startDate,
                                endDate: calendar.endDate,
                                key: 'select',
                                showDateDisplay: true,
                            }]}
                            direction="horizontal"
                            onChange={handleCalendarChange}
                            maxDate={new Date()}
                            // initialFocusedRange={selectedComparisonDates[0]}
                            // moveRangeOnFirstSelection={selectedComparisonDates.length ? false : true}
                            // initialFocusedRange={[0,0]}
                            focusedRange={selectedComparisonDates.length ? [0,0] : [0,1]}
                            // focusedRange={selectedComparisonDates.length ? [0,0] : [0,1]}
                            // focusedRange={focusedRange}
                            // onRangeFocusChange={(e,a)=>{console.log('onRangeFocusChange: ',e,a)}}
                            // onPreviewChange={(e,a)=>{console.log('onPreviewChange: ',e,a)}}

                            inputRanges={[]}
                            renderStaticRangeLabel={(range) => {
                                let disabled = false
                                if (firstSelectionDiff) {
                                    if (firstSelectionDiff > 0 && range.label === "Previous Day") {
                                        disabled = true
                                    }
                                    if (firstSelectionDiff > 6 && range.label === "Previous Week") {
                                        disabled = true
                                    }
                                }
                                return (
                                    <div
                                        className={`predefinedOptions ${disabled && 'disabled'}`}
                                        disabled
                                    >
                                        {range.label}
                                    </div>
                                )
                            }}
                            staticRanges= {[
                                ...createStaticRanges([
                                    {
                                        label: 'Custom',
                                        range: () => ({
                                            startDate:  definedsDateRanges.startOfToday,
                                            endDate:  definedsDateRanges.endOfToday
                                        }),
                                        hasCustomRendering: true,
                                        isSelected: (range) => { 
                                            if (
                                                (   isSameDay(range.startDate, rangePreviousDay.startDate) 
                                                    && isSameDay(range.endDate, rangePreviousDay.endDate)
                                                ) ||
                                                (   isSameDay(range.startDate, rangePreviousWeek.startDate) 
                                                    && isSameDay(range.endDate, rangePreviousWeek.endDate)
                                                ) ||
                                                (   isSameDay(range.startDate, rangePreviousMonth.startDate) 
                                                    && isSameDay(range.endDate, rangePreviousMonth.endDate)
                                                ) ||
                                                (   isSameDay(range.startDate, rangePreviousYear.startDate) 
                                                    && isSameDay(range.endDate, rangePreviousYear.endDate)
                                                )
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfYesterday) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfYesterday)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfWeek) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfLastWeek) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfLastWeek)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfMonth) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfLastMonth) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfLastMonth)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfYear) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                // ) ||
                                                // (   isSameDay(range.startDate, definedsDateRanges.startOfLastYear) 
                                                //     && isSameDay(range.endDate, definedsDateRanges.endOfLastYear)
                                                // )
                                            ) {
                                                return false
                                            }  

                                            return true 
                                        }
                                    },                                               
                                ]),
                                ...createStaticRanges([
                                    {
                                        label: 'Previous Day',
                                        range: () => (rangePreviousDay),
                                        hasCustomRendering: true,
                                        // range: () => ({
                                        //     startDate: definedsDateRanges.startOfYesterday,
                                        //     endDate: definedsDateRanges.endOfYesterday,
                                        // }),
                                    },
                                    {
                                        label: 'Previous Week',
                                        range: () => (rangePreviousWeek),
                                        hasCustomRendering: true,
                                        // range: () => ({
                                        //     startDate: definedsDateRanges.startOfLastWeek,
                                        //     endDate: definedsDateRanges.endOfLastWeek,
                                        // }),
                                        // isSelected: (range) => { 
                                        //     if (selectedComparisonDates.length) {
                                        //         const firstSelectionDiff = moment(selectedComparisonDates[0].endDate).diff(moment(selectedComparisonDates[0].startDate), 'days')
                                        //         if (firstSelectionDiff > 6 && range.label === "Previous Week") {
                                        //             return false
                                        //         }
                                        //     }
                                        //     return true
                                        // }
                                    },
                                    {
                                        label: 'Previous Month',
                                        range: () => (rangePreviousMonth),
                                        hasCustomRendering: true,
                                        // range: () => ({
                                        //     startDate: definedsDateRanges.startOfLastMonth,
                                        //     endDate: definedsDateRanges.endOfLastMonth,
                                        // }),
                                    },
                                    {
                                        label: 'Previous Year',
                                        range: () => (rangePreviousYear),
                                        hasCustomRendering: true,
                                        // range: () => ({
                                        //     startDate: definedsDateRanges.startOfLastYear,
                                        //     endDate: definedsDateRanges.endOfLastYear,
                                        // }),
                                    },
                                ])
                                // ...defaultStaticRanges.filter(({label}) => label !== 'Today' ),
                                // ...createStaticRanges([
                                //     {
                                //         label: 'Yesterday',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfYesterday,
                                //             endDate: definedsDateRanges.endOfYesterday,
                                //         }),
                                //     },
                                //     {
                                //         label: 'This Week',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfWeek,
                                //             endDate: definedsDateRanges.endOfToday,
                                //         }),
                                //     },
                                //     {
                                //         label: 'Last Week',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfLastWeek,
                                //             endDate: definedsDateRanges.endOfLastWeek,
                                //         }),
                                //     },
                                //     {
                                //         label: 'This Month',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfMonth,
                                //             endDate: definedsDateRanges.endOfToday,
                                //         }),
                                //     },
                                //     {
                                //         label: 'Last Month',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfLastMonth,
                                //             endDate: definedsDateRanges.endOfLastMonth,
                                //         }),
                                //     },
                                //     {
                                //         label: 'This Year',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfYear,
                                //             endDate: definedsDateRanges.endOfToday,
                                //         }),
                                //     },
                                //     {
                                //         label: 'Last Year',
                                //         range: () => ({
                                //             startDate: definedsDateRanges.startOfLastYear,
                                //             endDate: definedsDateRanges.endOfLastYear,
                                //         }),
                                //     },
                                // ])
                            ]}
                        />

                        {/* <div className="tabs">
                            <Button
                                variant="outline-primary"
                                className={`tab tabSelect button ${activeTab === 'select' && 'active'}`}
                                onClick={() => setActiveTab('select')}
                            >
                                Select
                            </Button>
                            <Button
                                variant="outline-primary"
                                className={`tab tabCompare button ${activeTab === 'compare' && 'active'}`}
                                onClick={() => setActiveTab('compare')}
                            >
                                Compare
                            </Button>
                        </div>

                        <div className="title">
                            {activeTab === 'select' ? 'Date Range:' : 'with:'}
                        </div> */}

                        <div className="title">
                            Date Range:
                        </div>

                        <Button
                            variant="outline-primary"
                            className="button applyButton"
                            onClick={handleCalendarApply}
                            // disabled={activeTab === 'select'}
                        >
                            {selectedComparisonDates.length > 0 ? compareButtonTitle : selectButtonTitle}
                        </Button>
                    </Popover>
                </Overlay>
        </div>
    )
}

export default DashboardCalendarCompare
