import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import EventsAdminComponent from '../../components/admin/EventsAdminComponent';
import {chooseEvent, setEditMode, setVisibilityAddForm} from "../../actions/filters";
import {getEvents, complexRequest} from "../../actions/apiRequests";


const mapStateToProps = state => {
    const {
        adminData,
        filters,
        user,
        language,
    } = state;
    return {
        events: adminData.events,
        shops: adminData.shops,
        editMode: filters.editMode,
        openAddForm: filters.openAddForm,
        user,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        chooseEvent: (event) => dispatch(chooseEvent(event)),
        setForm: (form, editMode)=> {
            dispatch(setVisibilityAddForm(form));
            dispatch(setEditMode(editMode));
        },
        getEvents: (options="") => dispatch(getEvents(options)),
        complexRequest: (reqArr, dispatchType) => dispatch(complexRequest(reqArr, dispatchType))
    };
};
const EventsAdminContainer = withRouter(connect(mapStateToProps,mapDispatchToProps)(EventsAdminComponent));


export default EventsAdminContainer;
