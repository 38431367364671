import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import {isEmpty} from "lodash";
import KeyboardEventHandler from "react-keyboard-event-handler";

import MallsFormContainer from "../containers/MallsFormContainer";


class MallsDashboardComponent extends Component {
    componentDidMount(){
        this.props.getMalls();
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    componentDidUpdate(prevProps){
        if(prevProps.location.key !== this.props.location.key){
            this.props.getMalls();
        }
    }

    switchEditMode = mall => this.props.history.push({ pathname: "/admin/malls/edit", state: { mall_id: mall.id } });

    handleChangeForm = () => this.props.setForm(!this.props.openAddForm, false);

    render(){
        const { malls, editMode, openAddForm, language } = this.props;

        const { adminSection, genericLabels, columns } = language;
        const {
            mainTitle,
            createMallButtonTitle,
        } = adminSection.mallsPage;
        const { tableCountPaginationLabels, searchFormPlaceholder } = genericLabels;
        const { nameLabel, descriptionLabel } = columns;

        const data = {
            columns: [
                {
                    label: nameLabel,
                    field: 'name',
                    sort: 'asc',
                    minimal: 'lg'
                },
                {
                    label: descriptionLabel,
                    field: 'description',
                    sort: 'asc',
                },
            ],
            rows: !isEmpty(malls) ? malls.map((item) => {
                return {
                    name: item.name ? item.name : "",
                    description: item.description ? item.description.slice(0, 120) : "",
                    clickEvent: () => this.switchEditMode(item)
                }
            }) : []
        };

        return (
            <div className={`block_wrapper admin ${openAddForm && !editMode ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <div className="wrap_header">
                    <h2 className="page_title">
                        {mainTitle}
                    </h2>

                    <Button
                        className="btn_new_item"
                        variant="outline-dark"
                        onClick={() => this.handleChangeForm()}
                    >
                        {createMallButtonTitle}
                    </Button>

                    {openAddForm &&
                    <div className="centralise open">
                        <MallsFormContainer/>
                    </div>}
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                />
            </div>
        )
    }
}

export default MallsDashboardComponent
