import React, { Component } from 'react';
import { Form, Button, Dropdown, DropdownButton, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { toastr } from 'react-redux-toastr';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { isEmpty, isNumber, pickBy, range, map } from 'lodash';
import Select from 'react-select'
import readImage from '../../helpers/readImage';
import validate from '../../helpers/validate';
import validateShopsCoords from '../../helpers/validateShopsCoords';

class ShopsAdminComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: {
                name: "",
                description: "",
                mall_id: "",
                id: "",
                owner_id: "",
                rent: 0,
                coords: "",
                shape: "",
                floor_number: "",
            },
            selectedShopTypes: [],
            chosenMallName: "all"
        };

        this.file = React.createRef();
        this.modal = React.createRef();
    }

    componentDidMount(){
        const { chosenMall, complexRequest, user: {roles} } = this.props;
        if (!isEmpty(chosenMall)) {
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${chosenMall.id}}` : ""}`},
                    {name: "shops_owners", method: "get", type: "shops_owners"}
                ],
                "GET_SHOPS_AND_SHOPS_OWNERS"
            )
        }
    }

    componentDidUpdate(prevProps){
        const { chosenMall, complexRequest, location, openAddForm, user: {roles} } = this.props;
        if((prevProps.chosenMall !== chosenMall || prevProps.location.key !== location.key) && !isEmpty(chosenMall)) {
            complexRequest(
                [
                    {name: "shops", method: "get", type: `shops${roles === "manager" ? `/?mall_id=${chosenMall.id}}` : ""}`},
                    {name: "shops_owners", method: "get", type: "shops_owners"}
                ],
                "GET_SHOPS_AND_SHOPS_OWNERS"
            )
        }

        if (openAddForm){
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount(){
        this.props.setForm(false, false);
    }

    addData(){
        const { id, name, mall_id, ...request } = this.state.shop;
        const { setForm, addShop, language } = this.props;
        const shop_type_ids = map(this.state.selectedShopTypes, 'value').join(',');
        const { warningTitle, filledInMessage } = language.notifications;

        if (validate({name, mall_id, shop_type_ids}, { warningTitle, filledInMessage})){
            setForm(false, false);
            addShop(pickBy({name, mall_id, shop_type_ids, ...request}))
        }
    }

    editData(){
        const { id, main_image, name, coords, shape, mall_id, ...request } = this.state.shop;
        const { setForm, editShop, language } = this.props;
        const shop_type_ids = map(this.state.selectedShopTypes, 'value').join(',');
        const { warningTitle, shopsCoordsValidation, filledInMessage } = language.notifications;
        if (
            validate({name, mall_id, shop_type_ids}, { warningTitle, filledInMessage })
            &&
            validateShopsCoords(shape, coords, { warningTitle, shopsCoordsValidation })){
            if (typeof main_image === "object" && main_image !== null){
                request.main_image = main_image
            }else if(main_image === ""){
                request.main_image = ""
            }

            editShop(id, {name, mall_id, coords, shape, shop_type_ids, ...request});
            setForm(false, false);

            this.setState({
                shop: {
                    name: "",
                    description: "",
                    mall_id: "",
                    id: "",
                    owner_id: "",
                    rent: 0,
                    coords: "",
                    shape: "",
                    floor_number: "",
                },
                selectedShopTypes: []
            });
            this.file.current.value = "";
        }
    }

    switchEditMode(shop){
        const { setForm } = this.props;
        setForm(true, true);
        this.setState({
            shop: {
                name: shop.name,
                description: shop.description,
                mall_id: shop.mall_id,
                id: shop.id,
                owner_id: shop.owner_id,
                rent: shop.rent,
                coords: shop.coords,
                shape: shop.shape,
                floor_number: shop.floor_number,
                main_image: shop.main_image,
            },
            selectedShopTypes: ( shop ? shop.types.map(({id, name}) => ({ value: id, label: name })) : [] )
        });
    }

    handleChangeForm(){
        const { setForm, openAddForm } = this.props;
        setForm(!openAddForm, false);
        this.setState({
            shop: {
                name: "",
                description: "",
                mall_id: "",
                id: "",
                owner_id: "",
                rent: 0,
                coords: "",
                shape: "",
                floor_number: "",
            }
        });
        this.file.current.value = "";
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.handleChangeForm();
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    handleSelectShopTypes = (types) => {
        this.setState({ selectedShopTypes: types })
    }

    render(){
        const {shop, chosenMallName, selectedShopTypes} = this.state,
            {name, description, mall_id, id, owner_id, rent, coords, shape, floor_number, main_image} = shop;
        const {
            shops,
            malls,
            shops_owners,
            getShops,
            deleteShop,
            editMode,
            openAddForm,
            user,
            role,
            shopTypes,
            language,
        } = this.props;
        const {
            columns,
            adminSection,
            genericLabels,
            notifications,
            images: { noImageUrl },
        } = language;
        const { deleteActionConfirmationMessage } = notifications;
        const {
            nameLabel,
            descriptionLabel,
            mallLabel,
            ownerLabel,
            typeLabel,
            rentLabel,
            floorLabel,
            locationLabel,
            coordinatesLabel,
            shapeLabel,
            polyLabel,
            rectLabel,
            circleLabel,
        } = columns;
        const {
            mainTitle,
            editShopTitle,
            addShopTitle,
            chooseOwnerTitle,
            selectShopPlaceholder,
        } = adminSection.shopsPage;
        const {
            tableCountPaginationLabels,
            searchFormPlaceholder,
            allMallButtonTitle,
            allTitle,
            deleteButtonTitle,
        } = genericLabels;

        const data = {
            columns: [
                {
                    label: nameLabel,
                    field: 'name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: descriptionLabel,
                    field: 'description',
                    sort: 'asc',
                    width: 200,
                    minimal: 'lg'
                },
                {
                    label: mallLabel,
                    field: 'mall',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: ownerLabel,
                    field: 'owner',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: typeLabel,
                    field: 'type',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: rentLabel,
                    field: 'rent',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: floorLabel,
                    field: 'floor_number',
                    sort: 'asc',
                    width: 200
                }
            ],
            rows: (!isEmpty(shops) && !isEmpty(shops_owners) && (!isEmpty(malls) || role==="manager")) ? shops.map((item) => {
                const {id, name, description, mall_name, owner, types, rent, floor_number} = item;

                let shopTypes = ''
                if (types.length < 3) {
                    shopTypes = map(types, 'name').join(', ')
                }
                else if (types.length > 2) {
                    const list = map(types, 'name')

                    shopTypes = [
                        list.slice(0,2).join(', '),
                        ', ',
                        <OverlayTrigger
                            key={id}
                            placement={'bottom'}
                            overlay={<Tooltip>{list.slice(2).map((name) => <div key={name}>{name}</div>)}</Tooltip>}
                        >
                            <span>...</span>
                        </OverlayTrigger>
                    ]
                }

                return {
                    name: name,
                    description: description  ? description.slice(0, 45) : "",
                    mall: mall_name ? mall_name : "",
                    owner: owner ? owner : "",
                    type: shopTypes,
                    rent: rent ? rent: "",
                    floor_number: floor_number ? floor_number : "",
                    clickEvent: () => this.switchEditMode(item)
                }
            }) : []
        },
        available_shop_types = ( shopTypes.length ? shopTypes.map(({id,name}) => ({ value: id, label: name })) : [] )

        return (
            <div className={`block_wrapper admin ${openAddForm ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => this.handleChangeForm()}/>

                <div className="wrap_header">
                    <h2 className="page_title">{mainTitle}</h2>

                    <Button
                        className="btn_new_item"
                        variant="outline-dark"
                        onClick={() => this.handleChangeForm()}
                    >
                        {addShopTitle}
                    </Button>

                    {role === "admin" && <Dropdown id="dropdown-basic-button" className="dropdown_btn">
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                            {chosenMallName === "all" ? allMallButtonTitle : chosenMallName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                                this.setState({chosenMallName: "all"});
                                getShops()
                            }}>
                                {allTitle}
                            </Dropdown.Item>

                            {malls.map(({id, name}) =>
                                <Dropdown.Item
                                    key={id}
                                    onClick={() => {
                                        this.setState({chosenMallName: name});
                                        getShops(`/?mall_id=${id}`)
                                    }}>
                                    {name}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>}

                    <div className={`centralise ${openAddForm ? "open" : ""}`}>
                        <Form className="add_new_data" ref={this.modal}>
                            <h3 className="mb-4">{editMode ? editShopTitle : addShopTitle}</h3>

                            <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/>

                            <div className="main_data mb-1" style={{'display':'block'}}>
                                <Row>
                                    <Col md={8}>
                                        <div className="cameras_option">
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>{nameLabel}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={nameLabel}
                                                            value={name}
                                                            onKeyDown={this.handleEnter}
                                                            onChange={(e) => this.setState({shop: {...shop, name: e.target.value}})}/>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>{typeLabel}</Form.Label>
                                                        <Select
                                                            isMulti         = {true}
                                                            isSearchable    = {true}
                                                            name        = "shops"
                                                            placeholder = {selectShopPlaceholder}
                                                            options     = {available_shop_types}
                                                            // styles      = {customSelectStyles}
                                                            className   = 'react-select-container'
                                                            classNamePrefix = "react-select"
                                                            value       = {selectedShopTypes}
                                                            onChange    = {this.handleSelectShopTypes}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Form.Group>
                                                        <Form.Label>{rentLabel}</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder={rentLabel}
                                                            min="0"
                                                            value={rent}
                                                            onKeyDown={this.handleEnter}
                                                            onChange={(e) => this.setState({shop: {...shop, rent: e.target.value}})}/>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={1}>
                                                    <Form.Group>
                                                        <Form.Label>{floorLabel}</Form.Label>
                                                        <DropdownButton
                                                            title={floor_number ? floor_number : "#"}
                                                            id="drop_for_mall_floors"
                                                            variant="outline-dark">
                                                            {mall_id && range(1, malls.find(({id}) => mall_id === id).floors_amount+1).map((it, index) => {
                                                                return <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => this.setState({shop: {...shop, floor_number: it}})}>
                                                                    {it}
                                                                </Dropdown.Item>
                                                            })}
                                                        </DropdownButton>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>

                                        <Form.Group>
                                            <Form.Label>{descriptionLabel}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                rows="5"
                                                placeholder={descriptionLabel}
                                                value={description}
                                                onChange={(e) => this.setState({shop: {...shop, description: e.target.value}})}/>
                                        </Form.Group>
                                    </Col>

                                    <Col md={4}>
                                        <div className="avatar_block w-100">
                                            {main_image &&
                                            <button
                                                className="close_btn local"
                                                type="button"
                                                onClick={() => {
                                                    this.file.current.value = "";
                                                    this.setState({shop: {...shop, main_image: ""}})
                                                }}
                                            />}

                                            <img className="photo"
                                                src={main_image ? main_image : noImageUrl}
                                                onErrorCapture={e => e.target.src = noImageUrl}
                                                id="shop_image"
                                                alt="shop view"/>

                                            <Form.Group>
                                                <Form.Label htmlFor="shopFile" className="labelForPhoto">
                                                    <img src="/images/plus_green.svg" alt="plus"/>
                                                </Form.Label>

                                                <Form.Control
                                                    type="file"
                                                    id="shopFile"
                                                    name="shopFile"
                                                    className="inputfile"
                                                    ref={this.file}
                                                    onChange={(e) => {
                                                        readImage("shopFile", "shop_image");
                                                        this.setState({shop: {...shop, main_image: e.target.files[0]}})
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="other_info">
                                <Form.Group>
                                    <Form.Label>{locationLabel}</Form.Label>

                                    <div style={{display: "flex"}}>
                                        <DropdownButton
                                            title={shape === "" ? shapeLabel : shape[0].toUpperCase() + shape.slice(1)}
                                            variant="outline-dark">
                                            <Dropdown.Item onClick={() => this.setState({shop: {...shop, shape: "poly"}})}>{polyLabel}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.setState({shop: {...shop, shape: "rect"}})}>{rectLabel}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.setState({shop: {...shop, shape: "circle"}})}>{circleLabel}</Dropdown.Item>
                                        </DropdownButton>

                                        <Form.Control
                                            type="text"
                                            placeholder={coordinatesLabel}
                                            value={coords}
                                            style={{marginLeft: "20px"}}
                                            onKeyDown={this.handleEnter}
                                            onChange={(e) => this.setState({shop: {...shop, coords: e.target.value}})}/>
                                    </div>
                                </Form.Group>

                                <div className="dropdown_shop_wrap">
                                    <Form.Group>
                                        <Form.Label>{mallLabel}</Form.Label>
                                        <DropdownButton
                                            id="dropdown--button-for_malls"
                                            title={(role === "admin" && isNumber(mall_id)) ?
                                                malls.filter(mall => mall.id === mall_id)[0].name :
                                                (role === "manager" && isNumber(mall_id)) ?
                                                    user.malls_name : selectShopPlaceholder}
                                            variant="outline-dark">
                                            {role === "admin" && malls.map((mall, index) =>
                                                <Dropdown.Item value={mall.name}
                                                               key={index}
                                                               onClick={() => this.setState({shop: {...shop, mall_id: mall.id}})}>
                                                    {mall.name}
                                                </Dropdown.Item>)}

                                            {role === "manager" &&
                                            <Dropdown.Item value={user.malls_name}
                                                           onClick={() => this.setState({shop: {...shop, mall_id: user.malls_id}})}>
                                                {user.malls_name}
                                            </Dropdown.Item>}
                                        </DropdownButton>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{ownerLabel}</Form.Label>

                                        <DropdownButton
                                            title={owner_id ? shops_owners.filter(owner => owner.id === owner_id)[0].first_name : chooseOwnerTitle}
                                            variant="outline-dark">
                                            {shops_owners.map(({first_name, last_name, id}, index) =>
                                                <Dropdown.Item value={`${first_name} ${last_name}`}
                                                               key={index}
                                                               onClick={() => this.setState({shop: {...shop, owner_id: id}})}>
                                                    {`${first_name} ${last_name}`}
                                                </Dropdown.Item>)}
                                        </DropdownButton>
                                    </Form.Group>

                                </div>
                            </div>

                            {editMode ?
                                <div className="wrap_for_submit-buttons">
                                    <Button variant="outline-success" onClick={() => this.editData()}>{editShopTitle}</Button>

                                    <Button
                                        onClick={() => toastr.confirm(
                                            deleteActionConfirmationMessage,
                                            {onOk: () => deleteShop(id)}
                                        )}
                                        variant="outline-danger">
                                        {deleteButtonTitle}
                                    </Button>
                                </div>
                                :
                                <Button
                                    variant="outline-success"
                                    className="submit"
                                    onClick={() => this.addData()}
                                >
                                    {addShopTitle}
                                </Button>}
                        </Form>
                    </div>
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                />
            </div>
        )
    }
}

export default ShopsAdminComponent
