import React, { useState, useRef } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'

const PolygonTooltip = (props) => {
	const { zoneId, name, poly, zoneBest, zoneWorst, filterTooltips, zonesDailyStats, zonesCheckedList, setZonesCheckedList } = props

	const [show, setShow] = useState(false)
	// const [clicked, setClicked] = useState(false)
	const target = useRef(null)
	const zoneStats = zonesDailyStats.find(({zone_id}) => zone_id === zoneId)

	const handlePolygonClick = () => {
		// setClicked(!clicked)
		if (zonesCheckedList.includes(zoneId)) {
			setZonesCheckedList(zonesCheckedList.filter((item) => item !== zoneId))
		} else {
			setZonesCheckedList([ ...zonesCheckedList, zoneId ])
		}
	}

	const clicked = zonesCheckedList.includes(zoneId)

	return (
		<>
	  		<polygon
			  	ref={target}
				points={poly}
				className={`zone ${zoneBest} ${zoneWorst} ${clicked && 'clicked'}`}
				// onClick={() => this.showZoneComparison(zone)}
				// onClick={() => {setClicked(!clicked);}}
				onClick={handlePolygonClick}
				onMouseOver={() => setShow(!show)}
				onMouseOut={() => setShow(!show)}
			/>
			<Overlay target={target.current} show={show || clicked} placement="top">
				<Tooltip className={`zone_tooltip ${zoneBest} ${zoneWorst}`}>
					<>
						<div style={{ fontWeight: 'bold', fontSize: '12px', lineHeight: '18px' }}>
							{name}
						</div>
						{filterTooltips.length > 0
						&& (
							<div style={{ fontSize: '10px', textAlign: 'left' }}>
								{
									filterTooltips.map((item) => {
										return (
											<div
												key={item.id}
												className="filterRow"
											>
												{item.label}
												<span className="value">
													{zoneStats && zoneStats[item.apiKey]}
												</span>
											</div>
										)
									})
								}
							</div>
						)}
					</>
				</Tooltip>
			</Overlay>
		</>
	)
}

export default PolygonTooltip
