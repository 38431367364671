import React, {Component} from 'react';
import {Form, DropdownButton, Dropdown, Button} from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import {toastr} from 'react-redux-toastr';
import {isEmpty, find} from "lodash";
import KeyboardEventHandler from "react-keyboard-event-handler";
import CreateUserContainer from "../../containers/CreateUserContainer";
import readImage from "../../helpers/readImage";
import validate from "../../helpers/validate";
import Image from "../Image";

class UsersAdminComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            User:{
                email:"",
                first_name:"",
                last_name:"",
                phone_number:""
            },
            chosenMallName: "all"
        };

        this.file = React.createRef();
        this.modal = React.createRef();
    }

    componentDidMount() {
        const { user, getUsers, complexRequest } = this.props;
        const { roles, malls_id } = user;
        // if (roles && malls_id){
        if (roles === "admin") {
            complexRequest([
                    {name: "malls", method: "get", type: "malls"},
                    {name: "users", method: "get", type: "users"}
                ],
                "GET_MALLS_AND_USERS")
        } else if (roles === "manager" && malls_id) {
            getUsers(`/?mall_id=${malls_id}`);
        }
        // }
    }

    componentDidUpdate(prevProps) {
        const { user, complexRequest, malls, getUsers, openAddForm, location } = this.props;
        const { chosenMallName } = this.state;
        const { roles, malls_id } = user;
        if (prevProps.user !== user) {
            if (roles === "admin") {
                complexRequest(
                    [
                        {name: "malls", method: "get", type: "malls"},
                        {name: "users", method: "get", type: "users"}
                    ],
                    "GET_MALLS_AND_USERS"
                )
            } else if (roles === "manager") {
                getUsers(`/?mall_id=${malls_id}`);
            }
        }

        if (prevProps.location.key !== location.key && malls_id) {  // this condition give possibility get actual data during click on current link of page
            if (roles === "admin") {
                complexRequest(
                    [
                        {name: "malls", method: "get", type: "malls"},
                        {name: "users", method: "get", type: `users${chosenMallName === "all" ? "" : `/?mall_id=${find(malls, ['name', chosenMallName]).id}`}`},
                    ],
                    "GET_MALLS_AND_USERS"
                )
            } else if (roles === "manager") {
                getUsers(`/?mall_id=${malls_id}`);
            }
        }

        if (openAddForm) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        this.props.setForm(false, false);
    }

    editData = () => {
        const { setForm, editUser, language } = this.props;
        const { User } = this.state;
        const { id, main_image, ...request } = User;
        const { warningTitle, filledInMessage } = language.notifications;

        if (validate(request, { warningTitle, filledInMessage })) {
            if (typeof main_image === "object" && main_image !== null) {
                request.main_image = main_image
            } else if (main_image === "") {
                request.main_image = ""
            }

            editUser(id, request);
            setForm(false, false);

            this.setState({
                User:{
                    email:"",
                    first_name:"",
                    last_name:"",
                    phone_number:"",
                    main_image:"",
                },
            });
        }
    }

    switchEditMode = (User) => {
        const { user: {roles} } = this.props;
        if ( roles === 'manager' && User.roles !== 'marketer' ) {
            return
        }

        const { setForm } = this.props;
        setForm(true, true);
        this.setState({
            User:{
                email:User.email,
                first_name:User.first_name,
                last_name:User.last_name,
                id:User.id,
                phone_number:User.phone_number,
                main_image:User.main_image,
            },
        });
    }

    handleChangeForm = () => {
        const { setForm, openAddForm } = this.props;
        setForm(!openAddForm, false);
        this.setState({
            User:{
                email:"",
                first_name:"",
                last_name:"",
                phone_number:"",
                main_image:""
            },
        })
    }

    handleClickOutside = (event) => {
        if (this.modal.current && !this.modal.current.contains(event.target)) {
            this.handleChangeForm();
        }
    };

    handleEnter = (e) => {
        if (e.keyCode === 13) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + 1].focus();

            e.preventDefault();
        }
    };

    render(){
        const { User, chosenMallName } = this.state;
        const {
            users,
            malls,
            deleteUser,
            editMode,
            openAddForm,
            getUsers,
            user: {roles},
            setForm,
            language,
        } = this.props;
        const usersList = (roles === "manager") ? users.filter(({roles}) => roles !== 'admin') : users

        const {
            adminSection,
            genericLabels,
            columns,
            notifications: { deleteActionConfirmationMessage },
        } = language;
        const {
            mainTitle,
            editUserTitle,
            createUserButtonTitle,
        } = adminSection.usersPage;
        const {
            allMallButtonTitle,
            tableCountPaginationLabels,
            searchFormPlaceholder,
            deleteButtonTitle,
            allTitle,
        } = genericLabels;
        const {
            photoLabel,
            nameLabel,
            surnameLabel,
            emailLabel,
            phoneNumberLabel,
            roleLabel,
            mallLabel,
        } = columns;

        const data = {
            columns: [
                {
                    label: photoLabel,
                    field: 'main_image',
                    sort: 'disabled',
                    width: 150,
                    minimal: 'sm none_sort'
                },
                {
                    label: nameLabel,
                    field: 'first_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: surnameLabel,
                    field: 'last_name',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: emailLabel,
                    field: 'email',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: phoneNumberLabel,
                    field: 'phone_number',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: roleLabel,
                    field: 'role',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: mallLabel,
                    field: 'mall',
                    sort: 'asc',
                    width: 150
                }
            ],
            rows: (!isEmpty(usersList) && (!isEmpty(malls) || roles==="manager")) ? usersList.map((item) => {
                return {
                    main_image: <Image src={item.main_image} alt="avatar" className="ava" spinnerSize={40} failedSrc="/images/profile.png"/>,
                    first_name: item.first_name ? item.first_name : "",
                    last_name: item.last_name ? item.last_name : "",
                    email: item.email ? item.email : "",
                    phone_number: item.phone_number ? item.phone_number : "",
                    role: item.roles ? item.roles : "",
                    mall: item.malls_name ? item.malls_name : "",
                    clickEvent: () => this.switchEditMode(item)
                }
            }) : []
        };

        return (
            <div className={`block_wrapper admin ${openAddForm ? "disabled" : ""}`}>
                <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={() => setForm(false, false)}/>
                <div className="wrap_header">
                    <h2 className="page_title">
                        {mainTitle}
                    </h2>

                    <Button className="btn_new_item"
                            variant="outline-dark"
                            onClick={() => this.handleChangeForm()}>
                       {createUserButtonTitle}
                    </Button>

                    {roles === "admin" &&
                    <DropdownButton
                        className="dropdown_btn"
                        id="dropdown-basic-button"
                        title={chosenMallName === "all" ? allMallButtonTitle : chosenMallName}>
                        <Dropdown.Item onClick={() => {
                            this.setState({chosenMallName: "all"});
                            getUsers()
                        }}>
                            {allTitle}
                        </Dropdown.Item>

                        {malls.map(({id, name}) =>
                            <Dropdown.Item
                                key={id}
                                onClick={() => {
                                    this.setState({chosenMallName: name});
                                    getUsers(`/?mall_id=${id}`)
                                }}>
                                {name}
                            </Dropdown.Item>)}
                    </DropdownButton>}

                    {(openAddForm && !editMode) && <CreateUserContainer/>}

                    {(openAddForm && editMode) && <div className="centralise open">
                        <Form className="add_new_data" ref={this.modal}>
                            <h3 className="mb-4">{editUserTitle}</h3>

                            <button className="close_btn" type="button" onClick={() => this.handleChangeForm()}/>

                            <div className="main_data mb-3">
                                <div className="main_info user">
                                    <div className="prof_wrap">
                                        <Form.Group>
                                            <Form.Label>{nameLabel}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={nameLabel}
                                                value={User.first_name}
                                                onKeyDown={this.handleEnter}
                                                onChange={(e) => this.setState({User: {...User, first_name: e.target.value}})}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>{surnameLabel}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={surnameLabel}
                                                value={User.last_name}
                                                onKeyDown={this.handleEnter}
                                                onChange={(e) => this.setState({User: {...User, last_name: e.target.value}})}/>
                                        </Form.Group>
                                    </div>

                                    <div className="prof_wrap">
                                        <Form.Group>
                                            <Form.Label>{phoneNumberLabel}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={phoneNumberLabel}
                                                value={User.phone_number}
                                                onKeyDown={this.handleEnter}
                                                onChange={(e) => this.setState({User: {...User, phone_number: e.target.value}})}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>{emailLabel}</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={emailLabel}
                                                value={User.email}
                                                onKeyDown={this.handleEnter}
                                                onChange={(e) => this.setState({User: {...User, email: e.target.value}})}/>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="avatar_block user">
                                    {User.main_image
                                    && (
                                        <button
                                            className="close_btn"
                                            type="button"
                                            onClick={() => {
                                                this.file.current.value = "";
                                                this.setState({User: {...User, main_image: ""}})
                                            }}
                                        />
                                    )}

                                    <img className="photo user"
                                        id="user_image"
                                        src={`${User.main_image ? User.main_image : "/images/profile.png"}`}
                                        onErrorCapture={e => e.target.src = "/images/profile.png"}
                                        alt="Avatar"
                                    />

                                    <Form.Group>
                                        <Form.Label htmlFor="userFile" className="labelForPhoto">
                                            <img src="/images/plus_green.svg" alt="plus"/>
                                        </Form.Label>

                                        <Form.Control
                                            type="file"
                                            id="userFile"
                                            name="file"
                                            className="inputfile"
                                            ref={this.file}
                                            onChange={e => {
                                                readImage("userFile", "user_image");
                                                this.setState({User: {...User, main_image: e.target.files[0]}})
                                            }}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="wrap_for_submit-buttons">
                                <Button variant="outline-success" onClick={() => this.editData()}>
                                    {editUserTitle}
                                </Button>

                                <Button
                                    onClick={() => toastr.confirm(deleteActionConfirmationMessage, {onOk: () => deleteUser(User.id)})}
                                    variant="outline-danger">
                                    {deleteButtonTitle}
                                </Button>
                            </div>
                        </Form>
                    </div>}
                </div>

                <MDBDataTable
                    className="data_table"
                    striped
                    data={data}
                    infoLabel={tableCountPaginationLabels}
                    searchLabel={searchFormPlaceholder}
                />
            </div>
        )
    }
}

export default UsersAdminComponent
