import React, {Component} from 'react'
import { Card, Button } from 'react-bootstrap'
import {isEmpty, max } from 'lodash'
import moment from "moment"
import Toggle from "react-toggle"
// import _ from 'lodash'

// import ShopPopoverComponent from './ShopPopoverComponent'
// import AreaShopNode from './AreaShopNode'
import MapImageTools from './MapImageTools'
import FilterTooltipContainer from '../pages/dashboard/FilterTooltipContainer'

import '../styles/MallMapComponent.sass'

class MallMapComponent extends Component {
    constructor(props){
        super(props);

        this.state = {
            popoverLeft:0,
            popoverTop:0,
            currentShop:null,
            floor: !isEmpty(props.chosenMall) && !isEmpty(props.chosenMall.floors) ?  props.chosenMall.floors.sort((a,b) => a.number - b.number)[0] : {}
        };

        this.onShopGraphPopover  = this.onShopGraphPopover.bind(this);
    }

    componentDidUpdate(prevProps){
        const {chosenMall} = this.props;
        if (chosenMall !== prevProps.chosenMall) {
            if (!isEmpty(chosenMall) && !isEmpty(chosenMall.floors)){
                this.setState({floor: chosenMall.floors.sort((a,b) => a.number - b.number)[0]})
            }else {
                this.setState({floor: {}})
            }
        }
    }

    getShopbarsStats = (shop_id) => {
        const params = {
            start_ts: moment(moment().format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
            end_ts: moment(moment().format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
            group_range: 3600,
            shop_id: shop_id
        }

        this.props.getShopbars(params)
    }

    onShopGraphPopover = (shop_id) => (e) => {
        const obj = this.props.mallshops.find((item) => item.id === shop_id),
        left = e.clientX + 50,
        top = max([e.clientY, 200]) - 180;
        this.setState({ popoverLeft: left, popoverTop: top, currentShop:obj }, () => this.getShopbarsStats(shop_id));
        this.props.switchPopover(true)
    };

    onPopover = (shop_id, e) => {
        const {setCoordsTopShopPopover, setShoppopover, mallshops} = this.props;
        e.persist();

        let obj = mallshops.find(({id}) => id === shop_id);
        if (obj) {
            obj.shop_name = obj.name;
            obj.shop_image = obj.main_image;
            obj.first_name = obj.owner_first_name;
            obj.last_name = obj.owner_last_name;
        }

        const left = e.clientX - 300, top = max([e.clientY, 140]) - 125;

        setCoordsTopShopPopover({left, top});
        setShoppopover(obj);
    };

    outPopover = () => this.props.switchTopShopPopover(false);

    handleCompareZonesClick = () => {
        const { /*calendar, */ daterange, setSelectedComparisonDates, switchZoneComparison } = this.props
        setSelectedComparisonDates([{ startDate: daterange.startDate, endDate: daterange.endDate }])
        switchZoneComparison(true)
    }

    render() {
        const {
            // switchShop,
            // showShop,
            // switchPopover,
            showPopover,
            // switchTopShopPopover,
            // addStoresToList,
            // mallshops,
            // storesList,
            // switchStoresSelectList,
            chosenMall,
            // chooseShop,
            daterange,
            setVisibilityHeatmap,
            setVisibilityHeatmapClustered,
            showHeatmap,
            showHeatmapClustered,
            language,
            getFloorHeatmapData,
            getFloorHeatmapDebugData,
            switchZoneComparison,
            chooseTopZone,
            zonesCheckedList,
            mallImageDimensions,
        } = this.props;

        const { floor }  = this.state;
        const { id, number, mall_id }  = floor;
        const {
            dashboardPage,
            genericLabels: { floorTitle },
            notifications: { clickToCompareShop },
            images: { noImageUrl },
        } = language;
        const { mainTitle, heatmapTitle }  = dashboardPage.mallMapBlock;

        return (
            <div className={`mall-map ${showPopover ? "disabled" : ""}`}>
                <Card>
                    <div className="panel-header">
                        <FilterTooltipContainer />

                        <div style={{ margin: 'auto 0', textAlign: 'center' }}>
                            <div style={{ display: 'inline-block' }}>
                                <div className="heatmap_wrap">
                                    <span className="mr-2">{heatmapTitle}</span>
                                    <Toggle
                                        checked={showHeatmap}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                const topfloorshopsOptions= {
                                                    start_ts: moment(daterange.startDate).format('YYYYMMDDHHmmss'),
                                                    end_ts: moment(daterange.endDate).format('YYYYMMDDHHmmss'),
                                                    mall_id: mall_id,
                                                    floor: number,
                                                }
                                                getFloorHeatmapData(topfloorshopsOptions)
                                            }
                                            setVisibilityHeatmap(e.target.checked)
                                        }}
                                        // disabled={!(mall_id === 47 && id === 15)} // floor 3
                                    />
                                </div>
                            </div>
                            {showHeatmap
                            && (
                                <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                                    <div className="heatmap_wrap">
                                        <span className="mr-2" style={{ fontWeight: '200'}}>Raw</span>
                                        <Toggle
                                            checked={showHeatmapClustered}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    const topfloorshopsOptions= {
                                                        start_ts: moment(daterange.startDate).format('YYYYMMDDHHmmss'),
                                                        end_ts: moment(daterange.endDate).format('YYYYMMDDHHmmss'),
                                                        mall_id: mall_id,
                                                        floor: number,
                                                    }
                                                    getFloorHeatmapDebugData(topfloorshopsOptions)
                                                } else {
                                                    const topfloorshopsOptions= {
                                                        start_ts: moment(daterange.startDate).format('YYYYMMDDHHmmss'),
                                                        end_ts: moment(daterange.endDate).format('YYYYMMDDHHmmss'),
                                                        mall_id: mall_id,
                                                        floor: number,
                                                    }
                                                    getFloorHeatmapData(topfloorshopsOptions)
                                                }
                                                setVisibilityHeatmapClustered(e.target.checked)
                                            }}
                                            // disabled={!(mall_id === 47 && id === 15)} // floor 3
                                        />
                                        <span className="ml-2" style={{ fontWeight: '200'}}>Clustered</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <Button
                            variant="outline-primary"
                            className="button btnCompareZones"
                            onClick={this.handleCompareZonesClick}
                            disabled={!zonesCheckedList.length}
                        >
                            Compare Zones
                        </Button>
                    </div>
                    <Card.Body>
                        <div className="mallmap" id="mallmap">
                            <div
                                id="img-magnifier-container"
                                className="img-magnifier-container"
                                style={{ minWidth: '1201px', minHeight: '1309px' }}
                                // style={{ minWidth: `${mallImageDimensions.width}px`, minHeight: `${mallImageDimensions.height}px` }}
                            >
                                <MapImageTools
                                    chosenStatsBlock={this.props.chosenStatsBlock}
                                    zonesDailyStats={this.props.zonesDailyStats}
                                    mallImageDimensions={this.props.mallImageDimensions}
                                    setMallImageDimensions={this.props.setMallImageDimensions}

                                    showHeatmap={showHeatmap}
                                    floor={this.state.floor}
                                    floorHeatmap={this.props.floorHeatmap}
                                    zones={this.props.zones}
                                    wrapperId="img-magnifier-container"
                                    imgId="image-mall"
                                    mapId="image-map"
                                    zoom={1.5}
                                    switchZoneComparison={switchZoneComparison}
                                    chooseTopZone={chooseTopZone}
                                    noImageUrl={noImageUrl}
                                />

                                {/*<map name="image-map" id="image-map">
                                     {mallshops
                                        .filter(({shape, coords, floor_number}) => (shape && coords && floor_number === number))
                                        .map((item) => {
                                            const {id, name, main_image} = item;
                                            return <AreaShopNode
                                                key={id}
                                                switchTopShopPopover={switchTopShopPopover}
                                                onPopover={debounce(this.onPopover, 50, {'leading': true, 'trailing': false})}
                                                outPopover={this.outPopover}
                                                addStoresToList={addStoresToList}
                                                storesList={storesList}
                                                onClick={this.onShopGraphPopover}
                                                switchStoresSelectList={switchStoresSelectList}
                                                shop_id={id}
                                                shop_name={name}
                                                shop_image={main_image}
                                                chooseShop={chooseShop}
                                                daterange={daterange}
                                                language={language}
                                                clickToCompareShop={clickToCompareShop}
                                                {...item}
                                            />
                                        })} 
                                </map>*/}
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                {/* {(showPopover && !showShop) &&
                <ShopPopoverComponent
                    switchShop={switchShop}
                    switchPopover={switchPopover}
                    left={this.state.popoverLeft}
                    top={this.state.popoverTop}
                    {...this.state.currentShop}
                />
                } */}
            </div>
        )
    }
}

export default MallMapComponent;
