import {connect} from 'react-redux';
import EventsSelectList from '../components/EventsSelectList';
import {withRouter} from 'react-router-dom';
import {switchEvent, addEventToList, deleteEventFromList, switchEventsSelectList, switchEventsComparison} from '../actions/filters';
import {chooseEvent} from '../actions/apiRequests';

const mapStateToProps = ({ filters, dashboardData, language }) => {
    return {
        showEventsSelectList: filters.showEventsSelectList,
        eventsList: dashboardData.eventsList,
        language: language.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchEvent: (state) => dispatch(switchEvent(state)),
        switchEventsComparison: (state) => dispatch(switchEventsComparison(state)),
        switchEventsSelectList: (state) => dispatch(switchEventsSelectList(state)),
        chooseEvent: (event, eventOptions, toList) => dispatch(chooseEvent(event, eventOptions, toList)),
        addEventToList: (event) => dispatch(addEventToList(event)),
        deleteEventFromList: (id) => dispatch(deleteEventFromList(id)),
    };
};
const EventsSelectListContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsSelectList));


export default EventsSelectListContainer;
