import {toastr} from "react-redux-toastr";
import {isEmpty} from "lodash";

function validateShopsCoords (shape, coords, { warningTitle, shopsCoordsValidation }){
    const {
        circleValidation,
        rectValidation,
        polyValidation,
        threeBitValidation,
        shapeValidation
    } = shopsCoordsValidation;

    if (shape){
        if(isEmpty(coords)){
            return true
        }else {
            let coordsArr = coords.split(",");

            if (coordsArr.every(el => el.length)) { //* possible coordinates  coords="364,100 385,143 475,104 522,188 639,165 620,89 569,0 " shape="polygon"
                switch (shape) {
                    case "circle":{
                        if (coordsArr.length !== 3 ){
                            toastr.info(warningTitle, circleValidation);
                            return false
                        }
                        return true
                    }
                    case "rect": {
                        if (coordsArr.length !== 4 ){
                            toastr.info(warningTitle, rectValidation);
                            return false
                        }
                        return true
                    }
                    case "poly": {
                        if (coordsArr.length < 5 ){
                            toastr.info(warningTitle, polyValidation);
                            return false
                        }
                        return true
                    }
                    default:
                        return false
                }
            }else {
                toastr.info(warningTitle, threeBitValidation);
                return false
            }
        }
    } else {
        toastr.info(warningTitle, shapeValidation);
        return false
    }
}

export default validateShopsCoords;