import React, { useState, useRef } from 'react'
import { OverlayTrigger, Popover, InputGroup, FormControl, Button } from 'react-bootstrap'
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range'
import { merge } from 'lodash'
import {toastr} from "react-redux-toastr"
import moment from 'moment'
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfYear,
    endOfYear,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
} from 'date-fns'

import languages from "../../lang"
import queryOptionsParser from "../../helpers/queryOptionsParser"
import getScheduleGroupRange from "../../helpers/getScheduleGroupRange"
import { getSubstUnit, getDataGroup } from "../../helpers/dashboardUtils"

import './DashboardCalendar.sass'

const definedsDateRanges = {
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOfLastYear: startOfYear(addYears(new Date(), -1)),
    endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const DashboardCalendar = (props) => {
    const overlayDataRange = useRef();
    const [calendar, setCalendar] = useState({ startDate: new Date(), endDate: new Date() });
    const {
        chosenMall,
        daterange,
        language,
        showDetailVisitorsData,
        showHeatmap,
        showHeatmapClustered,
        setProps,
        complexRequest,
        removeVisitorsComparisonData,
        setVisibilityVisitorsDetailData,
        setDaterange,
        setTimerange,
    } = props;
    const { id, open_time, close_time } = chosenMall;
    const { data, code } = language;
    const { genericLabels } = data;
    const { selectDateRangeTitle } = genericLabels;

    const selectedRange = () => {
        const { startDate, endDate, key } = daterange;
        // const start_ts = moment.utc(startDate).local().format('DD/MM/YYYY');
        // const end_ts = moment.utc(key === 'customTime' ? endDate : moment.utc()).local().format('DD/MM/YYYY');
        const start_ts = moment.utc(startDate).local();
        const end_ts = moment.utc(key === 'customTime' ? endDate : moment.utc()).local();

        // return `${start_ts}${moment(endDate).diff(moment(startDate), 'days') < 1 ? "" : ` - ${end_ts}`}`

        if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'day')) {
            return start_ts.format('DD/MM/YYYY')
        } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'month')) {
            return `${start_ts.format('DD')} - ${end_ts.format('DD/MM/YYYY')}`
        } else if (moment.utc(endDate).local().isSame(moment.utc(startDate).local(), 'year')) {
            return `${start_ts.format('DD/MM')} - ${end_ts.format('DD/MM/YYYY')}`
        } else {
            return `${start_ts.format('DD/MM/YYYY')} - ${end_ts.format('DD/MM/YYYY')}`
        }
    }

    const handleCalendarEnter = () => {
        const { startDate, endDate } = daterange;

        setCalendar({
            startDate: new Date(),
            endDate: new Date(),
            // startDate: new Date(moment.utc(startDate).format()),
            // endDate: new Date(moment.utc(endDate).format()),
        })
    }

    const handleCalendarChange = ({ select }) => {
        const { startDate, endDate } = select;

        setCalendar({ startDate, endDate })
    }

    const handleCalendarApply = () => {
        const { startDate, endDate } = calendar;
        const {
            attentionTitle,
            sameDateMessage,
            futureDateValidationMessage,
            wastePeriodMessage,
            lessThanOneYeaMessage,
        } = language.data.notifications;

        // diff between start of day and open_time = 32400000ms (9 hours)
        // diff between start of day and close_time = 79200000ms (22 hours)  
        const min = moment(open_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 32400000
        const max = moment(close_time, 'HHmmss').diff(moment().startOf('day'), 'milliseconds') // 79200000
        const diff = moment(endDate).diff(moment(startDate), "days") // 0
        const differenceTime  = moment(close_time, 'HHmmss').diff(moment(open_time, 'HHmmss'), 'hours') // 13 = 22 - 9

        const subst_unit = getSubstUnit(diff);
        const report_start_ts = subst_unit === "day"
            ? moment(startDate).startOf("day").add({milliseconds: min}).utc().format('YYYYMMDDHHmmss')
            : moment(moment(startDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss');
        const report_end_ts = subst_unit === "day"
            ? moment(startDate).startOf("day").add({milliseconds: max + 60000 /*+1min*/}).utc().format('YYYYMMDDHHmmss')
            : moment(moment(endDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss');
        const group_range = subst_unit === "day" ? getScheduleGroupRange(differenceTime) : getDataGroup(startDate, endDate);

        // validation selected date range
        if (moment(daterange.startDate).isSame(moment(report_start_ts, "YYYYMMDDHHmmss"))
            && moment(daterange.endDate).isSame(moment(report_end_ts, "YYYYMMDDHHmmss"))) {
            toastr.info(attentionTitle, sameDateMessage);
            return false
        }
        
        console.log('endDate1: ', endDate)
        console.log('endDate2: ', moment())
        if (moment(startDate).isAfter(moment(), 'day')
            || moment(endDate).isAfter(moment(), 'day')) {
            toastr.info(attentionTitle, futureDateValidationMessage);
            return false
        }

        if (!subst_unit) {
            toastr.info(wastePeriodMessage, lessThanOneYeaMessage);
            return false
        }
        // ***

        const scheduleConfig = subst_unit === "day"
            ? {add: {milliseconds: min}, subtract: {milliseconds: 86400000 - max - 60000 /*1min*/}}
            : {};

        const date_range = merge({
            key: 'customTime',
            startDate: moment(report_start_ts, "YYYYMMDDHHmmss").format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(report_end_ts, "YYYYMMDDHHmmss").format('YYYY-MM-DD HH:mm:ss'),
            group_range,
            subst_unit
        }, scheduleConfig);


        const mallDailyStatsOptions = {
            start_ts: report_start_ts,
            end_ts:  report_end_ts,
            mall_id: id,
        }
        // const visitorsParams = {
        //     start_ts: report_start_ts,
        //     end_ts:  report_end_ts,
        //     group_range,
        //     mall_id: id,
        // }
        // const totalcountOptions = {
        //     start_ts:   report_start_ts,
        //     end_ts:     report_end_ts,
        //     mall_id:    id,
        // }
        // const prevtotalcountOptions = {
        //     start_ts:   report_start_ts,
        //     end_ts:     report_end_ts,
        //     mall_id:    id,
        // }
        // const averageVisitorsTimeOptions = {
        //     start_ts:   report_start_ts,
        //     end_ts:     report_end_ts,
        //     mall_id:    id,
        // }
        // const zonesParams = {
        //     start_ts:   report_start_ts,
        //     end_ts:     report_end_ts,
        //     mall_id:    id,
        // }
        const heatmapParams = {
            start_ts: report_start_ts,
            end_ts: report_end_ts,
            mall_id: id,
            floor: 1
        }

        const arrComplexRequest = [
            {name: "mallDailyStats", method: "get", type: `reports/analysis/daily${queryOptionsParser(mallDailyStatsOptions)}`},
            // {name: "zonesDailyStats", method: "get", type: `reports/analysis/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
            {name: "zonesDailyStats", method: "get", type: `crossline/daily/zones${queryOptionsParser(mallDailyStatsOptions)}`},
            // {name: "visitors", method: "get", type: `stats${queryOptionsParser(visitorsParams)}`, catchType:{timestamp:[], visitors:[]}},
            // // {name: "topshops", method: "get", type: `topshops${queryOptionsParser(topshopsParams)}`},
            // // {name: "topfloorshops", method: "get", type: `topshops${queryOptionsParser(topfloorshopsParams)}`},
            // {name: "totalcount", method: "get", type: `count/visitors${queryOptionsParser(totalcountOptions)}`},
            // {name: "prev_totalcount", method: "get", type: `count/visitors${queryOptionsParser(prevtotalcountOptions)}`},
            // {name: "avgVisitorsTime", method: "get", type: `stats/avgtime-ranges${queryOptionsParser(averageVisitorsTimeOptions)}`},
            // {name: "topzones", method: "get", type: `stats/zones${queryOptionsParser(zonesParams)}`},
        ]
        if (showHeatmap) {
            if (showHeatmapClustered) {
                arrComplexRequest.push({
                    name: "floorHeatmap", method: "get", type: `stats/heatmap/debug${queryOptionsParser(heatmapParams)}`
                })
            } else {
                arrComplexRequest.push({
                    name: "floorHeatmap", method: "get", type: `stats/heatmap${queryOptionsParser(heatmapParams)}`
                })
            }
        }

        complexRequest(
            arrComplexRequest,
            "GET_VISITORS_AND_TOPSHOPS",
            () => {
                setProps({ timeValues: {min, max}}, () => setTimerange({min, max}));
                setDaterange(date_range);
                removeVisitorsComparisonData();
                showDetailVisitorsData && setVisibilityVisitorsDetailData(false);
            }
        );
        // this.refs.overlayDataRange.hide()
        overlayDataRange.current.hide()
    }

    return (
        <div className="selected-range dashboardCalendar">
            <OverlayTrigger
                // ref="overlayDataRange"
                ref={overlayDataRange}
                trigger="click"
                placement="bottom"
                rootClose
                onEnter = {handleCalendarEnter}
                className='DashboardCalendar'
                overlay={
                    <Popover
                        id='calendar-data-range'
                        title={selectDateRangeTitle}
                        className="DashboardCalendarPopover"
                    >
                        <DateRangePicker
                            showSelectionPreview
                            moveRangeOnFirstSelection={false}
                            className='DashboardCalendar'
                            months={2}
                            locale={languages[code].reactDateRangeLocale}
                            ranges={[{
                                startDate: calendar.startDate,
                                endDate: calendar.endDate,
                                // startDate: new Date(calendar.startDate),
                                // endDate: new Date(calendar.endDate),
                                // startDate: new Date(daterange.startDate),
                                // endDate: new Date(daterange.endDate),
                                key: 'select',
                                showDateDisplay: true,
                            }]}
                            direction="horizontal"
                            onChange={handleCalendarChange}
                            maxDate={new Date()}
                            // focusedRange={[0,1]}

                            inputRanges={[]}
                            staticRanges= {[
                                ...createStaticRanges([
                                    {
                                        label: 'Custom',
                                        range: () => ({
                                            startDate:  definedsDateRanges.startOfToday,
                                            endDate:  definedsDateRanges.endOfToday
                                        }),
                                        isSelected: (range) => {
                                            if (
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfYesterday) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfYesterday)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfWeek) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfLastWeek) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfLastWeek)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfMonth) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfLastMonth) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfLastMonth)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfYear) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfToday)
                                                ) ||
                                                (   isSameDay(range.startDate, definedsDateRanges.startOfLastYear) 
                                                    && isSameDay(range.endDate, definedsDateRanges.endOfLastYear)
                                                )
                                            ) {
                                                return false
                                            }  

                                            return true 
                                        }
                                    },                                               
                                ]),
                                // ...defaultStaticRanges.filter(({label}) => !['Today'].includes(label)),
                                ...createStaticRanges([
                                    {
                                        label: 'Yesterday',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfYesterday,
                                            endDate: definedsDateRanges.endOfYesterday,
                                        }),
                                    },
                                    {
                                        label: 'This Week',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfWeek,
                                            endDate: definedsDateRanges.endOfToday,
                                        }),
                                    },
                                    {
                                        label: 'Last Week',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfLastWeek,
                                            endDate: definedsDateRanges.endOfLastWeek,
                                        }),
                                    },
                                    {
                                        label: 'This Month',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfMonth,
                                            endDate: definedsDateRanges.endOfToday,
                                        }),
                                    },
                                    {
                                        label: 'Last Month',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfLastMonth,
                                            endDate: definedsDateRanges.endOfLastMonth,
                                        }),
                                    },
                                    {
                                        label: 'This Year',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfYear,
                                            endDate: definedsDateRanges.endOfToday,
                                        }),
                                    },
                                    {
                                        label: 'Last Year',
                                        range: () => ({
                                            startDate: definedsDateRanges.startOfLastYear,
                                            endDate: definedsDateRanges.endOfLastYear,
                                        }),
                                    },
                                ])
                            ]}
                        />

                        <div className="title">
                            Date Range:
                        </div>

                        <Button
                            variant="outline-primary"
                            className="button applyButton"
                            onClick={handleCalendarApply}
                        >
                            Select date range
                        </Button>
                    </Popover>
                }
            >
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text style={{
                            borderRadius: '20px 0px 0px 20px',
                            background: '#fff url(/images/calendar_icon.png) no-repeat center center',
                            padding: '0 16px' }}
                        />
                    </InputGroup.Prepend>
                    <FormControl disabled value={selectedRange()} />
                    <div className="arrowDown">
                        <span
                            className="separator" 
                            style={{                                       
                                backgroundColor: 'rgb(204, 204, 204)',
                                marginBottom: '8px',
                                marginTop: '8px',
                                width: '1px'
                            }}
                        />
                        <div className="arrow">
                            <svg height="20" width="20" viewBox="0 0 20 20" focusable="false">
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                            </svg>
                        </div>
                    </div>
                </InputGroup>
            </OverlayTrigger>
        </div>
    )
}

export default DashboardCalendar
