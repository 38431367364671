import React, {Component, memo} from 'react';
import {toastr} from "react-redux-toastr";
import { ClipLoader, PacmanLoader } from 'react-spinners';

import "../styles/Spinner.sass"

class Spinner extends Component{
    constructor(props){
        super(props);

        this.timeout = null
    }

    componentDidUpdate(prevProps){
        const { show, stopSpinner, language } = this.props;
        const { requestsStuckMessage, errorTitle } = language.notifications;

        if(show !== prevProps.show){
            clearTimeout(this.timeout);

            if (show !== 0) {
                this.timeout = setTimeout(() => {
                    stopSpinner();
                    toastr.error(errorTitle, requestsStuckMessage);
                }, 330000);
            }
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            this.timeout = null
        }
    }

    render(){
        const { show, event, setEvent } = this.props;

        return (
            <div className={`dimScreen ${(show > 0 || event === "show") ? "" : "disabled"}`}>
                <div onMouseDown={(e) => e.button===1 && setEvent("show")}>
                    <ClipLoader
                        sizeUnit={"px"}
                        size={150}
                        color={'#201158'}
                        loading={show > 0}
                    />

                    {event === "show" && <div className="adm">
                        <input onChange={e => e.target.value==="object" && setEvent(false)} placeholder="typeof null?"/>
                        <PacmanLoader
                            sizeUnit={"px"}
                            size={150}
                            color={'#201158'}
                            loading={true}
                        />
                    </div>}
                </div>
            </div>
        )
    }
}

export default memo(Spinner)
