import moment from 'moment'
import {isEmpty} from 'lodash'

const defaultState = {
    chosenStatsBlock: { id: 1, name: 'Conversion', unit: '%', tooltip: 'Amount of transactions divided by the foot traffic', apiKey: 'conversion' },
    mallDailyStats: {},
    zonesDailyStats: [],
    filterTooltips: [{ id: 1, value: 1, label: 'Conversion', apiKey: 'conversion' }],
    zonesCheckedList: [],

    heatmap:    {},
    visitors:   {
        timestamp:[],
        visitors:[]
    },
    visitorsDetailData:   {
        visitors:{
            timestamp:[],
            visitors:[]
        },
        avgVisitorsTime:{}
    },
    visitorsComparisonData:[],
    avgVisitorsTime:{},
    avgComparisonData:[],
    shopbars:   {},
    eventsList: [],
    storesList: [],
    topshops:   [], //* top 5 shops of the mall
    topfloorshops: [], //* top 5 shops of the mall's floor
    chosenTopFloor: 1, //* selected floor for the top 5 shops
    shopsVisitorsData:[],
    mallshops:  [],
    floorHeatmap: [],
    timerange:  {min: 0/*00:00*/, max: 86340000 /*23:59*/},
    daterange:  {
        key:       'yesterday',  //if you will change it, not forget investigate isDateRangeConsistTodayDate filter
        startDate:  moment(moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate:    moment(moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm:ss'),
        group_range: 10800,
        subst_unit: 'day',
        range:{day: 1},
        prev_range:{day: 2}
    },
    chosenEvent: {},
    chosenShop: {},
    chosenMall: {},
    groups: {
        statistics: 'visitors', //* [visitors, visits, avgvisit]  if is false display the Visits data instead of Visitors
        woman:      false,
        man:        false,
        boys:       false,
        girls:      false,
        all:        true,
    },
    shoppopover: {},
    shopTypes: [],
    chosenCompareWith:[],
    totalcount: {
        visitors: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        visits: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        avgvisit: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        }
    },
    prev_totalcount: {
        visitors: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        visits: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        avgvisit: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        }
    },
    topzones: [],
    zones: [],
    // chosenTopZone: {},
};

const dashboardData = (state = defaultState, action) =>{
    switch (action.type) {
        case 'GET_CURRENT_SESSION_FULFILLED': {
            return action.payload.rejected ? state : Object.assign({}, state, {
                shopTypes: action.payload.shopTypes
            })
        }
        case 'SET_DATERANGE': {
            return Object.assign({}, state, {
                daterange: action.payload
            });
        }
        case 'SET_TIMERANGE': {
            return Object.assign({}, state, {
                timerange: action.payload
            });
        }
        case 'GET_HEATMAP_FULFILLED': {
            return Object.assign({}, state, {
                heatmap: action.payload
            });
        }
        case 'GET_VISITORS_FULFILLED': {
            return Object.assign({}, state, {
                visitors: action.payload
            });
        }
        case 'GET_COMPARISON_DATA_FULFILLED': {
            return Object.assign({}, state, {
                visitorsComparisonData: action.payload.visitorsComparisonData,
                avgComparisonData: action.payload.avgComparisonData,
            });
        }
        case 'REMOVE_VISITORS_DATA_COMPARISON': {
            return Object.assign({}, state, {
                visitorsComparisonData: [],
                avgComparisonData: [],
                chosenCompareWith:  []
            });
        }
        case 'GET_AVG_VISITORS_TIME_COMPARISON_DATA_FULFILLED': {
            return Object.assign({}, state, {
                avgComparisonData: action.payload,
            });
        }
        case 'GET_SHOPBARS_FULFILLED': {
            return Object.assign({}, state, {
                shopbars: action.payload
            });
        }
        case 'ADD_EVENT_TO_LIST':
        case 'ADD_EVENT_TO_LIST_FULFILLED': {
            return Object.assign({}, state, {
                eventsList: action.payload ? [action.payload, ...state.eventsList] : []
            });
        }
        case 'REMOVE_EVENT_FROM_LIST': {
            return Object.assign({}, state, {
                eventsList: state.eventsList.filter(({id}) => action.payload !== id)
            });
        }
        case 'ADD_STORE_TO_LIST':
        case 'ADD_STORE_TO_LIST_FULFILLED': {
            return Object.assign({}, state, {
                storesList: action.payload ? [...state.storesList, action.payload] : []
            });
        }
        case 'REMOVE_STORE_FROM_LIST': {
            return Object.assign({}, state, {
                storesList: state.storesList.filter(({id}) => action.payload !== id)
            });
        }
        case 'GET_DASHBOARD_DATA_FULFILLED':
        case 'CHANGE_MALL_FULFILLED':{
            if(action.payload){
                return Object.assign({}, state, {
                    mallDailyStats: action.payload.mallDailyStats ? action.payload.mallDailyStats[0] : state.mallDailyStats,
                    zonesDailyStats: action.payload.zonesDailyStats ?action.payload.zonesDailyStats : state.zonesDailyStats,
                    zones: action.payload.zones ? action.payload.zones : state.zones,
                    chosenMall: action.payload.mall ? action.payload.mall : state.chosenMall,
                    // heatmap: action.payload.heatmap,
                    // visitors: action.payload.visitors,
                    // // topshops: action.payload.topshops,
                    // // mallshops:  action.payload.mallshops,
                    // chosenMall: action.payload.mall ? action.payload.mall : state.chosenMall,
                    // // topfloorshops: action.payload.topfloorshops,
                    // totalcount: (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount)) ? defaultState.totalcount : action.payload.totalcount,
                    // prev_totalcount: (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount)) ? defaultState.prev_totalcount : action.payload.prev_totalcount,
                    // avgVisitorsTime: (isEmpty(action.payload.avgVisitorsTime) && isEmpty(action.payload.avgVisitorsTime[0])) ? {} : action.payload.avgVisitorsTime[0],
                    // topzones:  action.payload.topzones,
                    // zones:  action.payload.zones,
                });
            }
            return state
        }
        case 'GET_TOTALCOUNT_FULFILLED':{
            if(action.payload) {
                const floorHeatmap = action.payload.floorHeatmap ? action.payload.floorHeatmap : state.floorHeatmap
                const totalcount = (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount))
                    ? defaultState.totalcount
                    : action.payload.totalcount;
                const prev_totalcount = (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount))
                    ? defaultState.prev_totalcount
                    : action.payload.prev_totalcount;

                return Object.assign({}, state, {
                    totalcount: {...state.totalcount, ...totalcount},
                    prev_totalcount: {...state.prev_totalcount, ...prev_totalcount},
                    floorHeatmap: floorHeatmap
                });
            }
            return state
        }
        case 'GET_MALL_BY_ALL_MALLS_FULFILLED': {
            return Object.assign({}, state, {
                chosenMall: action.payload.mall,
            });
        }
        case 'GET_MALL_FULFILLED': {
            return Object.assign({}, state, {
                chosenMall: action.payload,
            });
        }
        case 'EDIT_MALL_FULFILLED': {
            if (action.payload.id === state.chosenMall.id) {
                return Object.assign({}, state, {
                    chosenMall: Object.assign({}, state.chosenMall, {...action.payload})
                });
            } else {
                return state
            }
        }
        case 'GET_VISITORS_AND_TOPSHOPS_FULFILLED': {
            if (action.payload) {
                const floorHeatmap = action.payload.floorHeatmap ? action.payload.floorHeatmap : state.floorHeatmap
                return Object.assign({}, state, {
                    mallDailyStats: action.payload.mallDailyStats[0],
                    zonesDailyStats: action.payload.zonesDailyStats,
                    // visitors: action.payload.visitors,
                    // // topshops:   action.payload.topshops,
                    // // topfloorshops:   action.payload.topfloorshops,
                    // totalcount:   (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount)) ? defaultState.totalcount : action.payload.totalcount,
                    // prev_totalcount:   (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount)) ? defaultState.prev_totalcount : action.payload.prev_totalcount,
                    // avgVisitorsTime: (isEmpty(action.payload.avgVisitorsTime) && isEmpty(action.payload.avgVisitorsTime[0])) ? {} : action.payload.avgVisitorsTime[0],
                    // topzones:  action.payload.topzones,
                    floorHeatmap: floorHeatmap,
                });
            }
            return state
        }
        case 'GET_SHOPS_VISITORS_DATA_FULFILLED': {
            return Object.assign({}, state, {
                shopsVisitorsData: action.payload,
            });
        }
        case 'GET_TOPFLOOR_SHOPS_FULFILLED': {
            return Object.assign({}, state, {
                topfloorshops: action.payload
            });
        }
        case 'SET_CHOSENTOPFLOOR': {
            return Object.assign({}, state, {
                chosenTopFloor: action.payload
            });
        }
        case 'LOGGED_OUT_FULFILLED': {
            return defaultState
        }
        case 'SET_GROUPS': {
            const name = action.payload
            let groups = state.groups

            if (['visitors', 'visits', 'avgvisit'].indexOf(name) > -1 ) {
                groups.statistics = name
            }
            else {
                groups[name] = !state.groups[name]
            }

            if (name === 'all') {
                ['woman','man','boys','girls'].map( (item) => groups[item] = false)
            }
            else if (['woman','man','boys','girls'].indexOf(name) > -1 ) {
                groups.all = false
            }

            return Object.assign({}, state, {
                groups: {...groups}
            });
        }

        case 'SET_CHOSEN_STATS_BLOCK': {
            return Object.assign({}, state, {
                chosenStatsBlock: action.payload
            });
        }

        case 'SET_SHOPPOPOVER': {
            return Object.assign({}, state, {
                shoppopover: action.payload
            });
        }
        case 'CHOOSE_EVENT_FULFILLED':
        case 'CHOOSE_EVENT': {
            return Object.assign({}, state, {
                chosenEvent: action.payload
            });
        }
        case 'CHOOSE_SHOP_FULFILLED': {
            return Object.assign({}, state, {
                chosenShop: action.payload
            });
        }
        case 'SET_COMPARE_WITH': {
            let chosenCompareWith = state.chosenCompareWith;
            const index = chosenCompareWith.find((it) => it.key === action.payload.key);
            if (index) {
                chosenCompareWith = chosenCompareWith.filter((item) => action.payload.key !== item.key);
            } else {
                chosenCompareWith.push(action.payload);
            }
            return Object.assign({}, state, {
                chosenCompareWith: [...chosenCompareWith]
            })
        }
        case 'GET_FLOOR_HEATMAP_DATA_FULFILLED': {
            return Object.assign({}, state, {
                floorHeatmap:  action.payload
            })
        }
        case 'GET_VISITORS_DETAIL_DATA_FULFILLED': {
            return Object.assign({}, state, {
                visitorsDetailData:  action.payload
            })
        }
        case 'SET_EDITED_ZONE': {
            return Object.assign({}, state, {
                zones: action.payload ? state.zones.map((item) => {
                    if (item.id === action.payload.id) {
                        return Object.assign({}, item, {
                            ...action.payload
                        })
                    }
                    return item;
                }) : state.zones
            });
        }
        case 'SET_ADDED_ZONE': {
            return Object.assign({}, state, {
                zones: action.payload ? [...state.zones , action.payload] : state.zones
            });
        }
        case 'SET_REMOVED_ZONE': {
            const listId = action.payload.split(',')
            return Object.assign({}, state, {
                zones: state.zones.filter(({id}) => !listId.includes(id.toString()))
                // zones: state.zones.filter(({id}) => action.payload !== id)
            });
        }
        case 'SET_FILTER_TOOLTIPS': {
            return Object.assign({}, state, {
                filterTooltips: [ ...action.payload ]
            });
        }
        case 'SET_ZONES_CHECKED_LIST': {
            return Object.assign({}, state, {
                zonesCheckedList: [ ...action.payload ]
            });
        }
        // case 'CHOOSE_TOPZONE_FULFILLED':
        // case 'CHOOSE_TOPZONE': {
        //     return Object.assign({}, state, {
        //         chosenTopZone: action.payload
        //     });
        // }
        default:
            return state;
    }
};

export default  dashboardData
