import { isEmpty } from 'lodash'

const defaultState = {
    selectedComparisonDates:[],
    compareData: [],
    detailCompareData: [],
    showDetailCompareData: false,

    chosenTopZone: {},
    b2sZoneRelations: {},
    visitors:   {
        timestamp: [],
        visitors: []
    },
    visitorsComparisonData: [],
    visitorsComparisonZone: [],
    showDetailVisitorsData: false,
    showDetailVisitorsDataDeep: false,
    // visitorsDetailData: [],
    // visitorsDetailDataDeep: [],
    visitorsDetailData:   {
        timestamp:[],
        visitors:[],
    },
    visitorsDetailDataDeep:   {
        timestamp:[],
        visitors:[],
    },
    // Byte2Story
    b2sDepartments: [
        {id: 1, name: 'Departament 1'},
        {id: 2, name: 'Departament 2'},
        {id: 3, name: 'Departament 3'},
    ],
    b2sSubdepartments: [
        {id: 1, name: 'Sub Departament 1'},
        {id: 2, name: 'Sub Departament 2'},
        {id: 3, name: 'Sub Departament 3'},
    ],
    b2sDeepdepartments: [
        {id: 1, name: 'Deep Departament 1'},
        {id: 2, name: 'Deep Departament 2'},
        {id: 3, name: 'Deep Departament 3'},
    ],
    groups: {
        statistics: 'visitors', //* [visitors, visits, avgvisit]  if is false display the Visits data instead of Visitors
        woman:      false,
        man:        false,
        boys:       false,
        girls:      false,
        all:        true,
    },    
    totalcount: {
        visitors: {
            "count":0,
            "woman":0, 
            "man":0, 
            "boys":0,
            "girls":0,
            "boys010":0, 
            "boys1020":0,
            "girls010":0, 
            "girls1020":0,
            "man2040":0, 
            "man40":0,
            "woman2040":0, 
            "woman40":0
        },
        visits: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        avgvisit: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        }
    },
    totalcountStore: {
        visitors: {
            "count":0,
            "woman":0, 
            "man":0, 
            "boys":0,
            "girls":0,
            "boys010":0, 
            "boys1020":0,
            "girls010":0, 
            "girls1020":0,
            "man2040":0, 
            "man40":0,
            "woman2040":0, 
            "woman40":0
        },
        visits: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        avgvisit: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        }
    },
    prev_totalcount: {
        visitors: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        visits: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        },
        avgvisit: {
            "count":0,
            "woman":0, "man":0, "boys":0, "girls":0,
            "boys010":0, "boys1020":0,
            "girls010":0, "girls1020":0,
            "man2040":0, "man40":0,
            "woman2040":0, "woman40":0
        }
    },
};

const zoneData = (state = defaultState, action) => {
    switch (action.type) {
        case 'CHOOSE_TOPZONE_FULFILLED':
        case 'CHOOSE_TOPZONE': {
            return Object.assign({}, state, {
                chosenTopZone: action.payload
            });
        }
        case 'GET_ZONES_FULFILLED': {
            if (action.payload) {
                return Object.assign({}, state, {
                    b2sDepartments: action.payload.departments,
                    b2sSubdepartments: action.payload.subdepartments,
                    b2sDeepdepartments: action.payload.deepdepartments,
                });
            }
            return state
        }
        case 'GET_ZONE_COMPARISON_DATA_FULFILLED': {
            if (action.payload) {
                return Object.assign({}, state, {
                    compareData: action.payload.compareData,
                    // totalcount: isEmpty(action.payload.totalcount) ? defaultState.totalcount : action.payload.totalcount,
                    // prev_totalcount: (isEmpty(action.payload.totalcount) || isEmpty(action.payload.prev_totalcount)) ? defaultState.prev_totalcount : action.payload.prev_totalcount,
                    // visitors: action.payload.visitors,
                    // visitors: action.payload.visitors[0].stats,
                    // totalcountStore: action.payload.totalcountStore,
                    // visitorsComparisonData: [],
                });
            }
            return state
        }
        case 'GET_ZONE_COMPARISON_WITH_DATA_FULFILLED': {
            return Object.assign({}, state, {
                // visitorsComparisonData: action.payload.visitorsComparisonData,
                compareData: action.payload.compareData,
            });
        }
        case 'GET_ZONE_COMPARISON_ZONE_FULFILLED': {
            // res.content.forEach((item) => {
            //     console.log('item: ', item)
            //     const {stats:{timestamp, visitors}, zone_id} = item
            //     // records.push({...item, key})
            //     records.push({zone_id, key, timestamp, visitors})
            // })
            if (action.payload) {
                return Object.assign({}, state, {
                    visitorsComparisonZone: action.payload.visitors
                });
            }
            return state
        }
        case 'GET_ZONE_DETAIL_COMPARE_DATA_FULFILLED': {
            return Object.assign({}, state, {
                detailCompareData:  action.payload,
                showDetailCompareData: true,
            })
        }
        case 'GET_ZONE_VISITORS_DETAIL_DATA_FULFILLED': {
            return Object.assign({}, state, {
                visitorsDetailData:  action.payload,
                showDetailVisitorsData: true,
            })
        }
        case 'GET_ZONE_VISITORS_DETAIL_DATA_DEEP_FULFILLED': {
            return Object.assign({}, state, {
                visitorsDetailDataDeep:  action.payload
            })
        }
        case 'GET_B2S_ZONE_RELATIONS_FULFILLED':
        case 'GET_MALL_AND_POLYGONS_FULFILLED': {
            if (action.payload && action.payload.hasOwnProperty('zonerelations')){
                return Object.assign({}, state, {
                    b2sZoneRelations: action.payload.zonerelations,
                });
            }
            return state
        }
        case 'CLEAR_ZONE': {
            return Object.assign({}, defaultState);
        }
        case 'REMOVE_ZONE_VISITORS_COMPARISON_DATA': {
            return Object.assign({}, state, {
                compareData: [],
                // visitorsComparisonData: [],
            });
        }
        case 'REMOVE_ZONE_VISITORS_COMPARISON_DATA_DEEP': {
            return Object.assign({}, state, {
                visitorsComparisonDataDeep: [],
            });
        }
        case 'REMOVE_ZONE_COMPARISON_ZONE': {
            return Object.assign({}, state, {
                visitorsComparisonZone: [],
            });
        }
        case 'SET_GROUPS_ZONE': {
            const name = action.payload
            let groups = state.groups

            if (['visitors', 'visits', 'avgvisit'].indexOf(name) > -1 ) {
                groups.statistics = name
            }
            else {
                groups[name] = !state.groups[name]
            }

            if (name === 'all') {
                ['woman','man','boys','girls'].map( (item) => groups[item] = false)
            }
            else if (['woman','man','boys','girls'].indexOf(name) > -1 ) {
                groups.all = false
            }

            return Object.assign({}, state, {
                groups: {...groups}
            });
        }
        case 'SET_ZONE_VISIBILITY_VISITORS_DETAIL_DATA': {
            return Object.assign({}, state, {
                showDetailVisitorsData: action.payload
            });
        }
        case 'SET_ZONE_VISIBILITY_VISITORS_DETAIL_DATA_DEEP': {
            return Object.assign({}, state, {
                showDetailVisitorsDataDeep: action.payload
            });
        }
        case 'ADD_ZONE_RELATIONS_FULFILLED': {
            if (action.payload){
                return Object.assign({}, state, {
                    b2sZoneRelations: action.payload,
                });
            }
            return state
        }
        case 'EDIT_ZONE_RELATIONS_FULFILLED': {
            if (action.payload){
                return Object.assign({}, state, {
                    b2sZoneRelations: action.payload,
                });
            }
            return state
        }
        case 'SET_SELECTED_COMPARISON_DATES': {
            return Object.assign({}, state, {
                selectedComparisonDates: action.payload
            });
        }
        case 'SET_ZONE_VISIBILITY_DETAIL_COMPARE_DATA': {
            return Object.assign({}, state, {
                showDetailCompareData: action.payload
            });
        }
        default:
            return state;
    }
};

export default  zoneData
