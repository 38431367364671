import React from 'react';
import parseDate from "../helpers/parseDate";
import Image from "./Image";

const EventCard = ({ event, deleteEventFromList, chooseEvent, language }) => {
    const {visits, budget, id, start_datetime, shop_name, main_image, name} = event;
    const {
        columns,
        genericLabels,
        images: { noImageUrl },
    } = language;
    const { budgetLabel } = columns;
    const { moreTitle } = genericLabels;

    let  men = 0, women = 0, girl = 0, boy = 0;
    if (visits) {
        const {count, man, woman, girls, boys} = visits;
        men = count*man;
        women = count*woman;
        girl = girls*count;
        boy = boys*count;
    }
    return (
        <div className="event_card">
            <button className="close_btn" onClick={() => {
                deleteEventFromList(id);
                document.getElementById(`comparison_checkbox${id}`).checked = false;
            }}/>

            <div className="head">
                <p className="location">{shop_name || ""}</p>
                <p className="date">{start_datetime ? parseDate(start_datetime,"DD MMM YYYY") : ""}</p>
            </div>

            <div className="img">
                <Image src={main_image} failedSrc={noImageUrl}/>
            </div>

            <div className="header_info">
                <h4 className="name">{name || ""}</h4>
                <button className="more" onClick={() => chooseEvent(event)}>{moreTitle}</button>
            </div>

            <p className="title_square">{budgetLabel}</p>
            <p className="sum">
                {budget || 0} ₪
            </p>

            <div className="gender">
                <div className="stat-wrapper">
                    <img alt="" src="/images/male.png"/>
                    <span className="percentage male-blue">
                        {men}%
                    </span>
                </div>
                <div className="stat-wrapper">
                    <img alt="" src="/images/female.png"/>
                    <span className="percentage female-blue">
                        {women}%
                    </span>
                </div>
                <div className="stat-wrapper">
                    <img alt="" src="/images/boy.png"/>
                    <span className="percentage male-blue">
                        {boy}%
                    </span>
                </div>
                <div className="stat-wrapper">
                    <img alt="" src="/images/girl.png"/>
                    <span className="percentage girl-pink">
                        {girl}%
                    </span>
                </div>
            </div>
        </div>
    )
};

export default EventCard
