import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import {connect} from 'react-redux'

import MallsDashboardContainer from "../../containers/MallsDashboardContainer";
import MallsFormContainer from "../../containers/MallsFormContainer";

const MallsAdminComponent = ({showCamerasMap}) => {
    return (
        <Switch>
            <Route path="/admin/malls/dashboard" component={MallsDashboardContainer}/>
            <Route
                path="/admin/malls/edit"
                render={() => <div
                    className={`${showCamerasMap ? "shop disabled" : ""} content block_wrapper admin editing`}
                    style={{backgroundColor: "#ffffff"}}>
                    <MallsFormContainer/>
                </div>}
            />

            <Redirect to="/admin/malls/dashboard"/>
        </Switch>
    )
};


const mapStateToProps = ({filters}) => {
    return {
        showCamerasMap: filters.showCamerasMap
    }
};

export default connect(mapStateToProps)(MallsAdminComponent)
